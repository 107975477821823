import { render, staticRenderFns } from "./ChangePassword.vue?vue&type=template&id=ef54c8aa&scoped=true"
import script from "./ChangePassword.vue?vue&type=script&lang=js"
export * from "./ChangePassword.vue?vue&type=script&lang=js"
import style0 from "./ChangePassword.vue?vue&type=style&index=0&id=ef54c8aa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef54c8aa",
  null
  
)

export default component.exports