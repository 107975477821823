<template>
  <div class="content">
    <LoadingOverlay :loading="Isloading" />
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <md-card-header data-background-color="green">
            <div
              class="card-header d-flex justify-content-between align-items-center"
            >
              <h4 class="title">My Task Details</h4>
              <div class="ms-auto">
                <button
                  type="button"
                  title="Change Status"
                  class="btn btn-primary btn-sm ms-2"
                  @click="editTaskStatus(task.TaskID)"
                  :disabled="task.IsCompleted && task.TaskStatus === 4"
                >
                  Change Status
                </button>
                <button
                  hidden
                  type="button"
                  data-bs-toggle="modal"
                  title="Change Status"
                  data-bs-target="#taskEditStatusModal"
                  data-bs-whatever="@mdo"
                  class="btn btn-primary"
                  id="openEditStatusModal"
                >
                  Change Status
                </button>
                <button
                  type="button"
                  class="btn btn-dark btn-sm ms-2"
                  @click="goBack"
                  title="Back"
                >
                  Back
                </button>
              </div>
            </div>
          </md-card-header>
          <md-card-content>
            <!-- Display success alert -->
            <div
              v-if="successAlertOnSubmit"
              class="alert alert-success text-left"
              role="alert"
            >
              {{ successAlertOnSubmit }}
              <span @click="hideTaskAlert" class="close-icon">&times;</span>
            </div>
            <div v-if="formHeader" class="alert  alert-danger text-left">
                {{ formHeader }}
                <span @click="hideTaskAlert" class="close-icon">&times;</span>
              </div>
            <div v-if="loading">
              <div class="row">
                <div class="col-md-4 mt-2">
                  <div class="task-detail-row">
                    <span class="task-detail-label">Title:</span>
                    <p class="task-detail-value">{{ task.Title }}</p>
                  </div>
                  <div class="task-detail-row">
                    <span class="task-detail-label">Priority:</span>
                    <div
                      class="progress task-detail-value"
                      style="width: 55%; margin-top: 3px"
                    >
                      <div
                        v-if="task.PriorityName == 'Low'"
                        title="Low"
                        class="progress-bar bg-info"
                        role="progressbar"
                        style="width: 100%"
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {{ task.PriorityName }}
                      </div>
                      <div
                        v-else-if="task.PriorityName == 'Medium'"
                        title="Medium"
                        class="progress-bar bg-warning"
                        role="progressbar"
                        style="width: 100%; color: black"
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {{ task.PriorityName }}
                      </div>
                      <div
                        v-else-if="task.PriorityName == 'High'"
                        title="High"
                        class="progress-bar bg-danger"
                        role="progressbar"
                        style="width: 100%"
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {{ task.PriorityName }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="task-detail-row">
                    <span class="task-detail-label">Status:</span>
                    <div
                      class="progress task-detail-value"
                      style="width: 55%; margin-top: 3px"
                    >
                      <div
                        v-if="task.TaskStatus === 1"
                        title="Not Assigned"
                        class="progress-bar"
                        role="progressbar"
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style="background: none; color: black;width: 100%;"
                      >
                        Not Assigned
                      </div>
                      <div
                        v-else-if="task.TaskStatus === 2"
                        title="Assigned"
                        class="progress-bar"
                        role="progressbar"
                        style="width: 100%"
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        New Task
                      </div>
                      <div
                        v-else-if="task.TaskStatus === 3"
                        title="In Process"
                        class="progress-bar"
                        role="progressbar"
                        style="width: 100%"
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        In Process
                      </div>
                      <div
                        v-else-if="task.TaskStatus === 4"
                        title="Completed"
                        class="progress-bar bg-success"
                        role="progressbar"
                        style="width: 100%"
                        aria-valuenow="75"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        Completed
                      </div>
                    </div>
                  </div>

                  <!-- <div class="task-detail-row pt-2">
                    <span class="task-detail-label">Category:</span>
                    <p class="task-detail-value">{{ task.CategoryName }}</p>
                  </div> -->
                  <div class="task-detail-row">
                    <span class="task-detail-label">Due Date:</span>
                    <p class="task-detail-value">
                      {{ task.DueDate }}
                    </p>
                  </div>
                </div> 
                <div class="col-md-4 mt-2">
                  <div class="task-detail-row">
                    <span class="task-detail-label">Assigned By:</span>
                    <p class="task-detail-value">
                      {{ task.UserName }}
                    </p>
                  </div>
                  <div class="task-detail-row">
                    <span class="task-detail-label">Assigned Date:</span>
                    <p class="task-detail-value">
                      {{ formatAssignedDate(task.CreatedDate) }}
                    </p>
                  </div>
                </div>
                <div class="col-md-12 mb-2" >
                  <div class="row">
                    <div class="col-12">
                      <p><span class="task-detail-label">Description:</span>
                      <span class="task-detail-value">{{ task.Description }}</span></p>
                    </div>
                  </div>

                </div>
                <!-- <div class="col-md-4">
              
                  <div class="task-detail-row">
                    <span class="task-detail-label">Due Date:</span>
                    <p class="task-detail-value">
                      {{ task.DueDate }}
                    </p>
                  </div>
                </div> -->
                <div class="col-md-12"  v-if="task.IsCompleted && task.Comments!=null &&  task.Comments!=''">
                  <div class="row">
                    <div class="col-12"><p><span class="task-detail-label">Comments:</span><span class="task-detail-value">{{ task.Comments }}</span></p></div>
                  </div>
                </div>
                <div class="col-md-6" v-if="task.InfoFile!='null' && task.InfoFile!='' && task.InfoFile!=null">
                  <div class="row">
                    <div class="task-detail-row">
                    <div class="col-md-3">
                      <span class="task-detail-label">Attachment:</span>
                    </div>
                    <div class="col-md-9">
                      <p v-if="imageURLValid && task.InfoFile!=null" class="task-detail-value">
                        <a :href="task.InfoFile" target="blank" class="browser-link">
                      <img :src="task.InfoFile" class="image-link" alt="Image"></a>
                    </p>
                          <p v-if="!imageURLValid && task.InfoFile!=null" class="file-link task-detail-value">
                            <a :href="task.InfoFile" target="blank">File Link</a> 
                          </p>
                    </div>
                  </div>
                  </div>
                </div>
                <div class="col-md-6" v-if="task.IsCompleted && task.UserAttachment!=null">
                  <div class="task-detail-row">
                    <span class="task-detail-label" style="width:40%;">User Attachment:</span>
                    <p v-if="UserimageURLValid && task.UserAttachment!=null" class="task-detail-value">
                        <a :href="task.UserAttachment" target="blank" class="browser-link">
                      <img :src="task.UserAttachment" class="image-link" alt="Image" style="width:70% ;"></a>
                    </p>
                          <p v-if="!UserimageURLValid && task.UserAttachment!=null" class="file-link task-detail-value">
                            <a :href="task.UserAttachment" target="blank">File Link</a> 
                          </p>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div><span>Loading...</span></div>
            </div>
            <!-- Modal for Edit Task Status -->
            <div
              class="modal fade"
              id="taskEditStatusModal"
              tabindex="-1"
              aria-labelledby="taskEditStatusModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="taskEditStatusModalLabel">
                      Edit Task Status
                    </h1>
                    <button
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      tabindex="-1"
                      title="Close"
                    ></button>
                  </div>
                  <form @submit.prevent="submitEditTaskStatusForm1">
                    <input
                      v-model="newTask.TaskID"
                      type="hidden"
                      class="form-control"
                    />
                    <div class="modal-body">
                    
                      <div class="row">
                        <div class="col-md-6">
                          <div class="mb-2">
                            <label for="title" class="col-form-label pb-0"
                              >Title:</label
                            >
                            <input
                              v-model="newTask.Title"
                              type="text"
                              class="form-control pt-1"
                              :disabled="isInputDisabled"
                              style="border: 0 !important;"
                              id="title"
                              tabindex="1"
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-2">
                            <label for="priority" class="col-form-label pb-0"
                              >Priority:</label
                            >
                            <select
                              v-model="newTask.PriorityID"
                              class="form-control pt-1"
                              id="priority"
                              tabindex="3"
                              style="border: 0 !important;"
                              :disabled="isInputDisabled"
                              @change="isValidTaskForm"
                            >
                              <option :value="0">Select Priority</option>
                              <option
                                v-for="priorityOption in priorityDropdownOptions"
                                :key="priorityOption.PriorityID"
                                :value="priorityOption.PriorityID"
                              >
                                {{ priorityOption.Name }}
                              </option>
                            </select>
                            <span class="text-red-500 text-sm">{{
                              taskValidate.PriorityID
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <!-- <div class="col-md-6">
                          <div class="mb-2">
                            <label for="category" class="col-form-label pb-0"
                              >Category:</label
                            >
                            <select
                              v-model="newTask.CategoryID"
                              class="form-control pt-1"
                              id="category"
                              tabindex="4"
                              style="border: 0 !important;"
                              :disabled="isInputDisabled"
                              @change="isValidTaskForm"
                            >
                              <option :value="0">Select Category</option>
                              <option
                                v-for="categoryOption in categoryDropdownOptions"
                                :key="categoryOption.CategoryID"
                                :value="categoryOption.CategoryID"
                              >
                                {{ categoryOption.CategoryName }}
                              </option>
                            </select>
                            <span class="text-red-500 text-sm">{{
                              taskValidate.CategoryID
                            }}</span>
                          </div>
                        </div> -->
                        <div class="col-md-6">
                          <div class="mb-2">
                            <label for="dueDate" class="col-form-label pb-0"
                              >Due Date:</label
                            >
                            <!-- <input
                              v-model="newTask.DueDate"
                              type="date"
                              class="form-control pt-1"
                              id="dueDate"
                              tabindex="5"
                              style="border: 0 !important;"
                              :disabled="isInputDisabled"
                              @input="isValidTaskForm"
                            /> -->
                            <label style="font-weight:400;display: block;" class="col-form-label"
                              >{{ newTask.DueDate }}</label>
                            <span class="text-red-500 text-sm">{{
                              taskValidate.DueDate
                            }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label for="status" class="col-form-label"
                              >Status:</label
                            >
                            <select
                              v-model="newTask.TaskStatus"
                              class="dropdown"
                              id="status"
                              
                              tabindex="4"
                              @change="isValidTaskForm"
                            >
                              <option :value="2">Select Status</option>
                              <option value="3">In Process</option>
                              <option value="4">Completed</option>
                            </select>
                            <span class="text-red-500 text-sm">{{
                              taskValidate.TaskStatus
                            }}</span>
                          </div>
                        </div>
                        <div class="col-md-6" hidden>
                          <div class="mb-3">
                            <label for="isCompleted" class="col-form-label"
                              >Is Completed:</label
                            >
                            <div class="form-check form-switch">
                              <input
                                v-model="newTask.IsCompleted"
                                type="checkbox"
                                class="form-check-input"
                                id="isCompleted"
                                tabindex="6"
                                @input="isValidTaskForm"
                              />
                              <label class="form-check-label" for="isCompleted">
                                {{
                                  newTask.IsCompleted
                                    ? "Completed"
                                    : "Not Completed"
                                }}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary ml-2"
                        data-bs-dismiss="modal"
                        id="closeStatusModal"
                        title="Close"
                        tabindex="8"
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-bs-toggle="modal"
                        title="Update"
                        data-bs-target="#taskConfirmModal"
                        data-bs-whatever="@mdo"
                        tabindex="7"
                      >
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!-- Modal for Edit Task Status -->
            <div
              class="modal fade"
              id="taskConfirmModal"
              tabindex="-1"
              aria-labelledby="ConfirmModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-md">
                <div class="modal-content">
                 
                  <form @submit.prevent="submitEditTaskStatusForm">
                    <input
                      v-model="newTask.TaskID"
                      type="hidden"
                      class="form-control"
                    />
                    <div class="modal-body">
                      <div class="row">
                        <h4>
                          Are you sure you want to mark as
                          {{ this.funCheckStatus(newTask.TaskStatus) }}?
                        </h4>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary ml-2"
                        data-bs-dismiss="modal"
                        id="closeConfirmStatusModal"
                        title="No"
                        tabindex="8"
                      >
                        No
                      </button>
                      <button
                        type="submit"
                        class="btn btn-primary"
                        tabindex="7"
                        title="Yes"
                      >
                        Yes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import api, { setAuthToken } from "../apiConfig";
import { authMixin } from "./adminStore";
import Cookies from "js-cookie";
import { ErrorLog } from "../Common/APILog";
import LoadingOverlay from '../Common/blockoutpage.vue';
import { handleAuthorization, handleServerError } from "../Common/commonMethod";

export default {
  mixins: [authMixin],
  components: {
    LoadingOverlay
  },
  name: "myTaskDetail",
  props: {
    taskId: {
      validator(value) {
        const numberValue = parseInt(value);
        if (isNaN(numberValue)) {
          console.error('Invalid taskId prop: must be a number');
          return false;
        }
        return true;
      }
    }
  },
  // props: {
  //   taskId: {
  //     type: Number,
  //     required: true,
  //   },
  // },
  data() {
    return {
      task: [],
      imageURLValid:false,
      UserimageURLValid:false,
      isInputDisabled: true,
      Isloading:false,
      newSubTask: {
        SubTaskID: 0,
        TaskID: 0,
        Title: "",
        Description: "",
        IsCompleted: false,
        UserId: 0,
      },
      newTask: {
        TaskID: 0,
        Title: "",
        Description: "",
        DueDate: null,
        IsCompleted: false,
        TaskStatus: 1,
        PriorityID: 0,
        CategoryID: 0,
        UserId: 0,
      },
      taskValidate: {
        Title: "",
        Description: "",
        // Add additional validation fields as needed
      },
      priorityDropdownOptions: [],
      categoryDropdownOptions: [],
      title:"My Task Detail",
      handleTaskId: 0,
      loading: false,
      editTaskMode: false,
      editTaskStatusMode: false,
      successAlertOnSubmit: "",
      loadingTasks: "",
      taskFormHeader: "",
      formHeader:"",
      btnTaskEnable: false,
      flag:0,
    };
  },
  created() {
    // Fetch task details when the component is created
    this.fetchTaskDetails();
  },
  mounted() {
    this.getPriorityDropdown();
  },
  methods: {
    formatAssignedDate(originalDate) {
      if (!originalDate) return ""; // Handle null or undefined dates

      const dateObject = new Date(originalDate);
      const hour = dateObject.getHours().toString().padStart(2, "0");
      const minute = dateObject.getMinutes().toString().padStart(2, "0");
      const day = dateObject.getDate().toString().padStart(2, "0");
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObject.getFullYear();
      return `${day}/${month}/${year} ${hour}:${minute}`;
    },
    isUserImageURL(url){
  const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp'];
  const extension = url.substring(url.lastIndexOf('.')).toLowerCase();
  console.log(imageExtensions.includes(extension));
  if(imageExtensions.includes(extension)){
    this.UserimageURLValid=true;
  }
  //return imageExtensions.includes(extension);
},
          // Check if URL ends with a common image file extension
 isImageURL(url) {
  const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp'];
  const extension = url.substring(url.lastIndexOf('.')).toLowerCase();
  console.log(imageExtensions.includes(extension));
  if(imageExtensions.includes(extension)){
    this.imageURLValid=true;
  }
  //return imageExtensions.includes(extension);
},
    goBack() {
      // Go back to the previous page in the history
      this.$router.go(-1);
    },
    editTaskStatus(taskId) {
      this.editTaskMode = false;
      this.editTaskStatusMode = true;
      this.getTaskById(taskId);
    },
    async submitEditTaskStatusForm() {
      this.CompleteTask(this.newTask.TaskID, this.newTask.TaskStatus);
    },
    funCheckStatus(StatusID) {
      let tStatus = "not assigned"; 
      if (StatusID == 2) {
        tStatus = "new task";
      } else if (StatusID == 3) {
        tStatus = "in process";
      } else if (StatusID == 4) {
        tStatus = "completed";
      }
      return tStatus;
    },
    async getPriorityDropdown() {
      try {
        this.Isloading=true;
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        const response = await api.get(
          "/api/admin/tasks/get-priority-dropdown"
        );
        if (response.data) {
          this.priorityDropdownOptions = response.data;
        } else {
          // Handle case where no priorities are found
        }
      } catch (error) {
        // Handle API error
        if(this.flag>3){
          if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.formHeader = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.formHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 674, error.stack, "AdminPanel/myTaskDetails/getPriorityDropDown()");
          }
        }
        else {
          this.formHeader = "Something went wrong.";
        }
        }
        else{
          this.flag++;
        setTimeout(() => {
        this.getPriorityDropdown(); // Retry after a delay
      }, 1000);
    } 
      }
      finally{
        this.Isloading=false;
        setTimeout(() => {
          this.successAlertOnSubmit="";
          this.formHeader="";
        }, 5000); // 5 seconds in milliseconds
      }
    },
    async getTaskById(taskId) {
      try {
        this.Isloading=true;
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        const response = await api.get(
          `/api/admin/tasks/get-task-by-id/${taskId}`
        );
        if (response.data) {
          console.log("res", response.data);
          this.newTask = response.data.data[0];
          this.newTask.DueDate = this.formatDueDate(
            response.data.data[0].DueDate,
            "update"
          );
          if (this.editTaskMode) {
            this.openModal();
          }
          if (this.editTaskStatusMode) {
            this.openEditStatusModal();
          }

          this.resetTaskValidation();
        } else {
          this.formHeader=response.statusText;
        }
      } catch (error) {
        console.log(error);
        if(this.flag>3){
          if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.formHeader = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.formHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 740, error.stack, "AdminPanel/myTaskDetails/getTaskById()");
          }
        }
        else {
          this.formHeader = "Something went wrong.";
        }
        }
        else{
          this.flag++;
        setTimeout(() => {
        this.getTaskById(taskId); // Retry after a delay
      }, 1000);
    } 
      }
      finally{
        this.Isloading=false;
        setTimeout(() => {
          this.successAlertOnSubmit="";
          this.formHeader="";
        }, 5000); // 5 seconds in milliseconds
      }
    },
    closeModal() {
      this.editMode = false;
      this.resetTaskValidation();
      document.getElementById("closeModal").click();
    },
    openTaskModal(refresh = false) {
      if (refresh) {
        this.editTaskMode = false;
        this.newSubTask = {
          SubTaskID: 0,
          Title: "",
          Description: "",
          UserId: 0,
          IsCompleted: false,
        };
        this.resetTaskValidation();
      }
    },
    openModal() {
      document.getElementById("openModal").click();
    },
    openEditStatusModal() {
      document.getElementById("openEditStatusModal").click();
    },
    resetTaskValidation() {
      this.newSubTask = {
        SubTaskID: 0,
        Title: "",
        Description: "",
        UserId: 0,
        IsCompleted: false,
      };
    },
    truncateDescription(description) {
      // Truncate the description if it's longer than 30 characters
      return description.length > 30
        ? description.slice(0, 30) + "..."
        : description;
    },
    formatDueDate(originalDate, type = "show") {
      if (!originalDate) return ""; // Handle null or undefined dates

      const dateObject = new Date(originalDate);
      const day = dateObject.getDate().toString().padStart(2, "0");
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObject.getFullYear();
      if (type == "show") {
        return `${day}/${month}/${year}`;
      } else {
        return `${day}/${month}/${year}`;
      }
    },
    closeConfirmStatusModal() {
      document.getElementById("closeConfirmStatusModal").click();
    },
    async CompleteTask(taskId, status) {
      try {
        this.Isloading=true;
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        // Make your delete API call here
        const response = await api.post(`/api/admin/tasks/complete-task`, {
          TaskID: taskId,
          UserID: userCookie.UserID,
          Status: status,
        }); // Replace with your actual API endpoint

        if (response.data.success) {
          this.fetchTaskDetails();

          this.closeConfirmStatusModal();
          let tStatus = "not assigned";
          if (status == 2) {
            tStatus = "new task";
          } else if (status == 3) {
            tStatus = "in process";
          } else if (status == 4) {
            tStatus = "completed";
          }
          this.successAlertOnSubmit = `Task marked as ${tStatus} successfully.`;
        } else {
          alert(response.data.msg);
        }

        // Optionally, perform additional actions after successful deletion
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.formHeader = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.formHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 865, error.stack, "AdminPanel/myTaskDetails/CompleteTask()");
          }
        }
        else {
          this.formHeader = "Something went wrong.";
        }
     
      }
      finally{
        this.Isloading=false;
        setTimeout(() => {
          this.successAlertOnSubmit="";
          this.formHeader="";
        }, 5000); // 5 seconds in milliseconds
      }
    },
    // Fetch task details based on taskId
    async fetchTaskDetails() {
      try {
        this.task = [];
        this.Isloading=true;
        this.loading = false;
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        const response = await api.get(
          `/api/admin/tasks/get-task-by-id/${this.taskId}`
        );
        if (response.data) {
          console.log("res",response.data.data[0]);
          this.task = response.data.data[0];
          if(this.task.InfoFile!=null){
            this.isImageURL(this.task.InfoFile);
          }
          if(this.task.UserAttachment!=null){
            this.isUserImageURL(this.task.UserAttachment);
          }
          this.task.DueDate = this.formatDueDate(
            response.data.data[0].DueDate,
            "show"
          );
          this.handleTaskId = response.data.data[0].TaskID;
        } else {
          //alert(`Error: ${response.statusText}`);
        }
      } catch (error) {
        if(this.flag>3){
          if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.formHeader = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.formHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 926, error.stack, "AdminPanel/myTaskDetails/fetchTaskDetails()");
          }
        }
        else {
          this.formHeader = "Something went wrong.";
        }
        }
        else{
          this.flag++;
        setTimeout(() => {
        this.fetchTaskDetails(); // Retry after a delay
      }, 1000);
    }
      } finally {
        this.Isloading=false;
        this.loading = true;
        setTimeout(() => {
          this.successAlertOnSubmit="";
          this.categoryFormHeader="";
        }, 5000); // 5 seconds in milliseconds
      
      }
    },
    hideTaskAlert() {
      this.successAlertOnSubmit = "";
      this.formHeader="";
    },
    isValidTaskForm() {
      let isValid = true;

      // Title
      if (!this.newSubTask.Title.trim()) {
        this.taskValidate.Title = "Title is required.";
        isValid = false;
      }

      // Description
      if (!this.newSubTask.Description.trim()) {
        this.taskValidate.Description = "Description is required.";
        isValid = false;
      }

      return isValid;
    },
  },
};
</script>

<style scoped>
/* Add Tailwind CSS classes here if needed */
.close-icon {
  cursor: pointer;
  float: right;
  font-size: 30px;
  margin-top: -10px;
}
.mb-3 {
  text-align: left;
}
.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1em;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.table th {
  background-color: #f2f2f2;
}

/* Additional styling for left and right padding */
.table th,
.table td {
  padding-left: 12px;
  padding-right: 12px;
}

.actionBtns {
  text-align: right !important;
}

.task-details-card {
  /* Add styling for the task details card */
  margin-top: 2rem;
}

.task-details-header {
  /* Add styling for the card header */
  background-color: #4caf50; /* Use your preferred color */
  color: #fff;
  padding: 1rem;
}

.task-details-body {
  /* Add styling for the card body */
  padding: 1rem;
}

.task-detail-row {
  /* Add styling for each detail row */
  display: -webkit-box !important;
  -webkit-box-pack: start;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.task-detail-label {
  /* Add styling for the label within a detail row */
  font-weight: bold;
  font-size: 16px;
}
.task-detail-value {
  font-size: 16px;
  margin-left: 10px;
}

.taskNotAssigned {
  color: orange;
}
.alreadyAssignedText {
  color: #ed6524;
  font-size: 15px;
  text-align: center;
}

.taskAssigned {
  color: #ed6524;
}
.taskInProcess {
  color: #864426;
}

.taskCompleted {
  color: green;
}

.user-list {
  min-height: 120px;
  max-height: 120px; /* Set a maximum height for the user list */
  overflow-y: auto; /* Add a scrollbar when the content exceeds the height */
  border: 1px solid #ccc; /* Optional: Add a border for styling */
}

.user-item {
  margin-bottom: 8px; /* Optional: Add some spacing between user items */
}

.progress {
  font-size: 13px !important;
}
.image-link{
  width:75%;
  margin:0px auto;
}
.file-link{
  margin-bottom: 0px;
}
.file-link a{
  color:blue !important;
  font-weight: 500;
}
.modal-content .modal-header{
  padding-top:10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.modal-title{
  margin-top:10px;
}
.modal-content .modal-body{
  padding-top:10px;
}
.dropdown{
  width:100%;
  height: 36px;
  padding: 7px 0;
  font-size: 14px;
  line-height: 1.428571429;
  border-color:#D2D2D2;
}
</style>
