<template> 
  <div>
    <div class="container h-100">
      <div class="d-flex justify-content-center h-100">
        <div class="user_card">
          <div class="d-flex justify-content-center">
          
            <div class="logoDiv">
              <img
                src="../assets/img/trackerLogo_B.png"
                alt="Logo"
                class="mb-2"
                style="height: 50px"
              />
            </div>
          </div>
          <div class="d-flex justify-content-center form_container">
            <div v-if="formHeader" class="error-message text-red-500 mb-2">
              {{ formHeader }}
            </div>
            <div v-if="formHeaderSuccess" class="success-message mb-2">
              {{ formHeaderSuccess }}
            </div>
            <form @submit.prevent="ResetPassword">
              <!-- Vue.js bindings for input fields -->
              <div class="input-group mb-1">
                <div class="input-group-append">
                  <span class="input-group-text"
                    ><i class="fa fa-envelope"></i></span>
                </div>
                <input
                  v-model="email"
                  tabindex="1"
                  type="text"
                  name=""
                  class="form-control input_user"
                  placeholder="Enter your Email"
                />
              </div>
              <span class="text-red-500 text-sm">{{
                userValidate.email
              }}</span>
              <div class="d-flex justify-content-center mt-3 login_container">
                <button
                  :disabled="btnDisabled"
                  type="submit"
                  name="button"
                  class="btn login_btn"
                  title="Submit"
                  default
                  tabindex="3"
                >
                  <img src="../assets/img/loading.gif" class="processimg" id="processimg">Submit
                </button>
              </div>
            </form>
          </div>
          <div class="mt-3 mb-3">
            <div class="d-flex justify-content-center links">
            <a :href=this.url style="color: blue !important;">Back to Login</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "./apiConfig";
import { setAuthToken } from "../components/apiConfig";
import ErrorLog from "./superAdmin/ErrorLog.vue";

// Get the value of the 'type' parameter from the URL
const queryParams = new URLSearchParams(window.location.search);
const type = queryParams.get('type');

export default {
  name: "ForgotPassword",
  components: {
  },
  props: {
    msg: String,
  },
  data() {
    return {
      type:type,
      url:this.loginUrl(),
      email: "",
      formHeaderSuccess:"",
      formHeader: "",
      btnDisabled: false,
      userValidate: {
        email: "",
      },
    };
  },
  methods: {
    isValidEmail(email) {
      // A simple email validation function, you can replace it with a more robust one if needed
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    loginUrl() {
            // Get the value of the 'type' parameter from the URL
            const queryParams = new URLSearchParams(window.location.search);
            this.type = queryParams.get('type');
            // Construct the login URL based on the type parameter
            if(this.type==='SuperAdmin'){
              return "/adminLogin";
            }
            else if(this.type==='Admin'){
              return "/login"
            }
            else{
              return "/login"
            }
        },
    isValidTaskForm() {
      let isValid = true;
      this.formHeaderSuccess="";
      if (!this.email.trim()) {
        this.userValidate.email = "email is required.";
        isValid = false;
      }
      else if (!this.isValidEmail(this.email.trim())) {
        this.userValidate.email = "Please enter valid email address.";
        isValid = false;
      } else {
        this.userValidate.email = "";
      }
      return isValid;
    },
    async performForgotPassword() {
      try {
        // Get the value of the 'type' parameter from the URL
        const queryParams = new URLSearchParams(window.location.search);
        this.type = queryParams.get('type');
        this.btnDisabled = true;
        document.getElementById("processimg").style.display = "inline";
        let response;
        if(this.type==="SuperAdmin"){
         response = await api.put(`/api/superAdmin/reset-password/${this.email}`);
        }
        else{
           response = await api.put(`/api/users/reset-password/${this.email}`);
        }
        if (response.data.success) {
          // Handle success, for example, show a success message or redirect to another page
          this.formHeaderSuccess = "Reset Password   link sent.";
          // You can also perform any additional logic here
        } else {
          // Handle failure, show an error message
          this.formHeader = response.data.msg;
          // You can also handle specific cases or perform additional actions
        }
      } catch (error) {
        console.log(error);
        if (error.response && error.response.data) {
          // Handle HTTP error response from the server
          const jsonResponseString = JSON.stringify(error.response.data);
          try {
            const jsonObject = JSON.parse(jsonResponseString);
            this.formHeader = jsonObject.message;
          } catch (parseError) {
            this.formHeader = parseError.message;
      
            ErrorLog(error.message, error.response.status, 166, error.stack, "ForgotPassword/performForgotPassword")
          }
        } else {
          // Handle other types of errors, e.g., network errors
          this.formHeader = "Something went wrong.";
        }
      } finally {
        // Ensure to re-enable the button regardless of success or failure
        this.btnDisabled = false;
        document.getElementById("processimg").style.display = "none";
      }
    },

    ResetPassword() {
      if (this.isValidTaskForm()) {
        this.formHeader = "";

        this.formHeaderSuccess="";
        this.userValidate.email = "";
        // Call the performLogin method
        this.performForgotPassword();
      }
    },
  },
};
</script>

<style scoped>
/* Add Tailwind CSS classes here if needed */

.shadow-2-strong {
  min-width: 500px !important;
}
.container {
  justify-content: center !important;
  padding-top: 8%;
}
.links{
  text-align: center;
  font-weight: 500;
  color:blue;
}
.processimg{
  height:25px;
  margin-right:5px;
  display: none;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.success-message{
  color:green;
  text-align: center;
  font-weight: 500;
}
.form-control {
  padding: 7px 7px !important;
  height: 28px !important;
  background-color: white;
}

.logoDiv {
  height: 100px; /* Set a fixed height for logoDiv */
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid;
  text-align: center;
}

/* Coded with love by Mutiullah Samim */
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  background: #60a3bc !important;
}
.user_card {
  height: 100%;
  width: 350px;
  margin-top: auto;
  margin-bottom: auto;
  background: lightblue;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}
.brand_logo_container {
  position: absolute;
  height: 170px;
  width: 170px;
  top: -75px;
  border-radius: 50%;
  background: #60a3bc;
  padding: 10px;
  text-align: center;
}
.brand_logo {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  border: 2px solid white;
}
.form_container {
  margin-top: 30px;
}
.login_btn {
  width: 100%;
  background: #020272 !important;
  color: white !important;
}
.login_btn:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.login_container {
  padding: 0 2rem;
}
.input-group-text {
  background: #020272 !important;
  color: white !important;
  border: 0 !important;
  border-radius: 0.25rem 0 0 0.25rem !important;
}
.input_user,
.input_pass:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #c0392b !important;
}

.d-flex {
  display: block !important; /* or display: inline-block; or any other desired value */
  padding: 0px 10px 0px 10px;
}

.error-message {
  text-align: center;
  font-size: 15px;
  margin-top: -10px;
  font-style: italic;
}

.text-red-500 {
  font-style: italic;
}
</style>