<template>
  <div class="content">
    <LoadingOverlay :loading="Isloading" />
    <div class="md-layout">
      <div class="md-layout-item md-large-size-100 md-size-66">
        <form @submit.prevent="changePassword">
          <md-card>
            <md-card-header data-background-color="green">
              <h4 class="title">Change Password</h4>
              <!-- <p class="category">Change your password</p> -->
            </md-card-header>

            <md-card-content>
              <div v-if="successAlertOnSubmit" class="alert alert-success text-left" role="alert">
                {{ successAlertOnSubmit }}
                <span @click="hideAlert" class="close-icon">&times;</span>
              </div>
              <div v-if="formHeader" class="alert  alert-danger text-left">
                {{ formHeader }}
                <span @click="hideAlert" class="close-icon">&times;</span>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-33">
                  <md-field style="margin-bottom: 7px !important;">
                    <label>Current Password</label>
                    <md-input v-model="currentPassword" type="password" maxlength="15" tabindex="1"></md-input>
                  </md-field>
                  <p v-if="errors.currentPassword" class="text-red-500 text-sm text-left mt-1">
                    {{ errors.currentPassword }}
                  </p>
                </div>
                <div class="md-layout-item md-small-size-100 md-size-33">
                  <md-field style="margin-bottom: 7px !important;">
                    <label>New Password</label>
                    <md-input v-model="newPassword" type="password" maxlength="15" tabindex="2"></md-input>
                  </md-field>
                  <p v-if="errors.newPassword" class="text-red-500 text-sm text-left mt-1">
                    {{ errors.newPassword }}
                  </p>
                  <!-- <div id="message" class="mt-3">
                      <p style="color:red;"><b>New Password at least 8 characters and contain at least one special character.</b></p>
                      <span id="length" class="invalid">Minimum <b>8 characters</b></span>
                      <span id="capital" class="invalid">A <b>Uppercase</b> and A <b>lowercase</b> letter</span>
                      <span id="number" class="invalid">A <b>number</b> and A <b>Special Symbol</b></span>
                 </div> -->
                </div>
                <div class="md-layout-item md-small-size-100 md-size-33">
                  <md-field style="margin-bottom: 7px !important;">
                    <label>Confirm Password</label>
                    <md-input v-model="confirmPassword" type="password" maxlength="15" tabindex="3"></md-input>
                  </md-field>
                  <p v-if="errors.confirmPassword" class="text-red-500 text-sm text-left mt-1">
                    {{ errors.confirmPassword }}
                  </p>
                </div>
                <div class="md-layout-item md-size-100 text-right" style="margin-top: 12px !important;">
                  <md-button type="submit" class="md-raised md-success" tabindex="4" title="Change Password">Change
                    Password</md-button>
                  <button hidden type="button" data-bs-toggle="modal" data-bs-target="#UserConfirmPasswordModal"
                    data-bs-whatever="@mdo" class="btn btn-primary" title="Change Password" id="openConfirmModal">
                    Change Password
                  </button>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </form>
      </div>
    </div>
    <!-- Modal for confirm change password -->
    <div class="modal fade" id="UserConfirmPasswordModal" tabindex="-1" aria-labelledby="UserConfirmPasswordModal"
      aria-hidden="true">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <form @submit.prevent="submitConfirmForm">

            <div class="modal-body">
              <div class="row">
                <h4>
                  Are you sure you want to change password?
                </h4>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary ml-2" data-bs-dismiss="modal" id="closeModal" tabindex="8"
                title="No">
                No
              </button>
              <button type="submit" class="btn btn-primary" tabindex="7" :disabled="btnEnable" title="Yes"
                @click="performChangePassword()">
                Yes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import api, { setAuthToken } from "../apiConfig";
import { authMixin } from "./adminStore";
import Cookies from "js-cookie";
import { ErrorLog } from "../Common/APILog";
import showAlert from "../Common/sweetAlert";
import LoadingOverlay from '../Common/blockoutpage.vue';
import { handleAuthorization, handleServerError } from "../Common/commonMethod";


export default {
  mixins: [authMixin],
  created() {
    this.checkAuthorization(); // Check authorization when the component is created
  },
  name: "ChangePassword",
  components: {
    LoadingOverlay
  },
  data() {
    return {
      currentPassword: "",
      Isloading: false,
      successAlertOnSubmit: "",
      newPassword: "",
      confirmPassword: "",
      errors: {},
      formHeader: "",
      btnEnable: false,
      name: "Change Password",
    };
  },
  computed: {
    userId() {
      return this.$store.getters.userId;
    },
    userToken() {
      return this.$store.getters.userToken;
    },
  },
  mounted() {
  },
  methods: {
    hideAlert() {
      //this.taskFormHeader = "";
      this.formHeader = "";
      this.successAlertOnSubmit = "";
    },
    closeModal() {
      document.getElementById("closeModal").click();

    },
    async performChangePassword() {
      try {
        this.btnEnable = true;
        this.Isloading = true;
        let userCookie = await Cookies.get("user");
        userCookie = await userCookie ? JSON.parse(userCookie) : null;
        const uId = userCookie.UserID;
        const authToken = await Cookies.get("jwt");
        setAuthToken(authToken);

        const response = await api.post("/api/auth/admin-change-password", {
          userId: uId,
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
        });

        // Assuming the API response structure includes a 'success' property
        if (response.data.success) {
          // Password change was successful
          setAuthToken();
          showAlert("success", "Password has been changed successfully.", false, 7000)
          this.$router.push("/login");
        } else {
          // Password change failed, set error message
          this.formHeader = response.data.message || "Unable to change the Password.";
        }
      } catch (error) {
        //console.log(error);
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.formHeader = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.formHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 252, error.stack, "AdminPanel/ChangePassword/performChangePassword()");
          }
        }
        else {
          this.formHeader = "Something went wrong.";
        }
      }
      finally {
        this.btnEnable = false;
        this.Isloading = false;
        setTimeout(() => {
          this.formHeader = "";
          this.successAlertOnSubmit = "";
        }, 5000); // 5 seconds in milliseconds
        this.closeModal();
      }
    },
    changePassword() {
      this.formHeader = "";
      // Validation logic
      this.errors = {};

      if (!this.currentPassword) {
        this.errors.currentPassword = "Current Password is required.";
      }
      if (!this.newPassword) {
        this.errors.newPassword = "New Password is required.";
      }
      if (this.newPassword.length < 8 && this.newPassword) {
        this.errors.newPassword = "New Password must be minimum of 8 characters.";
      }
      else if (this.newPassword.length >= 8) {
        var lowerCaseLetters = /[a-z]/g;
        var upperCaseLetters = /[A-Z]/g;
        var number = /[0-9]/g;
        var special = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/;
        if (!this.newPassword.match(lowerCaseLetters)) {
          this.errors.newPassword = "New Password must contain lowercase letter.";
        }
        if (!this.newPassword.match(upperCaseLetters)) {
          this.errors.newPassword = "New Password must contain capital letter.";
        }
        if (!this.newPassword.match(number)) {
          this.errors.newPassword = "New Password must contain number.";
        }
        if (!this.newPassword.match(special)) {
          this.errors.newPassword = "New Password must contain special character.";
        }

      }
      if (!this.confirmPassword) {
        this.errors.confirmPassword = "Confirm Password is required.";
      } else if (this.confirmPassword !== this.newPassword) {
        this.errors.confirmPassword = "Confirmed Password didn't match with the new password.";
      }

      // If there are no validation errors, you can proceed with the change password logic
      if (Object.keys(this.errors).length === 0) {
        //this.performChangePassword();
        document.getElementById("openConfirmModal").click();
      }
    },
  },
};
</script>

<style scoped>
/* Add Tailwind CSS classes here if needed */
#message span {
  display: block;
}

.close-icon {
  cursor: pointer;
  float: right;
  font-size: 30px;
  margin-top: -10px;
}
</style>
