<!-- src/components/Header.vue -->
<template>
  <header>
    <div class="logo-container">
      <a href="#" class="logo-link">
        <img :src="imgLogo" alt="Logo" class="logo-img" />
      </a>
    </div>
    <!-- Add any other header content or navigation links -->
  </header>
</template>

<script>
export default {
  name: "HeaderView",
  props:{
    imgLogo: {
      type: String,
      default: require("@/assets/img/trackerLogo_W.png"),
    },
  }
};
</script>

<style scoped>
/* Add your header styles here */
header {
  background-color: #3e6d2c;
  color: #fff;
  padding: 10px;
  padding-top: 10px;
  text-align: left;
}

.logo-container {
  position: relative;
  padding: 10px 0;
  /* z-index: 4; */
  text-align: left;
}
.logo-container:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 15px;
  height: 1px;
  width: calc(100% - 30px);
  background-color: hsla(0, 0%, 71%, 0.3);
}

.logo-img {
  width: 10%;
}
</style>
