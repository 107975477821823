<template>
  <div
    class="sidebar"
    :data-color="sidebarItemColor"
    :data-image="sidebarBackgroundImage"
    :style="sidebarStyle"
  >
    <!-- <div class="logo">
      <a href="#" class="simple-text logo-mini">
        <div class="logo-img">
          <img :src="imgLogo" alt="" />
        </div>
      </a>

      <a href="javascript:void(0)" class="simple-text logo-normal">
        {{ headerTitle }}
      </a> 
    </div> -->
    <div class="logo-container">
      <a href="#" class="logo-link">
        <img :src="imgLogo" alt="Logo" class="logo-img" />
      </a>
    </div>
    <div class="sidebar-wrapper">
      <slot name="content"></slot>
      <md-list class="nav">
        <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
        <slot>
          <sidebar-link
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :to="link.path"
            :link="link"
          >
          </sidebar-link>
        </slot>
      </md-list>
    </div>
  </div>
</template>
<script>
import SidebarLink from "./SidebarLink.vue";
import Cookies from "js-cookie";

export default {
  components: {
    SidebarLink,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    sidebarBackgroundImage: {
      type: String,
      default: require("@/assets/img/sidebar-2.jpg"),
    },
    imgLogo: {
      type: String,
      default: require("@/assets/img/trackerLogo_W.png"),
    },
    sidebarItemColor: {
      type: String,
      default: "green",
      validator: (value) => {
        let acceptedValues = ["", "purple", "blue", "green", "orange", "red"];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  computed: {
    sidebarStyle() {
      return {
        backgroundImage: `url(${this.sidebarBackgroundImage})`,
      };
    },
  },
  created() {
    this.changeSidebarTitle();
  },
  data() {
    return {
      headerTitle: "",
    };
  },
  methods: {
    changeSidebarTitle() {
      let userCookie = Cookies.get("user");
      userCookie = userCookie ? JSON.parse(userCookie) : null;
      if (userCookie) {
        if (userCookie.UserType === "Admin") {
          this.headerTitle = "Admin";
        } else {
          this.headerTitle = "User";
        }
      } else {
        this.headerTitle = "Tasks Manager";
      }
    },
  },
};
</script>
<style>
@media screen and (min-width: 991px) {
  .nav-mobile-menu {
    display: none;
  }
}

.logo-container {
  position: relative;
  padding: 15px 0;
  z-index: 4;
  text-align: center;
}

.logo-container:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 15px;
  height: 1px;
  width: calc(100% - 30px);
  background-color: hsla(0, 0%, 71%, 0.3);
}

.logo-img {
  width: 70%;
}
</style>
