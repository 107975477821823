<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <md-card-header data-background-color="green">
            <div
              class="card-header d-flex justify-content-between align-items-center"
            >
              <h4 class="title">To-Do Assignment</h4>
              <div class="ms-auto">
                <RouterLink
                  to="/task-management"
                  class="btn btn-dark btn-sm ml-2"
                >
                  Back
                </RouterLink>
                <button
                  type="button"
                  title="Assign New To-Do"
                  data-bs-toggle="modal"
                  data-bs-target="#taskModal"
                  class="btn btn-primary btn-sm"
                  @click="openTaskModal(true)"
                >
                  Assign New To-Do
                </button>

                <button
                  hidden
                  type="button"
                  title="Add New Task"
                  data-bs-toggle="modal"
                  data-bs-target="#taskModal"
                  data-bs-whatever="@mdo"
                  class="btn btn-primary"
                  id="openModal"
                >
                  Add New Task
                </button>
              </div>
            </div>
          </md-card-header>
          <md-card-content>
            <div class="col-md-12">
              <!-- Display success alert -->
              <div
                v-if="successAlertOnSubmit"
                class="alert alert-success text-left"
                role="alert"
              >
                {{ successAlertOnSubmit }}
                <span @click="hideTaskAlert" class="close-icon">&times;</span>
              </div>
              <div class="table-responsive">
                <div v-if="loadingTasks" class="text-center">
                  <p>{{ loadingTasks }}</p>
                </div>
                <md-table
                  v-if="!loadingTasks"
                  v-model="tasks"
                  :table-header-color="tableHeaderColor"
                >
                  <md-table-row
                    slot="md-table-row"
                    slot-scope="{ item }"
                    :class="{ 'expired-row': isRowExpired(item) }"
                  >
                    <md-table-cell md-label="Title">{{
                      item.Title
                    }}</md-table-cell>
                    <md-table-cell md-label="Assigned To">
                      <span
                        v-if="item.AssignedToUsernames.length > 25"
                        :title="item.AssignedToUsernames"
                      >
                        {{ truncateDescription(item.AssignedToUsernames) }}
                      </span>
                      <span v-else>
                        {{ item.AssignedToUsernames }}
                      </span>
                    </md-table-cell>
                    <md-table-cell md-label="Assign Date">{{
                      item.CreatedDate
                    }}</md-table-cell>
                    <md-table-cell md-label="Due Date">{{
                      formatDueDate(item.DueDate)
                    }}</md-table-cell>
                    <md-table-cell md-label="Status">
                      <span
                        v-if="item.TaskStatus === 1"
                        class="taskNotAssigned"
                      >
                        Not Assigned
                      </span>
                      <span
                        v-else-if="item.TaskStatus === 2"
                        class="taskAssigned"
                        >Assigned
                      </span>
                      <span
                        v-else-if="item.TaskStatus === 3"
                        class="taskInProcess"
                        >In Process
                      </span>
                      <span
                        v-else-if="item.TaskStatus === 4"
                        class="taskCompleted"
                        >Completed
                      </span>
                    </md-table-cell>
                    <md-table-cell md-label="Actions" class="actions">
                      <button
                        v-if="!item.IsCompleted"
                        @click="getTaskById(item.TaskId)"
                        type="button"
                        title="Edit Task"
                        class="btn btn-primary btn-sm ml-2"
                      >
                        Edit
                      </button>
                      <button
                        v-if="!item.IsCompleted"
                        @click="confirmDelete(item.TaskId)"
                        type="button"
                        title="Delete Task"
                        class="btn btn-danger btn-sm"
                      >
                        Delete
                      </button>
                    </md-table-cell>
                  </md-table-row>
                </md-table>
              </div>
            </div>

            <!-- Modal for Task -->
            <div
              class="modal fade"
              id="taskModal"
              tabindex="-1"
              aria-labelledby="taskModalLabel"
              aria-hidden="true"
              @hidden="handleTaskModalHidden"
            >
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="taskModalLabel">
                      {{ editTaskMode ? "Edit To-Do" : "Assign New To-Do" }}
                    </h1>
                    <button
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <form @submit.prevent="submitTaskForm">
                    <input
                      v-model="newTask.TaskID"
                      type="hidden"
                      class="form-control"
                    />
                    <div class="modal-body">
                      <div
                        v-if="taskFormHeader"
                        class="alert alert-danger text-left"
                        role="alert"
                      >
                        {{ taskFormHeader }}
                        <span @click="hideTaskAlert" class="close-icon"
                          >&times;</span
                        >
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="mb-3">
                                <label for="priority" class="col-form-label"
                                  >Select Tasks:</label
                                >
                                <select
                                  v-model="newTask.TaskID"
                                  class="form-control"
                                  id="task"
                                  tabindex="4"
                                >
                                  <option value="0">Please select task</option>
                                  <option
                                    v-for="taskOption in DropdownOptions.taskOPtions"
                                    :key="taskOption.TaskID"
                                    :value="taskOption.TaskID"
                                  >
                                    {{ taskOption.Title }}
                                  </option>
                                </select>
                                <span class="text-red-500 text-sm">{{
                                  taskValidate.TaskID
                                }}</span>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="mb-3">
                                <label for="dueDate" class="col-form-label"
                                  >Due Date:</label
                                >
                                <input
                                  v-model="newTask.DueDate"
                                  type="date"
                                  class="form-control"
                                  id="dueDate"
                                  tabindex="3"
                                />
                                <span class="text-red-500 text-sm">{{
                                  taskValidate.DueDate
                                }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="mb-3">
                                <label for="category" class="col-form-label"
                                  >Assign to Users:</label
                                >
                                <div
                                  class="user-list"
                                  v-if="DropdownOptions.userOptions.length > 0"
                                >
                                  <div
                                    v-for="user in DropdownOptions.userOptions"
                                    :key="user.UserID"
                                    class="user-item"
                                  >
                                    <input
                                      type="checkbox"
                                      :id="'userCheckbox_' + user.UserID"
                                      :value="user.UserID"
                                      v-model="newTask.selectedUsers"
                                    />
                                    <label
                                      :for="'userCheckbox_' + user.UserID"
                                      >{{ user.UserName }}</label
                                    >
                                  </div>
                                </div>
                                <span class="text-red-500 text-sm">{{
                                  taskValidate.selectedUsers
                                }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary ml-2"
                        data-bs-dismiss="modal"
                        id="closeModal"
                        title="Close"
                      >
                        Close
                      </button>
                      <button
                        type="submit"
                        class="btn btn-primary"
                        :disabled="btnTaskEnable"
                        :title="editTaskMode ? 'Update':'Add'"
                      >
                        {{ editTaskMode ? "Update" : "Submit" }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import api, { setAuthToken } from "../apiConfig";
import { authMixin } from "./adminStore";
import Cookies from "js-cookie";
import { ErrorLog } from "../Common/APILog";

export default {
  mixins: [authMixin],
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  created() {
    this.checkAuthorization();
  },
  name: "TaskManagement",
  components: {},
  data() {
    return {
      tasks: [],
      newTask: {
        updateTaskId: 0,
        TaskID: 0,
        UserId: 0,
        selectedUsers: [],
        DueDate: null,
      },
      taskValidate: {
        TaskID: "",
        UserId: "",
        selectedUsers: "",
        DueDate: "",
        // Add additional validation fields as needed
      },
      taskFormHeader: "",
      successAlertOnSubmit: "",
      editTaskMode: false,
      loadingTasks: "",
      btnTaskEnable: false,
      DropdownOptions: {
        userOptions: [],
        taskOPtions: [],
      },
    };
  },
  computed: {
    isRowExpired() {
      return (item) => item.IsExpired === 1;
    },
  },
  mounted() {
    this.getTasks();
    this.getDropdownOptions();
  },
  methods: {
    formatDueDate(originalDate, type = "show") {
      if (!originalDate) return ""; // Handle null or undefined dates

      const dateObject = new Date(originalDate);
      const day = dateObject.getDate().toString().padStart(2, "0");
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObject.getFullYear();
      if (type == "show") {
        return `${day}/${month}/${year}`;
      } else {
        return `${year}-${month}-${day}`;
      }
    },
    truncateDescription(description) {
      // Truncate the description if it's longer than 30 characters
      return description.length > 25
        ? description.slice(0, 25) + "..."
        : description;
    },
    handleTaskModalHidden() {
      this.editTaskMode = false;
    },
    closeModal() {
      this.editMode = false;
      this.resetTaskValidation();
      document.getElementById("closeModal").click();
    },
    openTaskModal(refresh = false) {
      if (refresh) {
        this.editTaskMode = false;
        this.newTask = {
          TaskID: 0,
          selectedUsers: [],
        };
        this.resetTaskValidation();
      }
    },
    openModal() {
      document.getElementById("openModal").click();
    },
    async getTasks() {
      this.tasks = [];
      this.loadingTasks = "Loading...";
      try {
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        const response = await api.get(
          `/api/admin/tasks/get-assigned-tasks/${userCookie.UserID}`
        );
        if (response.data) {
          this.tasks = response.data;
        } else {
          // Handle case where no tasks are found
        }
      } catch (error) {
        // Handle API error
        
      } finally {
        if (this.tasks.length > 0) {
          this.loadingTasks = "";
        } else {
          this.loadingTasks = "No record found.";
        }
      }
    },
    async getTaskById(taskId) {
      try {
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        const response = await api.get(
          `/api/admin/tasks/get-assigned-tasks-details/${userCookie.UserID}?taskId=${taskId}`
        );
        if (response.data) {
          // Assign the values from the API response to newTask
          this.newTask.TaskID = response.data[0].TaskId;
          this.newTask.updateTaskId = response.data[0].TaskId;
          this.newTask.UserId = userCookie.UserID; // Assuming you want to keep the user who is updating the task
          this.newTask.DueDate = this.formatDueDate(
            response.data[0].DueDate,
            "update"
          );
          // If the API response provides an array of selected users, you can directly assign it
          const selectedUsersArray =
            response.data[0].SelectedUsers.split(",").map(Number);
          this.newTask.selectedUsers = selectedUsersArray;
          this.editMode = true;
          this.openModal();
          this.resetTaskValidation();
        } else {
          alert(`Error: ${response.statusText}`);
        }
      } catch (error) {
        if (error.response.status == 401) {
          const jsonResponseString = JSON.stringify(
            error.response.data.message
          );
          alert(jsonResponseString);
          this.$router.push("/");
        }
        alert("Internal server error.");
      }
    },
    isValidTaskForm() {
      let isValid = true;

      // Tasks
      if (this.newTask.TaskID <= 0) {
        this.taskValidate.TaskID = "Please select task.";
        isValid = false;
      }
      // Users
      if (this.newTask.selectedUsers.length == 0) {
        this.taskValidate.selectedUsers = "Please select users.";
        isValid = false;
      }

      // DueDate
      if (!this.newTask.DueDate) {
        this.taskValidate.DueDate = "Due Date is required.";
        isValid = false;
      }
      return isValid;
    },
    async assignTask() {
      this.taskFormHeader = "";
      try {
        this.btnTaskEnable = true;
        const token =await Cookies.get("jwt");
        setAuthToken(token);

        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        const response = await api.post(
          `/api/admin/tasks/assign-task/${this.newTask.TaskID}`,
          {
            assignBy: userCookie.UserID,
            userIds: this.newTask.selectedUsers,
            DueDate: this.newTask.DueDate,
          }
        );

        if (response.data.success) {
          this.getTasks();
          this.closeModal(); // hide modal after successful submit.
          this.successAlertOnSubmit = "To-Do assigned successfully.";
        } else {
          //console.log(response);
          this.taskFormHeader = response.data.msg;
        }
      } catch (error) {
        this.taskFormHeader = error.message;
      } finally {
        this.btnTaskEnable = false;
      }
    },
    async updateAssignTask() {
      this.taskFormHeader = "";
      try {
        this.btnTaskEnable = true;
        const token =await Cookies.get("jwt");
        setAuthToken(token);

        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        const response = await api.put(
          `/api/admin/tasks/update-assign-task/${this.newTask.TaskID}`,
          {
            assignBy: userCookie.UserID,
            userIds: this.newTask.selectedUsers,
            updateTaskId: this.newTask.updateTaskId,
            DueDate: this.newTask.DueDate,
          }
        );

        if (response.data.success) {
          this.getTasks();
          this.closeModal(); // hide modal after successful submit.
          this.successAlertOnSubmit = "To-Do updated successfully.";
        } else {
          //console.log(response);
          this.taskFormHeader = response.data.msg;
        }
      } catch (error) {
        this.taskFormHeader = error.message;
      } finally {
        this.btnTaskEnable = false;
      }
    },
    async submitTaskForm() {
      this.resetTaskValidation();

      if (this.isValidTaskForm()) {
        this.editMode ? this.updateAssignTask() : this.assignTask();
      }
    },
    resetTaskValidation() {
      this.taskValidate = {
        TaskID: "",
        selectedUsers: "",
      };
    },
    hideTaskAlert() {
      this.taskFormHeader = "";
      this.successAlertOnSubmit = "";
    },
    async getDropdownOptions() {
      try {
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie = userCookie ? JSON.parse(userCookie) : null;
        const response = await api.get(
          `/api/admin/tasks/get-dropdown-tasks-users/${userCookie.UserID}`
        );
        if (response.data) {
          this.DropdownOptions.taskOPtions = response.data.tasks;
          this.DropdownOptions.userOptions = response.data.users;
        } else {
          // Handle case where no priorities are found
        }
      } catch (error) {
        console.log(error);
        // Handle API error
      }
    },
    async deleteItem(taskId) {
      try {
        //console.log(taskId);
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        // Make your delete API call here
        const response = await api.delete(
          `/api/admin/tasks/delete-assign-task/${taskId}/${userCookie.UserID}`
        ); // Replace with your actual API endpoint

        if (response.data.success) {
          this.getTasks();
          this.successAlertOnSubmit = "Deleted Successfully";
        } else {
          alert(response.data.msg);
        }

        // Optionally, perform additional actions after successful deletion
      } catch (error) {
        if (error.response.status == 401) {
          const jsonResponseString = JSON.stringify(
            error.response.data.message
          );
          alert(jsonResponseString);
          this.$router.push("/");
        }
      }
    },
    confirmDelete(taskId) {
      const isConfirmed = window.confirm("Are you sure you want to delete?");

      if (isConfirmed) {
        // Call the deleteItem method when confirmed
        this.deleteItem(taskId);
      }
    },
  },
};
</script>

<style scoped>
/* Add Tailwind CSS classes here if needed */
.close-icon {
  cursor: pointer;
  float: right;
  font-size: 30px;
  margin-top: -10px;
}
.mb-3 {
  text-align: left;
}
.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1em;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.table th {
  background-color: #f2f2f2;
}

/* Additional styling for left and right padding */
.table th,
.table td {
  padding-left: 12px;
  padding-right: 12px;
}

.actionBtns {
  text-align: right !important;
}

.user-list {
  min-height: 120px;
  max-height: 120px; /* Set a maximum height for the user list */
  overflow-y: auto; /* Add a scrollbar when the content exceeds the height */
  border: 1px solid #ccc; /* Optional: Add a border for styling */
}

.user-item {
  margin-bottom: 8px; /* Optional: Add some spacing between user items */
}

.expired-row {
  background-color: #f66868;
}

.taskNotAssigned {
  color: orange;
}

.taskAssigned {
  color: #ed6524;
}
.taskInProcess {
  color: #864426;
}

.taskCompleted {
  color: green;
}
</style>
