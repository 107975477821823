<template>
    <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
      <notifications></notifications>
      <side-bar
        :sidebar-item-color="sidebarBackground"
        :sidebar-background-image="sidebarBackgroundImage"
      >
        <mobile-menu slot="content"></mobile-menu>
        <sidebar-link to="/superAdmin/dashboard">
          <md-icon>dashboard</md-icon>
          <p>Dashboard</p>
        </sidebar-link>
        <sidebar-link to="/superAdmin/manageAdmin">
        <md-icon>group</md-icon>
          <p>Manage Admins</p>
        </sidebar-link>
        <sidebar-link to="/superAdmin/profile">
        <md-icon>group</md-icon>
          <p>My Profile</p>
        </sidebar-link>
        <sidebar-link to="/superAdmin/change-password">
          <md-icon>password</md-icon>
          <p>Change Password</p>
        </sidebar-link>
        <sidebar-link to="/superAdmin/error-log">
          <md-icon>error</md-icon>
          <p>Error Log</p>
        </sidebar-link>
        <sidebar-link @click="logout" to="/adminLogin">
          <md-icon @click="logout">logout</md-icon>
          <p @click="logout">Logout</p>
        </sidebar-link>
      </side-bar>
      <div class="main-panel">
        <top-navbar></top-navbar>
        <dashboard-content> </dashboard-content>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </template>
  
  <script>
  import TopNavbar from "./Extra/SuperAdminLayout_Component/TopNavbar.vue";
  import ContentFooter from "./ContentFooter.vue";
  import DashboardContent from "./UserContent.vue";
  import MobileMenu from "@/pages/Layout/UserMobileMenu.vue";
  import { setAuthToken } from "../../components/apiConfig";
  import { handleSessionTimeout } from "../../components/Common/commonMethod";
  
  export default {
    components: {
      TopNavbar,
      DashboardContent,
      ContentFooter,
      MobileMenu,
    },
    data() {
      return {
        sidebarBackground: "green",
        sidebarBackgroundImage: require("@/assets/img/sidebar-2.jpg"),
      };
    },
    methods: {
      logout() {
        setAuthToken();
        //alert("Logout successfully.");
        this.$router.push("/login");
      },
    },
    async mounted(){
      handleSessionTimeout("/adminLogin")
    }
  };
  </script>
  