<template>
  <div class="content">
    <LoadingOverlay :loading="Isloading" />
    <div class="md-layout">
      <div class="md-layout-item md-large-size-100 md-size-66">
        <form @submit.prevent="changePassword">
          <md-card style="padding-bottom: 20px;">
            <md-card-header data-background-color="green" style="overflow: hidden;">
              <h4 class="title" style="float: left;">Edit Task</h4>
              <button type="button" title="Back" class="btn btn-secondary ml-2" style="float: right;" @click="goBack" tabindex="7">
                Back
              </button>
              <!-- <p class="category">Change your password</p> -->
            </md-card-header>
            <md-card-content>
              <!-- Display success alert -->
              <div v-if="successAlertOnSubmit" class="alert alert-success text-left" role="alert">
                {{ successAlertOnSubmit }}
                <span @click="hideTaskAlert" class="close-icon">&times;</span>
              </div>
              <div v-if="errorAlertOnSubmit" class="alert alert-danger text-left" role="alert">
                {{ errorAlertOnSubmit }}
                <span @click="hideTaskAlert" class="close-icon">&times;</span>
              </div>
              <div v-if="loadingTasks" class="text-center">
                <p>{{ loadingTasks }}</p>
              </div>
              <form @submit.prevent="submitTaskForm" v-if="!loadingTasks">
                <input v-model="editTask.TaskID" type="hidden" class="form-control" />
                <div class="row">
                  <div class="col-md-12">
                    <div class="mb-3">
                      <label for="title" class="col-form-label">Title</label>
                      <input v-model="editTask.Title" type="text" class="form-control" id="title" tabindex="1"
                        @input="isValidTaskForm" />
                      <span class="text-red-500 text-sm">{{
                        taskValidate.Title
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <div class="mb-3">
                      <label for="priority" class="col-form-label">Priority</label>
                      <select v-model="editTask.PriorityID" class="dropdown" id="priority" tabindex="3"
                        @change="isValidTaskForm">
                        <option :value="0">Select Priority</option>
                        <option v-for="priorityOption in priorityDropdownOptions" :key="priorityOption.PriorityID"
                          :value="priorityOption.PriorityID">
                          {{ priorityOption.Name }}
                        </option>
                      </select>
                      <span class="text-red-500 text-sm">{{
                        taskValidate.PriorityID
                      }}</span>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="mb-3" v-if="TaskPrivate">
                      <label for="dueDate" class="col-form-label">Due Date</label>
                      <flat-pickr :modelValue="editTask.DueDate" class="form-control"
                        @update:modelValue="editTask.DueDate = $event" :config="flatpickrConfig"></flat-pickr>
                      <span class="text-red-500 text-sm">{{
                        taskValidate.DueDate
                      }}</span>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="mb-3">
                      <label for="description" class="col-form-label">Description</label>
                      <textarea v-model="editTask.Description" class="form-control" id="description" tabindex="2"
                        @input="isValidTaskForm" style="height: 36px !important;"></textarea>
                      <span class="text-red-500 text-sm">{{
                        taskValidate.Description
                      }}</span>
                    </div>
                  </div>
                  <div class="col-md-6">

                    <div class="mb-3">
                      <label for="fileUpload" class="col-form-label">File Upload</label>
                      <input type="file" class="form-control" id="fileUpload" ref="file" name="file" tabindex="5"
                        @change="handleFileUpload" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <p v-if="imageURLValid && editTask.InfoFile != null && editTask.InfoFile != 'null'">
                        <a :href="editTask.InfoFile" target="blank" class="browser-link">
                          <img :src="editTask.InfoFile" class="image-link" alt="Image"></a>
                      </p>
                      <p v-if="!imageURLValid && editTask.InfoFile != null && editTask.InfoFile != 'null'"
                        class="file-link">
                        <a :href="editTask.InfoFile" target="blank">Attachment File</a>
                      </p>
                    </div>
                  </div>
                  <div class="col-md-6" hidden>
                    <div class="mb-3">
                      <label for="isCompleted" class="col-form-label">Is Completed</label>
                      <div class="form-check form-switch">
                        <input v-model="editTask.IsCompleted" type="checkbox" class="form-check-input" id="isCompleted"
                          tabindex="5" @input="isValidTaskForm" />
                        <label class="form-check-label" for="isCompleted">
                          {{
                            editTask.IsCompleted
                              ? "Completed"
                              : "Not Completed"
                          }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <button type="submit" title="Update" class="btn btn-primary" default tabindex="6" style="float: right;"
                  :disabled="btnTaskEnable">
                  Update
                  <!-- {{ editTaskMode ? "Update" : "Submit" }} -->
                </button>
              </form>
            </md-card-content>
          </md-card>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import api, { setAuthToken } from "../apiConfig";
import { authMixin } from "./userStore";
import Cookies from "js-cookie";
// import Datetime from 'vuejs-datetimepicker';
import Flatpickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { ErrorLog } from "../Common/APILog";
import LoadingOverlay from '../Common/blockoutpage.vue';
import { handleAuthorization, handleServerError } from "../Common/commonMethod";

export default {
  mixins: [authMixin],
  created() {
    this.checkAuthorization(); // Check authorization when the component is created
    this.getTaskById();
    this.getPriorityDropdown();
    // this.getCategoryDropdown();
  },
  name: "EditTask",
  components: {
    //Datetime,
    'flat-pickr': Flatpickr,
    LoadingOverlay
  },
  props: {
    taskId: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      loadingTasks: "",
      Isloading: false,
      successAlertOnSubmit: "",
      TaskPrivate: false,
      errorAlertOnSubmit: "",
      imageURLValid: false,
      categoryDropdownOptions: [],
      selectedDate: null,
      priorityDropdownOptions: [],
      btnTaskEnable: false,
      editTask: {
        TaskID: 0,
        Title: "",
        Description: "",
        DueDate: new Date(),
        IsCompleted: false,
        PriorityID: 0,
        CategoryID: 0,
        UserId: 0,
        InfoFile: null,
        file: null,
      },
      taskValidate: {
        Title: "",
        Description: "",
        PriorityID: "",
        CategoryID: "",
        // Add additional validation fields as needed
      },
      errors: {},
      formHeader: "",
      flatpickrConfig: {
        dateFormat: 'd/m/Y', // Set the date format to year-month-day
        // minDate: 'today' // Set minDate to today's date
      },
    };
  },
  computed: {
  },
  mounted() {
    // Initial data
  },
  methods: {
    convertDateFormat(date) {
      // Check if inputDate is in dd/mm/yyyy format
      const dateParts = date.split('/');
      if (dateParts.length !== 3) {
        console.error('Invalid date format. Please enter date in dd/mm/yyyy format.');
        return;
      }

      // Parse input date parts
      const day = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10);
      const year = parseInt(dateParts[2], 10);

      // Create a new Date object with dd/mm/yyyy format
      const parsedDate = new Date(year, month - 1, day + 1);

      // Format the date as yyyy-mm-dd
      const formattedDate = parsedDate.toISOString().split('T')[0];

      // Update inputDate with the formatted date
      return formattedDate;
    },
    handleFileUpload(event) {
      this.editTask.file = event.target.files[0];
    },
    // Check if URL ends with a common image file extension
    isImageURL(url) {
      const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp'];
      const extension = url.substring(url.lastIndexOf('.')).toLowerCase();
      console.log(imageExtensions.includes(extension));
      if (imageExtensions.includes(extension)) {
        this.imageURLValid = true;
      }
      //return imageExtensions.includes(extension);
    },
    goBack() {
      // Go back to the previous page in the history
      this.$router.go(-1);
    },
    hideTaskAlert() {
      //this.taskFormHeader = "";
      this.successAlertOnSubmit = "";
    },
    async updateTask() {
      this.successAlertOnSubmit = "";
      this.Isloading = true;
      try {
        this.btnTaskEnable = true;
        const token = await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie = await Cookies.get("user");
        userCookie = await userCookie ? JSON.parse(userCookie) : null;
        console.log("due", this.editTask.DueDate);
        const formData = new FormData();
        formData.append('Title', this.editTask.Title);
        // formData.append('taskId', this.editTask.TaskID);
        formData.append('Description', this.editTask.Description);
        formData.append('DueDate', this.convertDateFormat(this.editTask.DueDate));
        formData.append('IsCompleted', false);
        formData.append('PriorityID', this.editTask.PriorityID);
        formData.append('CategoryID', 0);
        formData.append('file', this.editTask.file);
        formData.append('InfoFile', this.editTask.InfoFile);
        formData.append('UserID', userCookie.UserID);
        const response = await api.put('/api/tasks/update-task/' + this.editTask.TaskID, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }

        });
        console.log(response);

        if (response.data.success) {
          this.successAlertOnSubmit = "Task updated successfully.";
          this.getTaskById();
        } else {
          this.successAlertOnSubmit = response.data.msg;
        }
      } catch (error) {
        //this.errorAlertOnSubmit = error.message;
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 407, error.stack, "UserPanel/EditTask/updateTask()");
          }
        }
        else {
          this.errorAlertOnSubmit = "Something went wrong.";
        }
      } finally {
        this.Isloading = false;
        this.btnTaskEnable = false;
        setTimeout(() => {
          this.errorAlertOnSubmit = "";
          this.successAlertOnSubmit = "";
        }, 5000); // 3 seconds in milliseconds
        setTimeout(() => {
          // Call your function here
          this.goBack();
        }, 2000); // 2000 milliseconds = 2 seconds
        // this.goBack();
      }
    },
    resetTaskValidation() {
      this.taskValidate = {
        Title: "",
        DueDate: "",
      };
    },
    async submitTaskForm() {
      this.resetTaskValidation();

      if (this.isValidTaskForm()) {
        //this.editTask.TaskID === 0 ? this.createTask() : this.updateTask();
        this.updateTask();
      }
    },
    async getPriorityDropdown() {
      try {
        const token = await Cookies.get("jwt");
        this.Isloading = true;
        setAuthToken(token);
        const response = await api.get("/api/tasks/get-priority-dropdown");
        if (response.data) {
          this.priorityDropdownOptions = response.data;
        } else {
          // Handle case where no priorities are found
        }
      } catch (error) {
        // Handle API error
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 469, error.stack, "UserPanel/EditTask/getPriorityDropdown()");
          }
        }
        else {
          this.errorAlertOnSubmit = "Something went wrong.";
        }
      } finally {
        this.Isloading = false;
      }
    },

    formatDueDate(originalDate, type = "show") {
      if (!originalDate) return ""; // Handle null or undefined dates

      const dateObject = new Date(originalDate);
      const day = dateObject.getDate().toString().padStart(2, "0");
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObject.getFullYear();
      if (type == "show") {
        return `${day}/${month}/${year}`;
      } else {
        return `${day}/${month}/${year}`;
      }
    },
    isValidTaskForm() {
      let isValid = true;

      // Title
      if (!this.editTask.Title.trim()) {
        this.taskValidate.Title = "Title is required.";
        isValid = false;
      } else {
        this.taskValidate.Title = "";
      }

      // Description
      if (!this.editTask.Description.trim()) {
        this.taskValidate.Description = "Description is required.";
        isValid = false;
      } else {
        this.taskValidate.Description = "";
      }

      // Priority
      if (this.editTask.PriorityID === 0 || this.editTask.PriorityID === "0") {
        this.taskValidate.PriorityID = "Please select priority.";
        isValid = false;
      } else {
        this.taskValidate.PriorityID = "";
      }

      // Category
      // if (this.editTask.CategoryID === 0 || this.editTask.CategoryID === "0") {
      //   this.taskValidate.CategoryID = "Please select category.";
      //   isValid = false;
      // } else {
      //   this.taskValidate.CategoryID = "";
      // }

      return isValid;
    },
    async getTaskById() {
      try {
        this.loadingTasks = "Loading...";
        this.Isloading = true;
        const token = await Cookies.get("jwt");
        setAuthToken(token);
        const response = await api.get(
          `/api/tasks/get-task-by-id/${this.taskId}`
        );
        if (response.data) {
          console.log(response.data);
          this.editTask = response.data.tasks[0];
          if (this.editTask.InfoFile != null) {
            this.isImageURL(this.editTask.InfoFile);
          }
          if (this.editTask.TaskType == "Private") {
            this.TaskPrivate = true;
          }
          this.editTask.DueDate = this.formatDueDate(
            response.data.tasks[0].DueDate,
            "update"
          );
          console.log("date", this.editTask.DueDate);
        } else {
          alert(`Error: ${response.statusText}`);
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 585, error.stack, "UserPanel/EditTask/getTaskById()");
          }
        }
        else {
          this.errorAlertOnSubmit = "Something went wrong.";
        }
      }
      finally {
        this.Isloading = false;
        this.loadingTasks = "";

      }
    },

  },

};
</script>

<style scoped>
/* Add Tailwind CSS classes here if needed */
.close-icon {
  cursor: pointer;
  float: right;
  font-size: 30px;
  margin-top: -10px;
}

.dropdown {
  width: 100%;
  height: 36px;
  padding: 7px 0;
  font-size: 14px;
  line-height: 1.428571429;
  border-color: #D2D2D2;
}

.modal-content .modal-header {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.modal-title {
  margin-top: 10px;
}

.modal-content .modal-body {
  padding-top: 10px;
}

.file-link {
  margin-top: 35px;
  margin-bottom: 0px;
}

.file-link a {
  color: blue !important;
  font-weight: 500;
}

.browser-link {
  color: blue !important;
  font-weight: 500;
  padding-left: 10px;
}
</style>
