<template>
  <div class="content" style="overflow-x:hidden;">
    <LoadingOverlay :loading="Isloading" />
    <div class="row">
      <div class="col-md-12 ms-3 pt-2 pb-3">
        <div class="color-box" v-on:click="filterData(6)">
          <span class="highlight-text">All Task</span>
        </div>
        <div class="color-box" v-on:click="filterData(4)">
          <div class="progress task-detail-value" style="width: 25px; margin-top: 3px;">
            <div title="Low" class="progress-bar bg-info" role="progressbar"
              style="width: 100%;background-color: #9ed79e !important;" aria-valuenow="0" aria-valuemin="0"
              aria-valuemax="100">&nbsp;</div>

          </div><span class="highlight-text">Complete</span>
        </div>

        <div class="color-box" v-on:click="filterData(5)">
          <div class="progress task-detail-value" style="width: 25px; margin-top: 3px;">
            <div title="Low" class="progress-bar bg-info" role="progressbar"
              style="width: 100%;background-color: #dc8484 !important;" aria-valuenow="0" aria-valuemin="0"
              aria-valuemax="100">&nbsp;</div>

          </div><span class="highlight-text">Due</span>
        </div>
        <div class="color-box" v-on:click="filterData(3)">
          <div class="progress task-detail-value" style="width: 25px; margin-top: 3px;">
            <div title="Low" class="progress-bar bg-info" role="progressbar"
              style="width: 100%;background-color: #b4e9d4 !important;" aria-valuenow="0" aria-valuemin="0"
              aria-valuemax="100">&nbsp;</div>

          </div><span class="highlight-text">In Process</span>
        </div>
        <div class="color-box" v-on:click="filterData(2)">
          <div class="progress task-detail-value" style="width: 25px; margin-top: 3px;">
            <div title="Low" class="progress-bar bg-info" role="progressbar"
              style="width: 100%;background-color: #eaea8d !important;" aria-valuenow="0" aria-valuemin="0"
              aria-valuemax="100">&nbsp;</div>

          </div><span class="highlight-text">Assigned</span>
        </div>

      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
        <md-card>
          <md-card-header data-background-color="green">
            <div class="card-header d-flex justify-content-between align-items-center">
              <h4 class="title">Assigned Tasks Messages</h4>
              <div class="ms-auto">
                <input type="text" v-model="searchText" class="gridFilter" @keyup="filterGrid"
                  style="margin:5px 5px 0 0;" placeholder="Search...">

              </div>
            </div>
          </md-card-header>
          <md-card-content>
            <div class="col-md-12" style="overflow-x:auto;">
              <!-- Display success alert -->
              <div v-if="successAlertOnSubmit" class="alert alert-success text-left" role="alert">
                {{ successAlertOnSubmit }}
                <span @click="hideTaskAlert" class="close-icon">&times;</span>
              </div>
              <div v-if="errorAlertOnSubmit" class="alert alert-danger text-left" role="alert">
                {{ errorAlertOnSubmit }}
                <span @click="hideTaskAlert" class="close-icon">&times;</span>
              </div>
              <div class="table-responsive" style="overflow: auto !important;">
                <div v-if="loadingTasks" class="text-center">
                  <p>{{ loadingTasks }}</p>
                </div>
                <div class="task-table">
                <table style="width: 100%;" :table-header-color="tableHeaderColor">
                  <tr class="toprow" v-if="!loadingTasks">
                    <td style="width:5%;padding-left:5px;" class="table-cell">ID</td>
                    <td style="width:25%" class="table-cell">Task</td>
                    <td style="width:10%" class="table-cell">Priority</td>
                    <td style="width:12%" class="table-cell">Assigned By</td>
                    <td style="width:12%" class="table-cell">Assigned On</td>
                    <td style="width:12%" class="table-cell">Due Date</td>
                    <td style="width:10%" class="table-cell">Status</td>
                    <td style="width:12%;text-align:right;padding-right:5px;" class="table-cell">Action</td>
                  </tr>
                  <tr slot="md-table-row" v-for="item in filteredTasks" :key="item.id" :class="checkRowColor(item)"
                    style="border-bottom:1px solid #ddd">
                    <td class="md-table-cell">{{ item.TaskId }}</td>
                    <td class="md-table-cell"><span v-if="item.Title.length > 60" :title="item.Title">
                        {{ truncateDescription(item.Title) }}
                      </span>
                      <span v-else>
                        {{ item.Title }}
                      </span>
                    </td>
                    <td class="md-table-cell">
                      {{
                        item.PriorityName
                      }}
                    </td>
                    <td class="md-table-cell">{{ item.AssignedBy }}</td>
                    <td class="md-table-cell">{{ item.CreatedDate }}</td>
                  
                    <td class="md-table-cell">{{ formatDueDate(item.DueDate) }}</td>
                    <td class="md-table-cell">
                      <span v-if="item.TaskStatus === 1" class="taskNotAssigned">
                        Not Assigned
                      </span>
                      <span v-else-if="item.TaskStatus === 2" class="taskAssigned">Assigned
                      </span>
                      <span v-else-if="item.TaskStatus === 3" class="taskInProcess">In Process
                      </span>
                      <span v-else-if="item.TaskStatus === 4" class="taskCompleted">Completed
                      </span>
                    </td>
                    <td class="md-table-cell" style="text-align:right">
                      <sup><span v-if="item.UnreadChatCount > 0" class="notification mx-1">
                          {{ formatChatCount(item.UnreadChatCount) }}
                          <!-- 34 -->
                        </span>
                      </sup>
                      <button @click="viewPersonalTaskDetail(item.TaskId)" type="button"
                        class="btn btn-primary btn-sm ml-2" title="View Task">
                        <i class="fa fa-eye" aria-hidden="true"></i>
                      </button>
                      <button hidden type="button" title="Edit Status" data-bs-toggle="modal" data-bs-target="#taskEditStatusModal"
                        data-bs-whatever="@mdo" class="btn btn-primary" id="openEditStatusModal">
                        Edit Status
                      </button>
                    </td>
                  </tr>
                  <tr v-if="IsTasks" style="border-top: 1px solid #ccc;">
                    <td colspan="8">
                      <p style="padding-top:10px;padding-left:10px;">No Task Found</p>
                    </td>
                  </tr>
                </table>
              </div>
              </div>
            </div>
            <!-- Modal for Edit Task Status -->
            <div class="modal fade" id="taskEditStatusModal" tabindex="-1" aria-labelledby="taskEditStatusModalLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="taskEditStatusModalLabel">
                      Edit Task Status
                    </h1>
                    <button class="btn-close" title="Close" v-on:click="hideCommentsandFile()" data-bs-dismiss="modal"
                      aria-label="Close" tabindex="-1"></button>
                  </div>
                  <form @submit.prevent="submitEditTaskStatusForm1">
                    <input v-model="newTask.TaskID" type="hidden" class="form-control" />
                    <div class="modal-body">
                      <div v-if="taskFormHeader" class="alert alert-danger text-left" role="alert">
                        {{ taskFormHeader }}
                        <span @click="hideTaskAlert" class="close-icon">&times;</span>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="mb-2">
                            <label for="title" class="col-form-label pb-0">Title</label>
                            <input v-model="newTask.Title" type="text" class="form-control pt-0"
                              :disabled="isInputDisabled" id="title" tabindex="1" style="border: 0;"
                              @input="isValidTaskForm" />
                            <span class="text-red-500 text-sm">{{
                              taskValidate.Title
                            }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-2">
                            <label for="priority" class="col-form-label pb-0">Priority</label>
                            <select v-model="newTask.PriorityID" class="form-control pt-0" id="priority" tabindex="3"
                              style="border: 0;" :disabled="isInputDisabled" @change="isValidTaskForm">
                              <option :value="0">Select Priority</option>
                              <option v-for="priorityOption in priorityDropdownOptions" :key="priorityOption.PriorityID"
                                :value="priorityOption.PriorityID">
                                {{ priorityOption.Name }}
                              </option>
                            </select>
                            <span class="text-red-500 text-sm">{{
                              taskValidate.PriorityID
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="mb-2">
                            <label for="category" class="col-form-label pb-0">Project</label>
                            <select v-model="newTask.CategoryID" class="form-control pt-0" id="category" tabindex="4"
                              :disabled="isInputDisabled" style="border: 0;" @change="isValidTaskForm">
                              <option :value="0">Select Project</option>
                              <option v-for="categoryOption in categoryDropdownOptions" :key="categoryOption.CategoryID"
                                :value="categoryOption.CategoryID">
                                {{ categoryOption.CategoryName }}
                              </option>
                            </select>
                            <span class="text-red-500 text-sm">{{
                              taskValidate.CategoryID
                            }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-2">
                            <label for="dueDate" class="col-form-label pb-0">Due Date</label>
                            <label style="font-weight:400;display: block;" class="col-form-label">{{ newTask.DueDate
                              }}</label>
                            <!-- <input
                              v-model="newTask.DueDate"
                              type="date"
                              class="form-control pt-0"
                              id="dueDate"
                              tabindex="5"
                              :disabled="isInputDisabled"
                              style="border: 0;"
                              @input="isValidTaskForm"
                            /> -->
                            <!-- <flat-pickr :modelValue="newTask.DueDate" style="border: 0;" class="form-control" :disabled="isInputDisabled" @update:modelValue="newTask.DueDate = $event" :config="flatpickrConfig" ></flat-pickr> -->
                            <span class="text-red-500 text-sm">{{
                              taskValidate.DueDate
                            }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label for="status" class="col-form-label">Status</label>
                            <select v-model="newTask.TaskStatus" class="dropdown" id="status" tabindex="4"
                              @change="isValidTaskForm">
                              <option value="2">Select Status</option>
                              <option value="3">In Process</option>
                              <option value="4">Completed</option>
                            </select>
                            <span class="text-red-500 text-sm">{{
                              taskValidate.TaskStatus
                            }}</span>
                          </div>
                        </div>
                        <div class="col-md-6" hidden>
                          <div class="mb-3">
                            <label for="isCompleted" class="col-form-label">Is Completed:</label>
                            <div class="form-check form-switch">
                              <input v-model="newTask.IsCompleted" type="checkbox" class="form-check-input"
                                id="isCompleted" tabindex="6" @input="isValidTaskForm" />
                              <label class="form-check-label" for="isCompleted">
                                {{
                                  newTask.IsCompleted
                                    ? "Completed"
                                    : "Not Completed"
                                }}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row" v-if="StatusCompleted">
                        <div class="col-md-12">
                          <div class="mb-3">
                            <label for="Comments" class="col-form-label">Comments</label>
                            <textarea v-model="newTask.Comments" class="form-control" id="Comments" tabindex="2"
                              style="height: 36px;"></textarea>
                          </div>

                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label for="fileUpload" class="col-form-label">File Upload</label>
                            <input type="file" class="form-control" id="fileUpload" ref="file" name="file" tabindex="5"
                              @change="handleFileUpload" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" title="Close" class="btn btn-secondary ml-2" data-bs-dismiss="modal" id="closeStatusModal"
                        tabindex="8" v-on:click="hideCommentsandFile()">
                        Close
                      </button>
                      <button type="button" title="Update" class="btn btn-primary" data-bs-toggle="modal"
                        data-bs-target="#taskConfirmModal" data-bs-whatever="@mdo" tabindex="7">
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!-- Modal for Edit Task Status -->
            <div class="modal fade" id="taskConfirmModal" tabindex="-1" aria-labelledby="ConfirmModalLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-md">
                <div class="modal-content">

                  <form @submit.prevent="submitEditTaskStatusForm">
                    <input v-model="newTask.TaskID" type="hidden" class="form-control" />
                    <div class="modal-body">
                      <div v-if="taskFormHeader" class="alert alert-danger text-left" role="alert">
                        {{ taskFormHeader }}
                        <span @click="hideTaskAlert" class="close-icon">&times;</span>
                      </div>
                      <div class="row">
                        <h4>
                          Are you sure you want to mark as
                          {{ this.funCheckStatus(newTask.TaskStatus) }}?
                        </h4>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" title="No" class="btn btn-secondary ml-2" data-bs-dismiss="modal"
                        id="closeConfirmStatusModal" tabindex="8">
                        No
                      </button>
                      <button type="submit" title="Yes" class="btn btn-primary" tabindex="7">
                        Yes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import api, { setAuthToken } from "../apiConfig";
import { authMixin } from "./userStore";
import Cookies from "js-cookie";
import { ErrorLog } from "../Common/APILog";
import LoadingOverlay from '../Common/blockoutpage.vue';
// import Flatpickr from 'vue-flatpickr-component';
// import 'flatpickr/dist/flatpickr.css';

export default {
  mixins: [authMixin],
  props: {
    tableHeaderColor: {
      type: String,
      default: "blue"
    },
  },
  created() {
    this.checkAuthorization();
  },
  name: "TaskManagement",
  components: {
    //'flat-pickr':Flatpickr,
    LoadingOverlay
  },
  data() {
    return {
      tasks: [],
      flagCount:0,
      Isloading:false,
      searchText: '',
      filteredTasks: [],
      loadingTasks: "",
      taskFormHeader: "",
      successAlertOnSubmit: "",
      errorAlertOnSubmit: "",
      StatusCompleted: false,
      IsTasks: false,
      newTask: {
        TaskID: 0,
        Title: "",
        Description: "",
        DueDate: null,
        IsCompleted: false,
        PriorityID: 0,
        CategoryID: 0,
        TaskStatus: 1,
        UserId: 0,
        TaskType: null,
        file: "",
        Comments: ""
      },
      flatpickrConfig: {
        dateFormat: 'd/m/Y', // Set the date format to year-month-day
        minDate: 'today' // Set minDate to today's date
      },
      taskValidate: {
        Title: "",
        Description: "",
        PriorityID: "",
        CategoryID: "",
        DueDate: "",
        // Add additional validation fields as needed
      },
      filtertask: [],
      categoryDropdownOptions: [],
      priorityDropdownOptions: [],
      isInputDisabled: true,
    };
  },
  computed: {
    isRowExpired() {
      return (item) => item.IsExpired === 1;
    },
  },
  mounted() {
    this.getTasks();
    this.getPriorityDropdown();
    this.getCategoryDropdown();
  },
  methods: {
    filterData: function (x) {
      this.IsTasks = false;
      if (parseInt(x) != 5 && parseInt(x) != 6) {
        this.filtertask = this.tasks.filter(item => {
          return (
            item.TaskStatus == parseInt(x)
          );

        });
        if (this.filtertask.length > 0) {
          this.filteredTasks = this.filtertask;
        }
        else {
          console.log("ok");
          this.filteredTasks = [];
          this.IsTasks = true;
        }
      }
      if (parseInt(x) == 5) {
        this.filtertask = this.tasks.filter(item => {
          return (
            item.IsExpired == 1
          );

        });
        if (this.filtertask.length > 0) {
          this.filteredTasks = this.filtertask;
        }
        else {
          console.log("ok");
          this.filteredTasks = [];
          this.IsTasks = true;
        }
      }
      if (parseInt(x) == 6) {
        this.filteredTasks = this.tasks;
      }

      // Filter tasks based on searchText

    },
    handleFileUpload(event) {
      this.newTask.file = event.target.files[0];
      console.log(this.newTask.file);
    },
    hideCommentsandFile() {
      this.newTask.Comments = "";
      this.file = "";
      this.StatusCompleted = false;
    },
    filterGrid: function () {
      var searchText = this.searchText;
      this.IsTasks = false;
      if (parseInt(searchText) > 0) {
        this.filtertask = this.tasks.filter(item => {
          return (
            item.TaskId == parseInt(searchText)
          );
        });
        if (this.filtertask.length > 0) {
          this.filteredTasks = this.filtertask;
        }
        else {
          this.filteredTasks = [];
          this.IsTasks = true;
        }
      }
      else {
        searchText = searchText.toLowerCase();
        this.filtertask = this.tasks.filter(item => {
          return (
            item.CategoryName.toLowerCase().includes(searchText) ||
            item.Title.toLowerCase().includes(searchText) ||
            item.Description.toLowerCase().includes(searchText) ||
            item.PriorityName.toLowerCase().includes(searchText)
          );
        });
        if (this.filtertask.length > 0) {
          this.filteredTasks = this.filtertask;
        }
        else {
          this.filteredTasks = [];
          this.IsTasks = true;
        }
      }
      // Filter tasks based on searchText

    },
    isValidTaskForm() {
      let isValid = true;

      // Title
      if (!this.newTask.Title.trim()) {
        this.taskValidate.Title = "Title is required.";
        isValid = false;
      } else {
        this.taskValidate.Title = "";
      }

      // Description
      if (!this.newTask.Description.trim()) {
        this.taskValidate.Description = "Description is required.";
        isValid = false;
      } else {
        this.taskValidate.Description = "";
      }

      // Priority
      if (this.newTask.PriorityID === 0 || this.newTask.PriorityID === "0") {
        this.taskValidate.PriorityID = "Please select priority.";
        isValid = false;
      } else {
        this.taskValidate.PriorityID = "";
      }

      // Category
      if (this.newTask.CategoryID === 0 || this.newTask.CategoryID === "0") {
        this.taskValidate.CategoryID = "Please select project.";
        isValid = false;
      } else {
        this.taskValidate.CategoryID = "";
      }

      // DueDate
      if (!this.newTask.DueDate) {
        this.taskValidate.DueDate = "Due Date is required.";
        isValid = false;
      } else {
        this.taskValidate.DueDate = "";
      }
      if (this.newTask.TaskStatus == 4) {
        this.newTask.Comments = "";
        this.StatusCompleted = true;
      }
      else {
        this.newTask.Comments = "";
        this.StatusCompleted = false;
      }

      return isValid;
    },
    async getPriorityDropdown() {
      try {
        this.Isloading=true;
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        const response = await api.get("/api/tasks/get-priority-dropdown");
        if (response.data) {
          this.priorityDropdownOptions = response.data;
        } else {
          // Handle case where no priorities are found
        }
      } catch (error) {
        // Handle API error
        if(this.flagCount<2){
            this.getPriorityDropdown();
            this.flagCount++;
        }else{
        ErrorLog(error.message, error.statuscode, 841, error.stack, "User Panel: getPriorityDropdown()");
        if (error.response && error.response.data) {
          const jsonResponseString = JSON.stringify(error.response.data);
          try {
            const jsonObject = JSON.parse(jsonResponseString);
            //    this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
          } catch (parseError) {
            //  this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
          }
        } else {
          //this.errorAlertOnSubmit = "Something went wrong.";
        }
      }
      }finally{
        this.Isloading=false;
      }
    },
    async submitEditTaskStatusForm() {
      this.CompleteTask(this.newTask.TaskID, this.newTask.TaskStatus);
    },
    async getCategoryDropdown() {
      try {
        this.Isloading=true;
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        const response = await api.get("/api/tasks/get-category-dropdown");
        if (response.data) {
          this.categoryDropdownOptions = response.data;
        } else {
          // Handle case where no categories are found
        }
      } catch (error) {
        // Handle API error
        if(this.flagCount<2){
            this.getCategoryDropdown();
            this.flagCount++;
        }else{
        ErrorLog(error.message, error.statuscode, 870, error.stack, "User Panel: Mytasks / getCategoryDropdown()");
        if (error.response && error.response.data) {
          const jsonResponseString = JSON.stringify(error.response.data);
          try {
            const jsonObject = JSON.parse(jsonResponseString);
            //this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
          } catch (parseError) {
            //  this.errorAlertOnSubmit = parseError.message || "Something went wrong.";

          }
        } else {
          //  this.errorAlertOnSubmit = "Something went wrong.";
        }}
      }finally{
        this.Isloading=false;
      }
    },
    checkRowColor(item) {
      const taskStatus = item.TaskStatus;
      if (item.IsExpired) {
        return "expired-row";
      }
      if (taskStatus === 1) {
        return "rowtaskNotAssigned";
      } else if (taskStatus === 2) {
        return "rowtaskAssigned";
      } else if (taskStatus === 3) {
        return "rowtaskInProcess";
      } else if (taskStatus === 4) {
        return "rowtaskCompleted";
      } else {
        return ""; // You can modify this based on your specific needs
      }
    },
    viewPersonalTaskDetail(taskId) {
      this.$router.push(`/user/task-details/${taskId}/1`);
    },
    editTaskStatus(taskId) {
      this.getTaskById(taskId);
    },
    openEditStatusModal() {
      document.getElementById("openEditStatusModal").click();
    },
    async getTaskById(taskId) {
      console.log(taskId);
      this.Isloading=true;
      try {
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        const response = await api.get(`/api/tasks/get-task-by-id/${taskId}`);
        if (response.data) {
          this.newTask = response.data.tasks[0];
          console.log(this.newTask);
          this.newTask.DueDate = this.formatDueDate(
            response.data.tasks[0].DueDate,
            "update"
          );
          this.openEditStatusModal();

        } else {
          //alert(`Error: ${response.statusText}`);
        }
      } catch (error) {
        //console.log(error);
        if(this.flagCount<2){
            this.getTaskById(taskId);
            this.flagCount++;
        }else{

        
        ErrorLog(error.message, error.statuscode, 931, error.stack, "User Panel: myTasks / getTaskById(taskId)");
        if (error.response && error.response.data) {
          const jsonResponseString = JSON.stringify(error.response.data);
          try {
            const jsonObject = JSON.parse(jsonResponseString);
            //this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
          } catch (parseError) {
            //this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
          }
        } else {
          //this.errorAlertOnSubmit = "Something went wrong.";
        }
      }
      }finally{
        this.Isloading=false;
      }
    },
    formatDueDate(originalDate, type = "show") {
      if (!originalDate) return ""; // Handle null or undefined dates

      const dateObject = new Date(originalDate);
      const day = dateObject.getDate().toString().padStart(2, "0");
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObject.getFullYear();
      if (type == "show") {
        return `${day}/${month}/${year}`;
      } else {
        return `${day}/${month}/${year}`;
      }
    },
    truncateDescription(description) {
      // Truncate the description if it's longer than 30 characters
      return description.length > 25
        ? description.slice(0, 25) + "..."
        : description;
    },
    async getTasks() {
      this.tasks = [];
      this.filteredTasks = [];
      this.Isloading=true;
      this.loadingTasks = "Loading...";
      try {
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        const response = await api.get(
          `/api/tasks/get-my-tasks/${userCookie.UserID}`
        );
        if (response.data) {
console.log(response.data);
          this.tasks = response.data.filter(item => {
          return (
            item.UnreadChatCount>0
          );
        });
          this.filteredTasks = this.tasks;
          console.log(response.data);
        } else {
          // Handle case where no tasks are found
        }
      } catch (error) {
        // Handle API error
        if(this.flagCount<2){
            this.getTasks();
            this.flagCount++;
        }else{
        ErrorLog(error.message, error.statuscode, 985, error.stack, "User Panel: MyTasks / getTasks()");
        if (error.response && error.response.data) {
          const jsonResponseString = JSON.stringify(error.response.data);
          try {
            const jsonObject = JSON.parse(jsonResponseString);
            //this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
          } catch (parseError) {
            // this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
          }
        } else {
          //this.errorAlertOnSubmit = "Something went wrong.";
        }
      }
      } finally {
        this.Isloading=false;
        if (this.tasks.length > 0) {
          this.loadingTasks = "";
        } else {
          this.loadingTasks = "No tasks found.";
        }
      }
    },
    hideTaskAlert() {
      this.taskFormHeader = "";
      this.successAlertOnSubmit = "";
    },
    closeConfirmStatusModal() {
      document.getElementById("closeConfirmStatusModal").click();
    },
    funCheckStatus(StatusID) {
      let tStatus = "not assigned";
      if (StatusID == 2) {
        tStatus = "assigned";
      } else if (StatusID == 3) {
        tStatus = "in process";
      } else if (StatusID == 4) {
        tStatus = "completed";
      }
      return tStatus;
    },
    async CompleteTask(taskId, status) {
      try {
        this.Isloading=true;
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        const formData = new FormData();
        formData.append('TaskID', taskId);
        formData.append('UserID', userCookie.UserID);
        formData.append('Status', status);
        formData.append('file', this.newTask.file);
        formData.append('TaskType', this.newTask.TaskType);
        formData.append('Comments', this.newTask.Comments);
        // Make your update API call here
        const response = await api.post('/api/tasks/complete-task', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }

        });
        // const response = await api.post(`/api/tasks/complete-task`, {
        //   TaskID: taskId,
        //   UserID: userCookie.UserID,
        //   Status: status,
        // }); 
        // Replace with your actual API endpoint
        if (response.data.success) {
          this.getTasks();
          this.closeConfirmStatusModal();
          let tStatus = "not assigned";
          if (status == 2) {
            tStatus = "assigned";
          } else if (status == 3) {
            tStatus = "in process";
          } else if (status == 4) {
            tStatus = "completed";
          }
          this.successAlertOnSubmit = `Task marked as ${tStatus} successfully.`;
        } else {
          //  alert(response.data.msg);
          this.errorAlertOnSubmit = response.data.msg + ".";
        }

        // Optionally, perform additional actions after successful deletion
      } catch (error) {
        console.log("erorr", error)
        ErrorLog(error.message, error.statuscode, 1069, error.stack, "User Panel: Mytasks / CompleteTask(taskId, status)");
        if (error.response && error.response.data) {
          const jsonResponseString = JSON.stringify(error.response.data);
          try {
            const jsonObject = JSON.parse(jsonResponseString);
            this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
          } catch (parseError) {
            this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
          }
        } else {
          this.errorAlertOnSubmit = "Something went wrong.";
        }
      }
      finally {
        this.Isloading=false;
        setTimeout(() => {
          this.errorAlertOnSubmit = "";
          this.successAlertOnSubmit = "";
        }, 5000); // 3 seconds in milliseconds
      }
    },
    confirmComplete(taskId, status) {
      let showStatusName = "in process";
      if (status === 4) {
        showStatusName = "complete";
      }
      const isConfirmed = window.confirm(
        `Are you sure you want to ${showStatusName}?`
      );

      if (isConfirmed) {
        // Call the deleteItem method when confirmed
        this.CompleteTask(taskId, status);
      }
    },  
    formatChatCount(count) {
      return count < 10 ? `0${count}` : count;
    },
  },
};
</script>

<style scoped>
/* Add Tailwind CSS classes here if needed */
.close-icon {
  cursor: pointer;
  float: right;
  font-size: 30px;
  margin-top: -10px;
}

.mb-3 {
  text-align: left;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1em;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.table th {
  background-color: #f2f2f2;
}

/* Additional styling for left and right padding */
.table th,
.table td {
  padding-left: 12px;
  padding-right: 12px;
}

.actionBtns {
  text-align: right !important;
}

@media screen and (min-width:1020px) {
  #taskEditStatusModal .modal-dialog {
    width: 35%;
  }
}

.user-list {
  max-height: 120px;
  /* Set a maximum height for the user list */
  overflow-y: auto;
  /* Add a scrollbar when the content exceeds the height */
  border: 1px solid #ccc;
  /* Optional: Add a border for styling */
}

.user-item {
  margin-bottom: 8px;
  /* Optional: Add some spacing between user items */
}

.expired-row {
  background-color: #dc8484;
}

.badge-primary {
  color: #4ba64f !important;
  font-size: 14px !important;
}

.table-cell {
  font-size: 1.0625rem;
  padding-bottom: 10px;
  font-weight: 500;
}

.rowtaskNotAssigned {
  background: #faead6;
}

.rowtaskAssigned {
  background: #eaea8d;
}

.rowtaskInProcess {
  background: #b4e9d4;
}

.rowtaskCompleted {
  background: #9ed79e;
}

.dropdown {
  width: 100%;
  height: 36px;
  padding: 7px 0;
  font-size: 14px;
  line-height: 1.428571429;
  border-color: #D2D2D2;
}

.progress {
  float: left;
}

.highlight-text {
  margin-left: 5px;
  margin-right: 8px;
}

.highlight-text:hover {
  color: blue;
  font-weight: 400;
}

.color-box {
  display: inline-block;
  padding-left: 6px;
  cursor: pointer;
  padding-top: 5px;
}

.modal-content .modal-header {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.modal-title {
  margin-top: 10px;
}

.modal-content .modal-body {
  padding-top: 10px;
}

.task-table {
  width: 100%;
}

@media screen and (min-width:990px) and (max-width: 1185px) {
  .task-table {
    width: 1100px;
    overflow-x: auto
  }
}

@media screen and (max-width: 890px) {
  .task-table {
    width: 830px;
    overflow-x: auto
  }
}
.toprow {
  color: #fff;
  background: #0d6efd;
  height: 35px;
  font-weight: 400;
  font-size: 16px;
}
.notification {
  border-radius: 100%;
  padding: 5px;
  text-align: center;
  font-size: 11px;
  color: white;
  background-color: red;
  width: 40px;
}
</style>
