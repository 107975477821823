<template>
  <div>
    <div class="topchart-div">
      <div class="inner-div">
        <canvas ref="chartCanvas"></canvas>
        <template>
          <h4 class="Count-header">Assigned & Completed Tasks: {{ TotalTaskCount }}</h4>
          <button class="zoom-in" title="Click to Zoom" data-bs-toggle="modal" data-bs-target="#taskChartModal" data-bs-whatever="@mdo">
            <i class="fas fa-search-plus"></i></button>
        </template>
      </div>
    </div>
    <!-- Modal for User Chart -->
    <div class="modal fade" id="taskChartModal" tabindex="-1" aria-labelledby="taskChartModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <!-- <h1 class="modal-title fs-5" id="taskModalLabel">
                      {{ editTaskMode ? "Edit Task" : "Add New Task" }}
                    </h1> -->
            <button class="btn-close" title="Close" data-bs-dismiss="modal" aria-label="Close" tabindex="-2"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <canvas ref="chartCanvasModal" width="100%"></canvas>
            </div>
          </div>
          <div class="modal-footer">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';
import api, { setAuthToken } from "../../components/apiConfig";
import Cookies from "js-cookie";
import { ErrorLog } from "../Common/APILog";
import { handleAuthorization, handleServerError } from '../Common/commonMethod';

export default {
  data() {
    return {
      CompletedTask: [],
      TotalTaskCount: 0,
      flag: 0,
      AssignedTask: []
    }
  },
  mounted() {
    //this.renderChart();
    this.getTotalCompletedTask();
  },
  methods: {
    async getTotalCompletedTask() {
      try {
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie = userCookie ? JSON.parse(userCookie) : null;
        const response = await api.get(`/api/admin/tasks/total-completed-task/${userCookie.UserID}`);
        if (response.data) {
          console.log("total", response.data.data)
          this.TotalTaskCount = Object.values(response.data.data.completeTaskResult[0]).reduce((acc, cur) => acc + cur, 0);
          this.TotalTaskCount = this.TotalTaskCount + Object.values(response.data.data.AssignTask[0]).reduce((acc, cur) => acc + cur, 0);
          console.log("count", this.TotalTaskCount);
          //  this.chartCompletedData.datasets[0].data=response.data[0];
          this.CompletedTask = response.data.data.completeTaskResult[0];
          this.AssignedTask = response.data.data.AssignTask[0];
          this.renderChart();
        }
        // Continue with any other logic (e.g., navigate to a different page)
      } catch (error) {
        if (this.flag > 3) {
          if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              // this.formHeader = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              // this.formHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 94, error.stack, "AdminPanel/TaskChart/getTotalCompletedTask()");
          }
        }
        else {
          // this.formHeader = "Something went wrong.";
        }
        }
        else {
          setTimeout(() => {
            this.getTotalCompletedTask(); // Retry after a delay
          }, 1000);
          this.flag++;
        }

      } finally {
        //this.loading = false; // Set loading state to false after data is fetched
      }
    },
    renderChart() {
      const ctx = this.$refs.chartCanvas.getContext('2d');
      const ctx2 = this.$refs.chartCanvasModal.getContext('2d');
      new Chart(ctx, {
        type: 'bar',
        data: {
          //labels: ['Category 1', 'Category 2', 'Category 3'],
          datasets: [
            {
              label: 'Completed Task',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
              borderColor: 'rgb(255, 99, 132)',
              borderWidth: 1,
              data: this.CompletedTask
            },
            {
              label: 'Assigned Task',
              backgroundColor: 'rgba(54, 162, 235, 0.5)',
              borderColor: 'rgb(54, 162, 235)',
              borderWidth: 1,
              data: this.AssignedTask
            }
          ]
        },
        options: {
          scales: {
            xAxes: [{ stacked: true }],
            yAxes: [{ stacked: true }]
          }
        }
      });
      new Chart(ctx2, {
        type: 'bar',
        data: {
          //labels: ['Category 1', 'Category 2', 'Category 3'],
          datasets: [
            {
              label: 'Completed Task',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
              borderColor: 'rgb(255, 99, 132)',
              borderWidth: 1,
              data: this.CompletedTask
            },
            {
              label: 'Assigned Task',
              backgroundColor: 'rgba(54, 162, 235, 0.5)',
              borderColor: 'rgb(54, 162, 235)',
              borderWidth: 1,
              data: this.AssignedTask
            }
          ]
        },
        options: {
          scales: {
            xAxes: [{ stacked: true }],
            yAxes: [{ stacked: true }]
          }
        }
      });
    }
  }
};
</script>

<style scoped>
/* Add any necessary styles here */
.Count-header {
  font-size: 14px;
  font-weight: 600;
  float: left;
}

.zoom-in {
  float: right;
  border-radius: 5px;
  color: #fff;
  background: #0d6efd;
  border: 2px solid #0d6efd;
  margin-top: 10px;
  padding-top: 3px;
}

.zoom-in i {
  font-size: 16px;
}

@media (min-width: 720px) {
  .modal-dialog {
    width: 700px;
    max-width: 700px;
  }
}

.topchart-div {
  overflow: hidden;
  position: relative;
}

.inner-div {
  position: absolute;
  bottom: 0;
  width: 100%;
}

@media screen and (max-width:1280px) {
  .inner-div {
    position: unset !important;
  }
}

@media screen and (min-width:1281px) {
  .topchart-div {
    height: 192px !important;
  }
}

@media screen and (min-width:1300px) {
  .topchart-div {
    height: 197px !important;
  }
}

@media screen and (min-width:1350px) {
  .topchart-div {
    height: 205px !important;
  }
}

@media screen and (min-width:1400px) {
  .topchart-div {
    height: 220px !important;
  }
}
</style>