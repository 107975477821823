// api.js

import axios from "axios";
import Cookies from "js-cookie";

const instance = axios.create({
//  baseURL: "http://localhost:3000", //local server
  baseURL: "http://trackerapi.workanthem.com",//live server
  // baseURL: "http://localhost:4000/",
  // timeout: 20000, // Set a timeout for requests (optional)
  headers: {
    "Content-Type": "application/json",
    // Add any other common headers here
  },
});

const setAuthToken = (token) => {
  if (token) {
    instance.defaults.headers.common["Authorization"] = `${token}`;
  } 
  else {
    delete instance.defaults.headers.common["Authorization"];
    Cookies.remove("jwt");
    Cookies.remove("user");
  }
};

// const baseWebsocketUrl= "ws://localhost:3000";
// const baseimageURL = "http://localhost:3000/uploads";
const baseimageURL = "http://trackerapi.workanthem.com/uploads";
const baseWebsocketUrl ="ws://trackerapi.workanthem.com";

// Send request to the server to get all
export { setAuthToken,baseWebsocketUrl,baseimageURL};
export default instance;