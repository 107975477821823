<template>
  <div class="content">
    <LoadingOverlay :loading="Isloading" />
    <div class="md-layout">
      <div class="md-layout-item md-large-size-100 md-size-66">
        <md-card style="padding-bottom: 20px;">
          <md-card-header data-background-color="green" style="overflow: hidden;padding-bottom: 10px;">
            <h4 class="title" style="float: left;">Edit Task</h4>
            <button type="button" class="btn btn-secondary ml-2" id="closeModal" title="Back" tabindex="7" style="float: right;"
              @click="goBack">
              Back
            </button>
          </md-card-header>
          <md-card-content>
            <!-- Display success alert -->
            <div v-if="successAlertOnSubmit" class="alert alert-success text-left" role="alert">
              {{ successAlertOnSubmit }}
              <span @click="hideTaskAlert" class="close-icon">&times;</span>
            </div>
            <div v-if="errorAlertOnSubmit" class="alert alert-danger text-left" role="alert">
              {{ errorAlertOnSubmit }}
              <span @click="hideTaskAlert" class="close-icon">&times;</span>
            </div>
            <div v-if="loadingTasks" class="text-center">
              <p>{{ loadingTasks }}</p>
            </div>
            <form @submit.prevent="submitTaskForm" v-if="!loadingTasks" enctype="multipart/form-data">
              <input v-model="editTask.TaskID" type="hidden" class="form-control" />
              <div class="row">
                <div class="col-md-12">
                  <div class="mb-3">
                    <label for="title" class="col-form-label">Title</label>
                    <input v-model="editTask.Title" type="text" class="form-control" maxlength="100" id="title"
                      tabindex="1" @input="isValidTaskForm" />
                    <span class="text-red-500 text-sm">{{
                      taskValidate.Title
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <div class="mb-3">
                    <label for="priority" class="col-form-label">Priority</label>
                    <select v-model="editTask.PriorityID" class="form-control" id="priority" tabindex="3"
                      @change="isValidTaskForm">
                      <option :value="0">Select Priority</option>
                      <option v-for="priorityOption in priorityDropdownOptions" :key="priorityOption.PriorityID"
                        :value="priorityOption.PriorityID">
                        {{ priorityOption.Name }}
                      </option>
                    </select>
                    <span class="text-red-500 text-sm">{{
                      taskValidate.PriorityID
                    }}</span>
                  </div>
                </div>
                <div class="col-md-5" v-if="!TaskPrivate">
                  <div class="mb-3">
                    <label for="category" class="col-form-label">Project</label>
                    <select v-model="editTask.CategoryID" class="form-control" id="category" tabindex="4"
                      @change="isValidTaskForm">
                      <option :value="0">Select Project</option>
                      <option v-for="categoryOption in categoryDropdownOptions" :key="categoryOption.CategoryID"
                        :value="categoryOption.CategoryID">
                        {{ categoryOption.CategoryName }}
                      </option>
                    </select>
                    <span class="text-red-500 text-sm">{{
                      taskValidate.CategoryID
                    }}</span>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="mb-3">
                    <label for="dueDate" class="col-form-label">Due Date</label>
                    <flat-pickr @input="isValidTaskForm" class="form-control" :modelValue="editTask.DueDate"
                      @update:modelValue="editTask.DueDate = $event" :config="flatpickrConfig"></flat-pickr>
                    <span class="text-red-500 text-sm">{{
                      taskValidate.DueDate
                    }}</span>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mb-3">
                    <label for="description" class="col-form-label">Description</label>
                    <textarea v-model="editTask.Description" class="form-control" id="description" maxlength="255"
                      tabindex="2" @input="isValidTaskForm" style="height: 36px !important;"></textarea>
                    <span class="text-red-500 text-sm">{{
                      taskValidate.Description
                    }}</span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="fileUpload" class="col-form-label">Attachment</label>
                    <input type="file" class="form-control" id="fileUpload" ref="file" name="file" tabindex="5"
                      @change="handleFileUpload" />
                  </div>
                </div>
                <div class="col-md-6"
                  v-if="editTask.InfoFile != 'null' && editTask.InfoFile != '' && editTask.InfoFile != null">
                  <div class="mb-3">
                    <p v-if="imageURLValid && editTask.InfoFile != null">
                      <a :href="editTask.InfoFile" target="blank" class="browser-link">
                        <img :src="editTask.InfoFile" class="image-link" alt="Image"></a>
                    </p>
                    <p v-if="!imageURLValid && editTask.InfoFile != null" class="file-link">
                      <a :href="editTask.InfoFile" target="blank">Attachment File</a>
                    </p>
                  </div>
                </div>
                <div class="col-md-12" v-if="!TaskPrivate">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-3">
                        <label for="category" class="col-form-label">Assign to Users</label>
                        <div class="user-list" v-if="DropdownOptions.userOptions.length > 0">
                          <div v-for="user in DropdownOptions.userOptions" :key="user.UserID" class="user-item">
                            <input tabindex="2" type="checkbox" :id="'userCheckbox_' + user.UserID" :value="user.UserID"
                              v-model="taskAssignment.selectedUsers" @input="isValidTaskAssignmentForm" />
                            <label :for="'userCheckbox_' + user.UserID">{{ user.UserName }}</label>
                          </div>
                        </div>
                        <div v-else>No users available.</div>
                        <span class="text-red-500 text-sm">{{
                          taskAssignmentValidate.selectedUsers
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6" hidden>
                  <div class="mb-3">
                    <label for="isCompleted" class="col-form-label">Is Completed</label>
                    <div class="form-check form-switch">
                      <input v-model="editTask.IsCompleted" type="checkbox" class="form-check-input" id="isCompleted"
                        tabindex="5" @input="isValidTaskForm" />
                      <label class="form-check-label" for="isCompleted">
                        {{
                          editTask.IsCompleted
                            ? "Completed"
                            : "Not Completed"
                        }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <button type="submit" title="Update" class="btn btn-primary" style="float: right;" default tabindex="6"
                :disabled="btnTaskEnable">
                Update
              </button>
            </form>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import api, { setAuthToken } from "../apiConfig";
import { authMixin } from "./adminStore";
import Cookies from "js-cookie";
import Flatpickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { ErrorLog } from "../Common/APILog";
import LoadingOverlay from '../Common/blockoutpage.vue';
import { handleAuthorization, handleServerError } from "../Common/commonMethod";


export default {
  mixins: [authMixin],
  created() {
    this.checkAuthorization(); // Check authorization when the component is created
    this.getTaskById();
    this.getDropdownOptions();
  },
  name: "EditTask",
  components: {
    'flat-pickr': Flatpickr,
    LoadingOverlay
  },
  props: {
    taskId: {
      validator(value) {
        const numberValue = parseInt(value);
        if (isNaN(numberValue)) {
          console.error('Invalid taskId prop: must be a number');
          return false;
        }
        return true;
      }
    },
  },
  data() {
    return {
      loadingTasks: "",
      Isloading:false,
      errorAlertOnSubmit: "",
      successAlertOnSubmit: "",
      TaskPrivate: false,
      categoryDropdownOptions: [],
      priorityDropdownOptions: [],
      btnTaskEnable: false,
      editTask: {
        TaskID: 0,
        Title: "",
        Description: "",
        DueDate: new Date(),
        IsCompleted: false,
        PriorityID: 0,
        CategoryID: 0,
        UserId: 0,
        InfoFile: null,
        TaskType: null,
        file: null,
      },
      taskValidate: {
        Title: "",
        Description: "",
        PriorityID: "",
        CategoryID: "",
        // Add additional validation fields as needed
      },
      taskAssignment: {
        updateTaskId: 0,
        TaskID: 0,
        TaskTitle: "",
        UserId: 0,
        selectedUsers: [],
        DueDate: null,
      },
      taskAssignmentValidate: {
        TaskID: "",
        UserId: "",
        selectedUsers: "",
        DueDate: "",
        // Add additional validation fields as needed
      },
      errors: {},
      imageURLValid: false,
      formHeader: "",
      selectedFile: "",
      DropdownOptions: {
        userOptions: [],
        taskOPtions: [],
      },
      flatpickrConfig: {
        dateFormat: 'd/m/Y', // Set the date format to year-month-day
        //  minDate: 'today' // Set minDate to today's date
      },
    };
  },
  computed: {
  },
  mounted() {
    this.getTaskAssignmentById();
  },
  methods: {
    convertDateFormat(date) {
      // Check if inputDate is in dd/mm/yyyy format
      const dateParts = date.split('/');
      if (dateParts.length !== 3) {
        console.error('Invalid date format. Please enter date in dd/mm/yyyy format.');
        return;
      }
      // Parse input date parts
      const day = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10);
      const year = parseInt(dateParts[2], 10);
      // Create a new Date object with dd/mm/yyyy format
      const parsedDate = new Date(year, month - 1, day + 1);
      // Format the date as yyyy-mm-dd
      const formattedDate = parsedDate.toISOString().split('T')[0];
      console.log("date", formattedDate)
      // Update inputDate with the formatted date
      return formattedDate;
    },
    handleFileUpload(event) {
      this.editTask.file = event.target.files[0];
    },
    isValidTaskAssignmentForm() {
      let isValid = true;
      this.taskAssignmentValidate.selectedUsers = "";
      return isValid;
    },
    async getTaskAssignmentById() {
      this.Isloading=true;
      this.taskAssignment = {
        updateTaskId: 0,
        TaskID: 0,
        TaskTitle: '',
        UserId: 0,
        selectedUsers: [],
        DueDate: null,
      };
      try {
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        const response = await api.get(
          `/api/admin/tasks/get-assigned-tasks-details/${userCookie.UserID}?taskId=${this.taskId}`
        );
        if (response.data) {
          if (response.data.length > 0) {
            console.log("assigned", response.data[0])
            // Assign the values from the API response to newTask
            this.taskAssignment.TaskID = response.data[0].TaskId;
            this.taskAssignment.updateTaskId = response.data[0].TaskId;
            this.taskAssignment.UserId = userCookie.UserID; // Assuming you want to keep the user who is updating the task
            this.taskAssignment.DueDate = this.formatDueDate(
              response.data[0].DueDate,
              "update"
            );
            // If the API response provides an array of selected users, you can directly assign it
            const selectedUsersArray =
              response.data[0].SelectedUsers.split(",").map(Number);
            this.taskAssignment.selectedUsers = selectedUsersArray;
            this.editMode = true;
          } else {
            this.taskAssignment.TaskID = this.taskId;
            this.taskAssignment.UserId = userCookie.UserID;
          }
        } else {
          this.errorAlertOnSubmit = "Something went wrong. please try again later.";
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 345, error.stack, "AdminPanel/EditTask/getTaskAssignmentId()");
          }
        }
        else {
          this.errorAlertOnSubmit = "Something went wrong.";
        }
      }
      finally {
        this.Isloading=false;
        setTimeout(() => {
          this.errorAlertOnSubmit = "";
          this.successAlertOnSubmit = "";
        }, 5000); // 3 seconds in milliseconds
      }
    },
    hideTaskAlert() {
      this.successAlertOnSubmit = "";
      this.errorAlertOnSubmit = "";
    },
    goBack() {
      // Go back to the previous page in the history
      this.$router.go(-1);
    },
    // Check if URL ends with a common image file extension
    isImageURL(url) {
      const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp'];
      const extension = url.substring(url.lastIndexOf('.')).toLowerCase();
      if (imageExtensions.includes(extension)) {
        this.imageURLValid = true;
      }
      //return imageExtensions.includes(extension);
    },
    async updateTask() {
      this.successAlertOnSubmit = "";
      this.Isloading=true;
      try {
        this.btnTaskEnable = true;
        const token = Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        const formData = new FormData();
        formData.append('Title', this.editTask.Title);
        formData.append('Description', this.editTask.Description);
        formData.append('TaskType', this.editTask.TaskType);
        formData.append('DueDate', this.convertDateFormat(this.editTask.DueDate));
        formData.append('IsCompleted', false);
        formData.append('PriorityID', this.editTask.PriorityID);
        formData.append('CategoryID', this.editTask.CategoryID);
        formData.append('file', this.editTask.file);
        formData.append('InfoFile', this.editTask.InfoFile);
        formData.append('UserID', userCookie.UserID);
        formData.append('assignBy', userCookie.UserID);
        formData.append('userIds', this.taskAssignment.selectedUsers);
        const response = await api.post('/api/admin/tasks/update-task/' + this.editTask.TaskID, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        //    console.log("res",response);
        if (response.data.success) {
          this.successAlertOnSubmit = "Task updated successfully.";
          this.getTaskById();
        } else {
          this.errorAlertOnSubmit = response.data.msg;
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 428, error.stack, "AdminPanel/EditTask/updateTask()");
          }
        }
        else {
          this.errorAlertOnSubmit = "Something went wrong.";
        }
      } finally {
        this.btnTaskEnable = false;
        this.Isloading=false;
        setTimeout(() => {
          this.errorAlertOnSubmit = "";
          this.successAlertOnSubmit = "";
        }, 5000); // 3 seconds in milliseconds
        setTimeout(() => {
          // Call your function here
          this.goBack();
        }, 2000); // 2000 milliseconds = 2 seconds
        // this.goBack();
      }
    },
    resetTaskValidation() {
      this.taskValidate = {
        Title: "",
        DueDate: "",
      };
    },
    async submitTaskForm() {
      this.resetTaskValidation();
      if (this.isValidTaskForm()) {
        this.updateTask();
      }
    },
    async getDropdownOptions() {
      try {
        const token =await Cookies.get("jwt");
        this.Isloading=true;
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        const response = await api.get(
          `/api/admin/tasks/get-dropdown-tasks-users/${userCookie.UserID}`
        );
        if (response.data) {
          this.DropdownOptions.userOptions = response.data.users;
          this.priorityDropdownOptions = response.data.priorities;
          this.categoryDropdownOptions = response.data.categories.slice().sort((a, b) => a.CategoryName.localeCompare(b.CategoryName));
        } else {
          // Handle case where no priorities are found
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 493, error.stack, "AdminPanel/EditTask/getDropdownOptions()");
          }
        }
        else {
          this.errorAlertOnSubmit = "Something went wrong.";
        }
        // Handle API error
      }finally{
        this.Isloading=false;
      }
    },
    formatDueDate(originalDate, type = "show") {
      console.log(originalDate);
      if (!originalDate) return ""; // Handle null or undefined dates
      const dateObject = new Date(originalDate);
      const day = dateObject.getDate().toString().padStart(2, "0");
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObject.getFullYear();
      if (type == "show") {
        return `${day}/${month}/${year}`;
      } else {
        return `${day}/${month}/${year}`;
      }
    },
    isValidTaskForm() {
      let isValid = true;
      // Title
      if (!this.editTask.Title.trim()) {
        this.taskValidate.Title = "Title is required.";
        isValid = false;
      } else {
        this.taskValidate.Title = "";
      }
      // DueDate
      if (!this.editTask.DueDate) {
        this.taskValidate.DueDate = "DueDate is required.";
        isValid = false;
      } else {
        this.taskValidate.DueDate = "";

      }
      // Description
      if (!this.editTask.Description.trim()) {
        this.taskValidate.Description = "Description is required.";
        isValid = false;
      } else {
        this.taskValidate.Description = "";
      }

      // Priority
      if (this.editTask.PriorityID === 0 || this.editTask.PriorityID === "0") {
        this.taskValidate.PriorityID = "Please select priority.";
        isValid = false;
      } else {
        this.taskValidate.PriorityID = "";
      }
      //assign user
      if (this.taskAssignment.selectedUsers.length == 0) {
        this.taskAssignmentValidate.selectedUsers = "Please select users.";
        isValid = false;
      } else {
        this.taskAssignmentValidate.selectedUsers = "";
      }
      // Category
      if (!this.TaskPrivate) {
        if (this.editTask.CategoryID === 0 || this.editTask.CategoryID === "0") {
          this.taskValidate.CategoryID = "Please select project.";
          isValid = false;
        } else {
          this.taskValidate.CategoryID = "";
        }
      }
      return isValid;
    },
    async getTaskById() {
      try {
        this.loadingTasks = "Loading...";
        const token =await Cookies.get("jwt");
        this.Isloading=true;
        setAuthToken(token);
        const response = await api.get(
          `/api/admin/tasks/get-task-by-id/${this.taskId}`
        );
        if (response.data) {
          console.log("data0", response.data.data[0]);
          this.editTask = response.data.data[0];
          if (this.editTask.InfoFile != null) {
            this.isImageURL(this.editTask.InfoFile);
          }
          if (this.editTask.TaskType == "Private") {
            this.TaskPrivate = true;
          }
          this.editTask.DueDate = this.formatDueDate(
            response.data.data[0].DueDate,
            "update"
          );
          console.log("duedate", this.editTask.DueDate)
        } else {
          // alert(`Error: ${response.statusText}`);
          this.errorAlertOnSubmit = "Something went wrong. please try again later.";
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 345, error.stack, "AdminPanel/EditTask/getTaskbyId()");
          }
        }
        else {
          this.errorAlertOnSubmit = "Something went wrong.";
        }
      }
      finally {
        this.loadingTasks = "";
        this.Isloading=false;
      }
    },

  },
};
</script>

<style scoped>
/* Add Tailwind CSS classes here if needed */
.user-item {
  display: inline;
  margin-right: 12px;
}

.close-icon {
  cursor: pointer;
  float: right;
  font-size: 30px;
  margin-top: -10px;
}

.image-link {
  width: 50%;
  margin: 0px auto;
}

.file-link {
  margin-top: 35px;
  margin-bottom: 0px;
}

.file-link a {
  color: blue !important;
  font-weight: 500;
}

.browser-link {
  color: blue !important;
  font-weight: 500;
  padding-left: 10px;
}
</style>
