<template>
  <div class="content" style="overflow-x:hidden;">
    <LoadingOverlay :loading="Isloading" />
    <div class="row">
      <div class="col-md-12 ms-3 pt-2 pb-3">
        <div class="color-box" v-on:click="filterData(6)">
          <span class="highlight-text">All Task</span>
        </div>
        <div class="color-box" v-on:click="filterData(4)">
          <div class="progress task-detail-value" style="width: 25px; margin-top: 3px;">
            <div title="Low" class="progress-bar bg-info" role="progressbar"
              style="width: 100%;background-color: #9ed79e !important;" aria-valuenow="0" aria-valuemin="0"
              aria-valuemax="100">&nbsp;</div>
          </div><span class="highlight-text">Complete</span>
        </div>
        <div class="color-box" v-on:click="filterData(5)">
          <div class="progress task-detail-value" style="width: 25px; margin-top: 3px;">
            <div title="Low" class="progress-bar bg-info" role="progressbar"
              style="width: 100%;background-color: #dc8484 !important;" aria-valuenow="0" aria-valuemin="0"
              aria-valuemax="100">&nbsp;</div>
          </div><span class="highlight-text">Due</span>
        </div>
        <div class="color-box" v-on:click="filterData(3)">
          <div class="progress task-detail-value" style="width: 25px; margin-top: 3px;">
            <div title="Low" class="progress-bar bg-info" role="progressbar"
              style="width: 100%;background-color: #b4e9d4 !important;" aria-valuenow="0" aria-valuemin="0"
              aria-valuemax="100">&nbsp;</div>
          </div><span class="highlight-text">In Process</span>
        </div>
        <div class="color-box" v-on:click="filterData(2)">
          <div class="progress task-detail-value" style="width: 25px; margin-top: 3px;">
            <div title="Low" class="progress-bar bg-info" role="progressbar"
              style="width: 100%;background-color: #eaea8d !important;" aria-valuenow="0" aria-valuemin="0"
              aria-valuemax="100">&nbsp;</div>
          </div><span class="highlight-text">Assigned</span>
        </div>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
        <md-card>
          <md-card-header data-background-color="green">
            <div class="card-header d-flex justify-content-between align-items-center">
              <h4 class="title">Project Tasks</h4>
              <!-- <select v-model="filterId" id="priority" class="gridFilter" @change="handleGridFilterChange">
                
                <option v-for="filterOption in taskFilterDropdownOPtions" :key="filterOption.Id"
                  :value="filterOption.Id">
                  {{ filterOption.Name }}
                </option>
              </select> -->
              <div class="ms-auto">
                <input type="text" v-model="searchText" class="gridFilter" @keyup="filterGrid"
                  style="margin:5px 5px 0 0;" placeholder="Search...">
                  <button type="button" title="Back" @click="GoBack" class="btn btn-dark btn-sm ms-2 ml-2">
                  Back
                </button>
                <button hidden type="button" title="Task Assignment" data-bs-toggle="modal" data-bs-target="#taskAssignmentModal"
                  data-bs-whatever="@mdo" class="btn btn-primary" id="openAssignmentModal">
                  Task Assignment
                </button>
            
           
              </div>
            </div>
          </md-card-header>
          <md-card-content>
            <div class="col-md-12">
              <!-- Display success alert -->
              <div v-if="successAlertOnSubmit" class="alert alert-success text-left" role="alert">
                {{ successAlertOnSubmit }}
                <span @click="hideTaskAlert" class="close-icon">&times;</span>
              </div>
              <div v-if="errorAlertOnSubmit" class="alert alert-danger text-left" role="alert">
                {{ errorAlertOnSubmit }}
                <span @click="hideTaskAlert" class="close-icon">&times;</span>
              </div>
              <div class="table-responsive" style="overflow: auto !important;">
                <div v-if="loadingTasks" class="text-center">
                  <p>{{ loadingTasks }}</p>
                </div>
                <div class="task-table" v-if="!loadingTasks">
                  <table style="width: 100%;" :table-header-color="tableHeaderColor">
                    <tr class="toprow">
                      <td style="width:5%;padding-left:5px;" class="table-cell">ID</td>
                      <td style="width:20%" class="table-cell">Task</td>
                      <!-- <td style="width:22%" class="table-cell">Description</td> -->
                      <td style="width:15%" class="table-cell">Priority</td>
                      <td style="width:15%" class="table-cell">Status</td>
                      <td style="width:30%;padding-left:5px;" class="table-cell">Progress Percent</td>
                      <td style="width:25%;text-align:center" class="table-cell">Actions</td>
                    </tr>
                    <tr slot="md-table-row" v-for="item in paginatedTasks" :key="item.id" :class="checkRowColor(item)"
                      style="border-bottom:1px solid #ddd">
                      <td class="md-table-cell">{{ item.TaskId }}</td>
                      <td class="md-table-cell"><span v-if="item.Title.length > 60" :title="item.Title">
                          {{ truncateDescription(item.Title) }}
                        </span>
                        <span v-else>
                          {{ item.Title }}
                        </span>
                      </td>
                      <!-- <td class="md-table-cell">
                        <span v-if="item.Description.length > 70" :title="item.Description">
                          {{ truncateDescription(item.Description) }}
                        </span>
                        <span v-else>
                          {{ item.Description }}
                        </span>
                      </td> -->
                      <td class="md-table-cell">{{ item.PriorityName }}</td>
                      <td class="md-table-cell"> <span v-if="item.TaskStatus === 1" class="taskNotAssigned">
                          Not Assigned
                        </span>
                        <span v-else-if="item.TaskStatus === 2" class="taskAssigned">Assigned
                        </span>
                        <span v-else-if="item.TaskStatus === 3" class="taskInProcess">In Process
                        </span>
                        <span v-else-if="item.TaskStatus === 4" class="taskCompleted">Completed
                        </span>
                      </td>
                      <td class="md-table-cell">
                      <progress-bar
                          :options="options"
                          :value="item.ProgressPercent"
                        />
                       <!-- <span v-if="item.IsCompleted!=0"> {{ item.ProgressPercent }}</span> -->
                    </td>
                      <td class="md-table-cell" style="text-align:right">
                        <sup><span v-if="item.UnreadChatCount > 0" class="notification mx-1">
                            {{ formatChatCount(item.UnreadChatCount) }}
                            <!-- 34 -->
                          </span>
                        </sup>
                        <button @click="viewTaskDetail(item.TaskId)" type="button" class="btn btn-primary btn-sm ml-2"
                          :title="item.AssignedToUsernames">
                          <i class="fa fa-users" aria-hidden="true"></i>
                        </button>
                        <button @click="viewTaskDetail(item.TaskId)" type="button" class="btn btn-primary btn-sm ml-2"
                          title="View">
                          <i class="fa fa-eye" aria-hidden="true"></i>
                        </button>
                        <button :disabled="item.IsCompleted" v-if="!item.IsCompleted"
                          @click="editTaskDetail(item.TaskId)" type="button" class="btn btn-primary btn-sm ml-2"
                          title="Edit">
                          <i class="fas fa-pencil-alt"></i>
                        </button>
                        <!-- <button @click="openDeleteModal(item.TaskID)" type="button" class="btn btn-danger btn-sm ml-2"
                          title="Delete Task">
                          
                          <i class="fa fa-trash"></i>
                        </button> -->
                      </td>
                    </tr>
                    <tr v-if="IsTasks" style="border-top: 1px solid #ccc;">
                      <td colspan="6">
                        <p style="padding-top:10px;padding-left:10px;">No Task Found</p>
                      </td>
                    </tr>
                  </table>
                  <div class="d-flex my-1 mt-2" v-if="!IsTasks">
                    <div style="width: 33%;" class="d-flex justify-content-between">
                      <div style="width: 60% !important;">
                        <span style="line-height:50px;">Tasks per page:</span>
                      </div>
                      <div  style="width: 40%;">
                        <Select @change="setPageSize" class="form-select PageSizeSelect"
                        v-model="pageSize">
                        <Option :value="5">5</Option>
                        <Option :value="10">10</Option>
                        <Option :value="20">20</Option>
                        <Option :value="30">30</Option>
                      </Select>
                      </div>
                    </div>
                    <div class="Pagination">
                      <button @click="previousPage" :disabled="currentPage === 1" title="Previous"
                        :class="{ 'disabled-button': currentPage === 1, 'enabled-button': currentPage !== 1 }"><i
                          class="fa fa-angle-left"></i> Prev</button>
                      <span class="current-page-indicator">{{ currentPage }}</span>
                      <button @click="nextPage" :disabled="currentPage === totalPages" title="Next"
                        :class="{ 'disabled-button': currentPage === totalPages, 'enabled-button': currentPage !== totalPages }">Next
                        <i class="fa fa-angle-right"></i></button>
                    </div>
                    <div style="width: 33%" class="d-flex justify-content-end mx-3">
                      <span style="line-height:50px;" class="text-right mx-1">Total Pages: </span>
                      <span style="line-height:50px;" class="text-right">{{ totalPages }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
      
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import api, { setAuthToken } from "../apiConfig";
import { authMixin } from "./adminStore";
import Cookies from "js-cookie";
import LoadingOverlay from '../Common/blockoutpage.vue';
import 'flatpickr/dist/flatpickr.css';
import { ErrorLog } from "../Common/APILog";
import Vue from "vue";
import ProgressBar from 'vuejs-progress-bar'
Vue.use(ProgressBar)
import { handleAuthorization, handleServerError } from "../Common/commonMethod";
export default {
  mixins: [authMixin],
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
    projectId: {
      validator(value) {
        const numberValue = parseInt(value);
        if (isNaN(numberValue)) {
          console.error('Invalid taskId prop: must be a number');
          return false;
        }
        return true;
      }
    },
    type: {
      validator(value) {
        const numberValue = parseInt(value);
        if (isNaN(numberValue)) {
          console.error('Invalid taskId prop: must be a number');
          return false;
        }
        return true;
      }
    },
  },
  created() {
    this.checkAuthorization();
  },
  name: "Project Tasks",
  components: {
    LoadingOverlay,
  },
  data() {
    return {
      tasks: [],
      Isloading: false,
      pageSize: 5, // Number of tasks per page
      currentPage: 1,// Current page
      searchText: '',
      filtertask: [],
      options: {
            text: {
              color: '#FFFFFF',
              shadowEnable: true,
              shadowColor: '#000000',
              fontSize: 14,
              fontFamily: 'Helvetica',
              dynamicPosition: false,
              hideText: false
            },
            progress: {
              color: '#43a047',
              backgroundColor: '#464a4e',
              inverted: false
            },
            layout: {
              height: 25,
              width: 140,
              verticalTextAlign: 61,
              horizontalTextAlign: 43,
              zeroOffset: 0,
              strokeWidth: 30,
              progressPadding: 0,
              type: 'line'
            }
          },
      newTask: {
        TaskID: 0,
        Title: "",
        Description: "",
        DueDate: null,
        file: null,
        IsCompleted: false,
        PriorityID: 0,
        CategoryID: 0,
        UserId: 0,
      },
    
    
     
      taskFormHeader: "",
      successAlertOnSubmit: "",
      editTaskMode: false,
      loadingTasks: "",
      IsTasks: false,
      btnTaskEnable: false,
      title: "Project Tasks",
      errorAlertOnSubmit: "",
      categoryDropdownOptions: [],
      priorityDropdownOptions: [],
      taskFilterDropdownOPtions: [
        { Id: 0, Name: "All Task" },
        { Id: 2, Name: "Assigned" },
        { Id: 4, Name: "Completed" },
        { Id: 3, Name: "In Process" }
      ],
      DropdownOptions: {
        userOptions: [],
        taskOPtions: [],
      },
      IsAlreadyAssigned: false,
      filterId: localStorage.getItem('filterid') ?? 0,
      filteredTasks: [],
    };
  },
  computed: {
    paginatedTasks() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.filteredTasks.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.filteredTasks.length / this.pageSize);
    },
    filteredData: function () {
      console.log("filter");
      const searchText = this.searchText.toLowerCase();
      return this.tasks.filter(item => {
        return (
          item.Title.toLowerCase().includes(searchText) ||
          item.Description.toString().includes(searchText)
        );
      });
    }
  },
  mounted() {
    this.getTasks();
    this.getDropdownOptions();
    //this.handleGridFilterChange();
  },
  methods: {
    setPageSize(event) {
      const selectedSize = event.target.value;
      this.pageSize = parseInt(selectedSize);
      this.currentPage = 1; // Reset to the first page
      this.paginatedTasks();
      // alert(`Page size changed to: ${selectedSize}`);
    },
    GoBack(){
        this.$router.go(-1)
    },
    convertDateFormat(date) {
      // Check if inputDate is in dd/mm/yyyy format
      const dateParts = date.split('/');
      if (dateParts.length !== 3) {
        console.error('Invalid date format. Please enter date in dd/mm/yyyy format.');
        return;
      }
      // Parse input date parts
      const day = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10);
      const year = parseInt(dateParts[2], 10);
      // Create a new Date object with dd/mm/yyyy format
      const parsedDate = new Date(year, month - 1, day + 1);
      // Format the date as yyyy-mm-dd
      const formattedDate = parsedDate.toISOString().split('T')[0];
      // Update inputDate with the formatted date
      return formattedDate;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    resetCreatTaskForm() {
      // Reset file input
      this.$refs.file.value = '';
      this.taskAssignment.selectedUsers = [];
    },
    handleFileUpload(event) {
      this.newTask.file = event.target.files[0];
    },
    filterGrid: function () {
      this.IsTasks = false;
      this.currentPage = 1;
      var searchText = this.searchText;
      if (parseInt(searchText) > 0) {
        this.filtertask = this.tasks.filter(item => {
          return (
            item.TaskID == parseInt(searchText)
          );

        });
        if (this.filtertask.length > 0) {
          this.filteredTasks = this.filtertask;
        }
        else {
          console.log("ok");
          this.filteredTasks = [];
          this.IsTasks = true;
        }
      }
      else {
        searchText = searchText.toLowerCase();
        this.filtertask = this.tasks.filter(item => {
          return (
            item.CategoryName.toLowerCase().includes(searchText) ||
            item.Title.toLowerCase().includes(searchText) ||
            // item.Description.toLowerCase().includes(searchText) ||
            item.PriorityName.toLowerCase().includes(searchText)
          );
        });
        if (this.filtertask.length > 0) {
          this.filteredTasks = this.filtertask;
        }
        else {
          console.log("ok");
          this.filteredTasks = [];
          this.IsTasks = true;
        }
      }
      // Filter tasks based on searchText
    },
    filterData: function (x) {
      this.currentPage = 1;
      this.IsTasks = false;
      if (parseInt(x) != 5 && parseInt(x) != 6) {
        this.filtertask = this.tasks.filter(item => {
          return (
            item.TaskStatus == parseInt(x)
          );
        });
        if (this.filtertask.length > 0) {
          this.filteredTasks = this.filtertask;
        }
        else {
          console.log("ok");
          this.filteredTasks = [];
          this.IsTasks = true;
        }
      }
      if (parseInt(x) == 5) {
        this.filteredTasks = this.tasks.filter(item => {
          return (
            item.IsExpired == 1
          );
        });
      }
      if (parseInt(x) == 6) {
        this.filteredTasks = this.tasks;
      }
      // Filter tasks based on searchText
    },
    checkRowColor(item) {
      const taskStatus = item.TaskStatus;
      if (item.IsExpired) {
        return "expired-row";
      }
      if (taskStatus === 1) {
        return "rowtaskNotAssigned";
      } else if (taskStatus === 2) {
        return "rowtaskAssigned";
      } else if (taskStatus === 3) {
        return "rowtaskInProcess";
      } else if (taskStatus === 4) {
        return "rowtaskCompleted";
      } else {
        return ""; // You can modify this based on your specific needs
      }
    },
    handleGridFilterChange() {
      this.getTasks();
    },
    async getDropdownOptions() {
      try {
        this.Isloading = true;
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie = userCookie ? JSON.parse(userCookie) : null;
        const response = await api.get(
          `/api/admin/tasks/get-dropdown-tasks-users/${userCookie.UserID}`
        );
        if (response.data) {
          this.DropdownOptions.userOptions = response.data.users;
          this.priorityDropdownOptions = response.data.priorities;
          this.categoryDropdownOptions = response.data.categories.slice().sort((a, b) => a.CategoryName.localeCompare(b.CategoryName));
        } else {
          // Handle case where no priorities are found
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              // this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              // this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 657, error.stack, "AdminPanel/Tasks/getDropDownOptions()");
          }
        }
        else {
          // this.errorAlertOnSubmit = "Something went wrong.";
        }
        // Handle API error
      }
      finally {
        this.Isloading = false;
      }
    },
    formatDueDate(originalDate, type = "show") {
      if (!originalDate) return ""; // Handle null or undefined dates

      const dateObject = new Date(originalDate);
      const day = dateObject.getDate().toString().padStart(2, "0");
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObject.getFullYear();
      if (type == "show") {
        return `${day}/${month}/${year}`;
      } else {
        return `${day}/${month}/${year}`;
      }
    },
    truncateDescription(description) {
      // Truncate the description if it's longer than 30 characters
      return description.length > 60
        ? description.slice(0, 60) + "..."
        : description;
    },
    handleTaskModalHidden() {
      this.editTaskMode = false;
    },
    closeModal() {
      this.editMode = false;
      this.resetTaskValidation();
      document.getElementById("closeModal").click();
    },

    closeAssignmentModal() {
      this.editMode = false;
      this.resetTaskAssignmentValidation();
      document.getElementById("closeAssignmentModal").click();
    },

    openModal() {
      document.getElementById("openModal").click();
    },
    openAssignmentModal() {
      document.getElementById("openAssignmentModal").click();
    },
    async getTasks() {
      this.Isloading = true;
      this.tasks = [];
      this.filteredTasks = [];
      this.loadingTasks = "Loading...";
      try {
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        //console.log(this.filterId);
        localStorage.setItem('filterid', this.filterId);
        const response = await api.get(
          `/api/categories/get-my-tasks-by-project/${this.projectId}/${this.type}`
        );
        if (response.data) {
          this.tasks = response.data;
          console.log("newdata", response.data);
          this.filteredTasks = this.tasks;
        } else {
          // Handle case where no tasks are found
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 764, error.stack, "AdminPanel/Tasks/getDropDownOptions()");
          }
        }
        else {
          this.errorAlertOnSubmit = "Something went wrong.";
        }
      } finally {
        this.Isloading = false;
        if (this.tasks.length > 0) {
          this.loadingTasks = "";
        } else {
          this.loadingTasks = "No tasks found.";
        }
      }
    },
    async getTaskById(taskId) {
      try {
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        const response = await api.get(
          `/api/admin/tasks/get-task-by-id/${taskId}`
        );
        if (response.data) {
          this.newTask = response.data.tasks[0];
          this.newTask.DueDate = this.formatDueDate(
            response.data.tasks[0].DueDate,
            "update"
          );
          this.openModal();
          this.resetTaskValidation();
        }
        else {
          this.errorAlertOnSubmit = "Something went wrong. please try again later.";
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 814, error.stack, "AdminPanel/Tasks/getTaskById()");
          }
        }
        else {
          this.errorAlertOnSubmit = "Something went wrong.";
        }
      }
    },
 
    editTask(taskId) {
      this.editTaskMode = true;
      this.getTaskById(taskId);
    },

    async OpenTask(TaskID) {
      this.taskFormHeader = "";
      this.IsReOpenTask = true;
      try {
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        const response = await api.put(
          `/api/admin/tasks/re-open-task/${TaskID}`
        );
        console.log("taskopen", response);
        if (response.data.success) {
          this.getTasks();
          this.successAlertOnSubmit = "Task Open successfully.";
        } else {
          this.taskFormHeader = response.data.msg;
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.taskFormHeader = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.taskFormHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 928, error.stack, "AdminPanel/Tasks/OpenTask()");
          }
        }
        else {
          this.taskFormHeader = "Something went wrong.";
        }
      } finally {
        //  this.btnTaskEnable = false;
        this.IsReOpenTask = false;
    
      }
    },
    isValidTaskForm() {
      let isValid = true;
      // Title
      if (!this.newTask.Title.trim()) {
        this.taskValidate.Title = "Title is required.";
        isValid = false;
      } else {
        this.taskValidate.Title = "";
      }

      // Description
      if (!this.newTask.Description.trim()) {
        this.taskValidate.Description = "Description is required.";
        isValid = false;
      } else {
        this.taskValidate.Description = "";
      }
      //assign user
      if (this.taskAssignment.selectedUsers.length == 0) {
        this.taskAssignmentValidate.selectedUsers = "Please select users.";
        isValid = false;
      } else {
        this.taskAssignmentValidate.selectedUsers = "";
      }
      // Priority
      if (this.newTask.PriorityID === 0 || this.newTask.PriorityID === "0") {
        this.taskValidate.PriorityID = "Please select priority.";
        isValid = false;
      } else {
        this.taskValidate.PriorityID = "";
      }

      // Category
      if (this.newTask.CategoryID === 0 || this.newTask.CategoryID === "0") {
        this.taskValidate.CategoryID = "Please select project.";
        isValid = false;
      } else {
        this.taskValidate.CategoryID = "";
      }
      // DueDate
      if (!this.newTask.DueDate) {
        this.taskValidate.DueDate = "Due Date is required.";
        isValid = false;
      } else {
        this.taskValidate.DueDate = "";
      }
      return isValid;
    },
    isValidTaskAssignmentForm() {
      let isValid = true;
      this.taskAssignmentValidate.selectedUsers = "";
      return isValid;
    },
    async assignTask() {
      this.taskFormHeader = "";
      try {
        this.btnTaskEnable = true;
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        const response = await api.post(
          `/api/admin/tasks/assign-task/${this.taskAssignment.TaskID}`,
          {
            assignBy: userCookie.UserID,
            userIds: this.taskAssignment.selectedUsers,
            DueDate: this.taskAssignment.DueDate,
          }
        );
        if (response.data.success) {
          this.getTasks();
          this.closeAssignmentModal(); // hide modal after successful submit.
          this.successAlertOnSubmit = "To-Do assigned successfully.";
        } else {
          //console.log(response);
          this.taskFormHeader = response.data.msg;
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.taskFormHeader = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.taskFormHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 1033, error.stack, "AdminPanel/Tasks/assignTask()");
          }
        }
        else {
          this.taskFormHeader = "Something went wrong.";
        }
      } finally {
        this.btnTaskEnable = false;
      }
    },
    async updateAssignTask() {
      this.taskFormHeader = "";
      try {
        this.btnTaskEnable = true;
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        const response = await api.put(
          `/api/admin/tasks/update-assign-task/${this.taskAssignment.TaskID}`,
          {
            assignBy: userCookie.UserID,
            userIds: this.taskAssignment.selectedUsers,
            updateTaskId: this.taskAssignment.updateTaskId,
            DueDate: this.taskAssignment.DueDate,
          }
        );
        if (response.data.success) {
          this.getTasks();
          this.closeAssignmentModal(); // hide modal after successful submit.
          this.successAlertOnSubmit = "To-Do assigned successfully.";
        } else {
          //console.log(response);
          this.taskFormHeader = response.data.msg;
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 1084, error.stack, "AdminPanel/Tasks/updateAssignTask()");
          }
        }
        else {
          this.errorAlertOnSubmit = "Something went wrong.";
        }
      } finally {
        this.btnTaskEnable = false;
      }
    },
    async submitTaskForm() {
      this.resetTaskValidation();

      if (this.isValidTaskForm()) {
        this.createTask();
      }
    },

    resetTaskValidation() {
      this.taskValidate = {
        Title: "",
        DueDate: "",
      };
    },
    hideTaskAlert() {
      this.taskFormHeader = "";
      this.errorAlertOnSubmit = "";
      this.successAlertOnSubmit = "";
    },
    async getTaskAssignmentById(taskId, title) {
      this.taskAssignment = {
        updateTaskId: 0,
        TaskID: 0,
        TaskTitle: title,
        UserId: 0,
        selectedUsers: [],
        DueDate: null,
      };
      try {
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        const response = await api.get(
          `/api/admin/tasks/get-assigned-tasks-details/${userCookie.UserID}?taskId=${taskId}`
        );
        if (response.data) {
          if (response.data.length > 0) {
            this.IsAlreadyAssigned = true;
            // Assign the values from the API response to newTask
            this.taskAssignment.TaskID = response.data[0].TaskId;
            this.taskAssignment.updateTaskId = response.data[0].TaskId;
            this.taskAssignment.UserId = userCookie.UserID; // Assuming you want to keep the user who is updating the task
            this.taskAssignment.DueDate = this.formatDueDate(
              response.data[0].DueDate,
              "update"
            );
            // If the API response provides an array of selected users, you can directly assign it
            const selectedUsersArray =
              response.data[0].SelectedUsers.split(",").map(Number);
            this.taskAssignment.selectedUsers = selectedUsersArray;
            this.editMode = true;
          } else {
            this.IsAlreadyAssigned = false;
            this.taskAssignment.TaskID = taskId;
            this.taskAssignment.UserId = userCookie.UserID;
          }
          this.openAssignmentModal();
          this.resetTaskAssignmentValidation();
        } else {
          this.errorAlertOnSubmit = "Something went wrong. please try again later."
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 1174, error.stack, "AdminPanel/Tasks/getTaskAssignmentById()");
          }
        }
        else {
          this.errorAlertOnSubmit = "Something went wrong.";
        }
      }
    },

    viewTaskDetail(taskId) {
      this.$router.push({
        path: `/task-details/${taskId}/3`
      });
    
    },
    editTaskDetail(taskId) {
      this.$router.push({
        name: "Edit Task",
        params: { taskId },
      });
    },
    resetTaskAssignmentValidation() {
      this.taskValidate = {
        TaskID: "",
        selectedUsers: "",
        DueDate: "",
      };
    },
    formatChatCount(count) {
      return count < 10 ? `0${count}` : count;
    },
  },
};
</script>

<style scoped>
/* Add Tailwind CSS classes here if needed */
.close-icon {
  cursor: pointer;
  float: right;
  font-size: 30px;
  margin-top: -10px;
}

.mb-3 {
  text-align: left;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1em;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.table th {
  background-color: #f2f2f2;
}

.expired-row {
  background-color: #dc8484;
}

/* Additional styling for left and right padding */
.table th,
.table td {
  padding-left: 12px;
  padding-right: 12px;
}

.user-list {
  min-height: 120px;
  max-height: 120px;
  /* Set a maximum height for the user list */
  overflow-y: auto;
  /* Add a scrollbar when the content exceeds the height */
  border: 1px solid #ccc;
  /* Optional: Add a border for styling */
}

.user-item {
  margin-bottom: 8px;
  /* Optional: Add some spacing between user items */
}

.actionBtns {
  text-align: right !important;
}

.alreadyAssignedText {
  color: #ed6524;
  font-size: 15px;
  text-align: center;
}

/* .taskNotAssigned {
  color: orange;
}

.taskAssigned {
  color: #ed6524;
}
.taskInProcess {
  color: #864426;
}

.taskCompleted {
  color: green;
} */

.rowtaskNotAssigned {
  background: #faead6;
}

.rowtaskAssigned {
  background: #eaea8d;
}

.rowtaskInProcess {
  background: #b4e9d4;
}

.PageSizeSelect {
  border-radius: 5px;
  margin: 5px;
  height: 35px;
  font-size: 17px;
}

.current-page-indicator {
  background-color: green;
  border-radius: 50%;
  /* Ensures the element is rounded */
  padding: 3px;
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  /* Adjust this value as needed */
  height: 25px;
  /* Ensure width and height are equal */
}

.rowtaskCompleted {
  background: #9ed79e;
}

.gridFilter {
  margin-left: auto;
  height: 30px;
  width: 130px;
}

.dropdown {
  width: 100%;
  height: 36px;
  padding: 7px 0;
  font-size: 14px;
  line-height: 1.428571429;
  border-color: #D2D2D2;
}

@media screen and (min-width:1020px) {
  #taskEditStatusModal .modal-dialog {
    width: 35%;
  }
}

.table-cell {
  font-size: 1.0625rem;
  padding-bottom: 10px;
  font-weight: 500;
  padding-top: 8px;
}

.toprow {
  color: #fff;
  background: #0d6efd;
  height: 35px;
  font-weight: 500;
  font-size: 16px;
}

.task-table {
  width: 100%;
}

@media screen and (min-width:990px) and (max-width: 1185px) {
  .task-table {
    width: 1100px;
    overflow-x: auto
  }
}

@media screen and (max-width: 890px) {
  .task-table {
    width: 830px;
    overflow-x: auto
  }
}

/*@media screen and (min-width:1100px) and (max-width: 1300px) {
  .task-table{
  width:1050px ;
  overflow-x:auto
}
} */
.user-item {
  width: 50%;
  float: left;
}

.user-list {
  min-height: 80px;
  border: 0px;
}

.progress {
  float: left;
}

.highlight-text {
  margin-left: 5px;
  margin-right: 8px;
}

.highlight-text:hover {
  color: blue;
  font-weight: 400;
}

.color-box {
  display: inline-block;
  padding-left: 6px;
  cursor: pointer;
  padding-top: 5px;
}

.modal-content .modal-header {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.modal-title {
  margin-top: 10px;
}

.modal-content .modal-body {
  padding-top: 10px;
}

.Pagination {
  width: 50%;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.Pagination button {
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 17px;
}

.enabled-button {
  background-color: #007bff;
  /* Blue color for enabled button */
  color: #ffffff;
  /* White text color for enabled button */
  border: 2px solid #007bff
}

.disabled-button {
  background-color: lightgray;
  /* Gray color for disabled button */
  color: #ffffff;
  /* White text color for disabled button */
  border: 2px solid lightgray;
}

.notification {
  border-radius: 50%;
  padding: 5px;
  text-align: center;
  font-size: 11px;
  color: white;
  background-color: red;
}
</style>
