<template>
  <div class="content" style="overflow-x:hidden;">
    <LoadingOverlay :loading="Isloading" />
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
        <md-card>
          <md-card-header data-background-color="green">
            <div class="card-header d-flex justify-content-between align-items-center">
              <h4 class="title">Manage Admin</h4>
              <div class="ms-auto">
                <input type="text" v-model="searchText" class="gridFilter" @keyup="filterGrid"
                  style="margin:5px 5px 0 0;" placeholder="Search...">
                <button type="button" data-bs-toggle="modal" data-bs-target="#userModal" title="Add New Admin" class="btn btn-primary btn-sm"
                  @click="openUserModal(true)">
                  Add New Admin
                </button>
                <button hidden type="button" data-bs-toggle="modal" title="Add New Admin" data-bs-target="#userModal" data-bs-whatever="@mdo"
                  class="btn btn-primary" id="openModal">
                  Add New Admin
                </button>
                <button hidden type="button" data-bs-toggle="modal" title="View Admin Details" data-bs-target="#AdminDetailsModal"
                  data-bs-whatever="@mdo" class="btn btn-primary" id="openAdminDetailsModal">
                  View Admin Details
                </button>
              </div>
            </div>
          </md-card-header>
          <md-card-content>
            <div class="col-md-12" style="overflow-x:auto;">
              <!-- Display success alert -->
              <div v-if="successAlertOnSubmit" class="alert alert-success text-left" role="alert">
                {{ successAlertOnSubmit }}
                <span @click="hideAdminAlert" class="close-icon">&times;</span>
              </div>
              <div class="admin-table">
                <div v-if="loadingAdmins" class="text-center">
                  <p>{{ loadingAdmins }}</p>
                </div>
                <table style="width: 100%;" :table-header-color="tableHeaderColor">
                  <tr v-if="!loadingAdmins" class="toprow">
                    <!-- <td style="width:4%" class="table-cell">S.N.</td> -->
                    <td style="width:10%;padding-left:5px;" class="table-cell">User Name</td>
                    <td style="width:15%" class="table-cell">First Name</td>
                    <td style="width:15%" class="table-cell">Last Name</td>
                    <td style="width:20%" class="table-cell">Email-ID</td>
                    <!-- <td style="width:12%" class="table-cell">User Type</td> -->
                    <td style="width:15%" class="table-cell">Contact</td>
                    <td style="width:5%" class="table-cell">Active</td>
                    <td style="width:20%;text-align:center;padding-right:7px" class="table-cell">Actions</td>
                  </tr>
                  <tr slot="md-table-row" v-for="item in paginatedAdmins" :key="item.id"
                    style="border-bottom:1px solid #ddd">
                    <!-- <td class="md-table-cell">{{ item.SN }}</td> -->
                    <td class="md-table-cell"><span v-if="item.Username.length > 60" :title="item.Username">
                        {{ truncateDescription(item.Username) }}
                      </span>
                      <span v-else>
                        {{ item.Username }}
                      </span>
                    </td>
                    <td class="md-table-cell">
                      <span v-if="item.FirstName.length > 25" :title="item.FirstName">
                        {{ truncateDescription(item.FirstName) }}
                      </span>
                      <span v-else>
                        {{ item.FirstName }}
                      </span>
                    </td>
                    <td class="md-table-cell">
                      {{
                        item.LastName
                      }}
                    </td>
                    <td class="md-table-cell">{{ item.Email }}</td>
                    <td class="md-table-cell">{{ item.ContactNumber }}</td>
                    <td class="md-table-cell">{{ item.IsActive ? "Yes" : "No" }}</td>
                    <td class="md-table-cell" style="text-align:right;white-space: nowrap;">
                      <button v-if="item.IsActive" :hidden="false"
                        @click="() => showConfirmation(item.UserID, item.IsActive)" type="button"
                        class="btn btn-primary btn-sm ml-2" title="Deactivate Admin">
                        <i class="fas fa-lock-open"></i>
                      </button>
                      <button v-if="!item.IsActive" :hidden="false"
                        @click="() => showConfirmation(item.UserID, item.IsActive)" type="button"
                        class="btn btn-primary btn-sm ml-2" title="Activate Admin">
                        <i class="fas fa-lock"></i>
                      </button>
                      <button @click="viewAdminDetail(item.UserID)" type="button" class="btn btn-primary btn-sm ml-2"
                        title="View Admin">
                        <i class="fa fa-eye" aria-hidden="true"></i>
                      </button>
                      <button @click="editAdminDetail(item.UserID)" type="button" class="btn btn-primary btn-sm ml-2"
                        title="Edit Admin">
                        <i class="fas fa-pencil-alt"></i>
                      </button>
                      <button @click="showConfirmationDelete(item.UserID, item.Username)" type="button"
                        class="btn btn-danger btn-sm ml-2" title="Delete Admin">
                        <md-icon style="color: white">delete</md-icon>
                      </button>
                    </td>
                  </tr>
                  <tr v-if="IsAdmins" style="border-top: 1px solid #ccc;">
                    <td colspan="8">
                      <p class="text-center" style="padding-top:10px;padding-left:10px;">No data found.</p>
                    </td>
                  </tr>
                </table>
                <div class="d-flex my-1" v-if="!IsAdmins">
                  <div style="width: 33%;" class="d-flex">
                    <span style="width: 50%;line-height:50px;">Admins per page:</span>
                    <Select @change="setPageSize" class="form-select PageSizeSelect" style="width: 30%;"
                      v-model="pageSize">
                      <Option :value="5">5</Option>
                      <Option :value="10">10</Option>
                      <Option :value="20">20</Option>
                      <Option :value="30">30</Option>
                    </Select>
                  </div>
                  <div class="Pagination">
                    <button @click="previousPage" :disabled="currentPage === 1" title="Previous"
                      :class="{ 'disabled-button': currentPage === 1, 'enabled-button': currentPage !== 1 }"><i
                        class="fa fa-angle-left"></i> Prev</button>
                    <span class="current-page-indicator">{{ currentPage }}</span>
                    <button @click="nextPage" :disabled="currentPage === totalPages" title="Next"
                      :class="{ 'disabled-button': currentPage === totalPages, 'enabled-button': currentPage !== totalPages }">Next
                      <i class="fa fa-angle-right"></i></button>
                  </div>
                  <div style="width: 33%" class="d-flex justify-content-end mx-3">
                    <span style="line-height:50px;" class="text-right mx-1">Total Pages: </span>
                    <span style="line-height:50px;" class="text-right">{{ totalPages }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- Modal to edit and Update  admin detail -->
            <div class="modal fade" id="userModal" tabindex="-1" aria-labelledby="userModalLabel" aria-hidden="true"
              @hidden="handleuserModalHidden">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="userModalLabel">
                      {{ editUserMode ? "Edit User" : "Add New User" }}
                    </h1>
                    <button class="btn-close" title="Close" data-bs-dismiss="modal" aria-label="Close" tabindex="-1"></button>
                  </div>
                  <form @submit.prevent="submitUserForm">
                    <input v-model="newUser.UserID" type="hidden" class="form-control" />
                    <div class="modal-body">
                      <div v-if="userFormHeader" class="alert alert-danger text-left" role="alert">
                        {{ userFormHeader }}
                        <span @click="hideAdminAlert" class="close-icon">&times;</span>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label for="firstName" class="col-form-label">First Name</label>
                            <input v-model="newUser.FirstName" type="text" @keydown="preventNumericInput"
                              class="form-control" id="firstName" tabindex="1" @input="isValidUserForm" />
                            <span class="text-red-500 text-sm">
                              {{
                                this.userValidate.FirstName
                              }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label for="lastName" class="col-form-label">Last Name</label>
                            <input v-model="newUser.LastName" type="text" class="form-control" id="lastName"
                              tabindex="1" @input="isValidUserForm" @keydown="preventNumericInput" />
                            <span class="text-red-500 text-sm">{{
                              this.userValidate.LastName
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label for="UserName" class="col-form-label">User Name</label>
                            <input v-model="newUser.Username" type="text" @keydown="preventSpace" class="form-control" id="Username"
                              tabindex="1" :disabled="editUserMode" @input="isValidUserForm" />
                            <span class="text-red-500 text-sm">{{
                              userValidate.Username
                            }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label for="email" class="col-form-label">Email-Id</label>
                            <input v-model="newUser.Email" type="text" class="form-control" id="email" tabindex="1"
                              @input="isValidUserForm" inputmode="email" autofocus />
                            <span class="text-red-500 text-sm">{{
                              userValidate.Email
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label for="Password" class="col-form-label">Password</label>
                            <input v-model="newUser.Password" type="password" class="form-control" id="Password"
                              tabindex="1" @input="isValidUserForm" />
                            <span class="text-red-500 text-sm">{{
                              userValidate.Password
                            }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label for="ConfirmPassword" class="col-form-label">Confirm Password</label>
                            <input v-model="newUser.ConfirmPassword" type="password" class="form-control"
                              id="ConfirmPassword" tabindex="1" @input="isValidUserForm" />
                            <span class="text-red-500 text-sm">{{
                              userValidate.ConfirmPassword
                            }}</span>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="mb-3">
                            <label for="contactNumber" class="col-form-label">Contact Number</label>
                            <input v-model="newUser.ContactNumber" type="tel" class="form-control" id="contactNumber"
                              tabindex="1" @input="isValidUserForm" maxlength="10" inputmode="numeric"
                              @keydown="preventAllTextInput" pattern="[0-9]*" />
                            <span class="text-red-500 text-sm">
                              {{ userValidate.ContactNumber }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" title="Close" class="btn btn-secondary ml-2" data-bs-dismiss="modal" id="closeModal"
                        tabindex="8">
                        Close
                      </button>
                      <button type="submit" :title='editUserMode ? "Update" : "Submit"' tabindex="1" class="btn btn-primary" :disabled="btnUserEnable" default>
                        {{ editUserMode ? "Update" : "Submit" }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!-- Modal for to show admin detail -->
            <div class="modal fade" id="AdminDetailsModal" tabindex="-1" aria-labelledby="AdminDetailsLabel"
              aria-hidden="true" @hidden="handleuserModalHidden">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="AdminDetailsLabel">
                      Admin Details
                    </h1>
                    <button class="btn-close" title="Close" data-bs-dismiss="modal" aria-label="Close" tabindex="-1"></button>
                  </div>
                  <div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="mb-3 d-flex flex-column">
                            <label class="col-form-label mb-0"><b>Email-Id</b></label>
                            <label class="col-form-label">{{ newUser.Email }}</label>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3 d-flex flex-column">
                            <label class="col-form-label"><b>Contact Number</b></label>
                            <label class="col-form-label">{{ newUser.ContactNumber }}</label>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="mb-3 d-flex flex-column">
                            <label class="col-form-label"><b>User Name</b></label>
                            <label class="col-form-label">{{ newUser.Username }}</label>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3 d-flex flex-column">
                            <label class="col-form-label bold"><b>Active</b></label>
                            <label class="col-form-label">{{ newUser.IsActive ? "Yes" : "No" }}</label>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="mb-3 d-flex flex-column">
                            <label class="col-form-label"><b>First Name</b></label>
                            <label class="col-form-label">{{ newUser.FirstName }}</label>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3 d-flex flex-column">
                            <label class="col-form-label bold"><b>Last Name</b></label>
                            <label class="col-form-label">{{ newUser.LastName }}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" title="Close" class="btn btn-secondary ml-2" data-bs-dismiss="modal" id="closeModal"
                        tabindex="8">
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ConfirmDailogBox v-if="confirmationVisibleActive" :message="ActivationMessage"
              @confirmed="confirmEditAdminStatus" @canceled="hideConfirmation" />
            <ConfirmDailogBox v-if="confirmationVisibleDelete" :message="ActivationMessage"
              @confirmed="submitDeleteAdminStatusForm" @canceled="hideConfirmation" />
            <!-- Modal for confirm delete Admin -->
            <div class="modal fade" id="userDeleteConfirmModal" tabindex="-1" aria-labelledby="DeleteConfirmModalLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-md">
                <div class="modal-content">
                  <form @submit.prevent="submitDeleteAdminStatusForm">
                    <input v-model="newUser.UserID" type="hidden" class="form-control" />
                    <input v-model="newUser.Username" type="hidden" class="form-control" />
                    <div class="modal-body">
                      <div class="row">
                        <h4>Are you sure you want to delete {{ newUser.Username }} ?</h4>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" title="No" class="btn btn-secondary ml-2" data-bs-dismiss="modal"
                        id="closeDeleteConfirmStatusModal" tabindex="8">
                        No
                      </button>
                      <button type="submit" title="Yes" class="btn btn-danger" tabindex="7">
                        Yes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import api, { setAuthToken } from "../apiConfig";
import { authMixin } from "./SuperAdminStore";
import Cookies from "js-cookie";
import { handleAuthorization, handleServerError, isValidEmail, isValidPhoneNumber, isValidText, preventNumberInput, preventTextInput } from "../Common/commonMethod";
import showAlert from "../Common/sweetAlert";
import LoadingOverlay from '../Common/blockoutpage.vue'
import ConfirmDailogBox from '../Common/confirmationbox.vue'
import { ErrorLog } from "../Common/APILog";
export default {
  mixins: [authMixin],
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  created() {
    this.checkAuthorization();
  },
  name: "UserManagement",
  components: {
    LoadingOverlay,
    ConfirmDailogBox,
  },
  data() {
    return {
      Admins: [],
      searchText: '',
      IsAdmins: false,
      pageSize: 5, // Number of admins per page
      currentPage: 1,// Current page
      activateUID: '',
      filteredAdmin: [],
      confirmationVisibleActive: false,
      confirmationVisibleDelete: false,
      Isloading: false,
      StatusCompleted: false,
      filteredAdmins: [],
      newUser: {
        UserID: 0,
        Username: "",
        Email: "",
        ContactNumber: "",
        FirstName: "",
        LastName: "",
        Password: "",
        ConfirmPassword: "",
      },
      userValidate: {
        Username: "",
        Email: "",
        ContactNumber: "",
        FirstName: "",
        Password: "",
        LastName: "",
        // Add additional validation fields as needed
      },
      isInputDisabled: true,
      userFormHeader: "",
      successAlertOnSubmit: "",
      editUserMode: false,
      loadingAdmins: "",
      btnUserEnable: false,
      categoryDropdownOptions: [],
      priorityDropdownOptions: [],
      IsAlreadyAssigned: false,

    };
  },
  watch: {
    pageSize(newSize) {
      this.pageSize = newSize;
      this.currentPage = 1; // Reset to first page
      this.paginatedAdmins();
    }
  },
  computed: {
    paginatedAdmins() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.filteredAdmins.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.filteredAdmins.length / this.pageSize);
    },
    filteredData: function () {
      console.log("filter");
      const searchText = this.searchText.toLowerCase();
      return this.tasks.filter(item => {
        return (
          item.Title.toLowerCase().includes(searchText) ||
          item.Description.toString().includes(searchText)
        );
      });
    }
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    preventSpace(event) {
      // Check if the key pressed is a space (key code 32)
      if (event.key === ' ') {
        event.preventDefault(); // Prevent the space from being entered
      }
    },
    async showConfirmation(UserId, Status) {
      this.confirmationVisibleActive = true;
      this.activateUID = UserId
      // alert("hello"+this.activateUID+"your : "+Status);
      if (Status) {
        this.ActivationMessage = "Are you sure you want to deactivate this Admin?"
      }
      else {
        this.ActivationMessage = "Are you sure you want to activate this Admin?"
      }
    },
    async showConfirmationDelete(UserId, Username) {
      // alert("hello"+UserId+"your : "+Status);
      this.confirmationVisibleDelete = true;
      this.newUser.UserID = UserId;
      this.newUser.Username = Username;
      // alert("hello"+this.activateUID+"your : "+Status);
      this.ActivationMessage = `Are you sure you want to Delete ${Username} Admin?`
    },
    hideConfirmation() {
      this.confirmationVisibleActive = false;
      this.confirmationVisibleDelete = false;
    },
    filterData: function (x) {
      this.IsAdmins = false;
      if (parseInt(x) != 5 && parseInt(x) != 6) {
        this.filteredAdmin = this.Admins.filter(item => {
          return (
            item.UserID == parseInt(x)
          );
        });
        if (this.filteredAdmin.length > 0) {
          this.filteredAdmins = this.filteredAdmin;
        }
        else {
          console.log("ok");
          this.filteredAdmins = [];
          this.IsAdmins = true;
        }
      }
      if (parseInt(x) == 5) {
        this.filteredAdmin = this.Admins.filter(item => {
          return (
            this.IsAdmins = true
          );

        });
        if (this.filteredAdmin.length > 0) {
          this.filteredAdmins = this.filteredAdmin;
        }
        else {
          console.log("ok");
          this.filteredAdmins = [];
          this.IsAdmins = true;
        }
      }
      if (parseInt(x) == 6) {
        this.filteredAdmins = this.Admins;
      }
      // Filter Admins based on searchText
    },
    filterGrid: function () {
      var searchText = this.searchText;
      this.IsAdmins = false;
      if (parseInt(searchText) > 0) {
        this.filteredAdmin = this.Admins.filter(item => {
          return (
            item.UserID == parseInt(searchText)
          );
        });
        if (this.filteredAdmin.length > 0) {
          this.filteredAdmins = this.filteredAdmin;
        }
        else {
          console.log("ok");
          this.filteredAdmins = [];
          this.IsAdmins = true;
        }
      }
      else {
        searchText = searchText.toLowerCase();
        this.filteredAdmin = this.Admins.filter(item => {
          return (
            item.Username.toLowerCase().includes(searchText) ||
            item.Email.toLowerCase().includes(searchText) ||
            item.FirstName.toLowerCase().includes(searchText) ||
            item.LastName.toLowerCase().includes(searchText)
          );
        });
        if (this.filteredAdmin.length > 0) {
          this.filteredAdmins = this.filteredAdmin;
        }
        else {
          console.log("ok");
          this.filteredAdmins = [];
          this.IsAdmins = true;
        }
      }
    },
    editAdminDetail(UserID) {
      this.editAdmin(UserID);
    },
    formatDueDate(originalDate, type = "show") {
      if (!originalDate) return ""; // Handle null or undefined dates

      const dateObject = new Date(originalDate);
      const day = dateObject.getDate().toString().padStart(2, "0");
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObject.getFullYear();
      if (type == "show") {
        return `${day}/${month}/${year}`;
      } else {
        return `${year}-${month}-${day}`;
      }
    },
    async confirmEditAdminStatus() {
      const UserId = this.activateUID
      this.editAdminStatus(UserId)
      this.hideConfirmation();
    },
    truncateDescription(description) {
      // Truncate the description if it's longer than 30 characters
      return description.length > 25
        ? description.slice(0, 25) + "..."
        : description;
    },
    handleuserModalHidden() {
      this.editUserMode = false;
    },
    closeModal() {
      this.editMode = false;
      this.resetAdminValidation();
      document.getElementById("closeModal").click();
    },
    openUserModal(refresh = false) {
      if (refresh) {
        this.editUserMode = false;
        this.newUser = {
          UserID: 0,
          Username: "",
          Description: "",
          CreatedDate: "",
          DueDate: "",
          IsCompleted: false,
          PriorityID: 0,
          CategoryID: 0,
        };
        this.resetAdminValidation();
      }
    },
    openModal() {
      document.getElementById("openModal").click();
    },
    openAdminDetailsModal() {
      document.getElementById("openAdminDetailsModal").click();
    },
    async getUsers() {
      this.Admins = [];
      this.Isloading = true;
      this.filteredAdmins = [];
      this.loadingAdmins = "Loading...";
      try {
        const token = Cookies.get("jwt");
        setAuthToken(token);
        let userCookie = Cookies.get("user");
        userCookie = userCookie ? JSON.parse(userCookie) : null;
        const response = await api.get(
          `/api/superAdmin/get-admins`
        );
        this.Isloading = false;
        if (response.data) {
          this.Admins = response.data;
          console.log(this.Admins);
          this.filteredAdmins = this.Admins;
        } else {
          this.userFormHeader = "Data not loaded.";
          // Handle case where no Admins are found
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.loadingAdmins = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.loadingAdmins = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 644, error.stack, "SuperAdminPanel/manageAdmins/getUsers");
          }
        }
        else {
          this.loadingAdmins = "Something went wrong.";
        }
      } finally {
        if (this.Admins.length > 0) {
          this.loadingAdmins = "";
        } else {
          this.Isloading = false;
          this.loadingAdmins = "No Admins found.";
        }
      }
    },
    async getAdminById(UserID) {
      this.Isloading = true;
      this.editUserMode = true;
      try {
        const token = Cookies.get("jwt");
        setAuthToken(token);
        const response = await api.get(`/api/superAdmin/getAdminById/${UserID}`);
        this.Isloading = false;
        if (response.data) {
          this.newUser = response.data[0];
          if (this.editUserMode) {
            this.openModal();
          }
          this.resetAdminValidation();
        } else {
          this.userFormHeader = "Error: " + response.statusText;
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.loadingAdmins = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.loadingAdmins = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 692, error.stack, "SuperAdminPanel/manageAdmins/getAdminById()");
          }
        }
        else {
          this.loadingAdmins = "Something went wrong.";
        }
      }
    },

    async getAdminDetailsById(UserID) {
      try {
        const token = Cookies.get("jwt");
        setAuthToken(token);
        const response = await api.get(`/api/superAdmin/getAdminById/${UserID}`);
        if (response.data) {
          this.newUser = response.data[0];
          this.openAdminDetailsModal()
        } else {
          this.userFormHeader = "Error: " + response.statusText;
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.userFormHeader = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.userFormHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 728, error.stack, "SuperAdminPanel/manageAdmins/getAdminDetailsById()");
          }
        }
        else {
          this.userFormHeader = "Something went wrong.";
        }
      }
    },
    async createAdmin() {
      const username = this.newUser.Username;
      const FirstName = this.newUser.FirstName;
      this.userFormHeader = "";
      try {
        this.btnUserEnable = true;
        const token = Cookies.get("jwt");
        setAuthToken(token);
        let userCookie = Cookies.get("user");
        userCookie = userCookie ? JSON.parse(userCookie) : null;
        const response = await api.post('/api/superAdmin/create-admin',
          {
            Username: username,
            Password: this.newUser.Password,
            UserType: "Admin",
            FirstName: this.newUser.FirstName,
            LastName: this.newUser.LastName,
            Email: this.newUser.Email,
            ContactNumber: this.newUser.ContactNumber,
            CreatedBy: (userCookie == null) ? null : userCookie.UserID
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          });
        console.log(this.newUser.UserID);
        if (response.data.success) {
          this.getUsers();
          this.closeModal(); // hide modal after successful submit.
          this.successAlertOnSubmit = "Admin added successfully.";
        } else {
          this.userFormHeader = response.data.msg;
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.userFormHeader = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.userFormHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 786, error.stack, "SuperAdminPanel/manageAdmin/CreateAdmin()");
          }
        }
        else {
          this.userFormHeader = "Something went wrong.";
        }
      }
      finally {
        this.btnUserEnable = false;
        this.hideMessage();
      }
    },
    async hideMessage() {
      setTimeout(() => {
        this.userFormHeader = "";
        this.successAlertOnSubmit = "";
      }, 7000);
    },
    editAdmin(UserID) {
      this.editUserMode = true;
      this.getAdminById(UserID);
    },
    async updateAdmin() {
      this.userFormHeader = "";
      try {
        this.btnUserEnable = true;
        this.Isloading = true;
        const token = Cookies.get("jwt");
        setAuthToken(token);
        let userCookie = Cookies.get("user");
        userCookie = userCookie ? JSON.parse(userCookie) : null;
        const response = await api.put(
          `/api/superAdmin/update-admin/${this.newUser.UserID}`,
          {
            Username: this.newUser.Username,
            Password: this.newUser.Password,
            FirstName: this.newUser.FirstName,
            LastName: this.newUser.LastName,
            Email: this.newUser.Email,
            ContactNumber: this.newUser.ContactNumber,
            ModifiedBy: (userCookie == null) ? null : userCookie.Username
          }
        );
        //console.log(this.newUser.DueDate);
        this.Isloading = false;
        if (response.data.success) {
          this.getUsers();
          this.closeModal(); // hide modal after successful submit.
          this.successAlertOnSubmit = "Admin updated successfully.";
        }
        else {
          this.userFormHeader = response.data.msg;
        }
      }
      catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.userFormHeader = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.userFormHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 754, error.stack, "SuperAdminPanel/manageAdmins/updateAdmin()");
          }
        }
        else {
          this.userFormHeader = "Something went wrong.";
        }
      }
      finally {
        this.btnUserEnable = false;
        this.hideMessage();
      }
    },
    async editAdminStatus(UserID) {
      this.userFormHeader = "";
      try {
        this.Isloading = true;
        // showAlert("info", "Are you sure to activate?",true);
        this.btnUserEnable = true;
        const token = Cookies.get("jwt");
        setAuthToken(token);
        let userCookie = Cookies.get("user");
        userCookie = userCookie ? JSON.parse(userCookie) : null;
        const response = await api.put(
          `/api/superAdmin/manage-admins/${UserID}`
        );
        this.Isloading = false;
        //console.log(this.newUser.DueDate);
        if (response.data.success) {
          this.getUsers();
          this.closeModal(); // hide modal after successful submit.
          console.log(response.data)
          this.successAlertOnSubmit = response.data.msg;
        }
        else {
          this.userFormHeader = response.data.msg;
        }
      }
      catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.userFormHeader = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.userFormHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 754, error.stack, "SuperAdminPanel/manageAdmins/editAdminStatus()");
          }
        }
        else {
          this.userFormHeader = "Something went wrong.";
        }
      }
      finally {
        this.btnUserEnable = false;
        this.hideMessage();
      }
    },
    preventAllTextInput(event) {
      preventTextInput(event)
    },
    preventNumericInput(event) {
      preventNumberInput(event)
    },
    isValidUserForm() {
      let isValid = true;
      const Email = this.newUser.Email;
      const Password = this.newUser.Password;
      const Username = this.newUser.Username;
      const ContactNumber = this.newUser.ContactNumber;
      const firstname = this.newUser.FirstName;
      const lastname = this.newUser.LastName;
      const ConfirmPassword = this.newUser.ConfirmPassword
      // Email
      if (Email === undefined || Email === "") {
        this.userValidate.Email = "Email-Id is required.";
        isValid = false;
      }
      else if (!isValidEmail(Email)) {
        this.userValidate.Email = "Please enter a valid email address.";
        isValid = false;
      }
      else {
        this.userValidate.Email = ""; // Clear validation message
      }
      // Contact Number
      if (ContactNumber === undefined || ContactNumber === "") {
        this.userValidate.ContactNumber = "Contact Number is required.";
        isValid = false;
      }
      else if (!isValidPhoneNumber(ContactNumber)) {
        this.userValidate.ContactNumber = "Please enter a valid Contact Number.";
        isValid = false;
      }
      else {
        this.userValidate.ContactNumber = ""; // Clear validation message
      }
      // Username
      if (Username === undefined || Username === "") {
        this.userValidate.Username = "User name is required.";
        isValid = false;
      }
      else {
        this.userValidate.Username = ""; // Clear validation message
      }
      if (Password === undefined || Password === "") {
        this.userValidate.Password = "Password is required.";
        isValid = false;
      }
      else if (
        !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}/.test(this.newUser.Password)
      ) {
        this.userValidate.Password = "Password must contains at least 8 characters, including 1 special character, 1 capital letter, and 1 number.";
        isValid = false;
      }
      else {
        this.userValidate.Password = ""; // Clear validation message
      }
      if (ConfirmPassword === undefined || ConfirmPassword === "") {
        this.userValidate.ConfirmPassword = "Confirm Password is required.";
        isValid = false;
      }
      else if (Password !== ConfirmPassword) {
        this.userValidate.ConfirmPassword = "Password doesn't match with Confirm Password.";
        isValid = false;
      }
      else {
        this.userValidate.ConfirmPassword = "";
      }
      // firstName
      if (firstname === undefined || firstname === "") {
        this.userValidate.FirstName = "First Name is required.";
        isValid = false;
      }
      {
        this.userValidate.FirstName = ""; // Clear validation message
      }
      // lastName
      if (lastname === undefined || lastname === "") {
        this.userValidate.LastName = "Last Name is required.";
        isValid = false;
      }
      else {
        this.userValidate.LastName = ""; // Clear validation message
      }
      return isValid;
    },

    async submitUserForm() {
      // this.resetAdminValidation();
      if (this.isValidUserForm()) {
        if (this.newUser.UserID === 0) {
          await this.createAdmin(); // Assuming you have a createAdmin method
        } else {
          await this.updateAdmin(); // Assuming you have an updateAdmin method
        }
      }
    },
    resetAdminValidation() {
      this.userValidate = {
        Username: "",
        Email: "",
        ContactNumber: "",
        FirstName: "",
        Password: "",
        LastName: "",
      };
    },
    hideAdminAlert() {
      this.userFormHeader = "";
      this.successAlertOnSubmit = "";
    },
    viewAdminDetail(UserID) {
      this.getAdminDetailsById(UserID)
    },
    async submitDeleteAdminStatusForm() {
      this.DeleteAdmin(this.newUser.UserID, this.newUser.Username);
      this.hideConfirmation();
    },
    async DeleteAdmin(UserID, Username) {
      try {
        //console.log(UserID);
        const token = Cookies.get("jwt");
        setAuthToken(token);
        let userCookie = Cookies.get("user");
        userCookie = userCookie ? JSON.parse(userCookie) : null;
        // Make your delete API call here
        const deletedby = userCookie.UserID;
        const response = await api.post(
          `/api/superAdmin/delete`,
          {
            UserID: UserID,
            ModifiedBy: Username
          }
        ); // Replace with your actual API endpoint

        if (response.data.success) {
          this.getUsers();
          this.successAlertOnSubmit = response.data.msg;
          // this.closeDeleteConfirmStatusModal();
        } else {
          this.userFormHeader = "Error: " + response.data.msg;
        }
        // Optionally, perform additional actions after successful deletion
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.userFormHeader = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.userFormHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 1083, error.stack, "SuperAdminPanel/manageAdmins/DeleteAdmin()");
          }
        }
        else {
          this.userFormHeader = "Something went wrong.";
        }
          this.userFormHeader = "Error: " + jsonResponseString;
          this.$router.push("/");
        
      }
      finally {
        this.hideMessage();
      }
    },
    setPageSize(event) {
      const selectedSize = event.target.value;
      this.pageSize = parseInt(selectedSize);
      this.currentPage = 1; // Reset to the first page
      this.paginatedAdmins();
      // alert(`Page size changed to: ${selectedSize}`);
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
  },
};
</script>
<style scoped>
/* Add Tailwind CSS classes here if needed */
.close-icon {
  cursor: pointer;
  float: right;
  font-size: 30px;
  margin-top: -10px;
}

.mb-3 {
  text-align: left;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1em;
}

.expired-row {
  background-color: #dc8484;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.table th {
  background-color: #f2f2f2;
}

/* Additional styling for left and right padding */
.table th,
.table td {
  padding-left: 12px;
  padding-right: 12px;
}

.user-list {
  min-height: 120px;
  max-height: 120px;
  /* Set a maximum height for the user list */
  overflow-y: auto;
  /* Add a scrollbar when the content exceeds the height */
  border: 1px solid #ccc;
  /* Optional: Add a border for styling */
}

.user-item {
  margin-bottom: 8px;
  /* Optional: Add some spacing between user items */
}

.actionBtns {
  text-align: right !important;
}

@media screen and (min-width:1020px) {
  #userEditStatusModal .modal-dialog {
    width: 35%;
  }
}

.alreadyAssignedText {
  color: #ed6524;
  font-size: 15px;
  text-align: center;
}

.dropdown {
  width: 100%;
  height: 36px;
  padding: 7px 0;
  font-size: 14px;
  line-height: 1.428571429;
  border-color: #D2D2D2;
}


.Pagination {
  width: 34%;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.Pagination button {
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 17px;
}

.enabled-button {
  background-color: #007bff;
  /* Blue color for enabled button */
  color: #ffffff;
  /* White text color for enabled button */
  border: 2px solid #007bff
}

.PageSizeSelect {
  border-radius: 5px;
  margin: 5px;
  height: 35px;

  font-size: 17px;
}

.current-page-indicator {
  background-color: green;
  border-radius: 50%;
  /* Ensures the element is rounded */
  padding: 3px;
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  /* Adjust this value as needed */
  height: 25px;
  /* Ensure width and height are equal */
}

.disabled-button {
  background-color: lightgray;
  /* Gray color for disabled button */
  color: #ffffff;
  /* White text color for disabled button */
  border: 2px solid lightgray;
}

.progress {
  float: left;
}

.highlight-text {
  margin-left: 5px;
  margin-right: 8px;
}

.color-box {
  display: inline-block;
  padding-left: 6px;
  padding-top: 5px;
}

.highlight-text {
  margin-left: 5px;
  margin-right: 8px;
}

.highlight-text:hover {
  color: blue;
  font-weight: 400;
}

.color-box {
  display: inline-block;
  padding-left: 6px;
  cursor: pointer;
  padding-top: 5px;
}
.toprow{
  color: #fff;
    background: #0d6efd;
    height: 35px;
    font-weight: 500;
    font-size: 16px;
}
.modal-content .modal-header {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.modal-title {
  margin-top: 10px;
}

.modal-content .modal-body {
  padding-top: 10px;
}

.admin-table {
  width: 100%;
}

@media screen and (min-width:990px) and (max-width: 1185px) {
  .admin-table {
    width: 1100px;
    overflow-x: auto
  }
}

@media screen and (max-width: 890px) {
  .admin-table {
    width: 830px;
    overflow-x: auto
  }
}
</style>
