<template>
  <md-toolbar md-elevation="0" class="md-transparent">
    <div class="md-toolbar-row">
      <div class="md-toolbar-section-start">
        <h3 class="md-title">{{ $route.name }}</h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-simple md-toolbar-toggle"
          :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>

        <div class="md-collapse">
          <md-list>
            <md-list-item href="/user/dashboard" title="Dashboard">
              <i class="material-icons">dashboard</i>
              <p class="hidden-lg hidden-md">Dashboard</p>
            </md-list-item>
            <!-- <div class="dropdown">
              <md-list-item href="javascript:void(0)">
                <i class="material-icons">person</i>
                <p class="hidden-lg hidden-md">Person</p>
              </md-list-item>
 
              <div class="dropdown-content">
                <a href="/user/change-password" class="text-center"
                  >Change Password</a
                >
                <button
                  class="btn btn-warning btn-sm"
                  type="button"
                  @click="logout"
                >
                  Logout
                </button>
              </div>
            </div> -->
          </md-list>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
import { setAuthToken } from "../../components/apiConfig";
export default {
  data() {
    return {
      selectedEmployee: null,
      employees: [
        "Jim Halpert",
        "Dwight Schrute",
        "Michael Scott",
        "Pam Beesly",
        "Angela Martin",
        "Kelly Kapoor",
        "Ryan Howard",
        "Kevin Malone",
      ],
    };
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    logout() {
      setAuthToken();
      //alert("Logout successfully.");
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="css">
/* Dropdown container */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Hidden by default */
.dropdown-content {
  left: -100px !important;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  z-index: 1;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Style the dropdown links */
.dropdown-content a {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  left: -100px !important;
}

.btn-warning {
  width: 100% !important;
}
</style>
