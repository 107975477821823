<template>
  <div class="content" style="overflow-x:hidden;">
    <LoadingOverlay :loading="Isloading" />
    <div class="row">
      <div class="col-md-12 ms-3 pt-2 pb-3">
        <div class="color-box" v-on:click="filterData(6)">
          <span class="highlight-text">All Task</span>
        </div>
        <div class="color-box" v-on:click="filterData(4)">
          <div class="progress task-detail-value" style="width: 25px; margin-top: 3px;">
            <div title="Low" class="progress-bar bg-info" role="progressbar"
              style="width: 100%;background-color: #9ed79e !important;" aria-valuenow="0" aria-valuemin="0"
              aria-valuemax="100">&nbsp;</div>
          </div><span class="highlight-text">Complete</span>
        </div>
        <div class="color-box" v-on:click="filterData(5)">
          <div class="progress task-detail-value" style="width: 25px; margin-top: 3px;">
            <div title="Low" class="progress-bar bg-info" role="progressbar"
              style="width: 100%;background-color: #dc8484 !important;" aria-valuenow="0" aria-valuemin="0"
              aria-valuemax="100">&nbsp;</div>
          </div><span class="highlight-text">Due</span>
        </div>
        <div class="color-box" v-on:click="filterData(3)">
          <div class="progress task-detail-value" style="width: 25px; margin-top: 3px;">
            <div title="Low" class="progress-bar bg-info" role="progressbar"
              style="width: 100%;background-color: #b4e9d4 !important;" aria-valuenow="0" aria-valuemin="0"
              aria-valuemax="100">&nbsp;</div>
          </div><span class="highlight-text">In Process</span>
        </div>
        <div class="color-box" v-on:click="filterData(2)">
          <div class="progress task-detail-value" style="width: 25px; margin-top: 3px;">
            <div title="Low" class="progress-bar bg-info" role="progressbar"
              style="width: 100%;background-color: #eaea8d !important;" aria-valuenow="0" aria-valuemin="0"
              aria-valuemax="100">&nbsp;</div>
          </div><span class="highlight-text">Assigned</span>
        </div>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
        <md-card>
          <md-card-header data-background-color="green">
            <div class="card-header d-flex justify-content-between align-items-center">
              <h4 class="title">Team Tasks</h4>
              <!-- <select v-model="filterId" id="priority" class="gridFilter" @change="handleGridFilterChange">
                
                <option v-for="filterOption in taskFilterDropdownOPtions" :key="filterOption.Id"
                  :value="filterOption.Id">
                  {{ filterOption.Name }}
                </option>
              </select> -->
              <div class="ms-auto">
                <input type="text" v-model="searchText" class="gridFilter" @keyup="filterGrid"
                  style="margin:5px 5px 0 0;" placeholder="Search...">
                <button type="button" data-bs-toggle="modal" title="Add New Task" data-bs-target="#taskModal" class="btn btn-primary btn-sm"
                  @click="openTaskModal(true)">
                  Add New Task
                </button>
                <button hidden type="button" title="Add New Task" data-bs-toggle="modal" data-bs-target="#taskModal" data-bs-whatever="@mdo"
                  class="btn btn-primary" id="openModal">
                  Add New Task
                </button>
                <button hidden type="button" title="Task Assignment" data-bs-toggle="modal" data-bs-target="#taskAssignmentModal"
                  data-bs-whatever="@mdo" class="btn btn-primary" id="openAssignmentModal">
                  Task Assignment
                </button>
                <button hidden type="button" title="Delete Task" data-bs-toggle="modal" data-bs-target="#taskDeleteConfirmModal"
                  data-bs-whatever="@mdo" class="btn btn-primary" id="openDeleteStatusModal">
                  Delete Task
                </button>
                <button hidden type="button" title="Reopen Task" data-bs-toggle="modal" data-bs-target="#ReOpenTaskConfirmModal"
                  data-bs-whatever="@mdo" class="btn btn-primary" id="ReOpenTaskModal">
                  Reopen Task
                </button>
              </div>
            </div>
          </md-card-header>
          <md-card-content>
            <div class="col-md-12">
              <!-- Display success alert -->
              <div v-if="successAlertOnSubmit" class="alert alert-success text-left" role="alert">
                {{ successAlertOnSubmit }}
                <span @click="hideTaskAlert" class="close-icon">&times;</span>
              </div>
              <div v-if="errorAlertOnSubmit" class="alert alert-danger text-left" role="alert">
                {{ errorAlertOnSubmit }}
                <span @click="hideTaskAlert" class="close-icon">&times;</span>
              </div>
              <div class="table-responsive" style="overflow: auto !important;">
                <div v-if="loadingTasks" class="text-center">
                  <p>{{ loadingTasks }}</p>
                </div>
                <div class="task-table" v-if="!loadingTasks">
                  <table style="width: 100%;" :table-header-color="tableHeaderColor">
                    <tr class="toprow">
                      <td style="width:5%;padding-left:5px;" class="table-cell">ID</td>
                      <td style="width:30%" class="table-cell">Task</td>
                      <!-- <td style="width:22%" class="table-cell">Description</td> -->
                      <td style="width:17%" class="table-cell">Priority</td>
                      <td style="width:20%" class="table-cell">Status</td>
                      <td style="width:25%;text-align:center" class="table-cell">Actions</td>
                    </tr>
                    <tr slot="md-table-row" v-for="item in paginatedTasks" :key="item.id"
                    :class="{
                          'expired-row': item.IsExpired,
                          'rowtaskNotAssigned': item.TaskStatus === 1,
                          'rowtaskAssigned': item.TaskStatus === 2,
                          'rowtaskInProcess': item.TaskStatus === 3,
                          'rowtaskCompleted': item.TaskStatus === 4
                        }"
                      style="border-bottom:1px solid #ddd">
                      <td class="md-table-cell">{{ item.TaskID }}</td>
                      <td class="md-table-cell"><span v-if="item.Title.length > 60" :title="item.Title">
                          {{ truncateDescription(item.Title) }}
                        </span>
                        <span v-else>
                          {{ item.Title }}
                        </span>
                      </td>
                      <!-- <td class="md-table-cell">
                        <span v-if="item.Description.length > 70" :title="item.Description">
                          {{ truncateDescription(item.Description) }}
                        </span>
                        <span v-else>
                          {{ item.Description }}
                        </span>
                      </td> -->
                      <td class="md-table-cell">{{ item.PriorityName }}</td>
                      <td class="md-table-cell"> <span v-if="item.TaskStatus === 1" class="taskNotAssigned">
                          Not Assigned
                        </span>
                        <span v-else-if="item.TaskStatus === 2" class="taskAssigned">Assigned
                        </span>
                        <span v-else-if="item.TaskStatus === 3" class="taskInProcess">In Process
                        </span>
                        <span v-else-if="item.TaskStatus === 4" class="taskCompleted">Completed
                        </span>
                      </td>
                      <td class="md-table-cell" style="text-align:right">
                        <sup><span v-if="item.UnreadChatCount > 0" class="notification mx-1">
                            {{ formatChatCount(item.UnreadChatCount) }}
                            <!-- 34 -->
                          </span>
                        </sup>
                        <button @click="viewTaskDetail(item.TaskID)" type="button" class="btn btn-primary btn-sm ml-2"
                          :title="item.AssignUser">
                          <i class="fa fa-users" aria-hidden="true"></i>
                        </button>
                        <button @click="ReOpenTaskModal(item.TaskID)" type="button" v-if="item.IsCompleted"
                          class="btn btn-info btn-sm ml-2" title="Re-Open Task">
                          <i class="fa fa-tasks" aria-hidden="true"></i>
                        </button>
                        <button @click="viewTaskDetail(item.TaskID)" type="button" class="btn btn-primary btn-sm ml-2"
                          title="View">
                          <i class="fa fa-eye" aria-hidden="true"></i>
                        </button>
                        <button :disabled="item.IsCompleted" v-if="!item.IsCompleted"
                          @click="editTaskDetail(item.TaskID)" type="button" class="btn btn-primary btn-sm ml-2"
                          title="Edit">
                          <i class="fas fa-pencil-alt"></i>
                        </button>
                        <button @click="openDeleteModal(item.TaskID)" type="button" class="btn btn-danger btn-sm ml-2"
                          title="Delete Task">
                          <!-- <md-icon style="color: white">delete</md-icon> -->
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                    <tr v-if="IsTasks" style="border-top: 1px solid #ccc;">
                      <td colspan="6">
                        <p style="padding-top:10px;padding-left:10px;">No Task Found</p>
                      </td>
                    </tr>
                  </table>
                  <div class="d-flex my-1 mt-2" v-if="!IsTasks">
                    <div style="width: 33%;" class="d-flex justify-content-between">
                      <div style="width: 60% !important;">
                        <span style="line-height:50px;">Tasks per page:</span>
                      </div>
                      <div  style="width: 40%;">
                        <Select @change="setPageSize" class="form-select PageSizeSelect"
                        v-model="pageSize">
                        <Option :value="5">5</Option>
                        <Option :value="10">10</Option>
                        <Option :value="20">20</Option>
                        <Option :value="30">30</Option>
                      </Select>
                      </div>
                    </div>
                    <div class="Pagination">
                      <button @click="previousPage" :disabled="currentPage === 1" title="Previous"
                        :class="{ 'disabled-button': currentPage === 1, 'enabled-button': currentPage !== 1 }"><i
                          class="fa fa-angle-left"></i> Prev</button>
                      <span class="current-page-indicator">{{ currentPage }}</span>
                      <button @click="nextPage" :disabled="currentPage === totalPages" title="Next"
                        :class="{ 'disabled-button': currentPage === totalPages, 'enabled-button': currentPage !== totalPages }">Next
                        <i class="fa fa-angle-right"></i></button>
                    </div>
                    <div style="width: 33%" class="d-flex justify-content-end mx-3">
                      <span style="line-height:50px;" class="text-right mx-1">Total Pages: </span>
                      <span style="line-height:50px;" class="text-right">{{ totalPages }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Modal for Task -->
            <div class="modal fade" id="taskModal" tabindex="-1" aria-labelledby="taskModalLabel" aria-hidden="true"
              @hidden="handleTaskModalHidden">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="taskModalLabel">
                      {{ editTaskMode ? "Edit Task" : "Add New Task" }}
                    </h1>
                    <button class="btn-close" title="Close" data-bs-dismiss="modal" aria-label="Close" v-on:click="resetCreatTaskForm"
                      tabindex="-2"></button>
                  </div>
                  <form @submit.prevent="submitTaskForm">
                    <input v-model="newTask.TaskID" type="hidden" class="form-control" />
                    <div class="modal-body">
                      <div v-if="taskFormHeader" class="alert alert-danger text-left" role="alert">
                        {{ taskFormHeader }}
                        <span @click="hideTaskAlert" class="close-icon">&times;</span>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="mb-3">
                            <label for="title" class="col-form-label">Title</label>
                            <input v-model="newTask.Title" type="text" class="form-control" id="title" tabindex="1"
                              maxlength="100" @input="isValidTaskForm" />
                            <span class="text-red-500 text-sm">{{
                              taskValidate.Title
                            }}</span>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="mb-3">
                            <label for="priority" class="col-form-label">Priority</label>
                            <select v-model="newTask.PriorityID" class="dropdown" id="priority" tabindex="3"
                              @change="isValidTaskForm">
                              <option :value="0">Select Priority</option>
                              <option v-for="priorityOption in priorityDropdownOptions" :key="priorityOption.PriorityID"
                                :value="priorityOption.PriorityID">
                                {{ priorityOption.Name }}
                              </option>
                            </select>
                            <span class="text-red-500 text-sm">{{
                              taskValidate.PriorityID
                            }}</span>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="mb-3">
                            <label for="category" class="col-form-label">Project</label>
                            <select v-model="newTask.CategoryID" class="dropdown" id="category" tabindex="4"
                              @change="isValidTaskForm">
                              <option :value="0">Select Project</option>
                              <option v-for="categoryOption in categoryDropdownOptions" :key="categoryOption.CategoryID"
                                :value="categoryOption.CategoryID">
                                {{ categoryOption.CategoryName }}
                              </option>
                            </select>
                            <span class="text-red-500 text-sm">{{
                              taskValidate.CategoryID
                            }}</span>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="mb-3">
                            <label for="dueDate" class="col-form-label">Due Date</label>
                            <flat-pickr :modelValue="newTask.DueDate" class="form-control" @input="isValidTaskForm"
                              @update:modelValue="newTask.DueDate = $event" :config="flatpickrConfig"></flat-pickr>
                            <span class="text-red-500 text-sm">{{
                              taskValidate.DueDate
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="mb-3">
                            <label for="description" class="col-form-label">Description</label>
                            <textarea v-model="newTask.Description" class="form-control" id="description" tabindex="2"
                              maxlength="255" style="height:36px;" @input="isValidTaskForm"></textarea>
                            <span class="text-red-500 text-sm">{{
                              taskValidate.Description
                            }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label for="fileUpload" class="col-form-label">Attachment</label>
                            <input type="file" class="form-control" id="fileUpload" ref="file" name="file" tabindex="5"
                              @change="handleFileUpload" />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="mb-3">
                                <label for="category" class="col-form-label">Assign to Users</label>
                                <div class="user-list" v-if="DropdownOptions.userOptions.length > 0">
                                  <div v-for="user in DropdownOptions.userOptions" :key="user.UserID" class="user-item">
                                    <input tabindex="2" type="checkbox" style="margin-right: 8px;"
                                      :id="'userCheckbox_' + user.UserID" :value="user.UserID"
                                      v-on:click="isValidTaskAssignmentForm" v-model="taskAssignment.selectedUsers" />
                                    <label :for="'userCheckbox_' + user.UserID">{{ user.UserName }}</label>
                                  </div>
                                </div>
                                <div v-else>No users available.</div>
                                <span class="text-red-500 text-sm">{{
                                  taskAssignmentValidate.selectedUsers
                                }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6" hidden>
                          <div class="mb-3">
                            <label for="isCompleted" class="col-form-label">Is Completed</label>
                            <div class="form-check form-switch">
                              <input v-model="newTask.IsCompleted" type="checkbox" class="form-check-input"
                                id="isCompleted" tabindex="5" @input="isValidTaskForm" />
                              <label class="form-check-label" for="isCompleted">
                                {{
                                  newTask.IsCompleted
                                    ? "Completed"
                                    : "Not Completed"
                                }}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" title="Close" class="btn btn-secondary ml-2" data-bs-dismiss="modal" id="closeModal"
                        v-on:click="resetCreatTaskForm" tabindex="7">
                        Close
                      </button>
                      <button type="submit" :title='editTaskMode ? "Update" : "Submit" ' class="btn btn-primary" default tabindex="6" :disabled="btnTaskEnable">
                        {{ editTaskMode ? "Update" : "Submit" }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!-- Modal for confirm delete Task -->
            <div class="modal fade" id="taskDeleteConfirmModal" tabindex="-1" aria-labelledby="DeleteConfirmModalLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-md">
                <div class="modal-content">
                  <form @submit.prevent="submitDeleteTaskStatusForm">
                    <input v-model="newTask.TaskID" type="hidden" class="form-control" />
                    <div class="modal-body">
                      <div class="row">
                        <h4>Are you sure you want to delete {{ newTask.TaskID }} TaskId?</h4>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" title="No" class="btn btn-secondary ml-2" data-bs-dismiss="modal"
                        id="closeDeleteConfirmStatusModal" tabindex="8">
                        No
                      </button>
                      <button type="submit" title="Yes" class="btn btn-danger" tabindex="7" :disabled="btnTaskEnable">
                        Yes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!-- Modal for confirm delete Task -->
            <div class="modal fade" id="ReOpenTaskConfirmModal" tabindex="-1" aria-labelledby="DeleteConfirmModalLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-md">
                <div class="modal-content">
                  <form @submit.prevent="submitReOpenTaskForm">
                    <input v-model="newTask.TaskID" type="hidden" class="form-control" />
                    <div class="modal-body">
                      <div class="row">
                        <h4>Are you sure you want to re open Task?</h4>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" title="No" class="btn btn-secondary ml-2" data-bs-dismiss="modal" tabindex="8"
                        id="HideReOpenTaskModal">
                        No
                      </button>
                      <button type="submit" title="Yes" class="btn btn-primary" tabindex="7" :disabled="IsReOpenTask">
                        Yes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import api, { setAuthToken } from "../apiConfig";
import { authMixin } from "./adminStore";
import Cookies from "js-cookie";
import LoadingOverlay from '../Common/blockoutpage.vue';
import Flatpickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { ErrorLog } from "../Common/APILog";
import { handleAuthorization, handleServerError } from "../Common/commonMethod";
export default {
  mixins: [authMixin],
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  created() {
    this.checkAuthorization();
  },
  name: "TaskManagement",
  components: {
    'flat-pickr': Flatpickr,
    LoadingOverlay,
  },
  data() {
    return {
      tasks: [],
      Isloading: false,
      pageSize: 5, // Number of tasks per page
      currentPage: 1,// Current page
      searchText: '',
      filtertask: [],
      IsReOpenTask: false,
      flatpickrConfig: {
        dateFormat: 'd/m/Y H:i', // Set the date format to year-month-day
        minDate: 'today' // Set minDate to today's date
      },
      newTask: {
        TaskID: 0,
        Title: "",
        Description: "",
        DueDate: null,
        file: null,
        IsCompleted: false,
        PriorityID: 0,
        CategoryID: 0,
        UserId: 0,
      },
      taskAssignment: {
        updateTaskId: 0,
        TaskID: 0,
        TaskTitle: "",
        UserId: 0,
        selectedUsers: [],
        DueDate: null,
      },
      taskValidate: {
        Title: "",
        Description: "",
        PriorityID: "",
        CategoryID: "",
        // Add additional validation fields as needed
      },
      taskAssignmentValidate: {
        TaskID: "",
        UserId: "",
        selectedUsers: "",
        DueDate: "",
        // Add additional validation fields as needed
      },
      taskFormHeader: "",
      successAlertOnSubmit: "",
      editTaskMode: false,
      loadingTasks: "",
      IsTasks: false,
      btnTaskEnable: false,
      title: "Team Tasks",
      errorAlertOnSubmit: "",
      categoryDropdownOptions: [],
      priorityDropdownOptions: [],
      taskFilterDropdownOPtions: [
        { Id: 0, Name: "All Task" },
        { Id: 2, Name: "Assigned" },
        { Id: 4, Name: "Completed" },
        { Id: 3, Name: "In Process" }
      ],
      DropdownOptions: {
        userOptions: [],
        taskOPtions: [],
      },
      IsAlreadyAssigned: false,
      filterId: localStorage.getItem('filterid') ?? 0,
      filteredTasks: [],
    };
  },
  computed: {
    paginatedTasks() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.filteredTasks.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.filteredTasks.length / this.pageSize);
    },
    filteredData: function () {
      console.log("filter");
      const searchText = this.searchText.toLowerCase();
      return this.tasks.filter(item => {
        return (
          item.Title.toLowerCase().includes(searchText) ||
          item.Description.toString().includes(searchText)
        );
      });
    }
  },
  mounted() {
    this.getTasks();
    this.getDropdownOptions();
    //this.handleGridFilterChange();
  },
  methods: {
    setPageSize(event) {
      const selectedSize = event.target.value;
      this.pageSize = parseInt(selectedSize);
      this.currentPage = 1; // Reset to the first page
      this.paginatedTasks();
      // alert(`Page size changed to: ${selectedSize}`);
    },
    convertDateFormat(date) {
      // Check if inputDate is in dd/mm/yyyy format
      const dateParts = date.split('/');
      if (dateParts.length !== 3) {
        console.error('Invalid date format. Please enter date in dd/mm/yyyy format.');
        return;
      }
      // Parse input date parts
      const day = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10);
      const year = parseInt(dateParts[2], 10);
      // Create a new Date object with dd/mm/yyyy format
      const parsedDate = new Date(year, month - 1, day + 1);
      // Format the date as yyyy-mm-dd
      const formattedDate = parsedDate.toISOString().split('T')[0];
      // Update inputDate with the formatted date
      return formattedDate;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    resetCreatTaskForm() {
      // Reset file input
      this.$refs.file.value = '';
      this.taskAssignment.selectedUsers = [];
    },
    handleFileUpload(event) {
      this.newTask.file = event.target.files[0];
    },
    filterGrid: function () {
      this.IsTasks = false;
      this.currentPage = 1;
      var searchText = this.searchText;
      if (parseInt(searchText) > 0) {
        this.filtertask = this.tasks.filter(item => {
          return (
            item.TaskID == parseInt(searchText)
          );

        });
        if (this.filtertask.length > 0) {
          this.filteredTasks = this.filtertask;
        }
        else {
          console.log("ok");
          this.filteredTasks = [];
          this.IsTasks = true;
        }
      }
      else {
        searchText = searchText.toLowerCase();
        this.filtertask = this.tasks.filter(item => {
          return (
            item.CategoryName.toLowerCase().includes(searchText) ||
            item.Title.toLowerCase().includes(searchText) ||
            // item.Description.toLowerCase().includes(searchText) ||
            item.PriorityName.toLowerCase().includes(searchText)
          );
        });
        if (this.filtertask.length > 0) {
          this.filteredTasks = this.filtertask;
        }
        else {
          console.log("ok");
          this.filteredTasks = [];
          this.IsTasks = true;
        }
      }
      // Filter tasks based on searchText
    },
    filterData: function (x) {
      this.currentPage = 1;
      this.IsTasks = false;
      if (parseInt(x) != 5 && parseInt(x) != 6) {
        this.filtertask = this.tasks.filter(item => {
          return (
            item.TaskStatus == parseInt(x)
          );
        });
        if (this.filtertask.length > 0) {
          this.filteredTasks = this.filtertask;
        }
        else {
          console.log("ok");
          this.filteredTasks = [];
          this.IsTasks = true;
        }
      }
      if (parseInt(x) == 5) {
        this.filteredTasks = this.tasks.filter(item => {
          return (
            item.IsExpired == 1
          );
        });
      }
      if (parseInt(x) == 6) {
        this.filteredTasks = this.tasks;
      }
      // Filter tasks based on searchText
    },
  
    handleGridFilterChange() {
      this.getTasks();
    },
    async getDropdownOptions() {
      try {
        this.Isloading = true;
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie = userCookie ? JSON.parse(userCookie) : null;
        const response = await api.get(
          `/api/admin/tasks/get-dropdown-tasks-users/${userCookie.UserID}`
        );
        if (response.data) {
          this.DropdownOptions.userOptions = response.data.users;
          this.priorityDropdownOptions = response.data.priorities;
          this.categoryDropdownOptions = response.data.categories.slice().sort((a, b) => a.CategoryName.localeCompare(b.CategoryName));
        } else {
          // Handle case where no priorities are found
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              // this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              // this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 657, error.stack, "AdminPanel/Tasks/getDropDownOptions()");
          }
        }
        else {
          // this.errorAlertOnSubmit = "Something went wrong.";
        }
        // Handle API error
      }
      finally {
        this.Isloading = false;
      }
    },
    formatDueDate(originalDate, type = "show") {
      if (!originalDate) return ""; // Handle null or undefined dates

      const dateObject = new Date(originalDate);
      const day = dateObject.getDate().toString().padStart(2, "0");
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObject.getFullYear();
      if (type == "show") {
        return `${day}/${month}/${year}`;
      } else {
        return `${day}/${month}/${year}`;
      }
    },
    truncateDescription(description) {
      // Truncate the description if it's longer than 30 characters
      return description.length > 60
        ? description.slice(0, 60) + "..."
        : description;
    },
    handleTaskModalHidden() {
      this.editTaskMode = false;
    },
    closeModal() {
      this.editMode = false;
      this.resetTaskValidation();
      document.getElementById("closeModal").click();
    },
    HideReOpenTaskModal() {
      document.getElementById("HideReOpenTaskModal").click();
    },
    closeAssignmentModal() {
      this.editMode = false;
      this.resetTaskAssignmentValidation();
      document.getElementById("closeAssignmentModal").click();
    },
    openTaskModal(refresh = false) {
      if (refresh) {
        this.editTaskMode = false;
        this.newTask = {
          TaskID: 0,
          Title: "",
          Description: "",
          // CreatedDate: "",
          DueDate: "",
          IsCompleted: false,
          PriorityID: 0,
          CategoryID: 0,
        };
        this.resetTaskValidation();
      }
    },
    openModal() {
      document.getElementById("openModal").click();
    },
    openAssignmentModal() {
      document.getElementById("openAssignmentModal").click();
    },
    async getTasks() {
      this.Isloading = true;
      this.tasks = [];
      this.filteredTasks = [];
      this.loadingTasks = "Loading...";
      try {
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        //console.log(this.filterId);
        localStorage.setItem('filterid', this.filterId);
        const response = await api.get(
          `/api/admin/tasks/get-tasks/${userCookie.UserID}?filterId=${this.filterId}`
        );
        if (response.data) {
          this.tasks = response.data;
          console.log("newdata", response.data);
          this.filteredTasks = this.tasks;
        } else {
          // Handle case where no tasks are found
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 764, error.stack, "AdminPanel/Tasks/getDropDownOptions()");
          }
        }
        else {
          this.errorAlertOnSubmit = "Something went wrong.";
        }
      } finally {
        this.Isloading = false;
        if (this.tasks.length > 0) {
          this.loadingTasks = "";
        } else {
          this.loadingTasks = "No tasks found.";
        }
      }
    },
    async getTaskById(taskId) {
      try {
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        const response = await api.get(
          `/api/admin/tasks/get-task-by-id/${taskId}`
        );
        if (response.data) {
          this.newTask = response.data.tasks[0];
          this.newTask.DueDate = this.formatDueDate(
            response.data.tasks[0].DueDate,
            "update"
          );
          this.openModal();
          this.resetTaskValidation();
        }
        else {
          this.errorAlertOnSubmit = "Something went wrong. please try again later.";
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 814, error.stack, "AdminPanel/Tasks/getTaskById()");
          }
        }
        else {
          this.errorAlertOnSubmit = "Something went wrong.";
        }
      }
    },
    async createTask() {
      this.taskFormHeader = "";
      try {
        this.btnTaskEnable = true;
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        const formData = new FormData();
        formData.append('Title', this.newTask.Title);
        // formData.append('taskId', this.editTask.TaskID);
        formData.append('Description', this.newTask.Description);
        formData.append('DueDate', this.convertDateFormat(this.newTask.DueDate));
        formData.append('IsCompleted', false);
        formData.append('PriorityID', this.newTask.PriorityID);
        formData.append('CategoryID', this.newTask.CategoryID);
        formData.append('TaskStatus', 1);
        formData.append('TaskType', "Public");
        formData.append('file', this.newTask.file);
        formData.append('UserID', userCookie.UserID);
        formData.append('assignBy', userCookie.UserID);
        formData.append('userIds', this.taskAssignment.selectedUsers);
        const response = await api.post('/api/admin/tasks/create-task/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        if (response.data.success) {
          this.getTasks();
          this.closeModal(); // hide modal after successful submit.
          this.successAlertOnSubmit = "Task added successfully.";
        } else {
          this.taskFormHeader = "Internal server error.";
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.taskFormHeader = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.taskFormHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 872, error.stack, "AdminPanel/Tasks/createTask()");
          }
        }
        else {
          this.taskFormHeader = "Something went wrong.";
        }
      } finally {
        setTimeout(() => {
          this.taskFormHeader = "";
          this.successAlertOnSubmit = "";
        }, 5000); // 5 seconds in milliseconds
        this.btnTaskEnable = false;
      }
    },
    editTask(taskId) {
      this.editTaskMode = true;
      this.getTaskById(taskId);
    },
    ReOpenTaskModal(TaskID) {
      this.newTask.TaskID = TaskID;
      document.getElementById("ReOpenTaskModal").click();
    },
    async OpenTask(TaskID) {
      this.taskFormHeader = "";
      this.IsReOpenTask = true;
      try {
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        const response = await api.put(
          `/api/admin/tasks/re-open-task/${TaskID}`
        );
        console.log("taskopen", response);
        if (response.data.success) {
          this.getTasks();
          this.successAlertOnSubmit = "Task Open successfully.";
        } else {
          this.taskFormHeader = response.data.msg;
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.taskFormHeader = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.taskFormHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 928, error.stack, "AdminPanel/Tasks/OpenTask()");
          }
        }
        else {
          this.taskFormHeader = "Something went wrong.";
        }
      } finally {
        //  this.btnTaskEnable = false;
        this.IsReOpenTask = false;
        this.HideReOpenTaskModal();
      }
    },
    isValidTaskForm() {
      let isValid = true;
      // Title
      if (!this.newTask.Title.trim()) {
        this.taskValidate.Title = "Title is required.";
        isValid = false;
      } else {
        this.taskValidate.Title = "";
      }

      // Description
      if (!this.newTask.Description.trim()) {
        this.taskValidate.Description = "Description is required.";
        isValid = false;
      } else {
        this.taskValidate.Description = "";
      }
      //assign user
      if (this.taskAssignment.selectedUsers.length == 0) {
        this.taskAssignmentValidate.selectedUsers = "Please select users.";
        isValid = false;
      } else {
        this.taskAssignmentValidate.selectedUsers = "";
      }
      // Priority
      if (this.newTask.PriorityID === 0 || this.newTask.PriorityID === "0") {
        this.taskValidate.PriorityID = "Please select priority.";
        isValid = false;
      } else {
        this.taskValidate.PriorityID = "";
      }

      // Category
      if (this.newTask.CategoryID === 0 || this.newTask.CategoryID === "0") {
        this.taskValidate.CategoryID = "Please select project.";
        isValid = false;
      } else {
        this.taskValidate.CategoryID = "";
      }
      // DueDate
      if (!this.newTask.DueDate) {
        this.taskValidate.DueDate = "Due Date is required.";
        isValid = false;
      } else {
        this.taskValidate.DueDate = "";
      }
      return isValid;
    },
    isValidTaskAssignmentForm() {
      let isValid = true;
      this.taskAssignmentValidate.selectedUsers = "";
      return isValid;
    },
    async assignTask() {
      this.taskFormHeader = "";
      try {
        this.btnTaskEnable = true;
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        const response = await api.post(
          `/api/admin/tasks/assign-task/${this.taskAssignment.TaskID}`,
          {
            assignBy: userCookie.UserID,
            userIds: this.taskAssignment.selectedUsers,
            DueDate: this.taskAssignment.DueDate,
          }
        );
        if (response.data.success) {
          this.getTasks();
          this.closeAssignmentModal(); // hide modal after successful submit.
          this.successAlertOnSubmit = "To-Do assigned successfully.";
        } else {
          //console.log(response);
          this.taskFormHeader = response.data.msg;
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.taskFormHeader = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.taskFormHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 1033, error.stack, "AdminPanel/Tasks/assignTask()");
          }
        }
        else {
          this.taskFormHeader = "Something went wrong.";
        }
      } finally {
        this.btnTaskEnable = false;
      }
    },
    async updateAssignTask() {
      this.taskFormHeader = "";
      try {
        this.btnTaskEnable = true;
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        const response = await api.put(
          `/api/admin/tasks/update-assign-task/${this.taskAssignment.TaskID}`,
          {
            assignBy: userCookie.UserID,
            userIds: this.taskAssignment.selectedUsers,
            updateTaskId: this.taskAssignment.updateTaskId,
            DueDate: this.taskAssignment.DueDate,
          }
        );
        if (response.data.success) {
          this.getTasks();
          this.closeAssignmentModal(); // hide modal after successful submit.
          this.successAlertOnSubmit = "To-Do assigned successfully.";
        } else {
          //console.log(response);
          this.taskFormHeader = response.data.msg;
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 1084, error.stack, "AdminPanel/Tasks/updateAssignTask()");
          }
        }
        else {
          this.errorAlertOnSubmit = "Something went wrong.";
        }
      } finally {
        this.btnTaskEnable = false;
      }
    },
    async submitTaskForm() {
      this.resetTaskValidation();

      if (this.isValidTaskForm()) {
        this.createTask();
      }
    },
    submitReOpenTaskForm() {
      this.OpenTask(this.newTask.TaskID)
    },
    resetTaskValidation() {
      this.taskValidate = {
        Title: "",
        DueDate: "",
      };
    },
    hideTaskAlert() {
      this.taskFormHeader = "";
      this.errorAlertOnSubmit = "";
      this.successAlertOnSubmit = "";
    },
    async getTaskAssignmentById(taskId, title) {
      this.taskAssignment = {
        updateTaskId: 0,
        TaskID: 0,
        TaskTitle: title,
        UserId: 0,
        selectedUsers: [],
        DueDate: null,
      };
      try {
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        const response = await api.get(
          `/api/admin/tasks/get-assigned-tasks-details/${userCookie.UserID}?taskId=${taskId}`
        );
        if (response.data) {
          if (response.data.length > 0) {
            this.IsAlreadyAssigned = true;
            // Assign the values from the API response to newTask
            this.taskAssignment.TaskID = response.data[0].TaskId;
            this.taskAssignment.updateTaskId = response.data[0].TaskId;
            this.taskAssignment.UserId = userCookie.UserID; // Assuming you want to keep the user who is updating the task
            this.taskAssignment.DueDate = this.formatDueDate(
              response.data[0].DueDate,
              "update"
            );
            // If the API response provides an array of selected users, you can directly assign it
            const selectedUsersArray =
              response.data[0].SelectedUsers.split(",").map(Number);
            this.taskAssignment.selectedUsers = selectedUsersArray;
            this.editMode = true;
          } else {
            this.IsAlreadyAssigned = false;
            this.taskAssignment.TaskID = taskId;
            this.taskAssignment.UserId = userCookie.UserID;
          }
          this.openAssignmentModal();
          this.resetTaskAssignmentValidation();
        } else {
          this.errorAlertOnSubmit = "Something went wrong. please try again later."
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 1174, error.stack, "AdminPanel/Tasks/getTaskAssignmentById()");
          }
        }
        else {
          this.errorAlertOnSubmit = "Something went wrong.";
        }
      }
    },

    viewTaskDetail(taskId) {
      this.$router.push({
        path: `/task-details/${taskId}/0`
      });
    },
    editTaskDetail(taskId) {
      this.$router.push({
        name: "Edit Task",
        params: { taskId },
      });
    },
    resetTaskAssignmentValidation() {
      this.taskValidate = {
        TaskID: "",
        selectedUsers: "",
        DueDate: "",
      };
    },
    async deleteItem(taskId) {
      try {
        //console.log(taskId);
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        // Make your delete API call here
        const response = await api.delete(
          `/api/admin/tasks/delete-assign-task/${taskId}/${userCookie.UserID}`
        ); // Replace with your actual API endpoint
        if (response.data.success) {
          this.getTasks();
          this.successAlertOnSubmit = "Deleted Successfully";
          this.closeAssignmentModal();
        } else {
          //   alert(response.data.msg);
          this.errorAlertOnSubmit = "Something went wrong. please try again later.";
        }
        // Optionally, perform additional actions after successful deletion
      }
      catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 1238, error.stack, "AdminPanel/Tasks/deleteItem()");
          }
        }
        else {
          this.errorAlertOnSubmit = "Something went wrong.";
        }
      }
    },
    confirmDelete(taskId) {
      const isConfirmed = window.confirm(
        "Are you sure you want to delete assignment?"
      );

      if (isConfirmed) {
        // Call the deleteItem method when confirmed
        this.deleteItem(taskId);
      }
    },

    closeDeleteConfirmStatusModal() {
      document.getElementById("closeDeleteConfirmStatusModal").click();
    },
    openDeleteModal(taskId) {
      this.newTask.TaskID = taskId;
      document.getElementById("openDeleteStatusModal").click();
    },
    async submitDeleteTaskStatusForm() {
      this.deleteTaskItem(this.newTask.TaskID);
    },
    async deleteTaskItem(taskId) {
      try {
        this.btnTaskEnable = true;
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        // Make your delete API call here
        const response = await api.post(
          `/api/admin/tasks/delete-task/${taskId}/${userCookie.UserID}`
        ); // Replace with your actual API endpoint
        if (response.data.success) {
          this.getTasks();
          this.successAlertOnSubmit = response.data.msg;
          this.closeDeleteConfirmStatusModal();
        } else {
          // alert(response.data.msg);
          this.errorAlertOnSubmit = response.data.msg;
        }
        // Optionally, perform additional actions after successful deletion
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 1303, error.stack, "AdminPanel/Tasks/deleteTaskItem()");
          }
        }
        else {
          this.errorAlertOnSubmit = "Something went wrong.";
        }
      }
      finally {
        this.btnTaskEnable = false;
      }
    },
    formatChatCount(count) {
      return count < 10 ? `0${count}` : count;
    },
  },
};
</script>

<style scoped>
/* Add Tailwind CSS classes here if needed */
.close-icon {
  cursor: pointer;
  float: right;
  font-size: 30px;
  margin-top: -10px;
}

.mb-3 {
  text-align: left;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1em;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.table th {
  background-color: #f2f2f2;
}

.expired-row {
  background-color: #dc8484;
}

/* Additional styling for left and right padding */
.table th,
.table td {
  padding-left: 12px;
  padding-right: 12px;
}

.user-list {
  min-height: 120px;
  max-height: 120px;
  /* Set a maximum height for the user list */
  overflow-y: auto;
  /* Add a scrollbar when the content exceeds the height */
  border: 1px solid #ccc;
  /* Optional: Add a border for styling */
}

.user-item {
  margin-bottom: 8px;
  /* Optional: Add some spacing between user items */
}

.actionBtns {
  text-align: right !important;
}

.alreadyAssignedText {
  color: #ed6524;
  font-size: 15px;
  text-align: center;
}

/* .taskNotAssigned {
  color: orange;
}

.taskAssigned {
  color: #ed6524;
}
.taskInProcess {
  color: #864426;
}

.taskCompleted {
  color: green;
} */

.rowtaskNotAssigned {
  background: #faead6;
}

.rowtaskAssigned {
  background: #eaea8d;
}

.rowtaskInProcess {
  background: #b4e9d4;
}

.PageSizeSelect {
  border-radius: 5px;
  margin: 5px;
  height: 35px;
  font-size: 17px;
}

.current-page-indicator {
  background-color: green;
  border-radius: 50%;
  /* Ensures the element is rounded */
  padding: 3px;
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  /* Adjust this value as needed */
  height: 25px;
  /* Ensure width and height are equal */
}

.rowtaskCompleted {
  background: #9ed79e;
}

.gridFilter {
  margin-left: auto;
  height: 30px;
  width: 130px;
}

.dropdown {
  width: 100%;
  height: 36px;
  padding: 7px 0;
  font-size: 14px;
  line-height: 1.428571429;
  border-color: #D2D2D2;
}

@media screen and (min-width:1020px) {
  #taskEditStatusModal .modal-dialog {
    width: 35%;
  }
}

.table-cell {
  font-size: 1.0625rem;
  padding-bottom: 10px;
  font-weight: 500;
  padding-top: 8px;
}

.toprow {
  color: #fff;
  background: #0d6efd;
  height: 35px;
  font-weight: 500;
  font-size: 16px;
}

.task-table {
  width: 100%;
}

@media screen and (min-width:990px) and (max-width: 1185px) {
  .task-table {
    width: 1100px;
    overflow-x: auto
  }
}

@media screen and (max-width: 890px) {
  .task-table {
    width: 830px;
    overflow-x: auto
  }
}

/*@media screen and (min-width:1100px) and (max-width: 1300px) {
  .task-table{
  width:1050px ;
  overflow-x:auto
}
} */
.user-item {
  width: 50%;
  float: left;
}

.user-list {
  min-height: 80px;
  border: 0px;
}

.progress {
  float: left;
}

.highlight-text {
  margin-left: 5px;
  margin-right: 8px;
}

.highlight-text:hover {
  color: blue;
  font-weight: 400;
}

.color-box {
  display: inline-block;
  padding-left: 6px;
  cursor: pointer;
  padding-top: 5px;
}

.modal-content .modal-header {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.modal-title {
  margin-top: 10px;
}

.modal-content .modal-body {
  padding-top: 10px;
}

.Pagination {
  width: 50%;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.Pagination button {
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 17px;
}

.enabled-button {
  background-color: #007bff;
  /* Blue color for enabled button */
  color: #ffffff;
  /* White text color for enabled button */
  border: 2px solid #007bff
}

.disabled-button {
  background-color: lightgray;
  /* Gray color for disabled button */
  color: #ffffff;
  /* White text color for disabled button */
  border: 2px solid lightgray;
}

.notification {
  border-radius: 50%;
  padding: 5px;
  text-align: center;
  font-size: 11px;
  color: white;
  background-color: red;
}
</style>
