<template>
  <div class="content" style="overflow-x:hidden;">
    <LoadingOverlay :loading="Isloading" />
    <div class="row">
      <div class="col-md-12 ms-3 pt-2 pb-3">
        <div class="color-box" v-on:click="filterData(6)">
          <span class="highlight-text">All Task</span>
        </div>
        <div class="color-box" v-on:click="filterData(1)">
          <div class="progress task-detail-value" style="width: 25px; margin-top: 3px;">
            <div title="Low" class="progress-bar bg-info" role="progressbar"
              style="width: 100%;background-color: #9ed79e !important;" aria-valuenow="0" aria-valuemin="0"
              aria-valuemax="100">&nbsp;</div>
          </div><span class="highlight-text">Complete</span>
        </div>
        <div class="color-box" v-on:click="filterData(2)">
          <div class="progress task-detail-value" style="width: 25px; margin-top: 3px;">
            <div title="Low" class="progress-bar bg-info" role="progressbar"
              style="width: 100%;background-color: #dc8484 !important;" aria-valuenow="0" aria-valuemin="0"
              aria-valuemax="100">&nbsp;</div>
          </div><span class="highlight-text">Pending</span>
        </div>
        <div class="color-box" v-on:click="filterData(3)">
          <div class="progress task-detail-value" style="width: 25px; margin-top: 3px;">
            <div title="Low" class="progress-bar bg-info" role="progressbar"
              style="width: 100%;background-color: #958c8c !important;" aria-valuenow="0" aria-valuemin="0"
              aria-valuemax="100">&nbsp;</div>
          </div><span class="highlight-text">Expired</span>
        </div>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
        <md-card>
          <md-card-header data-background-color="green">
            <div class="card-header d-flex justify-content-between align-items-center">
              <h4 class="title">My To-Do's</h4>
              <div class="ms-auto">

                <button hidden type="button" title="Delete Task" data-bs-toggle="modal"
                  data-bs-target="#taskDeleteConfirmModal" data-bs-whatever="@mdo" class="btn btn-primary"
                  id="openDeleteStatusModal">
                  Delete Task
                </button>
              </div>
            </div>
          </md-card-header>
          <md-card-content>
            <div class="col-md-12">
              <div v-if="successAlertOnSubmit" class="alert alert-success text-left" role="alert">
                {{ successAlertOnSubmit }}
                <span @click="hideTaskAlert" class="close-icon">&times;</span>
              </div>
              <div v-if="errorAlertOnSubmit" class="alert alert-danger text-left" role="alert">
                {{ errorAlertOnSubmit }}
                <span @click="hideTaskAlert" class="close-icon">&times;</span>
              </div>
              <form @submit.prevent="submitTaskForm">
                <input v-model="newTask.TaskID" type="hidden" class="form-control" />

                <div v-if="taskFormHeader" class="alert alert-danger text-left" role="alert">
                  {{ taskFormHeader }}
                  <span @click="hideTaskAlert" class="close-icon">&times;</span>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-1">
                      <label for="task" class="col-form-label">Task</label>
                      <input v-model="newTask.Task" type="text" placeholder="Task" class="form-control" id="task"
                        tabindex="1" maxlength="100" @input="isValidTaskForm" />
                      <span class="text-red-500 text-sm">{{ taskValidate.Task }}</span>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="mb-1">
                      <label for="dueDate" class="col-form-label">Due Date</label>
                      <flat-pickr v-model="newTask.DueDate" class="form-control" tabindex="2" ref="dueDatePicker"
                        @update:modelValue="newTask.DueDate = $event" :config="flatpickrConfig"></flat-pickr>
                      <!-- <flat-pickr :modelValue="newTask.DueDate" class="form-control" tabindex="2" ref="dueDatePicker"
                        @update:modelValue="newTask.DueDate = $event" :config="flatpickrConfig"></flat-pickr> -->
                      <span class="text-red-500 text-sm">{{
                        taskValidate.DueDate
                      }}</span>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="mb-1">
                      <label for="TastType" class="col-form-label">Task Type</label>
                      <select v-model="newTask.TaskType" class="dropdown" id="TastType" tabindex="3"
                        @change="isValidTaskForm">
                        <option value="0">Select Task Type</option>
                        <option value="Personal">Personal</option>
                        <option value="Official">Official</option>
                      </select>
                      <span class="text-red-500 text-sm">{{
                        taskValidate.TaskType
                      }}</span>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="mb-1" style="box-sizing: border-box;display: flex;">
                      <button type="submit" :title='editTaskMode ? "Update" : "Add"' style="margin-top: 32px;"
                        class="btn btn-primary text-right" :disabled="btnTaskEnable" default tabindex="7">
                        {{ editTaskMode ? "Update" : "Add" }}
                      </button>
                      <button type="button" title='Reset' style="margin-top: 32px;"
                        class="btn btn-danger text-right mx-2" default tabindex="7" @click="resetCreatTaskForm">
                        Reset
                      </button>
                    </div>
                  </div>
                </div>

              </form>
            </div>
            <div class="col-md-12 mt-3">
              <!-- Display success alert -->
              <div class="table-responsive" style="overflow: auto !important;">
                <div v-if="loadingTasks" class="text-center">
                  <p>{{ loadingTasks }}</p>
                </div>
                <div class="task-table">
                  <table style="width: 100%;" :table-header-color="tableHeaderColor">
                    <tr v-if="!loadingTasks" class="toprow">
                      <td style="width:55%" class="table-cell mx-2">Task</td>
                      <td style="width:15%" class="table-cell">Task Type</td>
                      <td style="width:15%" class="table-cell">Due Date</td>
                      <td style="width:15%;text-align:center" class="table-cell">Actions</td>
                    </tr>
                    <tr slot="md-table-row" v-for="item in paginatedTasks" :key="item.id" :class="{
                      'expired-row': item.IsExpired,
                      'rowtaskNotAssigned': item.TaskStatus === 'Pending',
                      'rowtaskCompleted': item.TaskStatus === 'Completed',
                      'expired-row': item.TaskStatus === 'Expired',

                      'duedateblink': dueDateBlink === true,
                    }" style="border-bottom: 1px solid #ddd;">
                      <!-- <td class="md-table-cell">{{ item.TaskID }}</td> -->
                      <td class="md-table-cell"><span v-if="item.Task.length > 80" :title="item.Task">
                          {{ truncateDescription(item.Task) }}
                        </span>
                        <span v-else>
                          {{ item.Task }}
                        </span>
                      </td>
                      <td class="md-table-cell">{{ item.TaskType }}</td>
                      <td class="md-table-cell" :class="checkDueDate(item.DueDate)"> {{ item.DueDate }} </td>
                      <td class="md-table-cell" style="text-align:right">
                        <!-- <button @click="OpenTask(item.TaskID)" type="button" v-if="item.IsCompleted"
                          class="btn btn-info btn-sm ml-2" title="Re-Open Task">
                          <i class="fa fa-tasks" aria-hidden="true"></i>
                        </button> -->
                        <button @click="submitEditTaskStatus(item.TaskID,item.Task)" :disabled="item.TaskStatus === 'Completed' || item.TaskStatus === 'Expired'"
                          type="button" class="btn btn-primary btn-sm ml-2" title="Mark Completed">
                          <md-icon style="color: white">add_task</md-icon>
                        </button>

                        <button @click="editTaskDetails(item.TaskID)" type="button" class="btn btn-primary btn-sm ml-2"
                          :disabled="item.TaskStatus === 'Completed' || item.TaskStatus === 'Expired'" title="Edit Task">
                          <i class="fas fa-pencil-alt"></i>
                        </button>
                        <button @click="openDeleteModal(item.TaskID)" :disabled="item.TaskStatus === 'Completed' || item.TaskStatus === 'Expired'" type="button" class="btn btn-danger btn-sm ml-2"
                          title="Delete Task">
                          <i class="fa fa-trash" aria-hidden="true"></i>
                          <!-- <md-icon style="color: white">delete</md-icon> -->
                        </button>
                      </td>
                    </tr>
                  </table>
                  <div class="d-flex my-1 mt-2" v-if="!IsTasks">
                    <div style="width: 33%;" class="d-flex">
                      <span style="width: 50%;line-height:50px;">Tasks per page:</span>
                      <Select @change="setPageSize" class="form-select PageSizeSelect" style="width: 40%;"
                        v-model="pageSize">
                        <Option :value="5">5</Option>
                        <Option :value="10">10</Option>
                        <Option :value="20">20</Option>
                        <Option :value="30">30</Option>
                      </Select>
                    </div>
                    <div class="Pagination">
                      <button @click="previousPage" title="Previous" :disabled="currentPage === 1"
                        :class="{ 'disabled-button': currentPage === 1, 'enabled-button': currentPage !== 1 }"><i
                          class="fa fa-angle-left"></i> Prev</button>
                      <span class="current-page-indicator">{{ currentPage }}</span>
                      <button title="Next" @click="nextPage" :disabled="currentPage === totalPages"
                        :class="{ 'disabled-button': currentPage === totalPages, 'enabled-button': currentPage !== totalPages }">Next
                        <i class="fa fa-angle-right"></i></button>
                    </div>
                    <div style="width: 33%" class="d-flex justify-content-end mx-3">
                      <span style="line-height:50px;" class="text-right mx-1">Total Pages: </span>
                      <span style="line-height:50px;" class="text-right">{{ totalPages }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <confirmationbox v-if="confirmTaskStatus" :message="ActivationMessage" @confirmed="confirmMarkTaskStatus"
              @canceled="hideConfirmation" />

            <!-- Modal for confirm delete Task -->
            <div class="modal fade" id="taskDeleteConfirmModal" tabindex="-1" aria-labelledby="DeleteConfirmModalLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-md">
                <div class="modal-content">
                  <form @submit.prevent="submitDeleteTaskStatusForm">
                    <input v-model="newTask.TaskID" type="hidden" class="form-control" />
                    <div class="modal-body">
                      <div class="row">
                        <h4>Are you sure you want to delete {{ newTask.TaskID }} TaskId?</h4>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" title="No" class="btn btn-secondary ml-2" data-bs-dismiss="modal"
                        id="closeDeleteConfirmStatusModal" tabindex="8">
                        No
                      </button>
                      <button type="submit" title="Yes" class="btn btn-danger" tabindex="7">
                        Yes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import api, { setAuthToken } from "../apiConfig";
import { authMixin } from "./adminStore";
import Cookies from "js-cookie";
import Flatpickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { ErrorLog } from "../Common/APILog";
import LoadingOverlay from '../Common/blockoutpage.vue';
import { handleAuthorization, handleServerError } from "../Common/commonMethod";
import confirmationbox from "../Common/confirmationbox.vue";
export default {
  mixins: [authMixin],
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  created() {
    this.checkAuthorization();
  },
  name: "TaskManagement",
  components: {
    'flat-pickr': Flatpickr,
    LoadingOverlay,
    confirmationbox
  },
  data() {
    return {
      tasks: [],
      filteredTasks: [],
      pageSize: 5, // Number of tasks per page
      currentPage: 1,// Current page
      IsTasks: false,
      dueDateBlink: false,
      filtertask: [],
      newTask: {
        TaskID: 0,
        Task: "",
        DueDate: "",
        // PriorityID: 0,/
        UserID: 0,
        TaskStatus: '',
        TaskType: 0,
      },
      taskValidate: {
        Task: "",
        TaskType: '',
        // PriorityID: "",
        DueDate: "",
        // Add additional validation fields as needed
      },
      taskFormHeader: "",
      errorAlertOnSubmit: "",
      successAlertOnSubmit: "",
      confirmationVisibleActive: false,
      confirmationVisibleDelete: false,
      confirmTaskStatus: false,
      ActivationMessage: '',
      editTaskMode: false,
      isInputDisabled: true,
      loadingTasks: "",
      btnTaskEnable: false,
      // priorityDropdownOptions: [],
      IsAlreadyAssigned: false,
      Isloading: false,
      title: "My Tasks",
      flag: 0,
      flatpickrConfig: {
        enableTime: true,
        dateFormat: "d-m-Y H:i",// Set the date format to year-month-day
        minDate: 'today', // Set minDate to today's date
        onChange: this.isValidTaskForm
      },
    };
  },
  computed: {
    paginatedTasks() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      console.log("filter task: " + this.filteredTasks.length)
      return this.filteredTasks.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.filteredTasks.length / this.pageSize);
    },
    isRowExpired() {
      return (item) => item.IsExpired === 1;
    },
  },
  mounted() {
    // this.newTask.DueDate=this.getCurrentFormattedDate();
    this.getTasks();
    this.resetCreatTaskForm();
    // this.getPriorityDropdown();
  },
  methods: {
    setPageSize(event) {
      const selectedSize = event.target.value;
      this.pageSize = parseInt(selectedSize);
      this.currentPage = 1; // Reset to the first page
      this.paginatedTasks();
      // alert(`Page size changed to: ${selectedSize}`);
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },

    hideConfirmation() {
      this.confirmTaskStatus = false;
      this.confirmationVisibleDelete = false;
    },

    resetCreatTaskForm() {
      this.newTask.DueDate = this.getCurrentFormattedDate(),
        this.newTask.Task = "";
      this.newTask.TaskStatus = "";
      this.newTask.TaskType = 0;
      this.newTask.TaskID = 0;
      // this.newTask.PriorityID = 0;
      this.resetTaskValidation();
    },
    filterData: function (x) {
      this.IsTasks = false;
      this.currentPage = 1;
      this.loadingTasks = ""
      if (parseInt(x) != 6) {
        if (parseInt(x) == 1) {
          this.filtertask = this.tasks.filter(item => {
            console.log("items here: " + item)
            return (
              item.TaskStatus == 'Completed'
            );

          });
        }

        if (parseInt(x) == 2) {
          this.filtertask = this.tasks.filter(item => {
            console.log("items here: " + item)
            return (
              item.TaskStatus == 'Pending'
            );
          });
        }
        if (parseInt(x) == 3) {
          this.filtertask = this.tasks.filter(item => {
            console.log("items here: " + item)
            return (
              item.TaskStatus == 'Expired'
            );
          });
        }

        if (this.filtertask.length > 0) {
          this.filteredTasks = this.filtertask;
        }
        else {
          console.log("ok");
          this.filteredTasks = [];
          this.IsTasks = true;
          this.loadingTasks = "No Task Found."
        }
      }
      if (parseInt(x) == 6) {
        this.filteredTasks = this.tasks;
      }
      // Filter tasks based on searchText
    },

    formatDueDate(originalDate, type = "show") {
      if (!originalDate) return ""; // Handle null or undefined dates
      let dateObject;

      // Check if the originalDate is in a recognizable format
      if (typeof originalDate === "string" && originalDate.includes("-")) {
        // Assuming originalDate is in "DD-MM-YYYY HH:mm" format
        const [datePart, timePart] = originalDate.split(" ");
        const [day, month, year] = datePart.split("-");
        const [hours, minutes] = (timePart || "00:00").split(":");

        // Create the date object with correct order of components
        dateObject = new Date(year, month - 1, day, hours, minutes);
      } 
      else {
        // If the format is already ISO 8601 or another recognizable format
        dateObject = new Date(originalDate);
      }
      
      const day = dateObject.getDate().toString().padStart(2, "0");
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObject.getFullYear();
      const hours = dateObject.getHours().toString().padStart(2, "0");
      const minutes = dateObject.getMinutes().toString().padStart(2, "0");

      if (type === "update") {
        // Return date format according to flatpickrConfig
        return `${day}-${month}-${year} ${hours}:${minutes}`;
      } else {
        return `${day}/${month}/${year}`;
      }
    },

    getCurrentFormattedDate() {
      const now = new Date();
      const day = now.getDate().toString().padStart(2, "0");
      const month = (now.getMonth() + 1).toString().padStart(2, "0");
      const year = now.getFullYear();
      const hours = now.getHours().toString().padStart(2, "0");
      const minutes = now.getMinutes().toString().padStart(2, "0");

      // Format date according to your flatpickrConfig
      return `${day}-${month}-${year} ${hours}:${minutes}`;
    },
    editTaskDetail(taskId) {
      this.getTaskById(taskId);
    },
    truncateDescription(description) {
      // Truncate the description if it's longer than 30 characters
      return description.length > 80
        ? description.slice(0, 80) + "..."
        : description;
    },
    closeDeleteConfirmStatusModal() {
      document.getElementById("closeDeleteConfirmStatusModal").click();
    },
    openDeleteModal(taskId) {
      this.newTask.TaskID = taskId;
      document.getElementById("openDeleteStatusModal").click();
    },

    async getTasks() {
      this.tasks = [];
      this.Isloading = true;
      this.loadingTasks = "Loading...";
      this.filteredTasks = [];
      try {
        const token = await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie = await Cookies.get("user");
        userCookie = await userCookie ? JSON.parse(userCookie) : null;
        const response = await api.get(
          `/api/admin/tasks/get-my-to-dos/${userCookie.UserID}`
        );
        console.log(response)
        if (response.data) {
          console.log(response.data);
          this.tasks = response.data;
          console.log("this task: " + this.tasks)
          this.filteredTasks = this.tasks;
        } else {
          // Handle case where no tasks are found
        }
      } catch (error) {
        // Handle API error
        if (this.flag > 3) {
          if (error.response && error.response.data) {
            if (error.response.status === 401) {
              handleAuthorization("/login")
            }
            else if (error.response.status === 500) {
              handleServerError("/login")
            }
            else {
              const jsonResponseString = JSON.stringify(error.response.data);
              try {
                const jsonObject = JSON.parse(jsonResponseString);
                this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
              } catch (parseError) {
                this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
              }
              ErrorLog(error.message, error.response.status, 1217, error.stack, "AdminPanel/personalTasks/getTasks()");
            }
          }
          else {
            this.errorAlertOnSubmit = "Something went wrong.";
          }
        }
        else {
          this.flag++;
          setTimeout(() => {
            this.getTasks(); // Retry after a delay
          }, 1000);
        }
      } finally {
        this.Isloading = false;
        setTimeout(() => {
          this.successAlertOnSubmit = "";
          this.errorAlertOnSubmit = "";
        }, 5000); // 5 seconds in milliseconds
        if (this.tasks.length > 0) {
          this.loadingTasks = "";
        } else {
          this.loadingTasks = "No tasks found.";
        }
      }
    },

    async createTask() {
      this.taskFormHeader = "";
      this.Isloading = true;
      try {
        this.btnTaskEnable = true;
        const token = await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie = await Cookies.get("user");
        userCookie = userCookie ? JSON.parse(userCookie) : null;
        const response = await api.post('/api/admin/tasks/create-update-to-dos', {
          TaskID: this.newTask.TaskID,
          Task: this.newTask.Task,
          DueDate: this.newTask.DueDate,
          // PID: this.newTask.PriorityID,
          TaskStatus: "Pending",
          TaskType: this.newTask.TaskType,
          UserID: userCookie.UserID
        });
        console.log(response);
        if (response.data.success) {
          this.resetCreatTaskForm()
          this.resetTaskValidation();
          this.getTasks();
          this.taskFormHeader = "";
          this.successAlertOnSubmit = response.data.msg;
        } else {
          this.taskFormHeader = "Internal server error";
        }
      } catch (error) {
        console.log("create task: " + error)
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          // else if (error.response.status === 500) {
          //   handleServerError("/login")
          // }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 1362, error.stack, "AdminPanel/personalTasks/createTask()");
          }
        }
        else {
          this.errorAlertOnSubmit = "Something went wrong.";
        }

      } finally {
        this.Isloading = false;
        this.btnTaskEnable = false;
        setTimeout(() => {
          this.taskFormHeader = "";
          this.successAlertOnSubmit = "";
        }, 5000); // 3 seconds in milliseconds
      }
    },

    //confirmStatus 
    async confirmMarkTaskStatus() {
      const taskID = this.newTask.TaskID
      this.markTaskStatus(taskID)
      this.hideConfirmation();
    },
    async markTaskStatus(taskId) {
      this.taskFormHeader = "";
      try {
        this.Isloading = true;
        this.btnTaskEnable = true;
        const token = await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie = await Cookies.get("user");
        userCookie = userCookie ? JSON.parse(userCookie) : null;
        const response = await api.put(`/api/admin/tasks/update-task-status/${taskId}`,);
        if (response.data.success) {
          this.getTasks();
          this.successAlertOnSubmit = "Task Status updated successfully.";
        } else {
          this.taskFormHeader = "Something went wrong. please try again later";
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.taskFormHeader = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.taskFormHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 1435, error.stack, "AdminPanel/personalTasks/markTaskStatus()");
          }
        }
        else {
          this.taskFormHeader = "Something went wrong.";
        }
      } finally {
        this.Isloading = false;
        this.btnTaskEnable = false;
        setTimeout(() => {
          this.successAlertOnSubmit = "";
          this.taskFormHeader = "";
        }, 5000); // 5 seconds in milliseconds
      }

    },
    async submitEditTaskStatus(taskId,task) {
      this.newTask.TaskID = taskId;
      this.confirmTaskStatus = true;
      this.ActivationMessage = `Are you sure to mark this "${task}" completed!`
    },

    //edit task details
    async editTaskDetails(taskId) {
      this.editTaskMode = true;
      this.getTaskById(taskId);
    },
    async getTaskById(taskId) {
      try {
        this.Isloading = true;
        const token = await Cookies.get("jwt");
        setAuthToken(token);
        const response = await api.get(
          `/api/admin/tasks/get-my-to-dos-by-id/${taskId}`
        );
        console.log("data", response)
        if (response.data) {
          console.log("res", response.data);
          this.newTask = response.data.data[0];
          this.newTask.DueDate = this.formatDueDate(response.data.data[0].DueDate, "update");
          this.resetTaskValidation();
        } else {
          //  alert(`Error: ${response.statusText}`);
          this.errorAlertOnSubmit = "Something went wrong. please try again later.";
        }
      } catch (error) {
        console.log(error);
        if (this.flag > 3) {
          if (error.response && error.response.data) {
            if (error.response.status === 401) {
              handleAuthorization("/login")
            }
            else if (error.response.status === 500) {
              handleServerError("/login")
            }
            else {
              const jsonResponseString = JSON.stringify(error.response.data);
              try {
                const jsonObject = JSON.parse(jsonResponseString);
                this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
              } catch (parseError) {
                this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
              }
              ErrorLog(error.message, error.response.status, 1289, error.stack, "AdminPanel/personalTasks/getTaskById()");
            }
          }
          else {
            this.errorAlertOnSubmit = "Something went wrong.";
          }
        }
        else {
          this.flag++;
          setTimeout(() => {
            this.getTaskById(taskId); // Retry after a delay
          }, 1000);
        }
      }
      finally {
        this.Isloading = false;
        setTimeout(() => {
          this.successAlertOnSubmit = "";
          this.errorAlertOnSubmit = "";
        }, 5000); // 5 seconds in milliseconds
      }
    },
    checkDueDate(date) {
      const dueDate = new Date(date); // Ensure date format matches what Date() expects
      const currentDateTime = new Date();
      const twoHoursLater = new Date(currentDateTime);
      twoHoursLater.setHours(twoHoursLater.getHours() + 2); // Set two hours later from current time

      console.log("Due Date:", dueDate);
      console.log("Current Date:", currentDateTime);
      console.log("Two Hours Later:", twoHoursLater);

      if (dueDate <= twoHoursLater) {
        console.log("Due date is within two hours from current time.");
        this.dueDateBlink = true;
        return 'duedateblink'; // Apply the style or indicator for blinking effect in UI
      } else {
        console.log("Due date is not within two hours from current time.");
        // this.dueDateBlink = false;
        return ''; // Return empty or handle other cases as needed
      }
    },

    isValidTaskForm() {
      let isValid = true;
      console.log("dd", this.newTask.DueDate)
      // Title
      if (!this.newTask.Task.trim()) {
        this.taskValidate.Task = "Title is required.";
        isValid = false;
      } else {
        this.taskValidate.Task = "";
      }

      // TaskType
      if (this.newTask.TaskType === 0 || this.newTask.TaskType === "0") {
        this.taskValidate.TaskType = "Please select task type.";
        isValid = false;
      } else {
        this.taskValidate.TaskType = "";
      }
      // DueDate
      if (!this.newTask.DueDate) {
        this.taskValidate.DueDate = "Due Date is required.";
        isValid = false;
      } else {
        this.taskValidate.DueDate = "";
      }

      return isValid;
    },
    async submitTaskForm() {
      this.resetTaskValidation();
      if (this.isValidTaskForm()) {
        console.log("hello validation ")
        this.createTask();
      }
    },

    async submitDeleteTaskStatusForm() {
      this.deleteItem(this.newTask.TaskID);
    },
    async deleteItem(taskId) {
      try {
        this.Isloading = true;
        //console.log(taskId);
        const token = await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie = await Cookies.get("user");
        userCookie = userCookie ? JSON.parse(userCookie) : null;
        // Make your delete API call here
        const response = await api.post(
          `/api/admin/tasks/delete-to-dos-task/${taskId}/${userCookie.UserID}`
        ); // Replace with your actual API endpoint

        if (response.data.success) {
          this.getTasks();
          this.successAlertOnSubmit = response.data.msg;
          this.closeDeleteConfirmStatusModal();
        } else {
          //alert(response.data.msg);
          this.errorAlertOnSubmit = "Something went wrong. please try again later.";
        }

        // Optionally, perform additional actions after successful deletion
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.taskFormHeader = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.taskFormHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 1538, error.stack, "AdminPanel/personalTasks/deleteItem()");
          }
        }
        else {
          this.taskFormHeader = "Something went wrong.";
        }
      }
      finally {
        this.Isloading = false;
        setTimeout(() => {
          this.successAlertOnSubmit = "";
          this.errorAlertOnSubmit = "";
        }, 5000); // 5 seconds in milliseconds
      }
    },
    resetTaskValidation() {
      this.taskValidate = {
        Task: "",
        TaskType: '',
        // PriorityID: "",
        DueDate: '',
      };
    },
    hideTaskAlert() {
      this.taskFormHeader = "";
      this.successAlertOnSubmit = "";
    },

    viewPersonalTaskDetail(taskId) {
      this.$router.push({
        name: "My Task Details",
        params: { taskId },
      });
    },
  },
};
</script>

<style scoped>
/* Add Tailwind CSS classes here if needed */
.close-icon {
  cursor: pointer;
  float: right;
  font-size: 30px;
  margin-top: -10px;
}

.mb-3 {
  text-align: left;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1em;
}

.expired-row {
  background-color: #958c8c;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.table th {
  background-color: #f2f2f2;
}

/* Additional styling for left and right padding */
.table th,
.table td {
  padding-left: 12px;
  padding-right: 12px;
}

.user-list {
  min-height: 120px;
  max-height: 120px;
  /* Set a maximum height for the user list */
  overflow-y: auto;
  /* Add a scrollbar when the content exceeds the height */
  border: 1px solid #ccc;
  /* Optional: Add a border for styling */
}

.user-item {
  margin-bottom: 8px;
  /* Optional: Add some spacing between user items */
}

.actionBtns {
  text-align: right !important;
}

.alreadyAssignedText {
  color: #ed6524;
  font-size: 15px;
  text-align: center;
}

@media screen and (min-width:1020px) {
  #taskEditStatusModal .modal-dialog {
    width: 35%;
  }
}



.rowtaskNotAssigned {
  background: #ce6161;
}

.table-cell {
  font-size: 1.0625rem;
  padding-bottom: 10px;
  font-weight: 500;
  padding-top: 8px;
}

.rowtaskCompleted {
  background: #9ed79e;
}

.dropdown {
  width: 100%;
  height: 36px;
  padding: 7px 0;
  font-size: 14px;
  line-height: 1.428571429;
  border-color: #D2D2D2;
}

.progress {
  float: left;
}

.highlight-text {
  margin-left: 5px;
  margin-right: 8px;
}

.highlight-text:hover {
  color: blue;
  font-weight: 400;
}

.color-box {
  display: inline-block;
  padding-left: 6px;
  cursor: pointer;
  padding-top: 5px;
}

.modal-content .modal-header {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.modal-title {
  margin-top: 10px;
}

.modal-content .modal-body {
  padding-top: 10px;
}

.task-table {
  width: 100%;
}

@media screen and (min-width:990px) and (max-width: 1185px) {
  .task-table {
    width: 1100px;
    overflow-x: auto
  }
}

@media screen and (max-width: 890px) {
  .task-table {
    width: 830px;
    overflow-x: auto
  }
}

.toprow {
  color: #fff;
  background: #0d6efd;
  height: 35px;
  font-weight: 500;
  font-size: 16px;
}

.Pagination {
  width: 50%;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.Pagination button {
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 17px;
}

.enabled-button {
  background-color: #007bff;
  /* Blue color for enabled button */
  color: #ffffff;
  /* White text color for enabled button */
  border: 2px solid #007bff
}

.disabled-button {
  background-color: lightgray;
  /* Gray color for disabled button */
  color: #ffffff;
  /* White text color for disabled button */
  border: 2px solid lightgray;
}

.current-page-indicator {
  background-color: green;
  border-radius: 50%;
  /* Ensures the element is rounded */
  padding: 3px;
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  /* Adjust this value as needed */
  height: 25px;
  /* Ensure width and height are equal */
}

.PageSizeSelect {
  border-radius: 5px;
  margin: 5px;
  height: 35px;
  font-size: 17px;
}

@keyframes duedateblink {
  50% {
    opacity: 0.4;
  }
}

.duedateblink {
  animation: duedateblink 2s linear infinite;
}
</style>
