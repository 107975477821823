<template>
  <div class="content">
    <LoadingOverlay :loading="Isloading" />
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
        <md-card>
          <md-card-header data-background-color="green">
            <div class="card-header d-flex justify-content-between align-items-center">
              <h4 class="title">Task Details</h4>
              <div class="ms-auto">
                <button :hidden="this.type == 1" @click="viewTaskMessages(task.TaskID)" type="button" id="btnChat"
                  class="btn btn-primary btn-sm ml-2" title="Task Messages">
                  Messages
                  <sup><span v-if="unReadChatCount > 0" class="notification">
                      {{ formatChatCount(unReadChatCount) }}
                      <!-- 34 -->
                    </span>
                  </sup>
                </button>
                <button type="button" title="Export" class="btn btn-primary btn-sm ml-2"
                  @click="generateAndDownloadPDF">
                  Export
                </button>
                <button type="button" title="Edit Task" class="btn btn-primary btn-sm" :disabled="task.IsCompleted"
                  id="openModalRefresh" @click="editTaskDetail(task.TaskID)">
                  Edit Task
                </button>
                <button type="button" class="btn btn-dark btn-sm ms-2 ml-2"
                  @click="goBack">
                 Back
                </button>
              </div>
            </div>
          </md-card-header>
          <md-card-content>
            <!-- Display success alert -->
            <div v-if="successAlertOnSubmit" class="alert alert-success text-left" role="alert">
              {{ successAlertOnSubmit }}
              <span @click="hideTaskAlert" class="close-icon">&times;</span>
            </div>
            <div v-if="errorAlertOnSubmit" class="alert alert-danger text-left" role="alert">
              {{ errorAlertOnSubmit }}
              <span @click="hideTaskAlert" class="close-icon">&times;</span>
            </div>
            <div v-if="loading">
              <div class="row">
                <div class="col-md-12 mt-2">
                  <div class="task-detail-row">
                    <p><span class="task-detail-label">Title:</span><span class="task-detail-value">{{ task.Title
                        }}</span></p>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="task-detail-row">
                    <span class="task-detail-label">Status:</span>
                    <div class="progress task-detail-value" style="width: 55%; margin-top: 3px">
                      <div v-if="task.TaskStatus === 1" title="Not Assigned" class="progress-bar" role="progressbar"
                        aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
                        style="background: none; color: black;width: 100%;">
                        Not Assigned
                      </div>
                      <div v-else-if="task.TaskStatus === 2" title="Assigned" class="progress-bar" role="progressbar"
                        style="width: 100%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                        Assigned
                      </div>
                      <div v-else-if="task.TaskStatus === 3" title="In Process" class="progress-bar" role="progressbar"
                        style="width: 100%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                        In Process
                      </div>
                      <div v-else-if="task.TaskStatus === 4" title="Completed" class="progress-bar bg-success"
                        role="progressbar" style="width: 100%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                        Completed
                      </div>
                    </div>
                  </div>

                  <div class="task-detail-row pt-2">
                    <span class="task-detail-label">Project:</span>
                    <p class="task-detail-value">{{ task.CategoryName }}</p>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="task-detail-row">
                    <span class="task-detail-label">Priority:</span>
                    <div class="progress task-detail-value" style="width: 55%; margin-top: 3px">
                      <div v-if="task.PriorityName == 'Low'" title="Low" class="progress-bar bg-info" role="progressbar"
                        style="width: 100%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                        {{ task.PriorityName }}
                      </div>
                      <div v-else-if="task.PriorityName == 'Medium'" title="Medium" class="progress-bar bg-warning"
                        role="progressbar" style="width: 100%; color: black" aria-valuenow="25" aria-valuemin="0"
                        aria-valuemax="100">
                        {{ task.PriorityName }}
                      </div>
                      <div v-else-if="task.PriorityName == 'High'" title="High" class="progress-bar bg-danger"
                        role="progressbar" style="width: 100%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                        {{ task.PriorityName }}
                      </div>
                    </div>
                  </div>
                  <div class="task-detail-row">
                    <span class="task-detail-label">Due Date:</span>
                    <p class="task-detail-value">
                      {{ task.DueDate }}
                    </p>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="task-detail-row">
                    <span class="task-detail-label">Assigned By:</span>
                    <p class="task-detail-value">
                      {{ task.UserName }}
                    </p>
                  </div>
                  <div class="task-detail-row">
                    <span class="task-detail-label">Assigned Date:</span>
                    <p class="task-detail-value">
                      {{ formatAssignedDate(AssignUsers[0].CreatedDate) }}
                    </p>
                  </div>
                </div>
                <div class="col-md-12 mb-3">
                  <div class="row">
                    <div class="col-12">
                      <p><span class="task-detail-label">Description:</span>
                        <span class="task-detail-value">{{ task.Description }}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mt-2" v-if="AssignUsers.length > 0">
                  <div class="row">
                    <div class="col-12">
                      <p><span class="task-detail-label" style="margin-right: 10px;">Assigned to Users:</span>
                        <span v-for="item in AssignUsers" v-bind:key="item.AssignTo" class="AssignUsers">{{
                          item.FirstName + " " + item.LastName
                        }}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mt-2"
                  v-if="task.InfoFile != 'null' && task.InfoFile != '' && task.InfoFile != null">
                  <div class="task-detail-row">
                    <span class="task-detail-label">Attachment:</span>
                    <p v-if="imageURLValid && task.InfoFile != null" class="task-detail-value">
                      <a :href="task.InfoFile" target="blank" class="browser-link">
                        <img :src="task.InfoFile" class="image-link" alt="Image"></a>
                    </p>
                    <p v-if="!imageURLValid && task.InfoFile != null" class="file-link task-detail-value">
                      <a :href="task.InfoFile" target="blank">File Link</a>
                    </p>
                  </div>
                </div>
                <div class="col-md-6" style=""
                  v-if="task.IsCompleted && task.Comments != 'null' && task.Comments != ''">
                  <div class="row">
                    <div class="col-12">
                      <p><span class="task-detail-label">Comments:</span>
                        <span class="task-detail-value">{{ task.Comments }}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6" v-if="task.IsCompleted && task.UserAttachment != null">
                  <div class="task-detail-row">
                    <span class="task-detail-label" style="width:40%;">User Attachment:</span>
                    <p v-if="UserimageURLValid && task.UserAttachment != null" class="task-detail-value">
                      <a :href="task.UserAttachment" target="blank" class="browser-link">
                        <img :src="task.UserAttachment" class="image-link" alt="Image" style="width:70%;"></a>
                    </p>
                    <p v-if="!UserimageURLValid && task.UserAttachment != null" class="file-link task-detail-value">
                      <a :href="task.UserAttachment" target="blank">File Link</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div><span>Loading...</span></div>
            </div>
            <!-- Modal for Task Assignment -->
            <div class="modal fade" id="taskAssignmentModal" tabindex="-1" aria-labelledby="taskModalLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="taskModalLabel">
                      Assign To-Do
                    </h1>
                    <button class="btn-close" title="Close" data-bs-dismiss="modal" aria-label="Close"
                      tabindex="-1"></button>
                  </div>
                  <div class="alreadyAssignedText" v-if="IsAlreadyAssigned">
                    <span>This task already assigned to users.</span>
                  </div>
                  <form @submit.prevent="submitTaskAssignmentForm">
                    <input v-model="taskAssignment.TaskID" type="hidden" class="form-control" />
                    <div class="modal-body">
                      <div v-if="taskFormHeader" class="alert alert-danger text-left" role="alert">
                        {{ taskFormHeader }}
                        <span @click="hideTaskAlert" class="close-icon">&times;</span>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="mb-3">
                                <label for="priority" class="col-form-label">Task:</label>
                                <input v-model="taskAssignment.TaskID" type="hidden" />
                                <input v-model="taskAssignment.TaskTitle" type="text" class="form-control" disabled />
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="mb-3">
                                <label for="dueDate" class="col-form-label">Due Date:</label>
                                <input v-model="taskAssignment.DueDate" type="date" class="form-control" id="dueDate"
                                  tabindex="1" />
                                <span class="text-red-500 text-sm">{{
                                  taskAssignmentValidate.DueDate
                                }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="mb-3">
                                <label for="category" class="col-form-label">Assign to Users:</label>
                                <div class="user-list" v-if="DropdownOptions.userOptions.length > 0">
                                  <div v-for="user in DropdownOptions.userOptions" :key="user.UserID" class="user-item">
                                    <input type="checkbox" :id="'userCheckbox_' + user.UserID" :value="user.UserID"
                                      v-model="taskAssignment.selectedUsers" tabindex="2" />
                                    <label :for="'userCheckbox_' + user.UserID">{{ user.UserName }}</label>
                                  </div>
                                </div>
                                <div v-else>No users available.</div>
                                <span class="text-red-500 text-sm">{{
                                  taskAssignmentValidate.selectedUsers
                                }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button v-if="IsAlreadyAssigned" @click="confirmDelete(taskAssignment.TaskID)" type="button"
                        class="btn btn-danger btn-sm ml-2" tabindex="5" title="Delete">
                        Delete
                      </button>
                      <button type="button" class="btn btn-secondary btn-sm ml-2" title="Close" data-bs-dismiss="modal"
                        id="closeAssignmentModal" tabindex="4">
                        Close
                      </button>
                      <button type="submit" :title='editTaskMode ? "Update" : "Submit"' class="btn btn-primary btn-sm"
                        :disabled="btnTaskEnable" default tabindex="3">
                        {{ editTaskMode ? "Update" : "Submit" }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
    <div class="row">
      <div class="row" ref="disenio" hidden>
        <div class="col-md-4 mt-2">
          <div class="task-detail-row">
            <span class="task-detail-label">Title:</span>
            <p class="task-detail-value" style="font-weight: 400;">{{ task.Title }}</p>
          </div>
          <div class="task-detail-row">
            <span class="task-detail-label">Priority:</span>
            <div class="progress task-detail-value" style="width: 55%; margin-top: 3px">
              <div v-if="task.PriorityName == 'Low'" title="Low" class="progress-bar bg-info" role="progressbar"
                style="width: 100%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                {{ task.PriorityName }}
              </div>
              <div v-else-if="task.PriorityName == 'Medium'" title="Medium" class="progress-bar bg-warning"
                role="progressbar" style="width: 100%; color: black" aria-valuenow="25" aria-valuemin="0"
                aria-valuemax="100">
                {{ task.PriorityName }}
              </div>
              <div v-else-if="task.PriorityName == 'High'" title="High" class="progress-bar bg-danger"
                role="progressbar" style="width: 100%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                {{ task.PriorityName }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 mt-2">
          <div class="task-detail-row">
            <span class="task-detail-label">Status:</span>
            <div class="progress task-detail-value" style="width: 55%; margin-top: 3px">
              <div v-if="task.TaskStatus === 1" title="Not Assigned" class="progress-bar" role="progressbar"
                aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="background: none; color: black">
                Not Assigned
              </div>
              <div v-else-if="task.TaskStatus === 2 && publicTask" title="New Task" class="progress-bar"
                role="progressbar" style="width: 100%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                Assigned
              </div>
              <div v-else-if="task.TaskStatus === 2 && !publicTask" title="New Task" class="progress-bar"
                role="progressbar" style="width: 100%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                New Task
              </div>
              <div v-else-if="task.TaskStatus === 3" title="In Process" class="progress-bar" role="progressbar"
                style="width: 100%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                In Process
              </div>
              <div v-else-if="task.TaskStatus === 4" title="Completed" class="progress-bar bg-success"
                role="progressbar" style="width: 100%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                Completed
              </div>
            </div>
          </div>

          <div class="task-detail-row pt-2" v-if="task.TaskType == 'public'">
            <span class="task-detail-label">Project:</span>
            <p class="task-detail-value">{{ task.CategoryName }}</p>
          </div>
        </div>
        <div class="col-md-4 mt-2">
          <div class="task-detail-row">
            <span class="task-detail-label">Assigned By:</span>
            <p class="task-detail-value">
              {{ task.UserName }}
            </p>
          </div>
          <div class="task-detail-row">
            <span class="task-detail-label">Assigned Date:</span>
            <p class="task-detail-value">
              {{ formatAssignedDate(task.CreatedDate) }}
            </p>
          </div>
        </div>
        <div class="col-md-8">
          <div class="row">
            <div class="col-12" style="margin-bottom: 1rem;">
              <p><span class="task-detail-label">Description:</span><span class="task-detail-value">{{
                task.Description }}</span></p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="task-detail-row">
            <span class="task-detail-label">Due Date:</span>
            <p class="task-detail-value">
              {{ task.DueDate }}
            </p>
          </div>
        </div>
        <div class="col-md-12" v-if="task.IsCompleted && task.Comments != null && task.Comments != ''">
          <div class="row">
            <div class="col-12">
              <p><span class="task-detail-label">Comments:</span><span class="task-detail-value">{{
                task.Comments }}</span></p>
            </div>
          </div>
        </div>
        <div class="col-md-6" v-if="task.InfoFile != 'null' && task.InfoFile != '' && task.InfoFile != null">
          <div class="row">
            <div class="task-detail-row">
              <div class="col-md-2">
                <span class="task-detail-label">Attachment:</span>
              </div>
              <div class="col-md-10">
                <p v-if="imageURLValid && task.InfoFile != null" class="task-detail-value">
                  <a :href="task.InfoFile" target="blank" class="browser-link">
                    <img :src="task.InfoFile" class="image-link" alt="Image">
                  </a>
                </p>
                <p v-if="!imageURLValid && task.InfoFile != null" class="file-link task-detail-value">
                  <a :href="task.InfoFile" target="blank">File Link</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6" v-if="task.IsCompleted && task.UserAttachment != null">
          <div class="task-detail-row">
            <span class="task-detail-label" style="width:40%;">User Attachment:</span>
            <p v-if="UserimageURLValid && task.UserAttachment != null" class="file-link task-detail-value">
              <a :href="task.UserAttachment" target="blank">File Link</a>
            </p>
          </div>
        </div>
      </div>
      <div class="row" ref="disenio" id="content1" hidden>
        <div class="col-md-4 mt-2">
          <div class="task-detail-row" style=" display: -webkit-box !important;
            -webkit-box-pack: start;
            justify-content: space-between;
            margin-bottom: 1rem;">
            <span class="task-detail-label" style="font-weight: bold;font-size: 16px;">Title:</span>
            <span class="task-detail-value" style="font-weight: 400;font-size: 16px;margin-left: 10px;">
              {{ task.Title }}</span>
          </div>
          <div class="task-detail-row" style=" display: -webkit-box !important;
            -webkit-box-pack: start;
            justify-content: space-between;
            margin-bottom: 1rem;">
            <span class="task-detail-label" style="font-weight: bold;font-size: 16px;">Priority:</span>
            <div class="progress task-detail-value" style="width: 55%;font-size: 16px;margin-left: 10px;">
              <div v-if="task.PriorityName == 'Low'" title="Low" class="progress-bar bg-info" role="progressbar"
                style="width: 100%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                {{ task.PriorityName }}
              </div>
              <div v-else-if="task.PriorityName == 'Medium'" title="Medium" class="progress-bar bg-warning"
                role="progressbar" style="width: 100%; color: black" aria-valuenow="25" aria-valuemin="0"
                aria-valuemax="100">
                {{ task.PriorityName }}
              </div>
              <div v-else-if="task.PriorityName == 'High'" title="High" class="progress-bar bg-danger"
                role="progressbar" style="width: 100%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                {{ task.PriorityName }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 mt-2">
          <div class="task-detail-row" style=" display: -webkit-box !important;
            -webkit-box-pack: start;
            justify-content: space-between;
            margin-bottom: 1rem;">
            <span class="task-detail-label" style="font-weight: bold;font-size: 16px;">Status:</span>
            <div class="progress task-detail-value" style="width: 55%;font-size: 16px;margin-left: 10px;">
              <div v-if="task.TaskStatus === 1" title="Not Assigned" class="progress-bar" role="progressbar"
                aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="background: none; color: black">
                Not Assigned
              </div>
              <div v-else-if="task.TaskStatus === 2 && publicTask" title="New Task" class="progress-bar"
                role="progressbar" style="width: 100%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                Assigned
              </div>
              <div v-else-if="task.TaskStatus === 2 && !publicTask" title="New Task" class="progress-bar"
                role="progressbar" style="width: 100%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                New Task
              </div>
              <div v-else-if="task.TaskStatus === 3" title="In Process" class="progress-bar" role="progressbar"
                style="width: 100%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                In Process
              </div>
              <div v-else-if="task.TaskStatus === 4" title="Completed" class="progress-bar bg-success"
                role="progressbar" style="width: 100%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                Completed
              </div>
            </div>
          </div>

          <div class="task-detail-row pt-2" v-if="task.TaskType == 'public'" style=" display: -webkit-box !important;
            -webkit-box-pack: start;
            justify-content: space-between;
            margin-bottom: 1rem;">
            <span class="task-detail-label" style="font-weight: bold;font-size: 16px;">Project:</span>
            <span class="task-detail-value" style="font-size: 16px;margin-left: 10px;">{{ task.CategoryName }}</span>
          </div>
        </div>
        <div class="col-md-4 mt-2">
          <div class="task-detail-row" style=" display: -webkit-box !important;
            -webkit-box-pack: start;
            justify-content: space-between;
            margin-bottom: 1rem;">
            <span class="task-detail-label" style="font-weight: bold;font-size: 16px;">Assigned By:</span>
            <span class="task-detail-value" style="font-size: 16px;margin-left: 10px;">
              {{ task.UserName }}
            </span>
          </div>
          <div class="task-detail-row" style=" display: -webkit-box !important;
            -webkit-box-pack: start;
            justify-content: space-between;
            margin-bottom: 1rem;">
            <span class="task-detail-label" style="font-weight: bold;font-size: 16px;">Assigned Date:</span>
            <span class="task-detail-value" style="font-size: 16px;margin-left: 10px;">
              {{ formatAssignedDate(task.CreatedDate) }}
            </span>
          </div>
        </div>
        <div class="col-md-8">
          <div class="row">
            <div class="col-12" style="margin-bottom: 1rem;display: flex;">
              <span class="task-detail-label" style="font-weight: bold;font-size: 16px;">Description:</span>
              <span class="task-detail-value" style="font-size: 16px;margin-left: 10px;">
                {{  task.Description }}
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="task-detail-row" style=" display: -webkit-box !important;
            -webkit-box-pack: start;
            justify-content: space-between;
            margin-bottom: 1rem;">
            <span class="task-detail-label" style="font-weight: bold;font-size: 16px;">Due Date:</span>
            <span class="task-detail-value" style="font-size: 16px;margin-left: 10px;">
              {{ task.DueDate }}
            </span>
          </div>
        </div>
        <div class="col-md-12" v-if="task.IsCompleted && task.Comments != null && task.Comments != ''">
          <div class="row">
            <div class="col-12" style="
            -webkit-box-pack: start;
            display: flex;
            margin-bottom: 1rem;">
              <span class="task-detail-label" style="font-weight: bold;font-size: 16px;">Comments:</span>
              <span class="task-detail-value" style="font-size: 16px;margin-left: 10px;">
                {{task.Comments }}
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-6" v-if="task.InfoFile != 'null' && task.InfoFile != '' && task.InfoFile != null">
          <div class="row">
            <div class="task-detail-row" 
            style=" display: -webkit-box !important;
              -webkit-box-pack: start;
              justify-content: space-between;
              margin-bottom: 1rem;">
              <div class="col-md-2">
                <span class="task-detail-label" style="font-weight: bold;font-size: 16px;">Attachment:</span>
              </div>
              <div class="col-md-10">
                <span v-if="imageURLValid && task.InfoFile != null" class="task-detail-value" style="font-size: 16px;margin-left: 10px;">
                  <a :href="task.InfoFile" target="blank" class="browser-link">
                    <img :src="task.InfoFile" class="image-link" alt="Image">
                  </a>
                </span>
                <span v-if="!imageURLValid && task.InfoFile != null" class="file-link task-detail-value" style="font-size: 16px;margin-left: 10px;">
                  <a :href="task.InfoFile" target="blank">File Link</a>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6" v-if="task.IsCompleted && task.UserAttachment != null">
          <div class="task-detail-row" style=" display: -webkit-box !important;
            -webkit-box-pack: start;
            justify-content: space-between;
            margin-bottom: 1rem;">
            <span class="task-detail-label" style="width:40%;font-weight: bold;font-size: 16px;">User Attachment:</span>
            <span v-if="UserimageURLValid && task.UserAttachment != null" class="file-link task-detail-value" style="font-size: 16px;margin-left: 10px;">
              <a :href="task.UserAttachment" target="blank">File Link</a>
            </span>
          </div>
        </div>
      </div>
      <div class="chat-box" id="content2" hidden>
        <div class="messages justify-content-between" ref="chatContainer" style="padding: 10px;">
          <div class="messagecontent">
            <div v-for="(messages, date) in groupedMessages" :key="date">
              <div class="date-partition text-center" style="text-align: center;margin: 5px 0;padding: 5px 5px;font-size: 14px;font-weight: bold;"><b>{{ date }}</b></div> 
            <div v-for="(message, index) in messages" :key="index">
              <div v-if="UserID != message.SenderId" class="text-left message"
                style="text-align: left; margin-bottom: 6px; justify-content: left; max-width: 70%; word-wrap: break-word;">
                <div v-if="message.FilePath && message.Message">
                  <span class="received text-left my-2"
                    style="align-items: flex-start; color: #ffffff; text-align: left; padding: 7px; display: flex; max-width: 100%;">
                    <span style="color: green;" class="mx-2">{{ `${message.SenderName}:` }}</span>
                    <a :href="`${baseImageURL}/${message.FilePath}`">
                      <span style="color: blue; text-decoration: underline;">{{ `${message.FileName}` }}</span>
                    </a>
                    <span class="mx-2 timestamp" style="font-size: 10px; margin-left: 10px;width: 60px;white-space: nowrap;">{{ message.TimestampHours
                      }}</span>
                  </span>
                  <br /><br />
                  <span class="received text-left"
                    style="align-items: flex-start; text-align: left; border-radius: 10px; padding: 7px; display: flex; max-width: 100%;">
                    <span style="color: green; font-weight: bold;">{{ message.SenderName }}:</span>
                    <span style="color: black;"> {{ message.Message }}</span>
                    <span class="mx-2 timestamp" style="font-size: 10px; margin-left: 10px;width: 60px;white-space: nowrap;">{{ message.TimestampHours
                      }}</span>
                  </span>
                </div>
                <div v-else-if="message.FilePath">
                  <span class="received text-left"
                    style="align-items: flex-start; text-align: left; border-radius: 10px; padding: 7px; display: flex; max-width: 100%;">
                    <span style="color: green; font-weight: bold;" class="mx-2">{{ `${message.SenderName}:` }}</span>
                    <a :href="`${baseImageURL}/${message.FilePath}`">
                      <span style="color: blue; text-decoration: underline;">{{ `${message.FileName}` }}</span>
                    </a>
                    <span class="mx-2 timestamp" style="font-size: 10px; margin-left: 10px;width: 60px;white-space: nowrap;">{{ message.TimestampHours
                        }}</span>
                  </span>
                </div>
                <div v-else-if="message.Message">
                  <span class="received text-left"
                    style="align-items: flex-start; text-align: left; padding: 7px; display: flex; max-width: 100%;">
                    <span style="color: green; font-weight: bold;">{{ message.SenderName }}:</span>
                    <span style="color: black;"> {{ message.Message }}</span>
                    <span class="mx-2 timestamp" style="font-size: 10px; margin-left: 10px;width: 60px;white-space: nowrap;">{{ message.TimestampHours
                      }}</span>
                  </span>
                </div>
              </div>
              <div v-else class="text-right message"
                style="text-align: right; margin-bottom: 6px; justify-content: right; max-width: 70%; margin-left: auto; word-wrap: break-word;">
                <div v-if="message.FilePath && message.Message" class="text-right">
                  <span class="text-right my-2"
                    style="align-items: flex-start; text-align: right; padding: 7px; display: flex; justify-content: flex-end; max-width: 100%;">
                    <span style="color: purple; font-weight: bold;" class="mx-2">{{ message.SenderName }}:</span>
                    <a :href="`${baseImageURL}/${message.FilePath}`">
                      <span style="color: blue; text-decoration: underline;">{{ `${message.FileName}` }}</span>
                    </a>
                    <span class="mx-2 timestamp" style="font-size: 10px; margin-left: 10px;width: 60px;white-space: nowrap;">{{ message.TimestampHours
                      }}</span>
                  </span>
                  <br /><br />
                  <span class="sent text-right"
                    style="align-items: flex-start; text-align: right; padding: 7px; display: flex; justify-content: flex-end; max-width: 100%;">
                    <span style="color: purple; font-weight: bold;">{{ message.SenderName }} :</span>
                    <span style="color: black;text-align: left;">{{ message.Message }}</span>
                    <span class="mx-2 timestamp" style="font-size: 10px; margin-left: 10px;width: 60px;white-space: nowrap;">{{ message.TimestampHours
                      }}</span>
                  </span>
                </div>
                <div v-else-if="message.FilePath">
                  <span class="sent text-right"
                    style="align-items: flex-start; text-align: right; display: flex; justify-content: flex-end; padding: 7px; max-width: 100%;">
                    <span style="color: purple; font-weight: bold;" class="mx-2">{{ message.SenderName }}:</span>
                    <a :href="`${baseImageURL}/${message.FilePath}`">
                      <span style="color: blue; text-decoration: underline;">{{ `${message.FileName}`}}</span>
                    </a>
                    <span class="mx-2 timestamp" style="font-size: 10px; margin-left: 10px;width: 60px;white-space: nowrap;">{{ message.TimestampHours
                      }}</span>
                  </span>
                </div>
                <div v-else-if="message.Message">
                  <span class="sent text-right"
                    style="align-items: flex-start; text-align: right; padding: 7px; display: flex; justify-content: flex-end; max-width: 100%;">
                    <span style="color: purple; font-weight: bold;">{{ message.SenderName }}: </span>
                    <span style="color: black;text-align: left;">{{ message.Message }}</span>
                    <span class="mx-2 timestamp" style="font-size: 10px; margin-left: 10px;width: 60px;white-space: nowrap;">{{ message.TimestampHours
                      }}</span>
                  </span>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api, { setAuthToken, baseimageURL } from "../apiConfig";
import { authMixin } from "./adminStore";
import Cookies from "js-cookie";
import { ErrorLog } from "../Common/APILog";
import html2pdf from 'html2pdf.js';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import LoadingOverlay from '../Common/blockoutpage.vue';
import { handleAuthorization, handleServerError } from "../Common/commonMethod";

export default {
  mixins: [authMixin],
  components: {
    LoadingOverlay
  },
  props: {
    taskId: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
  
  },
  data() {
    return {
      task: [],
      messages: [],
      groupedMessages:[],
      flagCount: 0,
      baseImageURL: baseimageURL,
      imageURLValid: false,
      Isloading: false,
      UserimageURLValid: false,
      publicTask: false,
      AssignUsers: {},
      subTasks: [],
      title: "Task Detail",
      unReadChatCount: 0,
      newSubTask: {
        SubTaskID: 0,
        TaskID: 0,
        Title: "",
        Description: "",
        IsCompleted: false,
        UserId: 0,
      },
      DropdownOptions: {
        userOptions: [],
        taskOPtions: [],
      },
      taskAssignment: {
        updateTaskId: 0,
        TaskID: 0,
        TaskTitle: "",
        UserId: 0,
        selectedUsers: [],
        DueDate: null,
      },
      taskValidate: {
        Title: "",
        Description: "",
        // Add additional validation fields as needed
      },
      IsAlreadyAssigned: false,
      errorAlertOnSubmit: "",
      taskAssignmentValidate: {
        TaskID: "",
        UserId: "",
        selectedUsers: "",
        DueDate: "",
        // Add additional validation fields as needed
      },
      handleTaskId: 0,
      loading: false,
      editTaskMode: false,
      successAlertOnSubmit: "",
      loadingTasks: "",
      taskFormHeader: "",
      btnTaskEnable: false,
      UserAssignMerge: "",
      callCount: 0,
    };
  },
  created() {
    // Fetch task details when the component is created
    this.getChatByTaskId(this.taskId);
    this.fetchTaskDetails();
    this.getDropdownOptions();
    this.getUsersAssignTaskById(this.taskId);
    //this.getUnreadChatById(this.taskId);
  },
  mounted() {
    this.getUnreadChatById(this.taskId);
  },
  methods: {
    // Check if URL ends with a common image file extension
    isImageURL(url) {
      const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp'];
      const extension = url.substring(url.lastIndexOf('.')).toLowerCase();
      console.log(imageExtensions.includes(extension));
      if (imageExtensions.includes(extension)) {
        this.imageURLValid = true;
      }
      //return imageExtensions.includes(extension);
    },
    isUserImageURL(url) {
      const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp'];
      const extension = url.substring(url.lastIndexOf('.')).toLowerCase();
      console.log(imageExtensions.includes(extension));
      if (imageExtensions.includes(extension)) {
        this.UserimageURLValid = true;
      }
      //return imageExtensions.includes(extension);
    },
    closeModal() {
      this.editMode = false;
      this.resetTaskValidation();
      document.getElementById("closeModal").click();
    },
    openTaskModal(refresh = false) {
      if (refresh) {
        this.editTaskMode = false;
        this.newSubTask = {
          SubTaskID: 0,
          Title: "",
          Description: "",
          UserId: 0,
          IsCompleted: false,
        };
        this.resetTaskValidation();
      }
    },
    openModal() {
      document.getElementById("openModal").click();
    },
    resetTaskValidation() {
      this.newSubTask = {
        SubTaskID: 0,
        Title: "",
        Description: "",
        UserId: 0,
        IsCompleted: false,
      };
    },
    truncateDescription(description) {
      // Truncate the description if it's longer than 30 characters
      return description.length > 30
        ? description.slice(0, 30) + "..."
        : description;
    },
    formatDueDate(originalDate, type = "show") {
      if (!originalDate) return ""; // Handle null or undefined dates
      const dateObject = new Date(originalDate);
      const day = dateObject.getDate().toString().padStart(2, "0");
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObject.getFullYear();
      if (type == "show") {
        return `${day}/${month}/${year}`;
      } else {
        return `${year}-${month}-${day}`;
      }
    },
    formatAssignedDate(originalDate) {
      if (!originalDate) return ""; // Handle null or undefined dates
      const dateObject = new Date(originalDate);
      const hour = dateObject.getHours(-5).toString().padStart(2, "0");
      const minute = dateObject.getMinutes().toString().padStart(2, "0");
      const day = dateObject.getDate().toString().padStart(2, "0");
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObject.getFullYear();
      return `${day}/${month}/${year} ${hour}:${minute}`;
    },
    openAssignmentModal() {
      document.getElementById("openAssignmentModal").click();
    },
    goBack() {
      this.$router.go(-1);
    },
    async getUsersAssignTaskById(taskId) {
      try {
        const token = await Cookies.get("jwt");
        this.Isloading = true;
        setAuthToken(token);
        const response = await api.get(
          `/api/admin/tasks/get-users-assigntask-by-id/${taskId}`
        );
        if (response.data) {
          console.log("users", response.data);
          this.AssignUsers = response.data.usersassigntask;
          this.UserAssignMerge = this.AssignUsers.map(item => `${item.FirstName} ${item.LastName}`).join(', ');
        }
        else {
          // alert(`Error: ${response.statusText}`);
          this.errorAlertOnSubmit = "Something went wrong. please try again later.";
        }
      } catch (error) {
        console.log(error);
        if (this.callCount < 3) {
          setTimeout(() => {
            this.getUsersAssignTaskById(this.taskId); // Retry after a delay
          }, 1000);
          this.callCount++;
        }
        else {
          if (error.response && error.response.data) {
            if (error.response.status === 401) {
              handleAuthorization("/login")
            }
            else if (error.response.status === 500) {
              handleServerError("/login")
            }
            else {
              const jsonResponseString = JSON.stringify(error.response.data);
              try {
                const jsonObject = JSON.parse(jsonResponseString);
                this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
              } catch (parseError) {
                this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
              }
              ErrorLog(error.message, error.response.status, 473, error.stack, "AdminPanel/TaskDetails/getUsersAssignTaskById()");
            }
          }
          else {
            this.errorAlertOnSubmit = "Something went wrong.";
          }
        }
      }
      finally {
        this.Isloading = false;
        setTimeout(() => {
          this.successAlertOnSubmit = "";
          this.errorAlertOnSubmit = "";
        }, 5000); // 5 seconds in milliseconds
        this.loading = true;
      }
    },

    async getUnreadChatById(taskId) {
      try {
        this.Isloading = true;
        const token = await Cookies.get("jwt");
        let userCookie = await Cookies.get("user");
        userCookie = await userCookie ? JSON.parse(userCookie) : null;
        const uid = userCookie.UserID
        setAuthToken(token);
        const response = await api.get(
          `/api/Chat/get-unread-chat-count/${taskId}/${uid}`
        );
        if (response.data) {
          const result = response.data[0];
          console.log(result)
          this.unReadChatCount = response.data[0].UnReadChatCount;
          console.log(response.data)
          console.log(this.unReadChatCount);
        }
        else {
          // alert(`Error: ${response.statusText}`);
          this.errorAlertOnSubmit = "Something went wrong. please try again later.";
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 529, error.stack, "AdminPanel/TaskDetails/getUnReadChatById()");
          }
        }
        else {
          this.errorAlertOnSubmit = "Something went wrong.";
        }
      }
      finally {
        this.Isloading = false;
        setTimeout(() => {
          this.successAlertOnSubmit = "";
          this.errorAlertOnSubmit = "";
        }, 5000); // 5 seconds in milliseconds
        this.loading = true;
      }
    },
    editTaskDetail(taskId) {
      this.$router.push({
        name: "Edit Task",
        params: { taskId },
      });
    },
    async getTaskAssignmentById(taskId, title) {
      this.Isloading = true;
      this.taskAssignment = {
        updateTaskId: 0,
        TaskID: 0,
        TaskTitle: title,
        UserId: 0,
        selectedUsers: [],
        DueDate: null,
      };
      try {
        const token = await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie = await Cookies.get("user");
        userCookie = await userCookie ? JSON.parse(userCookie) : null;
        const response = await api.get(
          `/api/admin/tasks/get-assigned-tasks-details/${userCookie.UserID}?taskId=${taskId}`
        );
        if (response.data) {
          if (response.data.length > 0) {
            this.IsAlreadyAssigned = true;
            // Assign the values from the API response to newTask
            this.taskAssignment.TaskID = response.data[0].TaskId;
            this.taskAssignment.updateTaskId = response.data[0].TaskId;
            this.taskAssignment.UserId = userCookie.UserID; // Assuming you want to keep the user who is updating the task
            this.taskAssignment.DueDate = this.formatDueDate(
              response.data[0].DueDate,
              "update"
            );
            // If the API response provides an array of selected users, you can directly assign it
            const selectedUsersArray =
              response.data[0].SelectedUsers.split(",").map(Number);
            this.taskAssignment.selectedUsers = selectedUsersArray;
            this.editMode = true;
          } else {
            this.IsAlreadyAssigned = false;
            this.taskAssignment.TaskID = taskId;
            this.taskAssignment.UserId = userCookie.UserID;
          }
          this.openAssignmentModal();
          this.resetTaskAssignmentValidation();
        } else {
          // alert(`Error: ${response.statusText}`);
          this.errorAlertOnSubmit = "Something went wrong. please try again later.";
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 613, error.stack, "AdminPanel/TaskDetails/getTaskAssignmentById()");
          }
        }
        else {
          this.errorAlertOnSubmit = "Something went wrong.";
        }
      }
      finally {
        this.Isloading = false;
        setTimeout(() => {
          this.successAlertOnSubmit = "";
          this.errorAlertOnSubmit = "";
        }, 5000); // 5 seconds in milliseconds
      }
    },
    async getDropdownOptions() {
      try {
        this.Isloading = true;
        const token = await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie = await Cookies.get("user");
        userCookie = userCookie ? JSON.parse(userCookie) : null;
        const response = await api.get(
          `/api/admin/tasks/get-dropdown-tasks-users/${userCookie.UserID}`
        );
        if (response.data) {
          this.DropdownOptions.userOptions = response.data.users;
          this.priorityDropdownOptions = response.data.priorities;
          this.categoryDropdownOptions = response.data.categories;
        } else {
          // Handle case where no priorities are found
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              // this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              // this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 661, error.stack, "AdminPanel/TaskDetails/getDropdownOptions()");
          }
        }
        else {
          // this.errorAlertOnSubmit = "Something went wrong.";
        }
      } finally {
        this.Isloading = false;
      }
    },
    // Fetch task details based on taskId
    async fetchTaskDetails() {
      this.loading = false;
      this.Isloading = true;
      try {
        this.task = [];
        this.subTasks = [];
        this.loading = false;
        const token = await Cookies.get("jwt");
        setAuthToken(token);
        const response = await api.get(
          `/api/admin/tasks/get-task-by-id/${this.taskId}`
        );
        if (response.data) {

          this.task = response.data.data[0];
          if (this.task.TaskType == "public") {
            this.publicTask = true;
          }
          if (this.task.InfoFile != null) {
            this.isImageURL(this.task.InfoFile);
          }
          if (this.task.UserAttachment != null) {
            this.isUserImageURL(this.task.UserAttachment);
          }
          this.task.DueDate = this.formatDueDate(
            response.data.data[0].DueDate,
            "show"
          );
          this.handleTaskId = response.data.data[0].TaskID;
        } else {
          // alert(`Error: ${response.statusText}`);
          this.errorAlertOnSubmit = "Something went wrong. please try again later.";
        }
      } catch (error) {
        console.log(error)
        if (this.callCount < 3) {
          setTimeout(() => {
            this.fetchTaskDetails(); // Retry after a delay
          }, 1000);
          this.callCount++;
        }
        else {
          if (error.response && error.response.data) {
            if (error.response.status === 401) {
              handleAuthorization("/login")
            }
            else if (error.response.status === 500) {
              handleServerError("/login")
            }
            else {
              const jsonResponseString = JSON.stringify(error.response.data);
              try {
                const jsonObject = JSON.parse(jsonResponseString);
                this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
              } catch (parseError) {
                this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
              }
              ErrorLog(error.message, error.response.status, 725, error.stack, "AdminPanel/TaskDetails/fetchTaskDetails()");
            }
          }
          else {
            this.errorAlertOnSubmit = "Something went wrong.";
          }
        }
      } finally {
        this.Isloading = false;
        setTimeout(() => {
          this.successAlertOnSubmit = "";
          this.errorAlertOnSubmit = "";
        }, 5000); // 5 seconds in milliseconds
        this.loading = true;
      }
    },

    async submitTaskForm() {
      if (this.isValidTaskForm()) {
        this.newSubTask.SubTaskID === 0
          ? this.createSubTask()
          : this.updateSubTask();
      }
    },
    async submitTaskAssignmentForm() {
      this.resetTaskAssignmentValidation();

      if (this.isValidTaskAssignmentForm()) {
        this.taskAssignment.updateTaskId == 0
          ? this.updateAssignTask()
          : this.assignTask();
      }
    },
    async assignTask() {
      this.taskFormHeader = "";
      this.Isloading = true;
      try {
        this.btnTaskEnable = true;
        const token = await Cookies.get("jwt");
        setAuthToken(token);

        let userCookie = await Cookies.get("user");
        userCookie = userCookie ? JSON.parse(userCookie) : null;
        const response = await api.post(
          `/api/admin/tasks/assign-task/${this.taskAssignment.TaskID}`,
          {
            assignBy: userCookie.UserID,
            userIds: this.taskAssignment.selectedUsers,
            DueDate: this.taskAssignment.DueDate,
          }
        );

        if (response.data.success) {
          this.fetchTaskDetails();
          this.closeAssignmentModal(); // hide modal after successful submit.
          this.successAlertOnSubmit = "To-Do assigned successfully.";
        } else {
          //console.log(response);
          this.taskFormHeader = response.data.msg;
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.taskFormHeader = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.taskFormHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 801, error.stack, "AdminPanel/TaskDetails/assignTask()");
          }
        }
        else {
          this.taskFormHeader = "Something went wrong.";
        }
      } finally {
        this.Isloading = false;
        setTimeout(() => {
          this.successAlertOnSubmit = "";
          this.taskFormHeader = "";
        }, 5000); // 5 seconds in milliseconds
        this.btnTaskEnable = false;
      }
    },
    viewTaskMessages(TaskID) {
      this.$router.push({
        path: `/chatmessages/${this.taskId}/${this.task.UserID}`
      });
    },
    closeAssignmentModal() {
      this.editMode = false;
      this.resetTaskAssignmentValidation();
      document.getElementById("closeAssignmentModal").click();
    },
    async updateAssignTask() {
      this.taskFormHeader = "";
      this.Isloading = true;
      try {
        this.btnTaskEnable = true;
        const token = await Cookies.get("jwt");
        setAuthToken(token);

        let userCookie = await Cookies.get("user");
        userCookie = userCookie ? JSON.parse(userCookie) : null;
        const response = await api.put(
          `/api/admin/tasks/update-assign-task/${this.taskAssignment.TaskID}`,
          {
            assignBy: userCookie.UserID,
            userIds: this.taskAssignment.selectedUsers,
            updateTaskId: this.taskAssignment.updateTaskId,
            DueDate: this.taskAssignment.DueDate,
          }
        );

        if (response.data.success) {
          // this.getTasks();
          this.closeAssignmentModal(); // hide modal after successful submit.
          this.successAlertOnSubmit = "To-Do assigned successfully.";
        } else {
          //console.log(response);
          this.taskFormHeader = response.data.msg;
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 870, error.stack, "AdminPanel/TaskDetails/updateAssignTask()");
          }
        }
        else {
          this.errorAlertOnSubmit = "Something went wrong.";
        }
      } finally {
        this.btnTaskEnable = false;
        this.Isloading = false;
        setTimeout(() => {
          this.successAlertOnSubmit = "";
          this.taskFormHeader = "";
        }, 5000); // 5 seconds in milliseconds
      }
    },
    async deleteItem(taskId) {
      try {
        //console.log(taskId);
        const token = await Cookies.get("jwt");
        this.Isloading = true;
        setAuthToken(token);
        let userCookie = await Cookies.get("user");
        userCookie = await userCookie ? JSON.parse(userCookie) : null;
        // Make your delete API call here
        const response = await api.delete(
          `/api/admin/tasks/delete-assign-task/${taskId}/${userCookie.UserID}`
        ); // Replace with your actual API endpoint

        if (response.data.success) {
          this.fetchTaskDetails();
          this.successAlertOnSubmit = "Deleted Successfully";
          this.closeAssignmentModal();
        } else {
          //alert(response.data.msg);
          this.errorAlertOnSubmit = "Something went wrong. please try again later.";
        }

        // Optionally, perform additional actions after successful deletion
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 924, error.stack, "AdminPanel/TaskDetails/deleteItem()");
          }
        }
        else {
          this.errorAlertOnSubmit = "Something went wrong.";
        }
      } finally {
        this.Isloading = false;
        setTimeout(() => {
          this.successAlertOnSubmit = "";
          this.errorAlertOnSubmit = "";
        }, 5000); // 5 seconds in milliseconds
      }
    },
    confirmDelete(taskId) {
      const isConfirmed = window.confirm(
        "Are you sure you want to delete assignment?"
      );

      if (isConfirmed) {
        // Call the deleteItem method when confirmed
        this.deleteItem(taskId);
      }
    },
    resetTaskAssignmentValidation() {
      this.taskValidate = {
        TaskID: "",
        selectedUsers: "",
        DueDate: "",
      };
    },
    formatChatCount(count) {
      return count < 10 ? `0${count}` : count;
    },

    isValidTaskAssignmentForm() {
      let isValid = true;

      // Users
      if (this.taskAssignment.selectedUsers.length == 0) {
        this.taskAssignmentValidate.selectedUsers = "Please select users.";
        isValid = false;
      }

      // DueDate
      if (!this.taskAssignment.DueDate) {
        this.taskAssignmentValidate.DueDate = "Due Date is required.";
        isValid = false;
      }
      return isValid;
    },
    hideTaskAlert() {
      this.successAlertOnSubmit = "";
    },
    isValidTaskForm() {
      let isValid = true;

      // Title
      if (!this.newSubTask.Title.trim()) {
        this.taskValidate.Title = "Title is required.";
        isValid = false;
      }

      // Description
      if (!this.newSubTask.Description.trim()) {
        this.taskValidate.Description = "Description is required.";
        isValid = false;
      }

      return isValid;
    },
    async getChatByTaskId(taskId) {
      try {
        //this.loadingTasks = "Loading...";
        this.Isloading = true;
        let userCookie = await Cookies.get("user");
        userCookie = await userCookie ? JSON.parse(userCookie) : null;
        this.UserID = userCookie.UserID;
        const uid = userCookie.UserID;
        const token = await Cookies.get("jwt");
        setAuthToken(token);
        const response = await api.get(`/api/Chat/get-chat-by-taskid/${taskId}/${uid}`);
        console.log(response)
        if (response.data) {
          this.messages = response.data;
          this.groupedMessages = this.groupMessagesByDate(this.messages)
          console.log(response.data);
          this.Isloading = false;
          this.loadingTasks = "";
        }
        else {
          this.Isloading = false;
          // alert(`Error: ${response.statusText}`);
        }
      } catch (error) {
        this.Isloading = false;
        //alert("Internal server error." + error);
      }
    },


    groupMessagesByDate(messages) {
        const groupedMessages = {};

        messages.forEach(message => {
          const date = new Date(message.Timestamp).toDateString();

          if (!groupedMessages[date]) {
            groupedMessages[date] = [];
          }
          groupedMessages[date].push(message);
        });

        return groupedMessages;
      },

    generateAndDownloadPDF() {
      // Access the content from refs
      // const chatContent = this.$refs.chatContainer1.$el.innerHTML;
      const taskcontent = document.getElementById('content1').innerHTML
      const messagecontent = document.getElementById('content2').innerHTML

      // Open a new window
      let printWindow = window.open('', '_blank');

      // Check if the window opened successfully
      if (!printWindow) {
        console.error('Failed to open new window. Please allow pop-ups for this site.');
        return;
      }

      // Construct the HTML content to be printed
     let htmlContent=''
      if (this.messages.length > 0) {
       htmlContent = `
          <!DOCTYPE html>
          <html lang="en">
          <head>
            <meta charset="UTF-8">
            <title>Task Document</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                margin: 15px;
              }
            </style>
          </head>
          <body>
            <div style="text-align: center; font-weight: bold; font-size: 22px; margin-bottom: 5px;">
              Task Detail
            </div>
            <hr style="margin: 1px; margin-bottom: 15px;">
            <div>
            ${taskcontent} 
            </div>
              <div style="text-align: center; font-weight: bold; font-size: 22px; margin-bottom: 5px;">
              Task Messages
            </div>
            <hr style="margin: 1px; margin-bottom: 15px;">
            <div style="margin: 1px; background:'yellow'">
            ${messagecontent}
            </div>
          </body>
          </html>
        `;
      }
      else {
         htmlContent = `
          <!DOCTYPE html>
          <html lang="en">
          <head>
            <meta charset="UTF-8">
            <title>Task Document</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                margin: 15px;
              }
            </style>
          </head>
          <body>
            <div style="text-align: center; font-weight: bold; font-size: 22px; margin-bottom: 5px;">
              Task Detail
            </div>
            <hr style="margin: 1px; margin-bottom: 15px;">
            <div>
            ${taskcontent} 
            </div>
          </body>
          </html>
        `;
      }
      // Write the HTML content to the new window
      printWindow.document.write(htmlContent);

      // Close the document after writing content
      printWindow.document.close();

      // Delay before printing to ensure content is fully loaded
      setTimeout(() => {
        // Print the document
        printWindow.print();

        // Close the print window
        printWindow.close();
      }, 1000); // Adjust delay time if necessary
    },

  },
};
</script>

<style scoped>
/* Add Tailwind CSS classes here if needed */
.close-icon {
  cursor: pointer;
  float: right;
  font-size: 30px;
  margin-top: -10px;
}

.mb-3 {
  text-align: left;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1em;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.table th {
  background-color: #f2f2f2;
}

/* Additional styling for left and right padding */
.table th,
.table td {
  padding-left: 12px;
  padding-right: 12px;
}

.actionBtns {
  text-align: right !important;
}

.task-details-card {
  /* Add styling for the task details card */
  margin-top: 2rem;
}

.task-details-header {
  /* Add styling for the card header */
  background-color: #4caf50;
  /* Use your preferred color */
  color: #fff;
  padding: 1rem;
}

.task-details-body {
  /* Add styling for the card body */
  padding: 1rem;
}

.task-detail-row {
  /* Add styling for each detail row */
  display: -webkit-box !important;
  -webkit-box-pack: start;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.task-detail-label {
  /* Add styling for the label within a detail row */
  font-weight: bold;
  font-size: 16px;
}

.task-detail-value {
  font-size: 16px;
  margin-left: 10px;
}

.taskNotAssigned {
  color: orange;
}

.alreadyAssignedText {
  color: #ed6524;
  font-size: 15px;
  text-align: center;
}

.taskAssigned {
  color: #ed6524;
}

.taskInProcess {
  color: #864426;
}

.taskCompleted {
  color: green;
}

.user-list {
  min-height: 120px;
  max-height: 120px;
  /* Set a maximum height for the user list */
  overflow-y: auto;
  /* Add a scrollbar when the content exceeds the height */
  border: 1px solid #ccc;
  /* Optional: Add a border for styling */
}

.user-item {
  margin-bottom: 8px;
  /* Optional: Add some spacing between user items */
}

.progress {
  font-size: 13px !important;
}

.image-link {
  width: 50%;
  margin: 0px auto;
}

.file-link {
  margin-bottom: 0px;
}

.file-link a {
  color: blue !important;
  font-weight: 500;
}

.browser-link {
  color: blue !important;
  font-weight: 500;
  /* padding-left:10px; */
}

@media screen and (max-width: 768px) {
  .col-sam-6 {
    width: 50%;
  }

}

.AssignUsers {
  background: #0d6efd;
  color: #fff;
  border-radius: 10px;
  padding: 3px 8px 3px 8px;
  margin-right: 4px;
  display: inline-block;
  margin-bottom: 4px;
}


.chat-box {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  background: white;
  box-sizing: border-box;
}

.message {
  padding: 6px;
}

.received {
  font-weight: bold;
  align-items: flex-start;
  color: #ffffff;
  text-align: left;
  background-color: #6b7076;
  border-radius: 10px;
  padding: 7px;
  max-width: 70%;
}

.sent {
  font-weight: bold;
  align-items: flex-end;
  text-align: right;
  color: #fff;
  background-color: #0084ff;
  border-radius: 10px;
  padding: 7px;
  max-width: 70%;
}

.messageHeader {
  font-weight: 500;
  font-size: 22px;
  margin: 2px;
  text-align: center;
}

.notification {
  border-radius: 50%;
  padding: 4px;
  text-align: center;
  font-size: 11px;
  color: white;
  background-color: red;
  padding-left: 7px;
  padding-right: 7px;
  padding-bottom: 5px;
}
</style>
