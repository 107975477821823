// commonMethods.js

import Swal from "sweetalert2";
import Cookies from "js-cookie"
import { setAuthToken } from "../apiConfig";
// import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';

// const ffmpeg = createFFmpeg({ log: true });

// Validate email format
export function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
} 

export function isValidPhoneNumber(phoneNumber) {
  // Regex for Indian phone numbers starting with 6, 7, 8, or 9 without hyphens
  const phoneRegex = /^[6-9]\d{9}$/;
  const isValidFormat = phoneRegex.test(phoneNumber);
  return isValidFormat;
}

// Validate text (non-empty and only text)
export function isValidText(text) {
  // Regular expression to match only alphabets (uppercase and lowercase)
  const textRegex = /^[a-zA-Z]+$/;

  // Check if the text is non-empty and contains only alphabets
  return text.trim() !== '' && textRegex.test(text);
}

export function preventTextInput(event) {
  if (!/[0-9]/.test(event.key) && event.key !== 'Backspace' && event.key !== 'Delete' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
    event.preventDefault();
  }
}


export function preventNumberInput(event) {
  if (!/[^0-9]/.test(event.key) && event.key !== 'Backspace' && event.key !== 'Delete' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
    event.preventDefault();
  }
}


export function handleSessionTimeout(route) {
  let activityTimer;
  const resetActivityTimer = () => {
    clearTimeout(activityTimer);
    activityTimer = setTimeout(() => {
      Swal.fire({
        icon: 'warning',
        title: 'Session Expired',
        text: 'Your session has been expired! Please login again.',
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: 'ok',
      }).then((result) => {
        if (result.isConfirmed) {
          setAuthToken();
          sessionStorage.clear();
          localStorage.clear();
          window.location.href = route;
        }
      });
    }, 20 * 60 * 1000);
  };

  // Add event listeners to reset the activity timer
  window.addEventListener('mousemove', resetActivityTimer);
  window.addEventListener('mousedown', resetActivityTimer);
  window.addEventListener('keydown', resetActivityTimer);
}




export function handleAuthorization(route) {
  Swal.fire({
    icon: 'warning',
    title: 'Authorization Failed!',
    text: 'Please login again.',
    showConfirmButton: true,
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    confirmButtonText: 'ok',
  }).then((result) => {
    if (result.isConfirmed) {
      setAuthToken();
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = route;
    }
  });
}

export function handleServerError(route) {
  Swal.fire({
    icon: 'warning',
    title: 'Server Error!',
    text: 'Server is not responding.',
    showConfirmButton: true,
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    confirmButtonText: 'ok',
  }).then((result) => {
    if (result.isConfirmed) {
      setAuthToken();
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = route;
    }
  });
}

export function setPageTitle(base) {
  // Access the current route name or any other relevant information
  const baseTitle = "Tracker"; // Set your base title here
  const pageTitle = base || 'Tracker - Track Your Employee at your fingertips.'; // Get current route name or set a default

  // Concatenate the base title with the page title
  const fullTitle = `${pageTitle} - ${baseTitle}`;
  // Set the document title dynamically
  document.title = fullTitle;
}

export function extractLineNumber(stack) {
  const lines = stack.split('\n');
  for (let i = 0; i < lines.length; i++) {
    const match = lines[i].match(/(.*):(\d+):(\d+)/);
    if (match) {
      return match[2]; // Return the line number
    }
  }
  return null; // Return null if no line number is found
}

export function isOnline() {
  // First check using the navigator.onLine property
  if (!navigator.onLine) {
      return false;
  }

  // Further check by sending a request to a reliable server
  return new Promise((resolve) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = () => {
          resolve(true);
      };
      xhr.onerror = () => {
          resolve(false);
      };
      xhr.open("GET", "https://www.google.com/favicon.ico", true); // Use a small and quick resource
      xhr.send();
  });
}


export const getCurrentTimestamp=()=> {
  // Get the current date and time
  let currentDate = new Date();
  
  // Add 10 hours and 34 minutes to the current date
  currentDate.setHours(currentDate.getHours());
  currentDate.setMinutes(currentDate.getMinutes());
  
  // Format the resulting date to HH:mm AM/PM format
  let formattedTimestamp = currentDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

  return formattedTimestamp;
}

export const preventSpaces=(event)=> {
  // Check if the key pressed is a space (key code 32)
  if (event.keyCode === 32) {
    event.preventDefault(); // Prevent the space from being entered
  }
}

export const compressAndUploadImage = (file) => {
  console.log("Starting image compression");

  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      console.log("File read successfully");

      const image = new Image();
      image.src = e.target.result;

      image.onload = () => {
        console.log("Image loaded successfully");

        const canvas = document.createElement('canvas');
        const maxDimensions = 1200;

        if (image.width > maxDimensions || image.height > maxDimensions) {
          const scale = Math.min(maxDimensions / image.width, maxDimensions / image.height);
          canvas.width = image.width * scale;
          canvas.height = image.height * scale;
        } else {
          canvas.width = image.width;
          canvas.height = image.height;
        }

        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
        console.log("Image drawn on canvas");

        const originalImageFormat = file.type;
        if (!originalImageFormat || !['image/jpeg', 'image/png', 'image/webp'].includes(originalImageFormat)) {
          console.error('Unsupported image format');
          reject(new Error('Unsupported image format'));
          return;
        }

        canvas.toBlob((blob) => {
          if (blob) {
            console.log("Image compressed successfully");
            const compressedFile = new File([blob], file.name, { type: blob.type });
            resolve(compressedFile);
          } else {
            console.error('Image compression failed');
            reject(new Error('Image compression failed'));
          }
        }, originalImageFormat, 0.9);
      };

      image.onerror = (err) => {
        console.error('Image loading failed', err);
        reject(new Error('Image loading failed'));
      };
    };

    reader.onerror = (err) => {
      console.error('File reading failed', err);
      reject(new Error('File reading failed'));
    };

    reader.readAsDataURL(file);
  });
};


// export const compressAndUploadVideo = async (file) => {
//   if (!ffmpeg.isLoaded()) {
//     await ffmpeg.load();
//   }

//   // Load the file into the FFmpeg filesystem
//   ffmpeg.FS('writeFile', file.name, await fetchFile(file));

//   // Compress the video
//   await ffmpeg.run('-i', file.name, '-vcodec', 'libx264', '-crf', '28', 'output.mp4');

//   // Read the compressed file from the FFmpeg filesystem
//   const data = ffmpeg.FS('readFile', 'output.mp4');

//   // Create a Blob from the compressed data
//   const compressedBlob = new Blob([data.buffer], { type: 'video/mp4' });

//   // Optionally convert the Blob to a File object
//   const compressedFile = new File([compressedBlob], 'compressed_' + file.name, { type: 'video/mp4' });

//   return compressedFile;
// };