// authMixin.js

import Cookies from "js-cookie";
import router from "../../routes/routes"; // Assuming you have a router instance in your project
import showAlert from "../Common/sweetAlert";
import { handleAuthorization } from "../Common/commonMethod";

export const authMixin = {
  methods: {
    isTokenExpired() {
      const token = Cookies.get("jwt");
      let userCookie = Cookies.get("user");
      userCookie = userCookie ? JSON.parse(userCookie) : null;

      if (!token || !userCookie) {
        // alert(
        //   "Access denied. Your token has been revoked or is no longer valid."
        // );
        return true; // No token and user found
      } else if (userCookie.UserType !== "User") {
        //alert("Access denied: Insufficient permissions");
        return true; // User role is not an user
      }

      const decodedToken = parseJwt(token);
      const currentTimestamp = Math.floor(Date.now() / 1000);

      // Check if the token expiration is more than 5 minutes from the current time
      return decodedToken.exp < currentTimestamp + 1800; // 1800 seconds = 30 minutes
    },
    checkAuthorization() {
      if (this.isTokenExpired()) {
        // Token expired, redirect to the home page
        handleAuthorization("/login")
        // showAlert("warning","Authorization Failed!",false,5000)
        // window.location.href = "/";
        //router.push("/");
      }
    },
  },
};

// Helper function to parse JWT tokens
function parseJwt(token) {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join("")
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    //console.error("Error parsing JWT token:", error.message);
    return null;
  }
}
