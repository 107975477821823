<template>
  <div>
    <HeaderView />
    <div class="mx-auto">
      <div class="card p-2 user_card mx-auto">
        <h4 class="text-xl font-semibold mb-1">Reset Password</h4>
        <hr />
        <div v-if="formHeader" class="error-message text-red-500 mb-2">
              {{ formHeader }}
            </div>
        <form @submit.prevent="resetPassword">
          <div class="input-group mb-1">
            <div class="input-group-append">
              <span class="input-group-text"><i class="fas fa-lock"></i></span>
            </div>
            <input v-model="newPassword" tabindex="1" type="text" name="" class="form-control input_user"
              placeholder="New Password" />
          </div>
          <span class="text-red-500 text-sm">{{
            errors.newPassword
          }}</span>
          <div class="input-group mt-3 mb-1">
            <div class="input-group-append">
              <span class="input-group-text"><i class="fas fa-lock"></i></span>
            </div>
            <input v-model="confirmPassword" tabindex="2" type="password" name="" class="form-control input_pass"
              placeholder="Confirm Password" />
          </div>
          <span class="text-red-500 text-sm">{{
            errors.confirmPassword
          }}</span>
          <div class="d-flex justify-content-center mt-3 login_container">
            <button type="submit" name="button" class="btn login_btn" title="Submit" default tabindex="3">
              <img src="../assets/img/loading.gif" class="processimg" id="processimg">Submit
            </button>
          </div>
        </form>
      </div>
    </div>
    <FooterView />
  </div>
</template>

<script>
import HeaderView from "./header.vue"; // Adjust the path if needed
import FooterView from "./footer.vue"; // Adjust the path if needed
import api from "./apiConfig";
import { ErrorLog } from "./Common/APILog";
import {jwtDecode} from "jwt-decode";
import showAlert from "./Common/sweetAlert";

export default {
  name: "ResetPassword",
  props: {
  },
  components: {
    HeaderView,
    FooterView,
  },
  data() {
    return {
      newPassword: "",
      confirmPassword: "",
      token:"",
      decodedvalue:[],
      errors: {},
      formHeader: "",
    };
  },
  created(){
    console.log(jwtDecode);
    const params = new URLSearchParams(window.location.search);
    this.token = params.get('token'); // "123"
    console.log("this is my token : "+ this.token)
    this.isTokenExpired(this.token)
  },
  methods: {

    async isTokenExpired(token) {
      try {
        this.decodedValue = jwtDecode(token);
        console.log(this.decodedValue);
        return this.decodedValue.exp < Date.now() / 1000;
      } catch (error) {
        console.error('Error decoding token:', error);
        return false; // If there's an error decoding, assume the token is invalid/expired
      }
    },
    async performResetPassword() {
      try {
        if(this.isTokenExpired){
        const response = await api.post("/api/auth/reset-password", {
          newPassword: this.newPassword,
          token: this.token,
        });
        // Assuming the API response structure includes a 'success' property
        if (response.data.success) {
          // Password change was successful
          // alert("Password reset successfully.");
          showAlert("Success","Password reset successfully.",false,7000)
          this.$router.push("/");
        } else {
          // Password change failed, set error message
          this.formHeader = response.data.msg || "Password reset failed";
        }
      }
      else{
        this.formHeader = "Token Expired";
      }
      } catch (error) {
        ErrorLog(error.message, error.statuscode, 87, error.stack, "ResetPassword/performResetPassword")
        console.log(error);
        
      }
    },
    resetPassword() {
      this.formHeader = "";
      // Validation logic
      this.errors = {};

      if (!this.newPassword) {
        this.errors.newPassword = "New Password is required.";
      }

      if (!this.confirmPassword) {
        this.errors.confirmPassword = "Confirm Password is required.";
      } else if (this.confirmPassword !== this.newPassword) {
        this.errors.confirmPassword = "Passwords do not match.";
      }

      // If there are no validation errors, you can proceed with the change password logic
      if (Object.keys(this.errors).length === 0) {
        this.performResetPassword();
      }
    },
  },
};
</script>
<style scoped>
/* Add Tailwind CSS classes here if needed */

.shadow-2-strong {
  min-width: 500px !important;
}

.container {
  justify-content: center !important;
  padding-top: 8%;
}

.processimg {
  height: 25px;
  margin-right: 5px;
  display: none;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.form-control {
  padding: 7px 7px !important;
  height: 28px !important;
  background-color: white;
}

.logoDiv {
  height: 100px;
  /* Set a fixed height for logoDiv */
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid;
  text-align: center;
}

/* Coded with love by Mutiullah Samim */
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  background: #60a3bc !important;
}

.user_card {
  height: 100%;
  width: 350px;
  margin-top: 100px;
  margin-bottom: auto;
  background: lightblue;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}


.form_container {
  margin-top: 10px;
}

.login_btn {
  width: 100%;
  background: #020272 !important;
  color: white !important;
}

.login_btn:focus {
  box-shadow: none !important;
  outline: 0px !important;
}

.login_container {
  padding: 0 2rem;
}

.input-group-text {
  background: #020272 !important;
  color: white !important;
  border: 0 !important;
  border-radius: 0.25rem 0 0 0.25rem !important;
}

.input_user,
.input_pass:focus {
  box-shadow: none !important;
  outline: 0px !important;
}

.links {
  text-align: center;
  font-weight: 500;
  color: blue;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: #c0392b !important;
}

.d-flex {
  display: block !important;
  /* or display: inline-block; or any other desired value */
  padding: 0px 10px 0px 10px;
}

.error-message {
  text-align: center;
  font-size: 15px;
  margin-top: -10px;
  font-style: italic;
}

.text-red-500 {
  font-style: italic;
}
</style>
