<template>
  <div>
    <div class="container h-100">
      <div class="d-flex justify-content-center h-100">
        <div class="user_card">
          <div class="d-flex justify-content-center">
            <div class="logoDiv">
              <img src="../assets/img/trackerLogo_B.png" alt="Logo" class="mb-2" style="height: 50px" />
            </div>
          </div>
          <div class="d-flex justify-content-center form_container">
            <div v-if="formHeader" class="error-message text-red-500 mb-2">
              {{ formHeader }}
            </div>
            <form @submit.prevent="login">
              <div class="input-group mb-1">
                <div class="input-group-append">
                  <span class="input-group-text"><i class="fas fa-user"></i></span>
                </div>
                <input v-model="username" tabindex="1" type="text" name="" class="form-control input_user"
                  placeholder="Username" />
              </div>
              <span class="text-red-500 text-sm">{{
                userValidate.username
              }}</span>
              <div class="input-group mt-3 mb-1">
                <div class="input-group-append">
                  <span class="input-group-text"><i class="fas fa-key"></i></span>
                </div>
                <input v-model="password" tabindex="2" type="password" name="" class="form-control input_pass"
                  placeholder="Password" />
              </div>
              <span class="text-red-500 text-sm">{{
                userValidate.password
              }}</span>
              <div class="d-flex justify-content-center mt-3 login_container">
                <button :disabled="btnDisabled" type="submit" name="button" title="Login" class="btn login_btn" default tabindex="3">
                  <img src="../assets/img/loading.gif" class="processimg" id="processimg">Login
                </button>
              </div>
            </form>
          </div>
          <div class="mt-4">
            <div class="d-flex justify-content-center links">
              <a href="/forgotpassword?type=user" style="color: blue !important;">Forgot Password?</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "./apiConfig";
import Cookies from "js-cookie";

import { setAuthToken } from "../components/apiConfig";
import { ErrorLog } from "./Common/APILog";
import { handleAuthorization, handleServerError } from "./Common/commonMethod";

export default {
  name: "LoginView",
  components: {},
  props: {
    msg: String,
  },
  created() {
    setAuthToken();
  },
  data() {
    return {
      username: "",
      password: "",
      formHeader: "",
      btnDisabled: false,
      userValidate: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    isValidTaskForm() {
      let isValid = true;

      // Title
      if (!this.username.trim()) {
        this.userValidate.username = "Username is required.";
        isValid = false;
      }
      else {
        this.userValidate.username = "";
      }

      // Description
      if (!this.password.trim()) {
        this.userValidate.password = "Password is required.";
        isValid = false;
      }
      else {
        this.userValidate.password = "";
      }
      return isValid;
    },
    async performLogin() {
      try {
        this.btnDisabled = true;
        document.getElementById("processimg").style.display = "inline";
        const response = await api.post("/api/auth/login", {
          username: this.username,
          password: this.password,
        }); 
        console.log("sam",response);
        if (response.data.success) {
          // var date = new Date();
          // date.setMinutes(date.getMinutes() + 20);
          // Set the received cookie in the browser
          Cookies.set("jwt", response.data.token, {
            // expires: date, // 30 minutes
            secure: false, // Set to true if using HTTPS
            sameSite: "Lax", // Adjust as needed
          });
          Cookies.set("user", JSON.stringify(response.data.user), {
            // expires: date,
            secure: false,
            sameSite: "Lax",
          });
          // Check the role (assuming response.data.role is available)
          const role = response.data.user.UserType;
          // Redirect based on the role
          if (role === "Admin") {
            this.$router.push("/dashboard");
          } else if (role === "User") {
            // Redirect to user page
            this.$router.push("/user/dashboard");
          }
          else {
            //Handle unknown role
            this.formHeader = "User Type " + role + " not authorized.";
            // alert(`User Type '${role}' not authorized.`);
            console.error("Unknown role:", role);
          }
        } else {
          this.formHeader = response.data.message + ".";
        }
        // Continue with any other logic (e.g., navigate to a different page)
      } catch (error) {
        console.log(error)
        if (error.request || error.response.data) {
         if(error.message==="Network Error"){
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.formHeader = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.formHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 754, error.stack, "User Panel: TaskDetail / fetchTaskDetails()");
          }
        }
        else {
          this.formHeader = "Something went wrong.";
          ErrorLog(error.message, error.response.status, 754, error.stack, "User Panel: TaskDetail / fetchTaskDetails()");

        }
      } finally {
        this.btnDisabled = false;
        document.getElementById("processimg").style.display = "none";
      }
    },
    login() {
      if (this.isValidTaskForm()) {
        this.formHeader = "";
        this.userValidate.username = "";
        this.userValidate.password = "";
        // Call the performLogin method
        this.performLogin();
      }
    },
  },
};
</script>

<style scoped>
/* Add Tailwind CSS classes here if needed */

.shadow-2-strong {
  min-width: 500px !important;
}

.container {
  justify-content: center !important;
  padding-top: 8%;
}

.processimg {
  height: 25px;
  margin-right: 5px;
  display: none;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.form-control {
  padding: 7px 7px !important;
  height: 28px !important;
  background-color: white;
}

.logoDiv {
  height: 100px;
  /* Set a fixed height for logoDiv */
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid;
  text-align: center;
}

/* Coded with love by Mutiullah Samim */
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  background: #60a3bc !important;
}

.user_card {
  height: 100%;
  width: 350px;
  margin-top: auto;
  margin-bottom: auto;
  background: lightblue;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}

.brand_logo_container {
  position: absolute;
  height: 170px;
  width: 170px;
  top: -75px;
  border-radius: 50%;
  background: #60a3bc;
  padding: 10px;
  text-align: center;
}

.brand_logo {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  border: 2px solid white;
}

.form_container {
  margin-top: 30px;
}

.login_btn {
  width: 100%;
  background: #020272 !important;
  color: white !important;
}

.login_btn:focus {
  box-shadow: none !important;
  outline: 0px !important;
}

.login_container {
  padding: 0 2rem;
}

.input-group-text {
  background: #020272 !important;
  color: white !important;
  border: 0 !important;
  border-radius: 0.25rem 0 0 0.25rem !important;
}

.input_user,
.input_pass:focus {
  box-shadow: none !important;
  outline: 0px !important;
}

.links {
  text-align: center;
  font-weight: 500;
  color: blue;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: #c0392b !important;
}

.d-flex {
  display: block !important;
  /* or display: inline-block; or any other desired value */
  padding: 0px 10px 0px 10px;
}

.error-message {
  text-align: center;
  font-size: 15px;
  margin-top: -10px;
  font-style: italic;
}

.text-red-500 {
  font-style: italic;
}
</style>
