<template>
  <div>
    <div class="topchart-div">
      <div class="inner-div" style="padding-top: 10px;">
        <apexchart type="pie" :options="chartOptions" :series="series" width="92%" />
        <template>
          <h4 class="Count-header" style="margin-top:5px;">Total To-Do's: {{ TotalTaskCount }}</h4>
          <button class="zoom-in" data-bs-toggle="modal" title="Click to Zoom" data-bs-target="#pieChartModal" data-bs-whatever="@mdo">
            <i class="fas fa-search-plus"></i></button>
        </template>
      </div>
    </div>
    <!-- Modal for User Chart -->
    <div class="modal fade" id="pieChartModal" tabindex="-1" aria-labelledby="pieChartModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <!-- <h1 class="modal-title fs-5" id="taskModalLabel">
                      {{ editTaskMode ? "Edit Task" : "Add New Task" }}
                    </h1> -->
            <button class="btn-close" data-bs-dismiss="modal" title="Close" aria-label="Close" tabindex="-2"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <!-- <h4>Are you sure you want to delete?</h4> -->
              <apexchart type="pie" :options="chartOptions" :series="series" width="90%" />
            </div>
          </div>
          <div class="modal-footer">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import api, { setAuthToken } from "../../components/apiConfig";
import Cookies from "js-cookie";
import { ErrorLog } from "../Common/APILog";
import { handleAuthorization, handleServerError } from '../Common/commonMethod';
export default {
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      TotalTaskCount: 0,
      flag: 0,
      series: [0, 0, 0],
      chartOptions: {
        chart: {
          type: 'pie'
        },
        labels: ['Assigned', 'Completed', 'In Process']
      }
    };
  },
  mounted() {
    // Initial data
    this.getTotalTask();
  },
  methods: {
    async getTotalTask() {
      try {
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        const response = await api.get(`/api/admin/tasks/total-task/${userCookie.UserID}`);
        if (response.data) {
          this.TotalTaskCount = Object.values(response.data[0]).reduce((acc, cur) => acc + cur, 0);
          var data = response.data[0];
          console.log("data", data);
          this.series = [data.Assigned, data.Completed, data.InProcess];
        }
      } catch (error) {
        console.log(error);
        if (this.flag > 3) {
          if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              // this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              // this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 95, error.stack, "AdminPanel/personalTasks/getTotalTask()");
          }
        }
        else {
          // this.errorAlertOnSubmit = "Something went wrong.";
        }
        }
        else {
          setTimeout(() => {
            this.getTotalTask(); // Retry after a delay
          }, 1000);
          this.flag++;
        }
      } finally {
        //this.loading = false; // Set loading state to false after data is fetched
      }
    },
  },
};
</script>
<style scoped>
.Count-header {
  font-size: 14px;
  font-weight: 600;
  float: left;
}

.zoom-in {
  float: right;

  border-radius: 5px;
  color: #fff;
  background: #0d6efd;
  border: 2px solid #0d6efd;
  margin-top: 5px;
  padding-top: 3px;
}

.zoom-in i {
  font-size: 16px;
}

/* .topchart-div{
    overflow: hidden;
    min-height: 250px;
  } */
@media (min-width: 720px) {
  .modal-dialog {
    width: 700px;
    max-width: 700px;
  }
}

.topchart-div {
  overflow: hidden;
  position: relative;
}

.inner-div {
  position: absolute;
  bottom: 0;
  width: 100%;
}

@media screen and (max-width:1280px) {
  .inner-div {
    position: unset !important;
  }
}

@media screen and (min-width:1281px) {
  .topchart-div {
    height: 192px !important;
  }
}

@media screen and (min-width:1300px) {
  .topchart-div {
    height: 197px !important;
  }
}

@media screen and (min-width:1350px) {
  .topchart-div {
    height: 205px !important;
  }
}

@media screen and (min-width:1400px) {
  .topchart-div {
    height: 220px !important;
  }
}
</style>