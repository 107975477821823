<template>
    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item md-large-size-100 md-size-66">
                <div class="row mx-auto px-auto justify-content-center align-items-center vh-100">
                    <div class="text-center">
                        <img src="../../assets/img/x.jpg"/>
                        <h3>Server Error!</h3> 
                        <h5>Please login again. <a href="/adminLogin">Login</a></h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: "error",
    components: {},
    props: {
    },
    data() {
        return {
            isChecked: false,
            loadingUser: "",
            successAlertOnSubmit: "",
            categoryDropdownOptions: [],
            priorityDropdownOptions: [],
            btnEnable: false,
            editSuperAdmin: {
                UserID: 0,
                FirstName: "",
                LastName: "",
                Username: "",
                ContactNumber: "",
                Email: "",
                Password: "",
                UserType: "User",
                // ConfirmPassword:"",
            },
            userValidate: {
                FirstName: "",
                LastName: "",
                Username: "",
                ContactNumber: "",
                Email: "",
                UserType: "",
                Password: "",
                // ConfirmPassword:"",
                // Add additional validation fields as needed
            },
            errors: {},
            formHeader: "",
        };
    },
    computed: {
    },
    mounted() {
        // Initial data
    },
    methods: {
        isNumber: function (evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },
        goBack() {
            // Go back to the previous page in the history
            this.$router.go(-1);
        },
    },
};
</script>

<style scoped>
/* Add Tailwind CSS classes here if needed */
.close-icon {
    cursor: pointer;
    float: right;
    font-size: 30px;
    margin-top: -10px;
}

.dropdown {
    width: 100%;
    height: 36px;
    padding: 7px 0;
    font-size: 14px;
    line-height: 1.428571429;
    border-color: #D2D2D2;
}
</style>