<!-- ConfirmationDialog.vue -->
<template>
  <div v-if="visible" class="confirmation-dialog">
    <div class="confirmation-content">
      <div>
        <p class="fs-6">{{ message }}</p>
      </div>
      <hr />
      <div class="text-right">
        <button @click="confirm" class="btn btn-success text-right">Yes</button>
        <button @click="cancel" class="btn btn-danger text-right">No</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['message'],
  data() {
    return {
      visible: true
    };
  },
  methods: {
    confirm() {
      this.visible = false;
      this.$emit('confirmed'); // Emit 'confirmed' event when user confirms
    },
    cancel() {
      this.visible = false;
      this.$emit('canceled'); // Emit 'canceled' event when user cancels
    }
  }
};
</script>

<style scoped>
.confirmation-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmation-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Add shadow */
}

.confirmation-content button {
  margin: 0 10px;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 5px;
  font-weight: bold;
}

.confirm-button {
  background-color: green;
  color: white;
  border: none;
}

.cancel-button {
  background-color: red;
  color: white;
  border: none;
}
</style>
