<template>
  <div class="content" style="overflow-x:hidden;">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
        <md-card>
          <md-card-header data-background-color="green">
            <div class="card-header d-flex justify-content-between align-items-center">
              <h4 class="title">Chat Box</h4>
              <div class="ms-auto"></div>
            </div>
          </md-card-header>
          <md-card-content>
            <div class="col-md-12" style="overflow-x:auto;">
              <div class="task-table">
                <div v-if="loadingTasks" class="text-center">
                  <p>{{ loadingTasks }}</p>
                </div>
                <div v-else id="app">
                  <div class="chat-box">
                    <div class="messages justify-content-between" ref="chatContainer"
                      style="overflow-y: auto; max-height: 250px;" id="ScrollDiv" v-chat-scroll>
                      <!-- <div v-for="(messages, date) in groupedMessages" :key="date">
                        <div class="date-partition">{{ date }}</div> -->
                        <div v-for="(message, index) in messages" :key="index">
                          <div>
                            <div v-if="UserID != message.SenderId" class="message">
                              <div class="messageboxleft">
                                <div class="d-flex justify-content-between">
                                  <span v-if="message.Message || message.FilePath" class="text-right received">{{
                                    `${message.SenderName}` }}</span>
                                  <span class="mx-2 timestamp">{{ message.TimestampHours }}</span>
                                </div>
                                <div v-if="message.FilePath">
                                  <div v-if="isImage(message.FilePath)" style="display: flex; flex-direction: column;">
                                    <span>{{ message.FileName }}</span>
                                    <img :src="`${baseimageURL}/${message.FilePath}`" class="uploaded-image" />
                                  </div>
                                  <div v-else-if="isVideo(message.FilePath)"
                                    style="display: flex; flex-direction: column;">
                                    <span>{{ message.FileName }}</span>
                                    <video controls :src="`${baseimageURL}/${message.FilePath}`"
                                      class="uploaded-video"></video>
                                  </div>
                                  <div v-else-if="isPDF(message.FilePath)" class="pdf-container">
                                    <iframe :src="`${baseimageURL}/${message.FilePath}`" class="uploaded-pdf"></iframe>
                                    <a :href="`${baseimageURL}/${message.FilePath}`" target="_blank">
                                      <md-icon class="text-danger mx-1">picture_as_pdf</md-icon>{{ message.FileName
                                      }}</a>
                                  </div>
                                  <div v-else-if="isCSV(message.FilePath)">
                                    <a :href="`${baseimageURL}/${message.FilePath}`" target="_blank"
                                      class="pdf-preview">
                                      {{ message.FileName }}</a>
                                  </div>
                                </div>
                                <span v-if="message.Message" class="text-right">{{ `${message.Message}` }}</span>
                              </div>
                            </div>
                            <div v-else class="justify-content-end message">
                              <div class="messageboxright">
                                <div class="d-flex justify-content-between">
                                  <span v-if="message.Message || message.FilePath" class="sent text-left">{{
                                    `${message.SenderName}` }}</span>
                                  <span class="mx-2 text-right timestamp">{{ message.TimestampHours }}
                                  </span>
                                </div>

                                <div v-if="message.FilePath">
                                  <div v-if="isImage(message.FilePath)" style="display: flex; flex-direction: column;">
                                    <span>{{ message.FileName }}</span>
                                    <img :src="`${baseimageURL}/${message.FilePath}`" class="uploaded-image" />
                                  </div>
                                  <div v-else-if="isVideo(message.FilePath)"
                                    style="display: flex; flex-direction: column;">
                                    <span>{{ message.FileName }}</span>
                                    <video controls :src="`${baseimageURL}/${message.FilePath}`"
                                      class="uploaded-video"></video>
                                  </div>
                                  <div v-else-if="isPDF(message.FilePath)" class="pdf-container">
                                    <iframe :src="`${baseimageURL}/${message.FilePath}`" class="uploaded-pdf"></iframe>
                                    <a :href="`${baseimageURL}/${message.FilePath}`" target="_blank">
                                      <md-icon class="text-danger mx-1">picture_as_pdf</md-icon>{{ message.FileName
                                      }}</a>
                                  </div>
                                  <div v-else-if="isCSV(message.FilePath)">
                                    <a :href="`${baseimageURL}/${message.FilePath}`" target="_blank"
                                      class="pdf-preview">
                                      {{ message.FileName }}</a>
                                  </div>
                                </div>
                                <span v-if="message.Message" class="text-right">{{ `${message.Message}` }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      <!-- </div> -->
                    </div>
                    <form @submit.prevent="sendMessage">
                      <div class="input-box">
                        <input type="text" v-model="text" ref="textfield" placeholder="Type your message..."
                          style="width: calc(100% - 60px);">
                        <input type="file" @change="onFileSelected" ref="fileInput" style="display: none;">
                        <button type="button" @click="selectFile" @keydown.enter.prevent class="text-white mx-2">
                          <span class="material-icons">attach_file</span>
                        </button>
                        <button @keyup.enter="sendMessage" type="submit" :disabled="IsLoading"
                          style="width: 60px;">Send</button>
                      </div>

                      <div v-if="filePreview" class="image-preview text-right">
                        <button class="clearbutton bg-light text-danger" @click="clearFileData">x</button>
                        <div class="overlay" v-if="IsLoading">
                          <div class="progress-container">
                            <circular-progress :progress="progress" :stroke-width="4" :size="100" :color="color"
                              style="width: 60px;height: 60px;" />
                            <div class="progress-text" v-if="progress >= 0" style="color: green;font-weight: 200;">
                              {{ progress }}%
                            </div>
                          </div>
                        </div>
                        <div v-if="filePreviewType === 'image'">
                          <img :src="filePreview" alt="Selected Image Preview" class="uploaded-image" />
                        </div>
                        <div v-if="filePreviewType === 'video'">
                          <video controls :src="`${filePreview}`" class="uploaded-image"></video>
                        </div>
                        <div v-if="filePreviewType === 'pdf'" class="pdf-preview-container">
                          <iframe :src="filePreview" class="pdf-preview"></iframe>
                        </div>
                      </div>
                    </form>
                    <div v-if="errormessage" class="mb-3" style="margin-bottom: 50px !important;">
                      <span class="text-danger mb-4" style="font-size: 12px;">{{ errormessage }} <i
                          class="fa fa-info infostyle" :title="validFormat"></i></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import Vue, { ref } from 'vue'
import VueChatScroll from 'vue-chat-scroll'
Vue.use(VueChatScroll)
import api, { baseWebsocketUrl, setAuthToken, baseimageURL } from "../apiConfig";
import { authMixin } from "../admin_pages/adminStore";
import Cookies from "js-cookie";
import { compressAndUploadImage, getCurrentTimestamp, handleSessionTimeout } from './commonMethod';
import { compressVideo } from '../../../ffmpegTest.js';
import { v4 as uuidv4 } from 'uuid';
import CircularProgress from 'vue-circular-progress'
export default {
  name: "ChatBox",
  components: {
    CircularProgress
  },
  mixins: [authMixin],
  props: {
    TaskID: {
      type: String,
      required: true,
    },
    AssignedBy: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tasks: [],
      notificationMessage: '',
      socket: null,
      color: '#42b983',
      message: '',
      groupedMessages: [],
      // errorMessage: "",
      baseimageURL: baseimageURL,
      UserID: '',
      loadingTasks: "",
      taskFormHeader: "",
      successAlertOnSubmit: "",
      StatusCompleted: false,
      IsTasks: false,
      taskValidate: {
        Message: "",
      },
      isInputDisabled: true,
    };
  },
  mounted() {

  },
  created() {
    this.getChatByTaskId(this.TaskID)
  },

  setup(props) {
    const TaskID = props.TaskID;
    const AssignedBy = props.AssignedBy;
    const username = ref(null)
    const connect = ref(false)
    let IsLoading = ref(false);
    const text = ref(null)
    let selectedFile = null
    const filePreview = ref(null)
    let filename = ref(null)
    let errormessage = ref(null)
    let validFormat = ref(null)
    let progress = ref(-1);
    let filePreviewType = ref(null)
    const notifications = []
    const messages = ref([])
    const socket = new WebSocket(baseWebsocketUrl)
    const handleConnect = (username) => {
      if (username) {
        connect.value = true
      }
    }

    const isValidFile = (filename) => {
      const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg', '.pdf', '.csv', '.mp4', '.webm', '.avi', '.mov', '.mkv', '.flv', '.wmv', '.m4v', '.mpeg'];
      const extension = filename.toLowerCase().slice(filename.lastIndexOf('.'));
      return allowedExtensions.includes(extension);
    };
    const onFileSelected = async (event) => {
      clearFileData();
      const file = event.target.files[0];
      if (isValidFile(file.name)) {
        // selectedFile=file
        console.log("file size: " + (file.size / 1024) / 1024)
        const toolarge = 300000000;
        console.log("toolarge: " + (toolarge / 1000) / 1000)
        filename = file.name;
        if (file) {
          const fileType = file.type;
          if (fileType.startsWith('image/')) {
            if (file.size > toolarge) {
              errormessage.value = `Too large, Max file size is ${((toolarge / 1000) / 1000)}MB`
            }
            else {
              selectedFile = file
              filePreviewType.value = 'image';
              filePreview.value = URL.createObjectURL(file);
            }

            // compressAndUploadImage(file)
            //   .then(compressedBlob => {
            //     console.log("Compressed image blob:", compressedBlob);
            //     // You can now upload the compressedBlob to your server
            //     console.log("file size1: " + (compressedBlob.size / 1024) / 1024);
            //     if (compressedBlob.size > toolarge) {
            //       errormessage.value = `Too large, Max file size is ${toolarge / 1000 * 1000}MB`
            //     }
            //     else {
            //       selectedFile = compressedBlob
            //       filePreviewType.value = 'image';
            //       filePreview.value = URL.createObjectURL(file);
            //     }
            //   })
            //   .catch(error => {
            //     console.error("Error during image compression:", error);
            //   });
          } else if (fileType === 'application/pdf') {
            if (file.size > toolarge) {
              errormessage.value = `Too large, Max file size is ${((toolarge / 1000) / 1000)}MB`
            }
            {
              selectedFile = file;
              filePreviewType.value = 'pdf';
              filePreview.value = URL.createObjectURL(file);
            }
          } else if (file.name.endsWith('.csv')) {
            if (file.size > toolarge) {
              errormessage.value = `Too large, Max file size is ${((toolarge / 1000) / 1000)}MB`
            }
            else {
              selectedFile = file;
              filePreviewType.value = 'csv';
              filePreview.value = URL.createObjectURL(file);
            }
          } else if (fileType.startsWith('video/')) {
            if (file.size > toolarge) {
              errormessage.value = `Too large, Max file size is ${((toolarge / 1000) / 1000)}MB`
            }
            else {
              selectedFile = file;
              filePreviewType.value = 'video';
              filePreview.value = URL.createObjectURL(file);
            }
          } else {
            filePreviewType.value = null;
            filePreview.value = null;
          }
        }

        setTimeout(() => {
          errormessage.value = "";
        }, 7000);
      } else {
        errormessage.value = "File format not valid.";
        validFormat.value = "Valid formats: '.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg', '.pdf', '.csv', '.mp4', '.webm', '.avi', '.mov', '.mkv', '.flv', '.wmv', '.m4v', '.mpeg'"
        console.log(errormessage.value);
      }
    };

    const clearFileData = () => {
      filePreview.value = null;
      selectedFile = null;
      filePreviewType.value = null;
      errormessage.value = null
      validFormat.value = null
      filename = "";
    }
    const getValidFormatsTitle = () => {
      const validExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg', '.pdf', '.csv', '.mp4', '.webm', '.avi', '.mov', '.mkv', '.flv', '.wmv', '.m4v', '.mpeg'];
      return `Valid formats: ${validExtensions.join(', ')}`;
    }
    const sendMessage = async () => {
      IsLoading.value = true
      const timestamp = getCurrentTimestamp();
      if (text.value != null && text.value.trim() != "" || selectedFile != null) {
        let userCookie = await Cookies.get("user");
        userCookie = await userCookie ? JSON.parse(userCookie) : null;
        handleConnect(userCookie.Username);
        const messageData = {
          Message: text.value,
          TaskID: parseInt(TaskID),
          Sender: userCookie.UserType,
          SenderName: userCookie.Username,
          SenderId: userCookie.UserID,
          Reciever: "Admin",
          RecieverId: parseInt(AssignedBy),
          TimestampHours: timestamp
        };
        if (selectedFile) {
          const chunkSize = 1 * 1024 * 1024; // 1MB
          const totalChunks = Math.ceil(selectedFile.size / chunkSize);
          const identifier = uuidv4();
          const file = selectedFile;
          for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
            const start = chunkIndex * chunkSize;
            const end = Math.min(start + chunkSize, file.size);
            const chunk = file.slice(start, end);
            const formData = new FormData();
            formData.append('chunk', chunk);
            formData.append('originalname', file.name);
            formData.append('chunkIndex', chunkIndex);
            formData.append('totalChunks', totalChunks);
            formData.append('identifier', identifier);
            formData.append('fileType', file.type);
            formData.append('data', JSON.stringify(messageData));
            // formData.append('file', selectedFile);
            try {
              const response = await api.post('/uploadFiles', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
              progress.value = Math.floor(((chunkIndex + 1) / totalChunks) * 100);
              if (progress.value === 100) {
                if (response.data) {
                  const message = response.data[0];
                  const Message1 = {
                    Message: text.value,
                    TaskID: parseInt(TaskID),
                    Sender: userCookie.UserType,
                    SenderName: userCookie.Username,
                    SenderId: userCookie.UserID,
                    Reciever: "Admin",
                    RecieverId: parseInt(AssignedBy),
                    FileName: filename,
                    FilePath: message.FilePath,
                    FileType: message.FileType,
                    TimestampHours: timestamp
                  }
                  // console.log(Message1)
                  handleConnect(userCookie.Username);
                  socket.send(JSON.stringify(Message1));
                  messages.value.push(message);
                }
                // const response=JSON.parse(result)
                // messages.value.push(messageData);
                text.value = null;
                selectedFile = null;
                filePreview.value = null;
                console.log(response.data)
              }
            } catch (error) {
              text.value = null;
              selectedFile = null;
              filePreview.value = null;
              console.error("Error uploading file:", error);
              console.error('Error uploading chunk', error);
              errormessage.value = 'Network error occurred during upload. Please try again.';
              break;
            }
          }

          // const formData = new FormData();
          // formData.append('file', selectedFile);
          // formData.append('data', JSON.stringify(messageData));
          // try {
          //   const response = await api.post('/upload', formData, {
          //     headers: {
          //       'Content-Type': 'multipart/form-data'
          //     }
          //   });
          //   if (response.data) {
          //     const message = response.data[0];
          //     const Message1 = {
          //       Message: text.value,
          //       TaskID: parseInt(TaskID),
          //       Sender: userCookie.UserType,
          //       SenderName: userCookie.Username,
          //       SenderId: userCookie.UserID,
          //       Reciever: "Admin",
          //       RecieverId: parseInt(AssignedBy),
          //       FileName: filename,
          //       FilePath: message.FilePath,
          //       FileType: message.FileType,
          //       TimestampHours: timestamp
          //     }
          //     // console.log(Message1)
          //     handleConnect(userCookie.Username);
          //     socket.send(JSON.stringify(Message1));
          //     messages.value.push(message);
          //   }
          //   // const response=JSON.parse(result)
          //   // messages.value.push(messageData);
          //   text.value = null;
          //   selectedFile = null;
          //   filePreview.value = null;
          // } catch (error) {
          //   text.value = null;
          //   selectedFile = null;
          //   filePreview.value = null;
          //   console.error("Error uploading file:", error);
          // }
        }

        else {
          socket.send(JSON.stringify(messageData));
          // console.log(messageData)
          messages.value.push(messageData);
          text.value = null;
          selectedFile = null;
          filePreview.value = null;
        }
      }
      IsLoading.value = false
    };

    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      let userCookie = Cookies.get("user");
      userCookie = userCookie ? JSON.parse(userCookie) : null;
      if (userCookie.UserID !== message.SenderId) {
        notifications.push({
          message: message.Message,
          icon: 'info',
          verticalAlign: 'top',
          horizontalAlign: 'center',
          type: 'info',
          timeout: 9000,
          timestamp: new Date(),
        });
      }
      messages.value.push(message);
    }
    return {
      text,
      errormessage,
      validFormat,
      connect,
      username,
      messages,
      notifications,
      filePreview,
      filename,
      filePreviewType,
      progress,
      IsLoading,
      getValidFormatsTitle,
      clearFileData,
      onFileSelected,
      handleConnect,
      sendMessage
    }
  },
  methods: {

    async getChatByTaskId(taskId) {
      try {
        this.loadingTasks = "Loading...";
        let userCookie = Cookies.get("user");
        userCookie = userCookie ? JSON.parse(userCookie) : null;
        this.UserID = userCookie.UserID;
        const uid = userCookie.UserID;
        const token = Cookies.get("jwt");
        setAuthToken(token);
        const response = await api.get(`/api/Chat/get-chat-by-task-id/${taskId}/${uid}`);
        if (response.data) {
          this.messages = response.data;
          // this.groupedMessages = this.groupMessagesByDate(this.messages)
          this.loadingTasks = "";
        }
        else {
          // console.log(response.data)
        }
      } catch (error) {
        handleSessionTimeout('/login')
      }
    },

    // groupMessagesByDate(messages) {
    //   const groupedMessages = {};

    //   messages.forEach(message => {
    //     const date = new Date(message.timestamp).toDateString();

    //     if (!groupedMessages[date]) {
    //       groupedMessages[date] = [];
    //     }

    //     groupedMessages[date].push(message);
    //   });

    //   return groupedMessages;
    // },
    removeNotification(index) {
      this.notifications.pop(index, 1);
    },
    selectFile() {
      this.$refs.fileInput.value = '';
      this.$refs.fileInput.click();
      this.$refs.textfield.focus();
    },
    isImage(filename) {
      const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg'];
      const extension = filename.toLowerCase().slice(filename.lastIndexOf('.'));
      return imageExtensions.includes(extension);
    },
    isVideo(filename) {
      const videoExtensions = ['.mp4', '.webm', '.avi', '.mov', '.mkv', '.flv', '.wmv', '.m4v', '.mpeg'];
      const extension = filename.toLowerCase().slice(filename.lastIndexOf('.'));
      return videoExtensions.includes(extension);
    },

    isPDF(filePath) {
      const pdfExtension = '.pdf';
      const fileExtension = filePath.slice(filePath.lastIndexOf('.')).toLowerCase();
      return fileExtension === pdfExtension;
    },
    isExcelSheet(filePath) {
      const excelExtension = '.xlsx';
      const fileExtension = filePath.slice(filePath.lastIndexOf('.')).toLowerCase();
      return fileExtension === excelExtension;
    },
    isCSV(filePath) {
      const csvExtension = '.csv';
      const fileExtension = filePath.slice(filePath.lastIndexOf('.')).toLowerCase();
      return fileExtension === csvExtension;
    }
  },
}
</script>


<style scoped>
/* Add Tailwind CSS classes here if needed */
.close-icon {
  cursor: pointer;
  float: right;
  font-size: 30px;
  margin-top: -10px;
}

/* chat message style */

.messageboxleft {
  width: fit-content;
  max-width: 70%;
  padding: 5px;
  background-color: #f4f8fb;
  border-radius: 10px;
  align-self: flex-start;
  margin-bottom: 10px;
}

.messageboxright {
  width: fit-content;
  max-width: 70%;
  padding: 5px;
  background-color: #f4f8fb;
  border-radius: 10px;
  align-self: flex-end;
  margin-bottom: 10px;
}


.timestamp {
  font-size: 10px;
}


.chat-box {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  overflow: auto;
  min-height: 500px;
  box-sizing: border-box;
  /* display: flex;
  flex-direction: column; */
}

.messages {
  min-height: 425px;
  /* flex-direction: column;
  display: flex; */
  overflow-y: auto;
}

.infostyle {
  background-color: red;
  color: white !important;
  font-size: 8px !important;
  border-radius: 50%;
  padding: 3px;
}

.message {
  display: flex;
  padding: 6px;
  /* background-color: #0084ff; */
}

.message:hover {
  background-color: #f4f8fb;
}

.received {
  font-weight: bold;
  align-items: flex-start;
  color: #535151;
  ;
  /* text-align: left; */
  /* background-color: #f4f8fb; */
  border-radius: 10px;
  max-width: 70%;
}

.sent {
  font-weight: bold;
  /* align-items: flex-end; */
  /* text-align: left !important; */
  color: #535151;
  /* background-color: #0084ff; */
  border-radius: 10px;
  max-width: 70%;
}

.input-box {
  display: flex;
  justify-content: space-between;
  align-items: bottom;
  position: absolute;
  width: 94%;
  bottom: 30px;
  margin-top: 10px;
}

.input-box input {
  flex: 1;
  margin-right: 5px;
}

.input-box button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}



.excel-preview-container {
  width: 100%;
  height: 50px;
  font-size: 12px;
  font-weight: bold;
}

/* chat message style */
.mb-3 {
  text-align: left;
}

.uploaded-video {
  width: 100%;
  height: 300px;
  align-items: end;
  justify-content: right;
  /* margin: 5px; */
  scroll-behavior: unset;
  border: 1px solid #ddd;
}

.uploaded-pdf {
  width: 100%;
  height: 100px;
  margin: 10px;
  scroll-behavior: unset;
  border: 1px solid #ddd;
}

.pdf-container {
  height: 130px;
  width: 250px;
  margin: 10px;
}

/* .pdf-preview-container {
  width: 250px;
  height: 120;
} */

/* .pdf-preview {
  width: 100%;
  height: 100%;
}
 */


/* 
.overlay {
  position: absolute;
  top: 25px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
} */

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

.image-preview,
.pdf-preview-container {
  position: relative;
  /* margin-top: 10px; */
  margin-bottom: 75px;
  width: 250px;
  height: 250px;
}

.uploaded-image,
.pdf-preview {
  width: 100%;
  height: 250px;
  border: 1px solid #ddd;
  padding: 5px;
  background: #f9f9f9;
}


.clearbutton {
  width: 20px;
  height: 20px;
  border: none;
  font-size: 20px;
  /* background-color: transparent; */
  position: relative;
  /* left: 230px; */
  top: 5px;
}

.actionBtns {
  text-align: right !important;
}

@media screen and (min-width:1020px) {
  #taskEditStatusModal .modal-dialog {
    width: 35%;
  }
}

.badge-primary {
  color: #4ba64f !important;
  font-size: 14px !important;
}

.progress {
  float: left;
}

.highlight-text {
  margin-left: 5px;
  margin-right: 8px;
}

.highlight-text:hover {
  color: blue;
  font-weight: 400;
}

.color-box {
  display: inline-block;
  padding-left: 6px;

  cursor: pointer;
  padding-top: 5px;
}

.no-scrollbar {
  scrollbar-width: none !important;
  /* Firefox */
  -ms-overflow-style: none !important;
  /* Internet Explorer 10+ */
}

.no-scrollbar::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}

/* .loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  position: relative;
  top: 0%;
  left: 0%;
 
  transform: translate(-50%, -50%);
  z-index: 1;
} */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* .overlay {
  position: relative;
  top: 150px;
  left: 0%;
  width: 250px;
  height: 150px;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
} */


.overlay {
  position: absolute;
  top: 25px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-text {
  position: absolute;
  color: green;
  font-weight: 300;
  font-size: 1.2em;
  /* Adjust the size as needed */
}
</style>
