<template>
  <div class="content" style="overflow-x:hidden;">
    <LoadingOverlay :loading="Isloading" />
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
        <md-card>
          <md-card-header data-background-color="green">
            <div class="card-header d-flex justify-content-between align-items-center">
              <h4 class="title">Error Log</h4>
              <div class="ms-auto">
                <input type="text" v-model="searchText" class="gridFilter" @keyup="filterGrid"
                  style="margin:5px 5px 0 0;" placeholder="Search...">
              </div>
            </div>
          </md-card-header>
          <md-card-content>
            <button hidden type="button" data-bs-toggle="modal" data-bs-target="#ErrorDetailsModal"
              data-bs-whatever="@mdo" class="btn btn-primary" title="View Error Details" id="openErrorDetailsModal">
              View Error Details
            </button>
            <div class="col-md-12" style="overflow-x:auto;">
              <!-- Display success alert -->
              <div v-if="successAlertOnSubmit" class="alert alert-success text-left" role="alert">
                {{ successAlertOnSubmit }}
                <span @click="hideAdminAlert" class="close-icon">&times;</span>
              </div>
              <div class="admin-table">
                <div v-if="loadingErrors" class="text-center">
                  <p>{{ loadingErrors }}</p>
                </div>
                <table style="width: 100%;" :table-header-color="tableHeaderColor">
                  <tr v-if="!loadingErrors" class="toprow">
                    <!-- <td style="width:4%" class="table-cell">S.N.</td> -->
                    <td style="width:35%;padding-left:5px;" class="table-cell">Message</td>
                    <!-- <td style="width:30%" class="table-cell">Error Stack</td> -->
                    <td style="width:22%" class="table-cell">Date and Time</td>
                    <td style="width:35%" class="table-cell">Location</td>
                    <!-- <td style="width:12%" class="table-cell">User Type</td> -->
                    <td style="width:15%" class="table-cell">Line No.</td>
                    <td style="width:15%" class="table-cell">Action</td>
                  </tr>
                  <tr slot="md-table-row" v-for="item in paginatedErrors" :key="item.id"
                    style="border-bottom:1px solid #ddd">
                    <!-- <td class="md-table-cell">{{ item.SN }}</td> -->
                    <td class="md-table-cell"><span v-if="item.ErrorMessage.length > 40" :title="item.ErrorMessage">
                        {{ truncateDescription(item.ErrorMessage) }}
                      </span>
                      <span v-else>
                        {{ item.ErrorMessage }}
                      </span>
                    </td>
                    <!-- <td class="md-table-cell">
                      <span v-if="item.ErrorStack.length > 100" :title="item.ErrorStack">
                        {{ truncateDescription(item.ErrorStack) }}
                      </span>
                      <span v-else>
                        {{ item.ErrorStack }}
                      </span>
                    </td> -->
                    <td class="md-table-cell">
                      {{
                        item.ErrorTime
                      }}
                    </td>
                    <td class="md-table-cell"><span v-if="item.functionname.length > 40" :title="item.functionname">
                        {{ truncateDescription(item.functionname) }}
                      </span>
                      <span v-else>
                        {{ item.functionname }}
                      </span>
                    </td>
                    <td class="md-table-cell">{{ item.ErrorLine }}</td>
                    <td class="md-table-cell">
                      <button @click="viewErrorDetail(item.ErrorLogID)" type="button"
                        class="btn btn-primary btn-sm ml-2" title="View Error Details">
                        <i class="fa fa-eye" aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>
                  <tr v-if="IsErrors" style="border-top: 1px solid #ccc;">
                    <td colspan="12" class="text-center">
                      <p style="padding-top:10px;padding-left:10px;">No data found.</p>
                    </td>
                  </tr>
                </table>
                <div class="d-flex my-1" v-if="!IsErrors">
                  <div style="width: 33%;" class="d-flex">
                    <span style="width: 40%;line-height:50px;">Errors per page:</span>
                    <Select @change="setPageSize" class="form-select PageSizeSelect" style="width: 30%;" v-model="pageSize">
                      <Option :value="5">5</Option>
                      <Option :value="10">10</Option>
                      <Option :value="20">20</Option>
                      <Option :value="30">30</Option>
                    </Select>
                  </div>
                  <div class="Pagination">
                    <button @click="previousPage" :disabled="currentPage === 1" title="Previous"
                      :class="{ 'disabled-button': currentPage === 1, 'enabled-button': currentPage !== 1 }"><i
                        class="fa fa-angle-left"></i> Prev</button>
                      <span class="current-page-indicator">{{ currentPage }}</span>
                    <button @click="nextPage" :disabled="currentPage === totalPages" title="Next"
                      :class="{ 'disabled-button': currentPage === totalPages, 'enabled-button': currentPage !== totalPages }">Next
                      <i class="fa fa-angle-right"></i></button>
                  </div>
                  <div style="width: 33%" class="d-flex justify-content-end mx-3">
                    <span style="line-height:50px;" class="text-right mx-1">Total Pages: </span>
                    <span style="line-height:50px;" class="text-right">{{ totalPages }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- Modal for to show admin detail -->
            <div class="modal fade" id="ErrorDetailsModal" tabindex="-1" aria-labelledby="ErrorDetailsLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="ErrorDetailsLabel">
                      Error Details
                    </h1>
                    <button class="btn-close" title="Close" data-bs-dismiss="modal" aria-label="Close" tabindex="-1"></button>
                  </div>
                  <div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="mb-3 d-flex flex-column">
                            <label class="col-form-label mb-0"><b>Message</b></label>
                            <label class="col-form-label">{{ Errors.ErrorMessage }}</label>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3 d-flex flex-column">
                            <label class="col-form-label bold"><b>Date and Time</b></label>
                            <label class="col-form-label">{{ Errors.ErrorTime }}</label>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="mb-3 d-flex flex-column">
                            <label class="col-form-label"><b>Location</b></label>
                            <label class="col-form-label">{{ Errors.functionname }}</label>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3 d-flex flex-column">
                            <label class="col-form-label"><b>Line Number</b></label>
                            <label class="col-form-label">{{ Errors.ErrorLine }}</label>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="mb-3 d-flex flex-column">
                            <label class="col-form-label"><b>Stack</b></label>
                            <label class="col-form-label">{{ Errors.ErrorStack }}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" title="Close" class="btn btn-secondary ml-2" data-bs-dismiss="modal" id="closeModal"
                        tabindex="8">
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <ConfirmDailogBox v-if="confirmationVisibleActive" :message="ActivationMessage"
              @confirmed="confirmEditErrorstatus" @canceled="hideConfirmation" />
            <ConfirmDailogBox v-if="confirmationVisibleDelete" :message="ActivationMessage"
              @confirmed="submitDeleteUserStatusForm" @canceled="hideConfirmation" /> -->
            <!-- Modal for confirm delete Admin -->
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import api, { setAuthToken } from "../apiConfig";
import { authMixin } from "./SuperAdminStore";
import Cookies from "js-cookie";
import LoadingOverlay from '../Common/blockoutpage.vue'
import ConfirmDailogBox from '../Common/confirmationbox.vue'
import { ErrorLog } from "../Common/APILog";
import { handleAuthorization, handleServerError } from "../Common/commonMethod";
export default {
  mixins: [authMixin],
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  created() {
    this.checkAuthorization();
  },
  name: "UserManagement",
  components: {
    LoadingOverlay,
    // ConfirmDailogBox,
  },
  data() {
    return {
      Errors: [],
      filterErrors:[],
      searchText: '',
      pageSize: 5, // Number of tasks per page
      currentPage: 1,// Current page
      IsErrors: false,
      Isloading: false,
      filteredErrors: [],
      userFormHeader: "",
      successAlertOnSubmit: "",
      editUserMode: false,
      loadingErrors: "",
    };
  },
  watch: {
    pageSize(newSize) {
      this.pageSize = newSize;
      this.currentPage = 1; // Reset to first page
      this.paginatedErrors();
    }
  },
  computed: {
    paginatedErrors() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.filteredErrors.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.filteredErrors.length / this.pageSize);
    },
    filteredData: function () {
      console.log("filter");
      const searchText = this.searchText.toLowerCase();
      return this.tasks.filter(item => {
        return (
          item.Title.toLowerCase().includes(searchText) ||
          item.Description.toString().includes(searchText)
        );
      });
    }
  },
  mounted() {
    this.getErrors();
  },
  methods: {
    filterData: function (x) {
      this.IsErrors = false;
      if (parseInt(x) != 5 && parseInt(x) != 6) {
        this.filteredErrors = this.Errors.filter(item => {
          return (
            item.UserID == parseInt(x)
          );
        });
        if (this.filteredErrors.length > 0) {
          this.filteredErrors = this.filteruser;
        }
        else {
          console.log("ok");
          this.filteredErrors = [];
          this.IsErrors = true;
        }
      }
      if (parseInt(x) == 5) {
        this.filteredErrors = this.Errors.filter(item => {
          return (
            item.IsErrors == 1
          );
        });
        if (this.filteredErrors.length > 0) {
          this.filteredErrors = this.filteruser;
        }
        else {
          console.log("ok");
          this.filteredErrors = [];
          this.IsErrors = false;
        }
      }
      if (parseInt(x) == 6) {
        this.filteredErrors = this.Errors;
      }
      // Filter Errors based on searchText
    },
    filterGrid: function () {
      var searchText = this.searchText;
      this.IsErrors = false;
      if (parseInt(searchText) > 0){
        this.filterErrors = this.Errors.filter(item => {
          return (
            item.ErrorLogID == parseInt(searchText)
          );
        });
        if (this.filterErrors.length > 0) {
          this.filteredErrors = this.filterErrors;
        }
        else {
          console.log("ok");
          this.filteredErrors = [];
          this.IsErrors = true;
        }
      }
      else {
        searchText = searchText.toLowerCase();
        this.filterErrors = this.Errors.filter(item => {
          return (
            item.ErrorMessage.toLowerCase().includes(searchText) ||
            item.ErrorStack.toLowerCase().includes(searchText) ||
            item.ErrorLine.toLowerCase().includes(searchText) ||
            item.functionname.toLowerCase().includes(searchText)
          );
        });
        if (this.filterErrors.length > 0) {
          this.filteredErrors = this.filterErrors;
        }
        else {
          console.log("ok");
          this.filteredErrors = [];
          this.IsErrors = true;
        }
      }
      // Filter Errors based on searchText
    },

    truncateDescription(description) {
      // Truncate the description if it's longer than 30 characters
      return description.length > 50
        ? description.slice(0, 50) + "..."
        : description;
    },

    async getErrors() {
      this.Errors = [];
      this.Isloading = true;
      this.filteredErrors = [];
      this.loadingErrors = "Loading...";
      try {
        const token = Cookies.get("jwt");
        setAuthToken(token);
        let userCookie = Cookies.get("user");
        userCookie = userCookie ? JSON.parse(userCookie) : null;
        const response = await api.get(
          `/api/errorLog/get-error-log`
        );
        this.Isloading = false;
        if (response.data) {
          this.Errors = response.data;
          console.log(this.Errors);
          this.filteredErrors = this.Errors;
        } else {
          this.userFormHeader = "Data not loaded.";
          // Handle case where no Errors are found
        }
      } catch (error) {
        console.log(error);
         if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.loadingErrors = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.loadingErrors = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 378, error.stack, "SuperAdminPanel/ErrorLog/getErrors");
          }
        }
        else {
          this.loadingErrors = "Something went wrong.";
        }
      } finally {
        if (this.Errors.length > 0) {
          this.loadingErrors = "";
        } else {
          this.Isloading = false;
          this.loadingErrors = "No Errors found.";
        }
      }
    },
    viewErrorDetail(ErrorLogID) {
      this.getErrorDetailsById(ErrorLogID)
    },
    async getErrorDetailsById(ErrorLogID) {
      try {
        const token = Cookies.get("jwt");
        setAuthToken(token);
        const response = await api.get(`/api/errorLog/get-error-log-by-Id/${ErrorLogID}`);
        if (response.data) {
          this.Errors = response.data[0];
          this.openErrorDetailsModal()
        } else {
          this.userFormHeader = "Error: " + response.statusText;
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.loadingErrors = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.loadingErrors = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 423, error.stack, "SuperAdminPanel/ErrorLog/getErrorDetailsById");
          }
        }
        else {
          this.loadingErrors = "Something went wrong.";
        }
      }
    },
    setPageSize(event) {
      const selectedSize = event.target.value;
      this.pageSize = parseInt(selectedSize);
      this.currentPage = 1; // Reset to the first page
      this.paginatedErrors();
      // alert(`Page size changed to: ${selectedSize}`);
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    async hideMessage() {
      setTimeout(() => {
        this.userFormHeader = "";
        this.successAlertOnSubmit = "";
      }, 7000);
    },
    openErrorDetailsModal() {
      document.getElementById("openErrorDetailsModal").click();
    },
  },
};
</script>
<style scoped>
/* Add Tailwind CSS classes here if needed */
.close-icon {
  cursor: pointer;
  float: right;
  font-size: 30px;
  margin-top: -10px;
}

.mb-3 {
  text-align: left;
}
.PageSizeSelect{
  border-radius: 5px;
  margin: 5px;
  height: 35px;
 font-size: 17px;
}
.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1em;
}
.current-page-indicator {
  background-color: green;
  border-radius: 50%; /* Ensures the element is rounded */
  padding: 3px;
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 25px; /* Adjust this value as needed */
  height: 25px; /* Ensure width and height are equal */
}

.expired-row {
  background-color: #dc8484;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.table th {
  background-color: #f2f2f2;
}

/* Additional styling for left and right padding */
.table th,
.table td {
  padding-left: 12px;
  padding-right: 12px;
}

.Pagination {
  width: 34%;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.Pagination button {
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 17px;
}

.enabled-button {
  background-color: #007bff;
  /* Blue color for enabled button */
  color: #ffffff;
  /* White text color for enabled button */
  border: 2px solid #007bff
}

.disabled-button {
  background-color: lightgray;
  /* Gray color for disabled button */
  color: #ffffff;
  /* White text color for disabled button */
  border: 2px solid lightgray;
}

.user-list {
  min-height: 120px;
  max-height: 120px;
  /* Set a maximum height for the user list */
  overflow-y: auto;
  /* Add a scrollbar when the content exceeds the height */
  border: 1px solid #ccc;
  /* Optional: Add a border for styling */
}

.user-item {
  margin-bottom: 8px;
  /* Optional: Add some spacing between user items */
}

.actionBtns {
  text-align: right !important;
}
.toprow{
  color: #fff;
    background: #0d6efd;
    height: 35px;
    font-weight: 500;
    font-size: 16px;
}
@media screen and (min-width:1020px) {
  #userEditStatusModal .modal-dialog {
    width: 35%;
  }
}

.alreadyAssignedText {
  color: #ed6524;
  font-size: 15px;
  text-align: center;
}

.dropdown {
  width: 100%;
  height: 36px;
  padding: 7px 0;
  font-size: 14px;
  line-height: 1.428571429;
  border-color: #D2D2D2;
}

.progress {
  float: left;
}

.highlight-text {
  margin-left: 5px;
  margin-right: 8px;
}

.color-box {
  display: inline-block;
  padding-left: 6px;
  padding-top: 5px;
}

.highlight-text {
  margin-left: 5px;
  margin-right: 8px;
}

.highlight-text:hover {
  color: blue;
  font-weight: 400;
}

.color-box {
  display: inline-block;
  padding-left: 6px;
  cursor: pointer;
  padding-top: 5px;
}

.modal-content .modal-header {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.modal-title {
  margin-top: 10px;
}

.modal-content .modal-body {
  padding-top: 10px;
}

.admin-table {
  width: 100%;
}

@media screen and (min-width:990px) and (max-width: 1185px) {
  .admin-table {
    width: 1100px;
    overflow-x: auto
  }
}

@media screen and (max-width: 890px) {
  .admin-table {
    width: 830px;
    overflow-x: auto
  }
}
</style>