<template>
  <div class="content">
    <LoadingOverlay :loading="Isloading" />
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
        <md-card>
          <md-card-header data-background-color="green">
            <div class="card-header d-flex justify-content-between align-items-center">
              <h4 class="title">Project Management</h4>
              <div class="ms-auto">
                <input type="text" v-model="searchText" class="gridFilter" @keyup="filterGrid"
                  style="margin:5px 5px 0 0;" placeholder="Search...">
                <button type="button" data-bs-toggle="modal" data-bs-target="#categoryModal"
                  class="btn btn-primary btn-sm" id="openCategoryModal" @click="openCategoryModal(true)">
                  Add New Project
                </button>

                <button hidden type="button" data-bs-toggle="modal" data-bs-target="#categoryModal"
                  class="btn btn-primary" id="openModal" @click="openModal()">
                  Add New Category
                </button>
                <button hidden type="button" data-bs-toggle="modal" data-bs-target="#categoryDeleteConfirmModal"
                  data-bs-whatever="@mdo" class="btn btn-primary" id="openDeleteCategoryModal">
                  Delete category
                </button>
                <button hidden type="button" data-bs-toggle="modal" data-bs-target="#ChartModal" data-bs-whatever="@mdo"
                  class="btn btn-primary" id="openChartModal">
                  Open Chart
                </button>
              </div>
            </div>
          </md-card-header>
          <md-card-content>
            <div class="col-md-12">
              <div v-if="successAlertOnSubmit" class="alert alert-success text-left" role="alert">
                {{ successAlertOnSubmit }}
                <span @click="hideCategoryAlert" class="close-icon">&times;</span>
              </div>
              <div v-if="errorAlertOnSubmit" class="alert alert-danger text-left" role="alert">
                {{ errorAlertOnSubmit }}
                <span @click="hideCategoryAlert" class="close-icon">&times;</span>
              </div>
              <div class="table-responsive">
                <div v-if="loadingCategories" class="text-center">
                  <p>{{ loadingCategories }}</p>
                </div>
                <table style="width: 100%;" v-if="!loadingCategories" :table-header-color="tableHeaderColor">
                  <tr class="toprow">
                  <td style="width:25%;padding-left:5px;" class="table-cell">Project Name</td>
                    <td style="width:25%;padding-left:5px;" class="table-cell">Progress Percent</td>
                    <td style="width:32%;padding-left:5px;" class="table-cell">Task Count</td>
                    <td style="width:18%;text-align:right;padding-right:10px;" class="table-cell">Actions</td>
                  </tr>
                  <tr slot="md-table-row" v-for="item in paginatedCategory" :key="item.id" class=""
                    style="border-bottom: 1px solid #ddd;">
                    <td class="md-table-cell">
                      {{ item.CategoryName }}
                    </td>
                    <td class="md-table-cell">
                      <progress-bar :options="options" :value="item.ProgressPercent[1]" />
                      <!-- <span v-if="item.IsCompleted!=0"> {{ item.ProgressPercent }}</span> -->
                    </td>
                    <td class="md-table-cell">
                      <button :disabled="item.NotAssigned===0" @click="showTaskbyProject(item.CategoryID,1)" type="button"
                        class="btn btn-dark btn-sm ml-2" title="Not Assigned">
                        {{ formatTaskCount(item.NotAssigned) }}
                      </button>
                      <button :disabled="item.Assigned===0" @click="showTaskbyProject(item.CategoryID,2)" type="button"
                        class="btn btn-primary btn-sm ml-2" title="Assigned">
                        {{ formatTaskCount(item.Assigned) }}
                      </button>
                      <button :disabled="item.InProcess===0" @click="showTaskbyProject(item.CategoryID,3)" type="button"
                        class="btn btn-info btn-sm ml-2" title="In Process">
                        {{ formatTaskCount(item.InProcess) }}
                      </button>
                      <button :disabled="item.Completed===0" @click="showTaskbyProject(item.CategoryID,4)" type="button"
                        class="btn btn-success btn-sm ml-2" title="Completed">
                        {{ formatTaskCount(item.Completed) }}
                      </button>
                      <button :disabled="item.TaskCount===0" @click="showTaskbyProject(item.CategoryID,0)" type="button"
                        class="btn btn-primary btn-sm ml-2" title="Tasks" :class="{ blink: item.TaskCount !== 0 }">
                        {{ formatTaskCount(item.TaskCount) }}
                      </button>
                    </td>
                    <td class="md-table-cell" style="text-align:right">
                      <button :disabled="item.TaskCount===0" :class="{ blink: item.TaskCount !== 0 }" @click="openChartModal(item.CategoryID)" type="button" class="btn btn-info btn-sm ml-2"
                        title="Chart">
                        <i class="fas fa-chart-pie"></i>
                      </button>
                      <button @click="editCategory(item.CategoryID)" type="button" class="btn btn-primary btn-sm ml-2"
                        title="Edit">
                        <i class="fas fa-pencil-alt"></i>
                      </button>
                      <button @click="openDeleteModal(item.CategoryID)" type="button" class="btn btn-danger btn-sm"
                        title="Delete">
                        <md-icon style="color: white">delete</md-icon>
                      </button>
                    </td>
                  </tr>
                </table>
                <div class="d-flex my-1" v-if="!IsCategory">
                  <div style="width: 33%;" class="d-flex">
                    <span style="width: 50%;line-height:50px;">Projects per page:</span>
                    <Select @change="setPageSize" class="form-select PageSizeSelect" style="width: 30%;"
                      v-model="pageSize">
                      <!-- <Option :value="5">5</Option> -->
                      <Option :value="10">10</Option>
                      <Option :value="20">20</Option>
                      <Option :value="30">30</Option>
                    </Select>
                  </div>
                  <div class="Pagination">
                    <button @click="previousPage" :disabled="currentPage === 1"
                      :class="{ 'disabled-button': currentPage === 1, 'enabled-button': currentPage !== 1 }"><i
                        class="fa fa-angle-left"></i> Prev</button>
                    <span class="current-page-indicator">{{ currentPage }}</span>
                    <button @click="nextPage" :disabled="currentPage === totalPages"
                      :class="{ 'disabled-button': currentPage === totalPages, 'enabled-button': currentPage !== totalPages }">Next
                      <i class="fa fa-angle-right"></i></button>
                  </div>
                  <div style="width: 33%" class="d-flex justify-content-end mx-3">
                    <span style="line-height:50px;" class="text-right mx-1">Total Pages: </span>
                    <span style="line-height:50px;" class="text-right">{{ totalPages }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal fade" id="categoryModal" tabindex="-1" aria-labelledby="categoryModalLabel"
              aria-hidden="true" @hidden="handleCategoryModalHidden">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="categoryModalLabel">
                      {{
                        editCategoryMode
                          ? "Edit Project"
                          : "Add New Project"
                      }}
                    </h1>
                    <button class="btn-close" data-bs-dismiss="modal" aria-label="Close" tabindex="-1"></button>
                  </div>
                  <form @submit.prevent="submitCategoryForm">
                    <input v-model="newCategory.CategoryID" type="hidden" class="form-control" />
                    <div class="modal-body">
                      <div v-if="categoryFormHeader" class="alert alert-danger text-left" role="alert">
                        {{ categoryFormHeader }}
                        <span @click="hideCategoryAlert" class="close-icon">&times;</span>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="mb-3">
                            <label for="name" class="col-form-label">Project Name</label>
                            <input v-model="newCategory.Name" type="text" class="form-control" maxlength="30" id="name"
                              tabindex="1" @input="isValidCategoryForm" />
                            <span class="text-red-500 text-sm">{{
                              categoryValidate.Name
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary ml-2" data-bs-dismiss="modal" title="Close"
                        tabindex="5">
                        Close
                      </button>
                      <button type="submit" class="btn btn-primary" :title="editCategoryMode ? 'Update' : 'Add'" default
                        tabindex="4" :disabled="btnCategoryEnable">
                        {{ editCategoryMode ? "Update" : "Add" }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!-- Modal for confirm delete category -->
            <div class="modal fade" id="categoryDeleteConfirmModal" tabindex="-1"
              aria-labelledby="DeleteConfirmModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-md">
                <div class="modal-content">
                  <form @submit.prevent="submitDeleteCategoryForm">
                    <input v-model="newCategory.CategoryID" type="hidden" class="form-control" />
                    <div class="modal-body">
                      <div class="row">
                        <h4>Are you sure you want to delete {{ newCategory.CategoryName }} Project?</h4>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary ml-2" title="No" data-bs-dismiss="modal"
                        id="closeDeleteConfirmCategoryModal" tabindex="8">
                        No
                      </button>
                      <button type="submit" class="btn btn-danger" title="No" tabindex="7"
                        :disabled="btnCategoryEnable">
                        Yes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="modal fade" id="ChartModal" tabindex="-1" aria-labelledby="ChartModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-md">
                <div class="modal-content">
                  <div id="chart">
                    <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import api, { setAuthToken } from "../apiConfig";
import Vue from "vue";
import { authMixin } from "./adminStore";
import Cookies from "js-cookie";
import { ErrorLog } from "../Common/APILog";
import LoadingOverlay from '../Common/blockoutpage.vue';
import ProgressBar from 'vuejs-progress-bar';
import VueApexCharts from "vue-apexcharts";
Vue.use(ProgressBar)
export default {
  mixins: [authMixin],
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  created() {
    this.checkAuthorization();
  },
  name: "CategoryManagement",
  data() {
    return {
      name: "Project",
      categories: [],
      options: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false
        },
        progress: {
          color: '#43a047',
          backgroundColor: '#464a4e',
          inverted: false
        },
        layout: {
          height: 25,
          width: 140,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: 'line'
        }
      },
      IsCategory: false,
      filterCategories: [],
      filterCategory: [],
      searchText: '',
      pageSize: 10, // Number of categories per page
      currentPage: 1,// Current page
      parentCategoriesDropdown: [],
      newCategory: {
        CategoryID: 0,
        Name: "",
        ParentCategoryID: null,
        OrderBy: 0,
      },
      categoryValidate: {
        Name: "",
      },
      categoryFormHeader: "",
      successAlertOnSubmit: "",
      errorAlertOnSubmit: "",
      editCategoryMode: false,
      loadingCategories: "",
      btnCategoryEnable: false,
      Isloading: false,
      ///chart parameter

      series: [{
        name: 'Assigned',
        type: 'column',
        data: []
      },
      {
        name: 'In Process',
        type: 'column',
        data: []
      },
      {
        name: 'Completed',
        type: 'column',
        data: []
      }
      ],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 430
        },
        plotOptions: {
          bar: {
            vertical: true,
            dataLabels: {
              position: 'top',
            },
          }
        },
        dataLabels: {
          enabled: false,
          offsetX: 0,
          style: {
            fontSize: '8px',
            colors: ['#fff']
          }
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['#fff']
        },
        title: {
              text: 'Project Wise Task Analysis',
              align: 'left',
              offsetX: 110
            },
        tooltip: {
          shared: true,
          intersect: false
        },
        xaxis: {
          categories: [],
          labels: {
            rotate:-45,   
            trim:true,
            rotateAlways: true,
            style: {
              colors: ['#008FFB', '#00E396', '#775DD0', '#FEB019', '#FF4560', '#546E7A', '#008FFB', '#00E396', '#775DD0'],
              padding:10
            }
          }
        },
      },
    };
  },
  computed: {
    paginatedCategory() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.filterCategories.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.filterCategories.length / this.pageSize);
    },
    filteredData: function () {
      console.log("filter");
      const searchText = this.searchText.toLowerCase();
      return this.tasks.filter(item => {
        return (
          item.CategoryName.toLowerCase().includes(searchText) ||
          item.CategoryName.toString().includes(searchText)
        );
      });
    }
  },
  watch: {
    pageSize(newSize) {
      this.pageSize = newSize;
      this.currentPage = 1; // Reset to first page
      this.paginatedCategory();
    }
  },
  components: {
    LoadingOverlay,
    apexchart: VueApexCharts,
  },
  async mounted() {
    this.getCategories();
    //this.fetchParentCategoriesDropdown();
    const token = await Cookies.get("jwt");
    setAuthToken(token);
  },
  methods: {
    handleCategoryModalHidden() {
      this.editCategoryMode = false;
    },
    openModal() {
      document.getElementById("openModal").click();
    },
    openCategoryModal(refresh = false) {
      if (refresh) {
        this.editCategoryMode = false;
        this.newCategory = {
          CategoryID: 0,
          Name: "",
          ParentCategoryID: 0,
          OrderBy: 0,
        };
        this.resetCategoryValidation();
      }
      document.getElementById("openCategoryModal").click();
    },
    closeDeleteConfirmCategoryModal() {
      document.getElementById("closeDeleteConfirmCategoryModal").click();
    },
    openDeleteModal(categoryId) {
      this.newCategory.CategoryID = categoryId;
      var filterCat = this.categories.filter(item => {
        return (
          item.CategoryID == parseInt(categoryId)
        );

      });
      this.newCategory.CategoryName = filterCat[0].CategoryName;
      console.log(filterCat[0].CategoryName);
      document.getElementById("openDeleteCategoryModal").click();
    },
    async showTaskbyProject(categoryId,type) {
      this.$router.push({ path: `/projectTasks/${categoryId}/${type}` })
      console.log("hello: " + categoryId)
    },
    formatTaskCount(taskCount) {
      return taskCount < 10 ? '0' + taskCount : taskCount;
    },
    async openChartModal(ProjectId) {
      await this.getChartData(ProjectId)
      document.getElementById("openChartModal").click();
    },
    async getChartData(ProjectId) {
      try {
        this.Isloading = true;
        console.log("projectId: " + ProjectId)
        const token = await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie = await Cookies.get("user");
        userCookie = await userCookie ? JSON.parse(userCookie) : null;
        const response = await api.get(`/api/admin/tasks/get-project-chart-data/${userCookie.UserID}/${ProjectId}`);
        console.log(response);
        if (response.data) {
          console.log("this is chart data: " + response.data);
          const usernames = await response.data.map(user => user.FirstName);
          const totalCompletedTasks = await response.data.map(user => user.TotalCompletedTask);
          const totalInProcessTasks = await response.data.map(user => user.TotalInProcessTask);
          const totalAssignedTasks = await response.data.map(user => user.TotalAssignedTask);
          console.log("Username: " + usernames);
          console.log("totalCompletedTasks: " + totalCompletedTasks)
          console.log("totalInProcessTasks: " + totalInProcessTasks)
          console.log("totalNotAssignedTasks: " + totalAssignedTasks)
          this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          ...this.chartOptions.xaxis,
          categories: usernames
        }
      };
      console.log("Updated categories: " + this.chartOptions.xaxis.categories);

      // Clone series to trigger reactivity
      this.series = [
        { ...this.series[0], data: totalAssignedTasks },
        { ...this.series[1], data: totalInProcessTasks },
        { ...this.series[2], data: totalCompletedTasks }
      ];
      console.log("Updated series: ", this.series);

          this.Isloading = false;
        } else {
          // Handle case where no categories are found
        }
      } catch (error) {
        // Handle API error
        console.log(error)
        ErrorLog(error.message, error.statuscode, 422, error.stack, "Admin Panel: Category / getCategories()");
        if (error.response && error.response.data) {
          const jsonResponseString = JSON.stringify(error.response.data);
          try {
            const jsonObject = JSON.parse(jsonResponseString);
            this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
          } catch (parseError) {
            this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
          }
        } else {
          this.errorAlertOnSubmit = "Something went wrong.";
        }
      } finally {
        console.log(this.categories.length);
        this.Isloading = false;
        setTimeout(() => {
          this.errorAlertOnSubmit = "";
          this.successAlertOnSubmit = "";
          this.categoryFormHeader = "";
        }, 5000); // 5 seconds in milliseconds
        if (this.categories.length > 0) {
          this.loadingCategories = "";
        } else {
          this.loadingCategories = "No record found.";
        }
      }
    },
    async getCategories() {
      this.categories = [];
      this.Isloading = true;
      this.loadingCategories = "Loading...";
      try {
        const token = await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie = await Cookies.get("user");
        userCookie = await userCookie ? JSON.parse(userCookie) : null;
        const response = await api.get(`/api/categories/get-categories/${userCookie.UserID}`);
        if (response.data) {
          this.categories = response.data;
          this.filterCategories = this.categories;
          console.log(response.data);
        } else {
          // Handle case where no categories are found
        }
      } catch (error) {
        // Handle API error
        ErrorLog(error.message, error.statuscode, 422, error.stack, "Admin Panel: Category / getCategories()");
        if (error.response && error.response.data) {
          const jsonResponseString = JSON.stringify(error.response.data);
          try {
            const jsonObject = JSON.parse(jsonResponseString);
            this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
          } catch (parseError) {
            this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
          }
        } else {
          this.errorAlertOnSubmit = "Something went wrong.";
        }
      } finally {
        console.log(this.categories.length);
        this.Isloading = false;
        setTimeout(() => {
          this.errorAlertOnSubmit = "";
          this.successAlertOnSubmit = "";
          this.categoryFormHeader = "";
        }, 5000); // 5 seconds in milliseconds
        if (this.categories.length > 0) {
          this.loadingCategories = "";
        } else {
          this.loadingCategories = "No record found.";
        }
      }
    },

    async getCategoryById(categoryId) {
      try {
        const token = await Cookies.get("jwt");
        this.Isloading = true;
        setAuthToken(token);
        const response = await api.get(
          `/api/categories/get-category-by-id/${categoryId}`
        );
        if (response.data) {
          this.newCategory = response.data[0];
          this.openModal();
          this.resetCategoryValidation();
        } else {
          this.errorAlertOnSubmit = response.statusText;
        }
        // Continue with any other logic (e.g., navigate to a different page)
      } catch (error) {
        //console.log(error);
        ErrorLog(error.message, error.statuscode, 476, error.stack, "Admin Panel: Category / getCategoryById(categoryId)");
        if (error.response && error.response.data) {
          const jsonResponseString = JSON.stringify(error.response.data);
          try {
            const jsonObject = JSON.parse(jsonResponseString);
            this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
          } catch (parseError) {
            this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
          }
        } else {
          this.errorAlertOnSubmit = "Something went wrong.";
        }
      } finally {
        this.Isloading = false;
        setTimeout(() => {
          this.errorAlertOnSubmit = "";
          this.successAlertOnSubmit = "";
          this.categoryFormHeader = "";
        }, 5000); // 5 seconds in milliseconds
      }
    },
    async createCategory() {
      this.categoryFormHeader = "";
      this.Isloading = true;
      try {
        this.btnCategoryEnable = true;
        const token = await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie = await Cookies.get("user");
        userCookie = await userCookie ? JSON.parse(userCookie) : null;
        const response = await api.post("/api/categories/create-category", {
          Name: this.newCategory.Name,
          ParentCategoryID: 0,
          UserId: userCookie.UserID,
          OrderBy: 0,
        });

        if (response.data.success) {
          this.getCategories();
          this.openCategoryModal(); // hide modal after successful submit.
          this.successAlertOnSubmit = "Project added successfully.";
          this.categoryFormHeader = "";
          this.errorAlertOnSubmit = "";
        } else {
          this.categoryFormHeader = response.data.msg;
          this.errorAlertOnSubmit = "";
          this.successAlertOnSubmit = "";
        }
      } catch (error) {
        // Handle API error
        ErrorLog(error.message, error.statuscode, 518, error.stack, "Admin Panel: Category / createCategory()");
        if (error.response && error.response.data) {
          const jsonResponseString = JSON.stringify(error.response.data);
          try {
            const jsonObject = JSON.parse(jsonResponseString);
            this.categoryFormHeader = jsonObject.message || "Something went wrong.";
          } catch (parseError) {
            this.categoryFormHeader = parseError.message || "Something went wrong.";
          }
        } else {
          this.categoryFormHeader = "Something went wrong.";
        }
      } finally {
        this.Isloading = false;
        setTimeout(() => {
          this.errorAlertOnSubmit = "";
          this.successAlertOnSubmit = "";
          this.categoryFormHeader = "";
        }, 5000); // 5 seconds in milliseconds
        this.btnCategoryEnable = false;
      }
    },
    editCategory(categoryId) {
      this.editCategoryMode = true;
      this.getCategoryById(categoryId);
    },
    async updateCategory() {
      this.categoryFormHeader = "";
      this.Isloading = true;
      try {
        this.btnCategoryEnable = true;
        const token = await Cookies.get("jwt");
        setAuthToken(token);
        const response = await api.put(
          `/api/categories/update-category/${this.newCategory.CategoryID}`,
          {
            Name: this.newCategory.Name,
            ParentCategoryID: 0,
            OrderBy: 0,
          }
        );

        if (response.data.success) {
          this.getCategories();
          this.openCategoryModal(); // hide modal after successful submit.
          this.errorAlertOnSubmit = "";
          this.successAlertOnSubmit = "Project updated successfully.";


        } else {
          this.categoryFormHeader = response.data.msg;
          this.errorAlertOnSubmit = "";
        }
      } catch (error) {
        ErrorLog(error.message, error.statuscode, 570, error.stack, "Admin Panel: Category / updateCategory()");
        if (error.response && error.response.data) {
          const jsonResponseString = JSON.stringify(error.response.data);
          try {
            const jsonObject = JSON.parse(jsonResponseString);
            this.categoryFormHeader = jsonObject.message || "Something went wrong.";
          } catch (parseError) {
            this.categoryFormHeader = parseError.message || "Something went wrong.";
          }
        } else {
          this.categoryFormHeader = "Something went wrong.";
        }
      } finally {
        this.Isloading = false;
        this.btnCategoryEnable = false;
        setTimeout(() => {
          this.errorAlertOnSubmit = "";
          this.successAlertOnSubmit = "";
          this.categoryFormHeader = "";
        }, 5000); // 5 seconds in milliseconds
      }
    },
    async deleteCategory(categoryId) {
      try {
        this.Isloading = true;
        const token = await Cookies.get("jwt");
        setAuthToken(token);
        this.btnCategoryEnable = true;
        const response = await api.delete(
          `/api/categories/delete-category/${categoryId}`
        );

        if (response.status == 200) {
          //console.log(response);
          if (response.data.success) {
            this.getCategories();
            //this.closeDeleteConfirmCategoryModal();
            this.successAlertOnSubmit = "Project deleted successfully.";
            this.errorAlertOnSubmit = "";
          } else {
            this.errorAlertOnSubmit = response.data.msg;
            this.successAlertOnSubmit = "";
          }
        } else {
          alert(response.statusText);
        }
      } catch (error) {
        // Handle API error
        ErrorLog(error.message, error.statuscode, 616, error.stack, "Admin Panel: Category / deleteCategory(categoryId)");
        if (error.response && error.response.data) {
          const jsonResponseString = JSON.stringify(error.response.data);
          try {
            const jsonObject = JSON.parse(jsonResponseString);
            this.successAlertOnSubmit = jsonObject.message || "Something went wrong.";
          } catch (parseError) {
            this.successAlertOnSubmit = parseError.message || "Something went wrong.";
          }
        } else {
          this.errorAlertOnSubmit = error.message;
        }
      }
      finally {
        this.Isloading = false;
        this.closeDeleteConfirmCategoryModal();
        this.btnCategoryEnable = false;
        setTimeout(() => {
          this.errorAlertOnSubmit = "";
          this.successAlertOnSubmit = "";
          this.categoryFormHeader = "";
        }, 5000); // 5 seconds in milliseconds
      }
    },

    submitDeleteCategoryForm() {
      this.deleteCategory(this.newCategory.CategoryID);
    },
    isValidCategoryForm() {
      let isValid = true;

      // Name
      if (!this.newCategory.Name.trim()) {
        this.categoryValidate.Name = "Project name is required";
        isValid = false;
      }
      else {
        this.categoryValidate.Name = "";
        isValid = true;
      }
      if (this.newCategory.Name.length > 30) {
        this.categoryValidate.Name = "max length is 30";
        isValid = false;
      }


      return isValid;
    },

    async submitCategoryForm() {
      this.resetCategoryValidation();

      if (this.isValidCategoryForm()) {
        this.newCategory.ParentCategoryID =
          this.newCategory.ParentCategoryID === "null"
            ? null
            : this.newCategory.ParentCategoryID;
        this.newCategory.CategoryID === 0
          ? this.createCategory()
          : this.updateCategory();
      }
    },

    resetCategoryValidation() {
      this.categoryValidate = {
        Name: "",
      };
    },
    filterData: function (x) {
      this.IsCategory = false;
      if (parseInt(x) != 5 && parseInt(x) != 6) {
        this.filterCategory = this.categories.filter(item => {
          return (
            item.CategoryID == parseInt(x)
          );
        });
        if (this.filterCategory.length > 0) {
          this.filterCategories = this.filterCategory;
        }
        else {
          console.log("ok");
          this.filterCategories = [];
          this.IsCategory = true;
        }
      }
      if (parseInt(x) == 5) {
        this.filterCategory = this.categories.filter(item => {
          return (
            this.IsCategory = true
          );

        });
        if (this.filterCategory.length > 0) {
          this.filterCategories = this.filterCategory;
        }
        else {
          console.log("ok");
          this.filterCategories = [];
          this.IsCategory = true;
        }
      }
      if (parseInt(x) == 6) {
        this.filterCategories = this.categories;
      }
      // Filter categories based on searchText
    },
    filterGrid: function () {
      var searchText = this.searchText;
      this.IsCategory = false;
      if (parseInt(searchText) > 0) {
        this.filterCategory = this.categories.filter(item => {
          return (
            item.UserID == parseInt(searchText)
          );
        });
        if (this.filterCategory.length > 0) {
          this.filterCategories = this.filterCategory;
        }
        else {
          console.log("ok");
          this.filterCategories = [];
          this.IsCategory = true;
        }
      }
      else {
        searchText = searchText.toLowerCase();
        this.filterCategory = this.categories.filter(item => {
          return (
            item.CategoryName.toLowerCase().includes(searchText)
          );
        });
        if (this.filterCategory.length > 0) {
          this.filterCategories = this.filterCategory;
        }
        else {
          console.log("ok");
          this.filterCategories = [];
          this.IsCategory = true;
        }
      }
    },

    hideCategoryAlert() {
      this.categoryFormHeader = "";
      this.successAlertOnSubmit = "";
      this.errorAlertOnSubmit = "";
    },
    setPageSize(event) {
      const selectedSize = event.target.value;
      this.pageSize = parseInt(selectedSize);
      this.currentPage = 1; // Reset to the first page
      this.paginatedCategory();
      // alert(`Page size changed to: ${selectedSize}`);
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
  },
};
</script>

<style scoped>
/* Add Tailwind CSS classes here if needed */
/* Add additional styling as needed for PriorityManagement */
.close-icon {
  cursor: pointer;
  float: right;
  font-size: 30px;
  margin-top: -10px;
}

.mb-3 {
  text-align: left;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1em;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.table-cell {
  font-size: 1.0625rem;
  padding-bottom: 10px;
  padding-top: 8px;
  font-weight: 500;
}

.table th {
  background-color: #f2f2f2;
}

/* Additional styling for left and right padding */
.table th,
.table td {
  padding-left: 12px;
  padding-right: 12px;
}

.actionBtns {
  text-align: right !important;
  width: 200px !important;
}

.modal-content .modal-header {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.modal-title {
  margin-top: 10px;
}

.modal-content .modal-body {
  padding-top: 10px;
}

.toprow {
  color: #fff;
  background: #0d6efd;
  height: 35px;
  font-weight: 500;
  font-size: 16px;
}

.dropdown {
  width: 100%;
  height: 36px;
  padding: 7px 0;
  font-size: 14px;
  line-height: 1.428571429;
  border-color: #D2D2D2;
}


.Pagination {
  width: 34%;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.Pagination button {
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 17px;
}

.enabled-button {
  background-color: #007bff;
  /* Blue color for enabled button */
  color: #ffffff;
  /* White text color for enabled button */
  border: 2px solid #007bff
}

.PageSizeSelect {
  border-radius: 5px;
  margin: 5px;
  height: 35px;

  font-size: 17px;
}

.current-page-indicator {
  background-color: green;
  border-radius: 50%;
  /* Ensures the element is rounded */
  padding: 3px;
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  /* Adjust this value as needed */
  height: 25px;
  /* Ensure width and height are equal */
}

.disabled-button {
  background-color: lightgray;
  /* Gray color for disabled button */
  color: #ffffff;
  /* White text color for disabled button */
  border: 2px solid lightgray;
}



@keyframes blink {
75% {
    opacity: 0.4;
  }
}

.blink {
  animation: blink 2s linear infinite;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotate-border {
  position: relative;
  z-index: 1;
}

.rotate-border::before {
  content: '';
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  border: 2px solid #007bff; /* Change the color as needed */
  border-radius: 4px; /* Match the button's border-radius if needed */
  animation: rotate 1s linear infinite;
  z-index: -1;
}

</style>
