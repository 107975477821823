<template>
  <div class="content">
    <LoadingOverlay :loading="Isloading" />
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <md-card-header data-background-color="green">
            <div
              class="card-header d-flex justify-content-between align-items-center"
            >
              <h4 class="title">User Management</h4>
              <div class="ms-auto">
                <input type="text" v-model="searchText" class="gridFilter" @keyup="filterGrid"
                  style="margin:5px 5px 0 0;" placeholder="Search...">

                  <button
                  hidden
                  type="button"
                  title="Activate User"
                  data-bs-toggle="modal"
                  data-bs-target="#UserConfirmModal"
                  data-bs-whatever="@mdo"
                  class="btn btn-primary"
                  id="openUserActivateModal"
                >
                  Activate User
                </button>
              <button
                type="button"
                data-bs-toggle="modal"
                title="Add New User"
                data-bs-target="#exampleModal"
                data-bs-whatever="@mdo"
                class="btn btn-primary btn-sm"
                id="openModalRefresh"
                @click="openModalRefresh()"
              >
                Add New User
              </button>
              <button
                hidden
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                data-bs-whatever="@mdo"
                title="Add New User"
                class="btn btn-primary"
                id="openModal"
              >
                Add New User
              </button>
              <button
                hidden
                type="button"
                data-bs-toggle="modal"
                title="Delete User"
                data-bs-target="#userDeleteConfirmModal"
                data-bs-whatever="@mdo"
                class="btn btn-primary"
                id="openDeleteUserModal"
              >
                Delete User
              </button>
              </div>
             
            </div>
          </md-card-header>
          <md-card-content>
            <div class="col-md-12">
              <div
                v-if="successAlertOnSubmit"
                class="alert alert-success text-left"
                role="alert"
              >
                {{ successAlertOnSubmit }}
                <span @click="hideAlert" class="close-icon">&times;</span>
              </div>
              <div v-if="tableHeader" class="alert alert-danger text-left">
                {{ tableHeader }}
              </div>
              <div class="table-responsive">
                <div v-if="loading" class="text-center">
                  {{ loading }}
                  <!-- <p>Loading..</p> -->
                </div>
                <md-table
                  v-if="!loading"
                  v-model="paginatedUsers"
                >
                  <md-table-row
                    slot="md-table-row"
                    slot-scope="{ item }"
                    v-if="item.UserID !== 1"
                  >
                  <!-- <md-table-cell md-label="User ID">{{
                      item.UserID 
                    }}</md-table-cell> -->
                    <md-table-cell md-label="Name" :title="item.Username" style="width:22%;">{{
                      item.FirstName +" "+item.LastName
                    }}</md-table-cell>
                    <md-table-cell md-label="User Name" :title="item.Username" style="width:18%;">{{
                      item.Username
                    }}</md-table-cell>
                    <!-- <md-table-cell md-label="Last Name">{{
                      item.LastName
                    }}</md-table-cell> -->
                    <!-- <md-table-cell md-label="User Name">{{
                      item.Username
                    }}</md-table-cell> -->
                    <md-table-cell md-label="Email" :title="item.Email" style="width:22%;">{{
                      truncateEmail(item.Email)
                    }}</md-table-cell>
                    <md-table-cell md-label="Contact" style="width:15%;">{{
                      item.ContactNumber
                    }}</md-table-cell>
                    <md-table-cell md-label="Tasks" style="width:12%;">
                      <button
                        @click="UserTasks(item.UserID,4)"
                        type="button"
                        style="background: rgb(158, 215, 158);color:blue;border: rgb(158, 215, 158);"
                        class="btn btn-primary btn-sm ml-2"
                        title="Completed Tasks"
                      >
                      {{
                      item.TotalCompletedTask 
                    }}
                      </button>
                      <button
                        @click="UserTasks(item.UserID,2)"
                        type="button"
                        style="background: rgb(234, 234, 141);color:blue;border:rgb(234, 234, 141);"
                        class="btn btn-primary btn-sm ml-2"
                        title="Assigned Tasks"
                      >
                      {{
                      item.TotalAssignedTask
                    }}
                      </button>
                    </md-table-cell>

                    <md-table-cell md-label="Actions" style="width:10%;text-align: right;">
                      <button v-if="item.IsActive" :hidden="false"
                      @click="() => showConfirmation(item.UserID, item.IsActive)" type="button" class="btn btn-primary btn-sm ml-2"
                        title="Deactivate User">
                        <i class="fas fa-lock-open"></i>
                      </button>
                      <button v-if="!item.IsActive" :hidden="false"
                      @click="() => showConfirmation(item.UserID, item.IsActive)" type="button" class="btn btn-primary btn-sm ml-2"
                        title="Activate User">
                        <i class="fas fa-lock"></i>
                      </button>
                      <button
                        @click="editUserDetail(item.UserID)"
                        type="button"
                        class="btn btn-primary btn-sm ml-2"
                        title="Edit User"
                      >
                        <i class="fas fa-pencil-alt"></i>
                      </button>
                      <button
                        @click="openDeleteModal(item.UserID)"
                        type="buttoncn"
                        class="btn btn-danger btn-sm"
                        title="Delete User"
                      >
                        <md-icon style="color: white">delete</md-icon>
                      </button>
                    </md-table-cell>
                   
                  </md-table-row>
                  <md-table-row v-if="IsUsers" md-label="Actions" style="width:10%;text-align: right;">
                    <div class="fs-6 text-center my-2">
                      No data found.
                    </div>
                    </md-table-row>
                </md-table>
                <div class="d-flex my-1 mt-2" v-if="!IsUsers">
                  <div style="width: 33%;" class="d-flex">
                    <span style="width: 50%;line-height:50px;">Users per page:</span>
                    <Select @change="setPageSize" class="form-select PageSizeSelect" style="width: 40%;" v-model="pageSize">
                      <Option :value="5">5</Option>
                      <Option :value="10">10</Option>
                      <Option :value="20">20</Option>
                      <Option :value="30">30</Option>
                    </Select>
                  </div>
                  <div class="Pagination">
                    <button @click="previousPage" :disabled="currentPage === 1" title="Previous"
                      :class="{ 'disabled-button': currentPage === 1, 'enabled-button': currentPage !== 1 }"><i
                        class="fa fa-angle-left"></i> Prev</button>
                      <span class="current-page-indicator">{{ currentPage }}</span>
                    <button @click="nextPage" :disabled="currentPage === totalPages" title="Next"
                      :class="{ 'disabled-button': currentPage === totalPages, 'enabled-button': currentPage !== totalPages }">Next
                      <i class="fa fa-angle-right"></i></button>
                  </div>
                  <div style="width: 33%" class="d-flex justify-content-end mx-3">
                    <span style="line-height:50px;" class="text-right mx-1">Total Pages: </span>
                    <span style="line-height:50px;" class="text-right">{{ totalPages }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal fade"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              @hidden="handleModalHidden"
            >
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">
                      {{ newUser.UserID == 0 ? "Add New User" : "Update User" }}
                    </h1>
                    <button
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      title="Close"
                      tabindex="-1"
                    ></button>
                  </div>
                  <form @submit.prevent="submitForm">
                    <input
                      v-model="newUser.UserID"
                      type="hidden"
                      class="form-control"
                    />
                    <div class="modal-body">
                      <div
                        v-if="formHeader"
                        class="alert alert-danger text-left"
                        role="alert"
                      >
                        {{ formHeader }}
                        <span @click="hideAlert" class="close-icon"
                          >&times;</span
                        >
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label for="firstname" class="col-form-label"
                              >First Name</label
                            >
                            <input
                              v-model="newUser.FirstName"
                              type="text"
                              class="form-control"
                              id="firstname"
                              tabindex="1"
                              @input="isValidForm"
                            />
                            <span class="text-red-500 text-sm">{{
                              userValidate.FirstName
                            }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label for="lastname" class="col-form-label"
                              >Last Name</label
                            >
                            <input
                              v-model="newUser.LastName"
                              type="text"
                              class="form-control"
                              id="lastname"
                              tabindex="2"
                              @keyup="isValidForm"
                            />
                            <span class="text-red-500 text-sm">{{
                              userValidate.LastName
                            }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label for="email" class="col-form-label"
                              >Email</label
                            >
                            <input
                              v-model="newUser.Email"
                              type="text"
                              class="form-control"
                              id="email"
                              tabindex="3"
                              @keyup="isValidForm"
                            /><span class="text-red-500 text-sm">{{
                              userValidate.Email
                            }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label for="username" class="col-form-label"
                              >Username</label
                            >
                            <input
                              v-model="newUser.Username"
                              type="text"
                              class="form-control"
                              id="username"
                              tabindex="4"
                              @keydown="preventSpace"
                              @keyup="isValidForm"
                            />
                            <span class="text-red-500 text-sm">{{
                              userValidate.Username
                            }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label for="password" class="col-form-label"
                              >Password</label
                            >
                            <input
                              v-model="newUser.Password"
                              type="password"
                              class="form-control"
                              id="password"
                              tabindex="5"
                              maxlength="15"
                              @keyup="isValidForm"
                            />
                            <span class="text-red-500 text-sm">{{
                              userValidate.Password
                            }}</span>
                          </div>

                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label for="password" class="col-form-label"
                              >Confirm Password</label
                            >
                            <input
                              v-model="newUser.ConfirmPassword"
                              type="password"
                              class="form-control"
                              id="confirmpassword"
                              tabindex="6"
                              maxlength="15"
                              @input="isValidForm"
                            />
                            <span class="text-red-500 text-sm">{{
                              userValidate.ConfirmPassword
                            }}</span>
                          </div>
                        </div>
                          <div class="col-md-6">
                          <div class="mb-3">
                            <label for="ContactNumber" class="col-form-label"
                              >Contact Number</label
                            >
                            <input
                              v-model="newUser.ContactNumber"
                              class="form-control"
                              id="ContactNumber"
                              tabindex="7"
                              type="text"
                              maxlength="10"
                              @keypress="isNumber($event)"
                              @keyup="isValidForm"
                            />
                            <span class="text-red-500 text-sm">{{
                              userValidate.ContactNumber
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        title="Close"
                        class="btn btn-secondary ml-2"
                        id="closeModal"
                        data-bs-dismiss="modal"
                        tabindex="9"
                      >
                        Close
                      </button>
                      <button
                        type="submit"
                        class="btn btn-primary"
                        default
                        :title='editMode ? "Update" : "Submit"'
                        tabindex="8"
                        :disabled="btnEnable"
                      >
                        {{ editMode ? "Update" : "Submit" }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!-- Modal for confirm Activate or De Activate Users -->
            <div
              class="modal fade"
              id="UserConfirmModal"
              tabindex="-1"
              aria-labelledby="UserConfirmModal"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-md">
                <div class="modal-content">
                  <form @submit.prevent="submitAcitivateUserForm">
                    <input
                      v-model="newUser.UserID"
                      type="hidden"
                      class="form-control"
                    />
                    <div class="modal-body">
                      <div class="row">
                        <h4>
                           {{ ActivationMessage }} 
                        </h4>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        title="No"
                        class="btn btn-secondary ml-2"
                        data-bs-dismiss="modal"
                        id="closeActivateDeActivateModal"
                        tabindex="8"
                      >
                        No
                      </button>
                      <button
                        type="submit"
                        title="Yes"
                        class="btn btn-primary"
                        tabindex="7"
                        :disabled="btnEnable"
                        @click="editUserStatus(newUser.UserID)"
                      >
                        Yes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <!-- Modal for confirm delete User -->
            <div
              class="modal fade"
              id="userDeleteConfirmModal"
              tabindex="-1"
              aria-labelledby="DeleteConfirmModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-md">
                <div class="modal-content">
                  <form @submit.prevent="submitDeleteUserForm">
                    <input
                      v-model="newUser.UserID"
                      type="hidden"
                      class="form-control"
                    />
                    <div class="modal-body">
                      <div class="row">
                        <h4>Are you sure you want to delete {{newUser.Username}} User?</h4>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary ml-2"
                        data-bs-dismiss="modal"
                        id="closeDeleteConfirmUserModal"
                        title="No"
                        tabindex="8"
                      >
                        No
                      </button>
                      <button type="submit" title="Yes" class="btn btn-danger" tabindex="7">
                        Yes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import api, { setAuthToken } from "../apiConfig";
import { authMixin } from "./adminStore";
import Cookies from "js-cookie";
import { handleAuthorization, handleServerError, isValidEmail, isValidPhoneNumber, isValidText, preventNumberInput, preventTextInput } from "../Common/commonMethod";
import { ErrorLog } from "../Common/APILog";
import LoadingOverlay from '../Common/blockoutpage.vue';

export default {
  mixins: [authMixin],
  created() {
    this.checkAuthorization(); // Check authorization when the component is created
  },
  name: "UserManagement",
  components: {
    LoadingOverlay
  },
  props: {
    msg: String,
  },
  data() {
    return {
      users: [],
      ActivationMessage:"",
      Isloading:false,
      pageSize: 5, // Number of users per page
      currentPage: 1,// Current page
      searchText: '',
      IsUsers:false,
      filteredUser:[],
      filterUser:[],
      maxValue:9999999999,
      newUser: {
        UserID: 0,
        FirstName: "",
        LastName: "",
        UserType: "User",
        Email: "",
        Username: "",
        Password: "",
        ContactNumber: "",
        ConfirmPassword:"",
      },
      userValidate: {
        FirstName: "",
        LastName: "",
        UserType: "",
        Email: "",
        Username: "",
        Password: "",
        ConfirmPassword:"",
        ContactNumber: "",
      },
      formHeader: "",
      editMode: false,
      tableHeader: "",
      successAlertOnSubmit: "",
      loading: "",
      btnEnable: false,
      name: "User Management",
     // userTypes: ["User"],
    };
  },
  computed: {
    isRowExpired() {
      return (item) => item.IsExpired === 1;
    },
    paginatedUsers() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.filteredUser.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.filteredUser.length / this.pageSize);
    },
  },
  async mounted() {
    this.getUsers();
    const token =await Cookies.get("jwt");
    setAuthToken(token); // Fetch users when the component is mounted
  },
  methods: {
    async editUserStatus(UserID) {
      this.tableHeader = "";
      try {

        this.btnEnable = true;
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        const response = await api.put(
          `/api/users/manage-users/${UserID}`
        );

        if (response.data.success) {
          this.getUsers();
          this.closeActivateDeActivateModal(); // hide modal after successful submit.
          console.log(response.data)
          this.successAlertOnSubmit = response.data.msg;
        }
        else {
          this.tableHeader = response.data.msg;
        }
      }
      catch (error) {
        //this.tableHeader = error.message;
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.tableHeader = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.tableHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 562, error.stack, "AdminPanel/UserManagement/editUserStatus()");
          }
        }
        else {
          this.tableHeader = "Something went wrong.";
        }
      }
      finally {
        this.btnEnable = false;
        setTimeout(() => {
         this.tableHeader = "";
          this.successAlertOnSubmit="";
        }, 5000); // 5 seconds in milliseconds
       // this.hideMessage();
      }
    },
    async showConfirmation(UserId,Status) {
      // alert("hello"+UserId+"your : "+Status);
   //   this.confirmationVisible = true;
      this.newUser.UserID=UserId;
      var filterUser=this.users.filter(item => {
            return (
              item.UserID==parseInt(UserId)
            );
          });
          this.newUser.Username=filterUser[0].Username;
      if(Status){
        this.ActivationMessage=`Are you sure you want to deactivate ${this.newUser.Username} User?`;
      }
      else{
        this.ActivationMessage=`Are you sure you want to activate ${this.newUser.Username} User?`
      }
      document.getElementById("openUserActivateModal").click();
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    handleModalHidden() {
      this.editMode = false;
    },
    editUserDetail(userId) {
      this.$router.push({
        name: "Edit User",
        params: { userId },
      });
    },
    closeModal() {
      this.editMode = false;
      this.resetForm();
      document.getElementById("closeModal").click();
      
    },
    closeActivateDeActivateModal() {
      
      document.getElementById("closeActivateDeActivateModal").click();
      
    },
    openModal() {
      document.getElementById("openModal").click();
    },
    truncateEmail(email) {
      // Truncate the description if it's longer than 30 characters
      return email.length > 50
        ? email.slice(0, 50) + "..."
        : email;
    },
    openModalRefresh() {
      this.editMode = false;
      this.newUser.UserID = 0;
      this.resetValidation();
      this.resetForm();
      document.getElementById("openModalRefresh").click();
    },
    closeDeleteConfirmUserModal() {
      document.getElementById("closeDeleteConfirmUserModal").click();
    },
    UserTasks(UserID,FilterID) {
      this.$router.push({
        name: "User Assigned Tasks",
        params: { UserID, FilterID},
      });
    },
    openDeleteModal(userId) {
      this.newUser.UserID = userId; 
      var filterUser=this.users.filter(item => {
            return (
              item.UserID==parseInt(userId)
            );
          });
          this.newUser.Username=filterUser[0].Username;
          console.log(this.newUser.Username)
      document.getElementById("openDeleteUserModal").click();
    },
    async getUsers() {
      this.users = [];

      this.loading = "loading..";
      this.Isloading=true;
      try {
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        const UserID= await userCookie.UserID
        const response = await api.get(`/api/users/get-users/${UserID}`);
        if (response.data.length>0) {
          this.users = response.data;
          this.filteredUser=this.users
          this.loading = "";
          console.log(this.users)
        } else {
        //  this.loading = "No record found!";
          //this.tableHeader = "No record found!";
        }
        // Continue with any other logic (e.g., navigate to a different page)
      } catch (error) {
        console.log(error)
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.tableHeader = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.tableHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 698, error.stack, "AdminPanel/UserManagement/getUsers()");
          }
        }
        else {
          this.tableHeader = "Something went wrong.";
        }
       } 
      finally {
        setTimeout(() => {
         this.tableHeader = "";
          this.successAlertOnSubmit="";
        }, 5000); // 5 seconds in milliseconds
        this.Isloading=false;
        if(this.users.length>0){
          this.loading = "";
        }
        else{
          this.loading = "No record found!";
        }
       //  // Set loading state to false after data is fetched
      }
    },
    async createUser() {
      this.formHeader = "";
      try {
        this.btnEnable = true;
        this.Isloading=true;
        const token =await Cookies.get("jwt");
        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        setAuthToken(token);
        const response = await api.post("/api/users/create-user", {
          Username: this.newUser.Username,
          Password: this.newUser.Password,
          UserType: this.newUser.UserType,
          FirstName: this.newUser.FirstName,
          CreatedBy:userCookie.UserID,
          LastName: this.newUser.LastName,
          Email: this.newUser.Email,
          ContactNumber: this.newUser.ContactNumber
        });
        console.log(response.data)
        if (response.data.success) {
          this.getUsers();
          this.successAlertOnSubmit = response.data.msg;
          this.closeModal(); // hide model after successfull submit.
         } else {
           this.formHeader = response.data.msg;
         }
      } catch (error) {
        console.log(error)
           if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.formHeader = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.formHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 765, error.stack, "AdminPanel/UserManagement/createUser()");
          }
        }
        else {
          this.formHeader = "Something went wrong.";
        }
      } finally {
        this.btnEnable = false;
        this.Isloading=false;
        setTimeout(() => {
         this.tableHeader = "";
          this.successAlertOnSubmit="";
        }, 5000); // 5 seconds in milliseconds
      }
    },

    async deleteItem(userId) {
      try {
        this.Isloading=true;
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        const userCookie =await Cookies.get("user");
       const ModifiedBy = await userCookie ? JSON.parse(userCookie) : null;
        // Make your delete API call here
        const response = await api.delete(`/api/users/delete-user/${userId}?ModifiedBy=${ModifiedBy.UserID}`); // Replace with your actual API endpoint
        if (response.status == 200) {
          this.getUsers();
          this.closeDeleteConfirmUserModal();
          this.successAlertOnSubmit = "User deleted successfully.";
        } 
        else {
          alert(response.statusText);
        }

        // Optionally, perform additional actions after successful deletion
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.formHeader= jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.formHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 816, error.stack, "AdminPanel/UserManagement/deleteItem()");
          }
        }
        else {
          this.formHeader = "Something went wrong.";
        }
      }
      finally{
        this.Isloading=false;
        setTimeout(() => {
         this.tableHeader = "";
          this.successAlertOnSubmit="";
        }, 5000); // 5 seconds in milliseconds
      }
    },
    submitDeleteUserForm() {
      // Call the deleteItem method when confirmed
      this.deleteItem(this.newUser.UserID);
    },
    isValidForm() {
      let isValid = true;
      // First Name
      if (!this.newUser.FirstName.trim()) {
        this.userValidate.FirstName = "First name is required.";
        isValid = false;
      } else {
        this.userValidate.FirstName = "";
      }

      if (!this.newUser.LastName.trim()) {
        this.userValidate.LastName = "Last name is required.";
        isValid = false;
      } else {
        this.userValidate.LastName = "";
      }

      if (!this.newUser.ContactNumber) {
        this.userValidate.ContactNumber = "Contact number is required.";
        isValid = false;
      } 
      else if (!isValidPhoneNumber(this.newUser.ContactNumber)) {
        this.userValidate.ContactNumber = "Please enter a valid Contact Number.";
        isValid = false;
      }
      else if(this.newUser.ContactNumber.length == 10){
        var userFilter=this.users.filter(item => {
            return (
              item.ContactNumber==parseInt(this.newUser.ContactNumber)
            );
        });
        if(userFilter.length>0){
          this.userValidate.ContactNumber = "The entered contact already exists.";
          isValid = false;
        }
        else{
          this.userValidate.ContactNumber = "";
        }
      }
      else {
        this.userValidate.ContactNumber = "";
      }
      // Email
      if (!this.newUser.Email.trim()) {
        this.userValidate.Email = "Email is required.";
        isValid = false;
      } else if (!isValidEmail(this.newUser.Email.trim())) {
        this.userValidate.Email = "Please enter valid email address.";
        isValid = false;
      } 
      else if(isValidEmail(this.newUser.Email.trim())){
       
        var userFilter=this.users.filter(item => {
            return (
              item.Email==this.newUser.Email.trim()
            );
        });
        if(userFilter.length>0){
          this.userValidate.Email = "The entered email already exists.";
          isValid = false;
        }
        else{
          this.userValidate.Email = "";
        }
      }
      else {
        this.userValidate.Email = "";
      }

      if (!this.newUser.Username.trim()) {
        this.userValidate.Username = "User name is required.";
        isValid = false;
      }
      else if(/\s/.test(this.newUser.Username)){
        this.userValidate.Username = "Please remove whitespace in user name.";
        isValid = false;
      } else {
        this.userValidate.Username = "";
      }

      if (!this.newUser.Password.trim()){
        this.userValidate.Password = "Password is required.";
        isValid = false;
      } else {
        this.userValidate.Password = "";
      }
      if (this.newUser.Password.length<8 && this.newUser.Password) {
        this.userValidate.Password = "Password must be minimum 8 characters.";
        isValid = false;
      }
      else if (this.newUser.Password.length>=8) {
        var lowerCaseLetters = /[a-z]/g;
        var upperCaseLetters = /[A-Z]/g;
        var number = /[0-9]/g;
        var special= /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/;
        if(!this.newUser.Password.match(lowerCaseLetters)){
          this.userValidate.Password = "Password must contain lowercase letter."; 
          isValid = false;     
        }
        if(!this.newUser.Password.match(upperCaseLetters)){
          this.userValidate.Password = "Password must contain capital letter.";    
          isValid = false;  
        }
        if(!this.newUser.Password.match(number)){
          this.userValidate.Password = "Password must contain number.";   
          isValid = false;   
        }
        if(!this.newUser.Password.match(special)){
          this.userValidate.Password = "Password must contain special character."; 
          isValid = false;     
        }
      }

      if (!this.newUser.ConfirmPassword) {
        this.userValidate.ConfirmPassword = "Confirm Password is required.";
        isValid = false;     
      } 
      else if (this.newUser.Password.trim() !== this.newUser.ConfirmPassword.trim()) {
        this.userValidate.ConfirmPassword = "Confirmed Password didn't match with the new password.";
        isValid = false;     
      }
      else {
        this.userValidate.ConfirmPassword = "";
      }

      return isValid;
    },
  
    async submitForm() {
      // Reset validation messages
      this.resetValidation();

      // Validate form
      if (this.isValidForm()) {
        //this.newUser.UserID == 0 ? this.createUser() : this.updateUser();
        this.createUser();
      }
    },
    resetValidation() {
      this.userValidate = {
        FirstName: "",
        LastName: "",
        ContactNumber: "",
        Email: "",
        Username: "",
        Password: "",
      };
    },
    preventSpace(event) {
      // Check if the key pressed is a space (key code 32)
      if (event.key === ' ') {
        event.preventDefault(); // Prevent the space from being entered
      }
    },
    resetForm() {
      this.newUser = {
        UserID: 0,
        FirstName: "",
        LastName: "",
        UserType: "User",
        Email: "",
        Username: "",
        Password: "",
      };
      this.formHeader = "";
    },
    hideAlert() {
      // Clear the formHeader or set it to an empty string to hide the alert
      this.formHeader = "";
      this.successAlertOnSubmit = "";
    },


    setPageSize(event) {
      const selectedSize = event.target.value;
      this.pageSize = parseInt(selectedSize);
      this.currentPage = 1; // Reset to the first page
      this.paginatedUsers();
      // alert(`Page size changed to: ${selectedSize}`);
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    filterData: function (x) {
      this.IsUsers = false;
      this.currentPage=1;
      if (parseInt(x) != 5 && parseInt(x) != 6) {
        this.filterUser = this.users.filter(item => {
          return (
            item.TaskStatus == parseInt(x)
          );

        });
        if (this.filterUser.length > 0) {
          this.filteredUser = this.filterUser;
        }
        else {
          console.log("ok");
          this.filteredUser = [];
          this.IsUsers = true;
        }
      }
      if (parseInt(x) == 5) {
        this.filterUser = this.users.filter(item => {
          return (
            item.IsExpired == 1
          );

        });
        if (this.filterUser.length > 0) {
          this.filteredUser = this.filterUser;
        }
        else {
          console.log("ok");
          this.filteredUser = [];
          this.IsUsers = true;
        }
      }
      if (parseInt(x) == 6) {
        this.filteredUser = this.users;
      }

      // Filter tasks based on searchText

    },
    filterGrid: function () {
      var searchText = this.searchText;
      this.IsUsers = false;
      this.currentPage=1;
      if (parseInt(searchText) > 0) {
        this.filterUser = this.users.filter(item => {
          return (
            item.UserID == parseInt(searchText)
          );
        });
        if (this.filterUser.length > 0) {
          this.filteredUser = this.filterUser;
        }
        else {
          console.log("ok");
          this.filteredUser = [];
          this.IsUsers = true;
        }
      }
      else {
        searchText = searchText.toLowerCase();
        this.filterUser = this.users.filter(item => {
          return (
            item.FirstName.toLowerCase().includes(searchText) ||
            item.LastName.toLowerCase().includes(searchText) ||
            item.Username.toLowerCase().includes(searchText) ||
            item.Email.toLowerCase().includes(searchText)
          );
        });
        if (this.filterUser.length > 0) {
          this.filteredUser = this.filterUser;
        }
        else {
          console.log("ok");
          this.filteredUser = [];
          this.IsUsers = true;
        }
      }
      // Filter users based on searchText

    },
    truncateDescription(description) {
      // Truncate the description if it's longer than 30 characters
      return description.length > 25
        ? description.slice(0, 25) + "..."
        : description;
    },
  },
};
</script>

<style scoped>
/* Add Tailwind CSS classes here if needed */
.close-icon {
  cursor: pointer;
  float: right;
  font-size: 30px;
  margin-top: -10px;
}
.mb-3 {
  text-align: left;
}
.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1em;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
.toprow{
  color: #fff;
    background: #0d6efd;
    height: 35px;
    font-weight: 500;
    font-size: 16px;
}
.link-a{
  color:blue !important;
  font-weight: 700;
}
.table th {
  background-color: #f2f2f2;
}

/* Additional styling for left and right padding */
.table th,
.table td {
  padding-left: 12px;
  padding-right: 12px;
}

.actionBtns {
  text-align: right !important;
}
.modal-content .modal-header{
  padding-top:10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.modal-title{
  margin-top:10px;
}
.modal-content .modal-body{
  padding-top:10px;
}


.Pagination {
  width: 60%;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.Pagination button {
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 17px;
}

.enabled-button {
  background-color: #007bff;
  /* Blue color for enabled button */
  color: #ffffff;
  /* White text color for enabled button */
  border: 2px solid #007bff
}

.disabled-button {
  background-color: lightgray;
  /* Gray color for disabled button */
  color: #ffffff;
  /* White text color for disabled button */
  border: 2px solid lightgray;
}
.current-page-indicator {
  background-color: green;
  border-radius: 50%; /* Ensures the element is rounded */
  padding: 3px;
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 25px; /* Adjust this value as needed */
  height: 25px; /* Ensure width and height are equal */
}
.PageSizeSelect{
  border-radius: 5px;
  margin: 5px;
  height: 35px;
 font-size: 17px;
}
</style>
