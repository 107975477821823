<template>
    <div class="content">
        <LoadingOverLay :loading="Isloading" />
        <div v-if="!Isloading" class="row">
            <div class="col-md-12">
                <div class="md-layout">
                    <div class="md-layout-item md-small-size-50 md-medium-size-50 md-xsmall-size-100 md-size-33">
                        <div class="bg-color" @click="GotoAdmins" style="margin: 0px; margin-top:15px;">
                            <div class="media-body text-xs-left float-right" id="calories-value"
                                style="max-width: 445px; border-radius: 4px; position:relative;">
                                <h3 class="card-value" id="calories-text" style="color: rgb(130, 130, 130);">{{
                                    TotalAdminCount }}</h3>
                                <div id="foodimg"><span class="card-text" id="calories-subtitle"
                                        style="color: rgb(130, 130, 130);">Total Admins</span>
                                    <span id="food-img" class="icon-user"
                                        style="font-size: 65px; margin-right: 3%; margin-bottom: 2%; color: rgb(153, 147, 147);"><i
                                            class="fas fa-users"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="md-layout-item md-small-size-50 md-medium-size-50 md-xsmall-size-100 md-size-33">
                        <div class="bg-color" @click="GotoUsers" style="margin: 0px; margin-top:15px;">
                            <div class="media-body text-xs-left float-right" id="calories-value"
                                style="max-width: 445px; border-radius: 4px; position:relative;"
                                v-on:click="OpenUserPage()">
                                <h3 class="card-value" id="calories-text" style="color: rgb(130, 130, 130);">{{
                                    TotalUserCount }}</h3>
                                <div id="foodimg"><span class="card-text" id="calories-subtitle"
                                        style="color: rgb(130, 130, 130);">Total Users</span>
                                    <span id="food-img" class="icon-user"
                                        style="font-size: 65px; margin-right: 3%; margin-bottom: 2%; color: rgb(153, 147, 147);"><i
                                            class="fas fa-users"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12" style="box-sizing: border-box !important;">
                <div class="bg-color mx-3 d-flex" style="box-sizing: border-box !important;">
                    <div class="col-md-6">
                        <div id="chart">
                            <apexchart type="line" height="350" :options="adminchartOptions" :series="adminseries">
                            </apexchart>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div id="chart">
                            <apexchart type="line" height="350" :options="projectchartOptions" :series="projectseries">
                            </apexchart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { authMixin } from "../../../../components/superAdmin/SuperAdminStore";
import api, { setAuthToken } from "../../../../components/apiConfig";
import Cookies from "js-cookie";
import VueApexCharts from "vue-apexcharts";
import LoadingOverLay from "../../../../components/Common/blockoutpage.vue";
export default {
    mixins: [authMixin],
    props: {
        tableHeaderColor: {
            type: String,
            default: "",
        },
    },
    components: {
        LoadingOverLay,
        apexchart: VueApexCharts,
    },
    created() {
        this.checkAuthorization();
    },

    data() {
        return {
            Isloading: true,
            taskFormHeader: "",
            loading: "",
            TotalAdminCount: 0,
            TotalUserCount: 0,
            title: "Dashboard",
            adminseries: [{
                name: 'Users',
                type: 'column',
                data: []
            },
            ],
            adminchartOptions: {
                chart: {
                    type: 'bar',
                    height: 430
                },
                plotOptions: {
                    bar: {
                        vertical: true,
                        dataLabels: {
                            position: 'top',
                        },
                    }
                },
                dataLabels: {
                    enabled: false,
                    offsetX: 0,
                    style: {
                        fontSize: '8px',
                        colors: ['#fff']
                    }
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['#fff']
                },
                title: {
                    text: 'Admin Wise User Count',
                    align: 'left',
                    offsetX: 110
                },
                tooltip: {
                    shared: true,
                    intersect: false
                },
                xaxis: {
                    categories: [],
                    labels: {
                        style: {
                            colors: ['#008FFB', '#00E396', '#775DD0', '#FEB019', '#FF4560', '#546E7A', '#008FFB', '#00E396', '#775DD0'],
                            padding: 10
                        }
                    }
                },
            },

            //project analysis

            projectseries: [{
                name: 'Assigned',
                type: 'column',
                data: []
            },
            {
                name: 'In Process',
                type: 'column',
                data: []
            },
            {
                name: 'Completed',
                type: 'column',
                data: []
            }
            ],
            projectchartOptions: {
                chart: {
                    type: 'bar',
                    height: 430
                },
                plotOptions: {
                    bar: {
                        vertical: true,
                        dataLabels: {
                        position: 'top',
                        },
                    }
                },
                dataLabels: {
                    enabled: false,
                    offsetX: 0,
                    style: {
                        fontSize: '8px',
                        colors: ['#000000']
                    }
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['#fff']
                },
                title: {
                    text: 'Project Wise Task Status',
                    align: 'left',
                    offsetX: 110
                },
                tooltip: {
                    shared: true,
                    intersect: false
                },
                xaxis: {
                    categories: [],
                    labels: {
                        rotate:-45,
                        style: {
                            colors: ['#008FFB', '#00E396', '#775DD0', '#FEB019', '#FF4560', '#546E7A', '#008FFB', '#00E396', '#775DD0'],
                            padding: 10
                        },
                        rotateAlways: true,
                        trim:true
                    }
                },
            },
        };
    },
    mounted() {
        this.getSuperAdminDashboardDetails();
        this.getadminChartData();
        this.getProjectChartData();
    },
    methods: {
        GotoAdmins() {
            this.$router.push({ name: 'Admins' })
        },
        GotoUsers() {
            this.$router.push({ name: 'Users' })
        },
        async getSuperAdminDashboardDetails() {
            this.loading = "Loading...";
            try {
                this.Isloading = true;
                const token = await Cookies.get("jwt");
                setAuthToken(token);
                let userCookie = await Cookies.get("user");
                userCookie = await userCookie ? JSON.parse(userCookie) : null;
                // this.UserName = userCookie.Username;
                const response = await api.get(
                    `/api/superAdmin/get-super-admin-dashboard-details`
                );
                this.Isloading = false;
                if (response.data) {
                    console.log(response.data)
                    // get data
                    this.TotalAdminCount = response.data[0].AdminCount;
                    this.TotalUserCount = response.data[0].UserCount;
                    // console.log("taskcount", this.TotalTaskCount);
                } else {
                    // Handle case where no data are found
                }
            } catch (error) {
                // Handle API error
                setTimeout(() => {
                    this.getSuperAdminDashboardDetails(); // Retry after a delay
                }, 2000);
            } finally {
                this.loading = "No data found.";
            }
        },
        async getadminChartData() {
            try {
                this.Isloading = true;
                const token = await Cookies.get("jwt");
                setAuthToken(token);
                let userCookie = await Cookies.get("user");
                userCookie = await userCookie ? JSON.parse(userCookie) : null;
                const response = await api.get(`/api/superAdmin/get-users-by-admin/${userCookie.UserID}`);
                console.log("Userid : "+userCookie.UserID)
                console.log(response);
                if (response.data) {
                    console.log("this is chart data: " + response.data);
                    const usernames = await response.data.map(user => user.FirstName);
                    const UserCount = await response.data.map(user => user.UserCount);
                    console.log("Username: " + usernames);
                    this.adminchartOptions = {
                        ...this.adminchartOptions,
                        xaxis: {
                            ...this.adminchartOptions.xaxis,
                            categories: usernames
                        }
                    };
                    console.log("Updated categories: " + this.adminchartOptions.xaxis.categories);
                    // Clone series to trigger reactivity
                    this.adminseries = [
                        { ...this.adminseries[0], data: UserCount },
                    ];
                    console.log("Updated series: ", this.series);
                    this.Isloading = false;
                } else {
                    // Handle case where no categories are found

                }
            } catch (error) {
                // Handle API error
                console.log(error)
                // ErrorLog(error.message, error.statuscode, 422, error.stack, "Admin Panel: Category / getCategories()");
                if (error.response && error.response.data) {
                    const jsonResponseString = JSON.stringify(error.response.data);
                    try {
                        const jsonObject = JSON.parse(jsonResponseString);
                        this.taskFormHeader = jsonObject.message || "Something went wrong.";
                    } catch (parseError) {
                        this.taskFormHeader = parseError.message || "Something went wrong.";
                    }
                }
                else {
                    this.taskFormHeader = "Something went wrong.";
                }
            } finally {
                this.Isloading = false;
                setTimeout(() => {
                    this.taskFormHeader = "";
                }, 5000); // 5 seconds in milliseconds
            }
        },

        async getProjectChartData() {
            try {
                this.Isloading = true;
                const token = await Cookies.get("jwt");
                setAuthToken(token);
                let userCookie = await Cookies.get("user");
                userCookie = await userCookie ? JSON.parse(userCookie) : null;
                const response = await api.get(`/api/superAdmin/get-tasks-count-by-project/${userCookie.UserID}`);
                console.log("Userid : "+userCookie.UserID)
                console.log(response);
                if (response.data) {
                    console.log("this is chart data: " + response.data);
                    const usernames = await response.data.map(user => user.Name);
                    const totalCompletedTasks = await response.data.map(user => user.TotalCompletedTask);
                    const totalInProcessTasks = await response.data.map(user => user.TotalInProcessTask);
                    const totalAssignedTasks = await response.data.map(user => user.TotalAssignedTask);
                    console.log("Username: " + usernames);
                    this.projectchartOptions = {
                        ...this.projectchartOptions,
                        xaxis: {
                            ...this.projectchartOptions.xaxis,
                            categories: usernames
                        }
                    };
                    console.log("Updated categories: " + this.projectchartOptions.xaxis.categories);
                    // Clone series to trigger reactivity
                    this.projectseries = [
                        { ...this.projectseries[0], data: totalAssignedTasks },
                        { ...this.projectseries[1], data: totalInProcessTasks },
                        { ...this.projectseries[2], data: totalCompletedTasks }
                    ];
                    console.log("Updated series: ", this.projectseries);
                    this.Isloading = false;
                } else {
                    // Handle case where no categories are found

                }
            } catch (error) {
                // Handle API error
                console.log(error)
                // ErrorLog(error.message, error.statuscode, 422, error.stack, "Admin Panel: Category / getCategories()");
                if (error.response && error.response.data) {
                    const jsonResponseString = JSON.stringify(error.response.data);
                    try {
                        const jsonObject = JSON.parse(jsonResponseString);
                        this.taskFormHeader = jsonObject.message || "Something went wrong.";
                    } catch (parseError) {
                        this.taskFormHeader = parseError.message || "Something went wrong.";
                    }
                }
                else {
                    this.taskFormHeader = "Something went wrong.";
                }
            } finally {
                this.Isloading = false;
                setTimeout(() => {
                    this.taskFormHeader = "";
                }, 5000); // 5 seconds in milliseconds
            }
        },
    },
};
</script>

<style scoped>
.close-icon {
    cursor: pointer;
    float: right;
    font-size: 30px;
    margin-top: -10px;
}

.expired-row {
    background-color: #dc8484;
    border-bottom: 1px solid #eee;
}

.table-cell {
    font-size: 1.0625rem;
    padding-bottom: 10px;
    font-weight: 500;
}

.modal-backdrop.show {
    opacity: 0;
    display: none;
}

#tab-posts {
    margin-left: 20px;
    overflow: auto;
}

#tab-Dues {
    overflow-x: auto;
}

.md-tabs-navigation {
    padding-left: 100px !important;
}

.bg-color {
    background: white;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .14);
    margin: 25px 0;
    padding: 6px;
}

.main-panel>.content {
    padding-top: 0px !important;
}

.Count-header {
    font-size: 14px;
    font-weight: 600;
}

#tab-Dues {
    margin-left: -20px !important;
}

.dropdown {
    width: 100%;
    height: 36px;
    padding: 7px 0;
    font-size: 14px;
    line-height: 1.428571429;
    border-color: #D2D2D2;
}

#tab-posts {
    margin-left: 20px;
}

.nav-btn {
    color: #fff !important;
    padding: 6px;
    margin: 4px;
    border-radius: 5px;
    background: #0d6efd;
    font-weight: 400 !important;
}

.break {
    display: none;
}

@media screen and (max-width:700px) {
    .break {
        display: block;
    }
}

@media screen and (max-width:595px) {
    .nav-btn {
        padding: 4px;
        font-size: 13px;
        margin: 2px;
    }
}

/* @media screen and (max-width:510px) {
    .nav-btn{
  display: none;
  }
  } */

#calories-text {
    padding-left: 5%;
}

.card-value {
    font-weight: 400;
    font-size: 32px;
    color: #727272;
    margin-bottom: 0px;
    margin-top: 10px;
    letter-spacing: 0;
}

#calories-subtitle {
    padding-left: 5%;
}

.card-text {
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0;
    color: #303030;
}

.icon-user {
    position: absolute;
    right: 0px;
    top: 5px;
}

.media-body {
    cursor: pointer;
}

.media-body:hover h3,
.media-body:hover i {
    color: #0d6efd !important;
}

.media-body:hover .card-text {
    color: #555 !important;
}

.task-table {
    width: 100%;
}

@media screen and (min-width:990px) and (max-width: 1185px) {
    .task-table {
        width: 1100px;
        overflow-x: auto
    }
}

@media screen and (max-width: 890px) {
    .task-table {
        width: 830px;
        overflow-x: auto
    }
}
</style>