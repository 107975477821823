import api from "../apiConfig";

export const ErrorLog=async(ErrroMessage,ErrorCode,ErrorLine,ErrorStack,funcitonname)=> {
    try {
      const response = await api.post('/api/errorLog/add-error-log',
        {
          ErrorMessage: ErrroMessage,
          ErrorCode: ErrorCode,
          ErrorLine: ErrorLine,
          ErrorStack: ErrorStack,
          functionname:funcitonname,
        })        
      if (response.data.success) {
          console.log(response.data.msg)
      } else {
        console.log(response.data.msg)
      }
    } catch (error) {
        console.log(response.data.msg)
    } 
  }