
// SweetAlert.js

import Swal from "sweetalert2";

const showAlert = (type, message, showCancelButton = false,timer) => {
  let title = "";

  switch (type) {
    case "success":
      title = "Success";
      break;
    case "error":
      title = "Error";
      break;
    case "warning":
      title = "Warning";
      break;
    case "info":
      title = "Info";
      break; // Add a new case for "info"
    default:
      title = "Info"; // Default to 'Info' if the type is not recognized
  }

   Swal.fire({
    title,
    text: message,
    icon: type, // Maps to Swal icons for success, error, warning, and info
    showCancelButton: showCancelButton,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    timer: timer,
    confirmButtonText: "OK",
    cancelButtonText: "Cancel",
  });
  return null; // SweetAlert does not render any visible UI
};

export default showAlert;
