<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar :sidebar-item-color="sidebarBackground" :sidebar-background-image="sidebarBackgroundImage">
      <mobile-menu slot="content"></mobile-menu>
      <sidebar-link to="/dashboard">
        <md-icon>dashboard</md-icon>
        <p>Dashboard</p>
      </sidebar-link>
      <sidebar-link to="/task-management">
        <md-icon>task</md-icon>
        <p>Team Tasks
          <sup><span v-if="unReadChatCount > 0" class="notification">
              {{ formatChatCount(unReadChatCount) }}
              <!-- 34 -->
            </span>
          </sup>
        </p>
      </sidebar-link>
      <sidebar-link to="/my-task-management">
        <md-icon>task</md-icon>
        <p>My To-Do's</p>
      </sidebar-link>
      <sidebar-link to="/my-profile">
        <md-icon>person</md-icon>
        <p>My Profile</p>
      </sidebar-link>
      <sidebar-link to="/user-management">
        <!-- <md-icon>person</md-icon> -->
        <i class="fas fa-users" style="color:#fff;opacity:0.8;"></i>
        <p>Users Management</p>
      </sidebar-link>
      <sidebar-link to="/task-messages">
        <i class="fas fa-comment" style="color:#fff;opacity:0.8;"></i>
        <p>Messages <sup><span v-if="unReadChatCount > 0" class="notification">
              {{ formatChatCount(unReadChatCount) }}

              <!-- 34 -->
            </span>
          </sup>
        </p>
      </sidebar-link>
      <sidebar-link to="/Category">
        <md-icon>category</md-icon>
        <p>Projects</p>
      </sidebar-link>
      <sidebar-link to="/change-password">
        <md-icon>password</md-icon>
        <p>Change Password</p>
      </sidebar-link>
      <sidebar-link @click="logout" to="/login">
        <md-icon @click="logout">logout</md-icon>
        <p @click="logout">Logout</p>
      </sidebar-link>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>
      <dashboard-content> </dashboard-content>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
    <div class="notificationbar">
      <notification v-for="(notification1, index) in notifications" :key="index" :message="notification1.message"
        :icon="notification1.icon" :verticalAlign="notification1.verticalAlign"
        :horizontalAlign="notification1.horizontalAlign" :type="notification1.type" :timeout="notification1.timeout"
        :timestamp="notification1.timestamp" @on-close="removeNotification(index)" />
    </div>
  </div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "@/pages/Layout/MobileMenu.vue";
import { handleAuthorization, handleServerError, handleSessionTimeout } from "../../components/Common/commonMethod";
import api, { baseWebsocketUrl, setAuthToken } from "../../components/apiConfig";
import Cookies from "js-cookie"
import { ErrorLog } from "../../components/Common/APILog";
import Notification from "../../components/NotificationPlugin/Notification.vue";

export default {
  components: {
    TopNavbar,
    DashboardContent,
    ContentFooter,
    MobileMenu,
    Notification,
  },
  data() {
    return {
      unReadChatCount: 0,
      sidebarBackground: "green",
      notifications: [],
      sidebarBackgroundImage: require("@/assets/img/sidebar-2.jpg"),
      logoutTimer: null,
      chatCountInterval: null,
    };
  },
  created() {
    setTimeout(()=>{
      this.getUnReadChatCount()
    },10*1000)
    this.getUnReadChatCount();
  },
  async mounted() {
    this.getUnReadChatCount();
    const socket = new WebSocket(baseWebsocketUrl)
    // const socket = new WebSocket('ws://trackerapi.workanthem.com')
    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      // debugger;
      console.log(message)
      let userCookie = Cookies.get("user");
      userCookie = userCookie ? JSON.parse(userCookie) : null;
      if (userCookie.UserID !== message.SenderId) {
        // shownotication(message)
        this.notifications.push({
          message: `${message.SenderName}: ${message.Message}`,
          icon: 'info',
          verticalAlign: 'top',
          horizontalAlign: 'center',
          type: 'info',
          timeout: 9000, // Optional timeout to automatically close the notification
          timestamp: new Date()
        });
      }
    }
    handleSessionTimeout("/login")

    this.chatCountInterval = setInterval(this.getUnReadChatCount, 5000);
  },
  beforeDestroy() {
    // Clear intervals when the component is destroyed
    clearInterval(this.chatCountInterval);
    // clearInterval(this.taskCountInterval);
  },
  methods: {

    // get Unread chat count
    async getUnReadChatCount() {
      try {
        const token = Cookies.get("jwt");
        let userCookie = Cookies.get("user");
        userCookie = userCookie ? JSON.parse(userCookie) : null;
        const uid = userCookie.UserID
        setAuthToken(token);
        const response = await api.get(
          `/api/Chat/get-unread-chat-count-by-userid/${uid}`
        );
        if (response.data) {
          const result = response.data[0];
          console.log(result)
          this.unReadChatCount = response.data[0].UnReadChatCount;
          console.log(this.unReadChatCount);
        }
        else {
          this.errorAlertOnSubmit = "Something went wrong. please try again later.";
        }
      } 
      catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")             
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              // this.err = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              // this.tableHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 165, error.stack, "Layout/DashboardLayout/GetUnreadChatCount()");
          }
        }
        else {
          // this.tableHeader = "Something went wrong.";
        }
      }
      finally {
        this.loading = true;
      }
    },
    removeNotification(index) {
      this.notifications.pop(index, 1);
    },
    formatChatCount(count) {
      return count < 10 ? `0${count}` : count;
    },
    logout() {
      setAuthToken();
      //alert("Logout successfully.");
      this.$router.push("/login");
    },
  },
};
</script>
<style>
.notificationbar {
  position: relative;
  top: 100px;
}

.notification {
  border-radius: 100%;
  padding: 5px;
  text-align: center;
  font-size: 11px;
  color: white;
  background-color: red;
  width: 40px;
}
</style>