<template>
  <div class="content">
    <LoadingOverlay :loading="Isloading" />
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
        <md-card>
          <md-card-header data-background-color="green">
            <div class="card-header d-flex justify-content-between align-items-center">
              <h4 class="title">Users</h4>
              <div class="ms-auto">
                <input type="text" v-model="searchText" class="gridFilter" @keyup="filterGrid"
                  style="margin:5px 5px 0 0;" placeholder="Search...">
                  <button type="button" title="Back" @click="GoBack" class="btn btn-dark btn-sm ms-2 ml-2">
                            Back
                        </button>
                  <button hidden type="button" data-bs-toggle="modal" title="View Admin Details" data-bs-target="#UserDetailsModal"
                    data-bs-whatever="@mdo" class="btn btn-primary" id="openUserDetailsModal">
                    View User Details
                  </button>
              </div>
            </div>
          </md-card-header>
          <md-card-content>
            <div class="col-md-12">
              <div v-if="successAlertOnSubmit" class="alert alert-success text-left" role="alert">
                {{ successAlertOnSubmit }}
                <span @click="hideAlert" class="close-icon">&times;</span>
              </div>
              <div v-if="tableHeader" class="alert alert-danger text-left">
                {{ tableHeader }}
              </div>
              <div class="table-responsive">
                <div v-if="loading" class="text-center">
                  {{ loading }}
                  <!-- <p>Loading..</p> -->
                </div>
                <md-table v-if="!loading" v-model="paginatedUsers">
                  <md-table-row slot="md-table-row" slot-scope="{ item }" v-if="item.UserID !== 1">
                    <!-- <md-table-cell md-label="User ID">{{
                        item.UserID 
                      }}</md-table-cell> -->

                    <md-table-cell md-label="User Name" :title="item.Username" style="width:18%;">{{
                      item.Username
                    }}
                    </md-table-cell>
                    <md-table-cell md-label="First Name" :title="item.FirstName" style="width:22%;">
                      {{ item.FirstName }}
                    </md-table-cell>
                    <md-table-cell md-label="Last Name" :title="item.LastName" style="width:22%;">
                      {{ item.LastName }}
                    </md-table-cell>
                    <!-- <md-table-cell md-label="Last Name">{{
                        item.LastName
                      }}</md-table-cell> -->
                    <!-- <md-table-cell md-label="User Name">{{
                        item.Username
                      }}</md-table-cell> -->
                    <md-table-cell md-label="Email" :title="item.Email" style="width:22%;">
                      {{ truncateEmail(item.Email) }}
                    </md-table-cell>
                    <md-table-cell md-label="Contact" style="width:15%;">
                      {{ item.ContactNumber }}
                    </md-table-cell>
                    <md-table-cell md-label="Active" style="width:15%;">{{
                      item.IsActive }}
                    </md-table-cell>
                    <md-table-cell md-label="Active" style="width:15%;">
                      <button @click="viewUserDetail(item.UserID)" type="button" class="btn btn-primary btn-sm ml-2"
                        title="View User">
                        <i class="fa fa-eye" aria-hidden="true"></i>
                      </button>
                    </md-table-cell>
                    <!-- <md-table-cell md-label="Tasks" style="width:12%;">
                        <button
                          @click="UserTasks(item.UserID,4)"
                          type="button"
                          style="background: rgb(158, 215, 158);color:blue;border: rgb(158, 215, 158);"
                          class="btn btn-primary btn-sm ml-2"
                          title="Completed Tasks"
                        >
                        {{
                        item.TotalCompletedTask 
                      }}
                        </button>
                        <button
                          @click="UserTasks(item.UserID,2)"
                          type="button"
                          style="background: rgb(234, 234, 141);color:blue;border:rgb(234, 234, 141);"
                          class="btn btn-primary btn-sm ml-2"
                          title="Assigned Tasks"
                        >
                        {{
                        item.TotalAssignedTask
                      }}
                        </button>
                      </md-table-cell> -->

                    <!-- <md-table-cell md-label="Actions" style="width:10%;text-align: right;">
                        <button v-if="item.IsActive" :hidden="false"
                        @click="() => showConfirmation(item.UserID, item.IsActive)" type="button" class="btn btn-primary btn-sm ml-2"
                          title="Deactivate User">
                          <i class="fas fa-lock-open"></i>
                        </button>
                        <button v-if="!item.IsActive" :hidden="false"
                        @click="() => showConfirmation(item.UserID, item.IsActive)" type="button" class="btn btn-primary btn-sm ml-2"
                          title="Activate User">
                          <i class="fas fa-lock"></i>
                        </button> -->
                    <!-- <button
                          @click="editUserDetail(item.UserID)"
                          type="button"
                          class="btn btn-primary btn-sm ml-2"
                          title="Edit User"
                        >
                          <i class="fas fa-pencil-alt"></i>
                        </button>
                        <button
                          @click="openDeleteModal(item.UserID)"
                          type="buttoncn"
                          class="btn btn-danger btn-sm"
                          title="Delete User"
                        >
                          <md-icon style="color: white">delete</md-icon>
                        </button>
                      </md-table-cell>-->
                  </md-table-row>
                  <md-table-row v-if="IsUsers" md-label="Actions" style="width:10%;text-align: right;">
                    <div class="fs-6 text-center my-2">
                      No data found.
                    </div>
                  </md-table-row>
                </md-table>
                <div class="d-flex my-1 mt-2" v-if="!IsUsers">
                  <div style="width: 33%;" class="d-flex">
                    <span style="width: 50%;line-height:50px;">Users per page:</span>
                    <Select @change="setPageSize" class="form-select PageSizeSelect" style="width: 40%;"
                      v-model="pageSize">
                      <Option :value="5">5</Option>
                      <Option :value="10">10</Option>
                      <Option :value="20">20</Option>
                      <Option :value="30">30</Option>
                    </Select>
                  </div>
                  <div class="Pagination">
                    <button @click="previousPage" :disabled="currentPage === 1" title="Previous"
                      :class="{ 'disabled-button': currentPage === 1, 'enabled-button': currentPage !== 1 }"><i
                        class="fa fa-angle-left"></i> Prev</button>
                    <span class="current-page-indicator">{{ currentPage }}</span>
                    <button @click="nextPage" :disabled="currentPage === totalPages" title="Next"
                      :class="{ 'disabled-button': currentPage === totalPages, 'enabled-button': currentPage !== totalPages }">Next
                      <i class="fa fa-angle-right"></i></button>
                  </div>
                  <div style="width: 33%" class="d-flex justify-content-end mx-3">
                    <span style="line-height:50px;" class="text-right mx-1">Total Pages: </span>
                    <span style="line-height:50px;" class="text-right">{{ totalPages }}</span>
                  </div>
                </div>
              </div>
            </div>
  <!-- Modal to show User  details-->
            <div class="modal fade" id="UserDetailsModal" tabindex="-1" aria-labelledby="UserDetailsLabel"
                aria-hidden="true" @hidden="handleuserModalHidden">
                <div class="modal-dialog modal-lg">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h1 class="modal-title fs-5" id="UserDetailsLabel">
                        User Details
                      </h1>
                      <button class="btn-close" title="Close" data-bs-dismiss="modal" aria-label="Close" tabindex="-1"></button>
                    </div>
                    <div>
                      <div class="modal-body">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="mb-3 d-flex flex-column">
                              <label class="col-form-label mb-0"><b>Email-Id</b></label>
                              <label class="col-form-label">{{ newUser.Email }}</label>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="mb-3 d-flex flex-column">
                              <label class="col-form-label"><b>Contact Number</b></label>
                              <label class="col-form-label">{{ newUser.ContactNumber }}</label>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="mb-3 d-flex flex-column">
                              <label class="col-form-label"><b>User Name</b></label>
                              <label class="col-form-label">{{ newUser.Username }}</label>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="mb-3 d-flex flex-column">
                              <label class="col-form-label bold"><b>Active</b></label>
                              <label class="col-form-label">{{ newUser.IsActive ? "Yes" : "No" }}</label>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="mb-3 d-flex flex-column">
                              <label class="col-form-label"><b>First Name</b></label>
                              <label class="col-form-label">{{ newUser.FirstName }}</label>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="mb-3 d-flex flex-column">
                              <label class="col-form-label bold"><b>Last Name</b></label>
                              <label class="col-form-label">{{ newUser.LastName }}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button type="button" title="Close" class="btn btn-secondary ml-2" data-bs-dismiss="modal" id="closeModal"
                          tabindex="8">
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import api, { setAuthToken } from "../apiConfig";
import { authMixin } from "./SuperAdminStore";
import Cookies from "js-cookie";
import { handleAuthorization, handleServerError, isValidEmail, isValidPhoneNumber, isValidText, preventNumberInput, preventTextInput } from "../Common/commonMethod";
import { ErrorLog } from "../Common/APILog";
import LoadingOverlay from '../Common/blockoutpage.vue';


export default {
  mixins: [authMixin],
  created() {
    this.checkAuthorization(); // Check authorization when the component is created
  },
  name: "Users",
  components: {
    LoadingOverlay,
   
  },
  props: {
    msg: String,
  },
  data() {
    return {
      users: [],
      ActivationMessage: "",
      Isloading: false,
      pageSize: 5, // Number of users per page
      currentPage: 1,// Current page
      searchText: '',
      IsUsers: false,
      filteredUser: [],
      filterUser: [],
      maxValue: 9999999999,
      newUser: {
        UserID: 0,
        FirstName: "",
        LastName: "",
        UserType: "User",
        Email: "",
        Username: "",
        Password: "",
        ContactNumber: "",
        ConfirmPassword: "",
      },
      userValidate: {
        FirstName: "",
        LastName: "",
        UserType: "",
        Email: "",
        Username: "",
        Password: "",
        ConfirmPassword: "",
        ContactNumber: "",
      },
      formHeader: "",
      editMode: false,
      tableHeader: "",
      successAlertOnSubmit: "",
      loading: "",
      btnEnable: false,
      name: "Users",
      // userTypes: ["User"],
    };
  },
  computed: {
    isRowExpired() {
      return (item) => item.IsExpired === 1;
    },
    paginatedUsers() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.filteredUser.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.filteredUser.length / this.pageSize);
    },
  },
  async mounted() {
    this.getUsers();
    const token = await Cookies.get("jwt");
    setAuthToken(token); // Fetch users when the component is mounted
  },
  methods: {

    isNumber: function (evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    handleModalHidden() {
      this.editMode = false;
    },
    editUserDetail(userId) {
      this.$router.push({
        name: "Edit User",
        params: { userId },
      });
    },
    handleuserModalHidden() {
        this.editUserMode = false;
      },
    truncateEmail(email) {
      // Truncate the description if it's longer than 30 characters
      return email.length > 50
        ? email.slice(0, 50) + "..."
        : email;
    },
    GoBack(){
      this.$router.go(-1)
    },
    // UserTasks(UserID, FilterID) {
    //   this.$router.push({
    //     name: "User Assigned Tasks",
    //     params: { UserID, FilterID },
    //   });
    // },

    viewUserDetail(UserID) {
      this.getUserDetailsById(UserID)
    },

    async getUserDetailsById(UserID) {
      try {
        const token = Cookies.get("jwt");
        setAuthToken(token);
        const response = await api.get(`/api/superAdmin/getAdminById/${UserID}`);
        if (response.data) {
          this.newUser = response.data[0];
          this.openUserDetailsModal()
        } else {
          this.userFormHeader = "Error: " + response.statusText;
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.userFormHeader = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.userFormHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 728, error.stack, "SuperAdminPanel/manageAdmins/getAdminDetailsById()");
          }
        }
        else {
          this.userFormHeader = "Something went wrong.";
        }
      }
    },
    openUserDetailsModal() {
        document.getElementById("openUserDetailsModal").click();
      },
    async getUsers() {
      this.users = [];

      this.loading = "loading..";
      this.Isloading = true;
      try {
        const token = await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie = await Cookies.get("user");
        userCookie = await userCookie ? JSON.parse(userCookie) : null;
        const UserID = await userCookie.UserID
        const response = await api.get(`/api/superAdmin/get-users/${UserID}`);
        if (response.data.length > 0) {
          this.users = response.data;
          this.filteredUser = this.users
          this.loading = "";
          console.log(this.users)
        } else {
          //  this.loading = "No record found!";
          //this.tableHeader = "No record found!";
        }
        // Continue with any other logic (e.g., navigate to a different page)
      } catch (error) {
        console.log(error)
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/adminlogin")
          }
          else if (error.response.status === 500) {
            handleServerError("/adminlogin")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.tableHeader = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.tableHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 698, error.stack, "AdminPanel/UserManagement/getUsers()");
          }
        }
        else {
          this.tableHeader = "Something went wrong.";
        }
      }
      finally {
        setTimeout(() => {
          this.tableHeader = "";
          this.successAlertOnSubmit = "";
        }, 5000); // 5 seconds in milliseconds
        this.Isloading = false;
        if (this.users.length > 0) {
          this.loading = "";
        }
        else {
          this.loading = "No record found!";
        }
        //  // Set loading state to false after data is fetched
      }
    },

    preventSpace(event) {
      // Check if the key pressed is a space (key code 32)
      if (event.key === ' ') {
        event.preventDefault(); // Prevent the space from being entered
      }
    },
    resetForm() {
      this.newUser = {
        UserID: 0,
        FirstName: "",
        LastName: "",
        UserType: "User",
        Email: "",
        Username: "",
        Password: "",
      };
      this.formHeader = "";
    },
    hideAlert() {
      // Clear the formHeader or set it to an empty string to hide the alert
      this.formHeader = "";
      this.successAlertOnSubmit = "";
    },


    setPageSize(event) {
      const selectedSize = event.target.value;
      this.pageSize = parseInt(selectedSize);
      this.currentPage = 1; // Reset to the first page
      this.paginatedUsers();
      // alert(`Page size changed to: ${selectedSize}`);
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    filterData: function (x) {
      this.IsUsers = false;
      this.currentPage = 1;
      if (parseInt(x) != 5 && parseInt(x) != 6) {
        this.filterUser = this.users.filter(item => {
          return (
            item.TaskStatus == parseInt(x)
          );

        });
        if (this.filterUser.length > 0) {
          this.filteredUser = this.filterUser;
        }
        else {
          console.log("ok");
          this.filteredUser = [];
          this.IsUsers = true;
        }
      }
      if (parseInt(x) == 5) {
        this.filterUser = this.users.filter(item => {
          return (
            item.IsExpired == 1
          );

        });
        if (this.filterUser.length > 0) {
          this.filteredUser = this.filterUser;
        }
        else {
          console.log("ok");
          this.filteredUser = [];
          this.IsUsers = true;
        }
      }
      if (parseInt(x) == 6) {
        this.filteredUser = this.users;
      }

      // Filter tasks based on searchText

    },
    filterGrid: function () {
      var searchText = this.searchText;
      this.IsUsers = false;
      this.currentPage = 1;
      if (parseInt(searchText) > 0) {
        this.filterUser = this.users.filter(item => {
          return (
            item.UserID == parseInt(searchText)
          );
        });
        if (this.filterUser.length > 0) {
          this.filteredUser = this.filterUser;
        }
        else {
          console.log("ok");
          this.filteredUser = [];
          this.IsUsers = true;
        }
      }
      else {
        searchText = searchText.toLowerCase();
        this.filterUser = this.users.filter(item => {
          return (
            item.FirstName.toLowerCase().includes(searchText) ||
            item.LastName.toLowerCase().includes(searchText) ||
            item.Username.toLowerCase().includes(searchText) ||
            item.Email.toLowerCase().includes(searchText)
          );
        });
        if (this.filterUser.length > 0) {
          this.filteredUser = this.filterUser;
        }
        else {
          console.log("ok");
          this.filteredUser = [];
          this.IsUsers = true;
        }
      }
      // Filter users based on searchText
    },
    truncateDescription(description) {
      // Truncate the description if it's longer than 30 characters
      return description.length > 25
        ? description.slice(0, 25) + "..."
        : description;
    },
  },
};
</script>

<style scoped>
/* Add Tailwind CSS classes here if needed */
.close-icon {
  cursor: pointer;
  float: right;
  font-size: 30px;
  margin-top: -10px;
}

.mb-3 {
  text-align: left;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1em;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.toprow {
  color: #fff;
  background: #0d6efd;
  height: 35px;
  font-weight: 500;
  font-size: 16px;
}

.link-a {
  color: blue !important;
  font-weight: 700;
}

.table th {
  background-color: #f2f2f2;
}

/* Additional styling for left and right padding */
.table th,
.table td {
  padding-left: 12px;
  padding-right: 12px;
}

.actionBtns {
  text-align: right !important;
}

.modal-content .modal-header {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.modal-title {
  margin-top: 10px;
}

.modal-content .modal-body {
  padding-top: 10px;
}


.Pagination {
  width: 60%;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.Pagination button {
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 17px;
}

.enabled-button {
  background-color: #007bff;
  /* Blue color for enabled button */
  color: #ffffff;
  /* White text color for enabled button */
  border: 2px solid #007bff
}

.disabled-button {
  background-color: lightgray;
  /* Gray color for disabled button */
  color: #ffffff;
  /* White text color for disabled button */
  border: 2px solid lightgray;
}

.current-page-indicator {
  background-color: green;
  border-radius: 50%;
  /* Ensures the element is rounded */
  padding: 3px;
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  /* Adjust this value as needed */
  height: 25px;
  /* Ensure width and height are equal */
}

.PageSizeSelect {
  border-radius: 5px;
  margin: 5px;
  height: 35px;
  font-size: 17px;
}
</style>