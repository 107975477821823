<template>
    <div>
      <pie-chart />
      <input type="file" ref="fileInput">
      <button title="Upload File" @click="uploadFile">Upload</button>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import api, { setAuthToken } from "../apiConfig";
  import PieChart from './PieChart.vue';
  export default {
    components: {
    PieChart
  },
    methods: {
      async uploadFile() {
        try {
          const formData = new FormData();
          formData.append('file', this.$refs.fileInput.files[0]);
  const file=this.$refs.fileInput.files[0];
  console.log(file)
        //   const response = await axios.post('http://localhost:4000/api/admin/tasks/update-dummy',formData ,{
        //     headers: {
        //         'Content-Type': 'multipart/form-data'
        //     }
            
        //   });
        const response = await api.post('/api/admin/tasks/update-dummy',formData ,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
            
          });
  
          console.log('File uploaded:', response.data);
        } catch (error) {
          console.error('Error:', error);
        }
      }
    }
  }
  </script>
  