<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">

    <!-- <ul id="notifications"></ul> -->
    <side-bar :sidebar-item-color="sidebarBackground" :sidebar-background-image="sidebarBackgroundImage">
      <mobile-menu slot="content"></mobile-menu>
      <sidebar-link to="/user/dashboard">
        <md-icon>dashboard</md-icon>
        <p>Dashboard</p>
      </sidebar-link>
      <sidebar-link to="/user/my-tasks">
        <md-icon>assignment_add</md-icon>
        <p>Assigned Tasks
          <sup>
            <span v-if="NewTaskCount>0" class="notification">
              <!-- {{ unReadChatCount }} -->
              {{ formatChatCount(NewTaskCount)}}
              <!-- 34 -->
            </span>
          </sup>
        </p>
      </sidebar-link>
      <sidebar-link to="/user/task-management">
        <md-icon>task</md-icon>
        <p>My To-Do's</p>
      </sidebar-link>
      <sidebar-link to="/user/task-messages">
        <i class="fas fa-comment" style="color:#fff;opacity:0.8;"></i>
        <p>Messages
          <sup>
            <span v-if="unReadChatCount > 0" class="notification">{{ formatChatCount(unReadChatCount) }}</span>
          </sup>
        </p>
      </sidebar-link>
      <sidebar-link to="/user/user-profile">
        <md-icon>person</md-icon>
        <p>My Profile</p>
      </sidebar-link>
      <sidebar-link to="/user/change-password">
        <md-icon>password</md-icon>
        <p>Change Password</p>
      </sidebar-link>
      <sidebar-link @click="logout" to="/login">
        <md-icon @click="logout">logout</md-icon>
        <p @click="logout">Logout</p>
      </sidebar-link>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>
      <dashboard-content> </dashboard-content>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
    <div class="notificationbar">
        <notification v-for="(notification1, index) in notifications" :key="index" :message="notification1.FileName?notification1.FileName : notification1.message"
        :icon="notification1.icon" :verticalAlign="notification1.verticalAlign"
        :horizontalAlign="notification1.horizontalAlign" :type="notification1.type" :timeout="notification1.timeout"
        :timestamp="notification1.timestamp" @on-close="removeNotification(index)" />
    </div>

  </div>
</template>

<script>
import TopNavbar from "./UserTopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./UserContent.vue";
import MobileMenu from "@/pages/Layout/UserMobileMenu.vue";
import { handleAuthorization, handleServerError, handleSessionTimeout } from "../../components/Common/commonMethod";
import api, { baseWebsocketUrl, setAuthToken } from "../../components/apiConfig";
import Cookies from "js-cookie"
import { ErrorLog } from "../../components/Common/APILog";
import Notification from "../../components/NotificationPlugin/Notification.vue";

export default {
  components: {
    TopNavbar,
    DashboardContent,
    ContentFooter,
    MobileMenu,
    Notification,
  },
  data() {
    return {
      unReadChatCount: 0,
      NewTaskCount:0,
      sidebarBackground: "green",
      notifications: [],
      flagCount: 0,
      sidebarBackgroundImage: require("@/assets/img/sidebar-2.jpg"),
      logoutTimer: null,
      chatCountInterval: null,
      taskCountInterval: null,
    };
  },
  created() {
    this.getUnReadChatCount();
    this.getNewTaskCount();
  },
  mounted() {
    const socket = new WebSocket(baseWebsocketUrl)
    // const socket = new WebSocket('ws://trackerapi.workanthem.com')
    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      // debugger;
      // console.log(message)
      let userCookie = Cookies.get("user");
      userCookie = userCookie ? JSON.parse(userCookie) : null;
      if (userCookie.UserID !== message.SenderId) {
        // shownotication(message)
        this.notifications.push({
          message: message.Message,
          icon: 'info',
          verticalAlign: 'top',
          horizontalAlign: 'center',
          type: 'info',
          timeout: 9000, // Optional timeout to automatically close the notification
          timestamp: new Date()
        });
      }
    }
    handleSessionTimeout("/login")
    // Set intervals to call the functions every 5 seconds
    this.chatCountInterval = setInterval(this.getUnReadChatCount, 5000);
    this.taskCountInterval = setInterval(this.getNewTaskCount, 5000);
  },
  beforeDestroy() {
    // Clear intervals when the component is destroyed
    clearInterval(this.chatCountInterval);
    clearInterval(this.taskCountInterval);
  },
  methods: {
    async getUnReadChatCount() {
      try {
        const token = await Cookies.get("jwt");
        let userCookie = await Cookies.get("user");
        let userCookies =await userCookie ? JSON.parse(userCookie) : null;
        const uid = userCookies.UserID
        setAuthToken(token);
        const response = await api.get(
          `/api/Chat/get-unread-chat-count-by-userid/${uid}`
        );
        if (response.data) {
          const result = response.data[0];
          // console.log(result)
          this.unReadChatCount = response.data[0].UnReadChatCount;
          // console.log(response.data)
          // console.log(this.unReadChatCount);
        }
        // else {
        //   this.errorAlertOnSubmit = "Something went wrong. please try again later.";
        // }
      } 
      catch (error) {
        if (this.flagCount > 3) {
          if (error.response && error.response.data) {
            if (error.response.status === 401) {
              handleAuthorization("/login")
            }
            else if (error.response.status === 500) {
              handleServerError("/login")
            }
            else {
              const jsonResponseString = JSON.stringify(error.response.data);
              try {
                const jsonObject = JSON.parse(jsonResponseString);
                // this.tableHeader = jsonObject.message || "Something went wrong.";
              } catch (parseError) {
                // this.tableHeader = parseError.message || "Something went wrong.";
              }
              ErrorLog(error.message, error.response.status, 152, error.stack, "Layout/UserDashboard/getUnReadChatCount()");
            }
          }
          else {
            // this.er = "Something went wrong.";
          }
        }
        else {
          setTimeout(() => {
            this.getUnReadChatCount()
          }, 10000); // 1 seconds in milliseconds        
          this.flagCount++;
        }
      }
      finally {
        this.loading = true;
      }
    },
    removeNotification(index) {
      this.notifications.pop(index, 1);
    },
    formatChatCount(count) {
      return count < 10 ? `0${count}` : count;
    },
    logout() {
      setAuthToken();
      //alert("Logout successfully.");
      this.$router.push("/login");
    },

    async getNewTaskCount() {
      try {
        const token = await Cookies.get("jwt");
        let userCookie = await Cookies.get("user");
        let userCookies =await userCookie ? JSON.parse(userCookie) : null;
        const uid = userCookies.UserID
        setAuthToken(token);
        const response = await api.get(
          `/api/tasks/get-new-task-count/${uid}`
        );
        if (response.data) {
          const result = response.data[0];
          // console.log(result)
          this.NewTaskCount = response.data[0].NewTaskCount;
          // console.log(response.data)
          // console.log("new task count : "+this.NewTaskCount);
        }
      } 
      catch (error) {
        if (this.flagCount > 3) {
          if (error.response && error.response.data) {
            if (error.response.status === 401) {
              handleAuthorization("/login")
            }
            else if (error.response.status === 500) {
              handleServerError("/login")
            }
            else {
              const jsonResponseString = JSON.stringify(error.response.data);
              try {
                const jsonObject = JSON.parse(jsonResponseString);
                // this.tableHeader = jsonObject.message || "Something went wrong.";
              } catch (parseError) {
                // this.tableHeader = parseError.message || "Something went wrong.";
              }
              ErrorLog(error.message, error.response.status, 152, error.stack, "Layout/UserDashboard/getNewTaskCount()");
            }
          }
          else {
            // this.er = "Something went wrong.";
          }
        }
        else {
          setTimeout(() => {
            this.getNewTaskCount()
          }, 10000); // 1 seconds in milliseconds        
          this.flagCount++;
        }
      }
      finally {
        this.loading = true;
      }
    },
  },
};
</script>
<style>
.notificationbar {
  position: relative;
  top: 100px;
}

.notification {
  border-radius: 100%;
  padding: 5px;
  text-align: center;
  font-size: 11px;
  color: white;
  background-color: red;
  width: 40px;
}
</style>