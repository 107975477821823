<template>
  <router-view></router-view>
</template>

<script>

export default {
  mounted(){

  }
};
</script>
<style scoped>
  body{
    overflow-x: hidden !important;
  }
</style>