<template>
  <div class="content">
    <LoadingOverlay :loading="Isloading" />
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
        <md-card>
          <md-card-header data-background-color="green">
            <div class="card-header d-flex justify-content-between align-items-center">
              <h4 class="title">My To-Do</h4>
              <div class="ms-auto">
                <RouterLink to="/task-management" class="btn btn-dark btn-sm ml-2">
                  Back
                </RouterLink>
              </div>
            </div>
          </md-card-header>
          <md-card-content>
            <div class="col-md-12">
              <!-- Display success alert -->
              <div v-if="successAlertOnSubmit" class="alert alert-success text-left" role="alert">
                {{ successAlertOnSubmit }}
                <span @click="hideTaskAlert" class="close-icon">&times;</span>
              </div>
              <div class="table-responsive">
                <div v-if="loadingTasks" class="text-center">
                  <p>{{ loadingTasks }}</p>
                </div>
                <md-table v-if="!loadingTasks" v-model="tasks" :table-header-color="tableHeaderColor">
                  <md-table-row slot="md-table-row" slot-scope="{ item }"
                    :class="{ 'expired-row': isRowExpired(item) }">
                    <md-table-cell md-label="Title">{{
                      item.Title
                    }}</md-table-cell>
                    <md-table-cell md-label="Description">
                      <span v-if="item.Description.length > 25" :title="item.Description">
                        {{ truncateDescription(item.Description) }}
                      </span>
                      <span v-else>
                        {{ item.Description }}
                      </span></md-table-cell>
                    <md-table-cell md-label="Due Date">{{
                      formatDueDate(item.DueDate)
                    }}</md-table-cell>
                    <md-table-cell md-label="Priority">{{
                      item.PriorityName
                    }}</md-table-cell>
                    <md-table-cell md-label="Category">{{
                      item.CategoryName
                    }}</md-table-cell>
                    <md-table-cell md-label="Assign By">{{
                      item.AssignedBy
                    }}</md-table-cell>
                    <md-table-cell md-label="Is Completed">{{
                      item.IsCompleted ? "Yes" : "No"
                    }}</md-table-cell>
                    <md-table-cell md-label="Actions" class="actions">
                      <button v-if="!item.IsCompleted" @click="confirmComplete(item.TaskId)" type="button"
                        class="btn btn-primary btn-sm ml-2" title="Mark as completed.">
                        Complete
                      </button>
                    </md-table-cell>
                  </md-table-row>
                </md-table>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import api, { setAuthToken } from "../apiConfig";
import { authMixin } from "./adminStore";
import Cookies from "js-cookie";
import LoadingOverlay from '../Common/blockoutpage.vue';

import { ErrorLog } from "../Common/APILog";
import { handleAuthorization, handleServerError } from "../Common/commonMethod";

export default {
  mixins: [authMixin],
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  created() {
    this.checkAuthorization();
  },
  name: "TaskManagement",
  components: {
    LoadingOverlay
  },
  data() {
    return {
      tasks: [],
      title: "My Task Management",
      Isloading:false,
      taskFormHeader: "",
      successAlertOnSubmit: "",
      loadingTasks: "",
    };
  },
  computed: {
    isRowExpired() {
      return (item) => item.IsExpired === 1;
    },
  },
  mounted() {
    this.getTasks();
  },
  methods: {
    formatDueDate(originalDate, type = "show") {
      if (!originalDate) return ""; // Handle null or undefined dates
      const dateObject = new Date(originalDate);
      const day = dateObject.getDate().toString().padStart(2, "0");
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObject.getFullYear();
      if (type == "show") {
        return `${day}/${month}/${year}`;
      } else {
        return `${year}-${month}-${day}`;
      }
    },
    truncateDescription(description) {
      // Truncate the description if it's longer than 30 characters
      return description.length > 25
        ? description.slice(0, 25) + "..."
        : description;
    },
    async getTasks() {
      this.tasks = [];
      this.Isloading=true;
      this.loadingTasks = "Loading...";
      try {
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        const response = await api.get(
          `/api/admin/tasks/get-my-tasks/${userCookie.UserID}`
        );
        if (response.data) {
          this.tasks = response.data;
        } else {
          // Handle case where no tasks are found
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.taskFormHeader = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.taskFormHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 166, error.stack, "AdminPanel/myTasks/getTasks()");
          }
        }
        else {
          this.taskFormHeader = "Something went wrong.";
        }
        // Handle API error
      } finally {
        this.Isloading=false;
        if (this.tasks.length > 0) {
          this.loadingTasks = "";
        } else {
          this.loadingTasks = "No record found.";
        }
      }
    },
    hideTaskAlert() {
      this.taskFormHeader = "";
      this.successAlertOnSubmit = "";
    },
    async CompleteTask(taskId) {
      try {
        this.Isloading=true;
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        // Make your delete API call here
        const response = await api.post(`/api/admin/tasks/complete-task`, {
          TaskID: taskId,
          UserID: userCookie.UserID,
        }); // Replace with your actual API endpoint

        if (response.data.success) {
          this.getTasks();
          this.successAlertOnSubmit = response.data.msg;
        } else {
          alert(response.data.msg);
        }
        // Optionally, perform additional actions after successful deletion
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.taskFormHeader = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.taskFormHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 223, error.stack, "AdminPanel/myTasks/CompleteTask()");
          }
        }
        else {
          this.taskFormHeader = "Something went wrong.";
        }
      }
      finally{
        this.Isloading=false;
      }
    },
    confirmComplete(taskId) {
      const isConfirmed = window.confirm("Are you sure you want to complete?");
      if (isConfirmed) {
        // Call the deleteItem method when confirmed
        this.CompleteTask(taskId);
      }
    },
  },
};
</script>

<style scoped>
/* Add Tailwind CSS classes here if needed */
.close-icon {
  cursor: pointer;
  float: right;
  font-size: 30px;
  margin-top: -10px;
}

.mb-3 {
  text-align: left;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1em;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.table th {
  background-color: #f2f2f2;
}

/* Additional styling for left and right padding */
.table th,
.table td {
  padding-left: 12px;
  padding-right: 12px;
}

.actionBtns {
  text-align: right !important;
}

.user-list {
  max-height: 120px;
  /* Set a maximum height for the user list */
  overflow-y: auto;
  /* Add a scrollbar when the content exceeds the height */
  border: 1px solid #ccc;
  /* Optional: Add a border for styling */
}

.user-item {
  margin-bottom: 8px;
  /* Optional: Add some spacing between user items */
}

.expired-row {
  background-color: #f66868;
}
</style>
