<template>
  <div class="content">
    <LoadingOverlay :loading="Isloading" />
    <div class="md-layout">
      <div class="md-layout-item md-large-size-100 md-size-66">
        <form @submit.prevent="">
          <md-card style="padding-bottom: 20px;">
            <md-card-header data-background-color="green" style="overflow: hidden;padding-bottom: 10px;">
              <h4 class="title" style="float: left;">Edit User</h4>
              <!-- <button type="button" title="Back" class="btn btn-secondary ml-2" id="closeModal" tabindex="7" style="float: right;"
                @click="goBack">
                Back
              </button> -->
              <RouterLink style="float: right;" to="/user-management" title="Back" class="btn btn-dark btn-sm ms-2 ml-2">
                  Back
                </RouterLink>
            </md-card-header>
            <md-card-content>
              <!-- Display success alert -->
              <div v-if="successAlertOnSubmit" class="alert alert-success text-left" role="alert">
                {{ successAlertOnSubmit }}
                <span @click="hideTaskAlert" class="close-icon">&times;</span>
              </div>
              <div v-if="errorAlertOnSubmit" class="alert alert-danger text-left" role="alert">
                {{ errorAlertOnSubmit }}
                <span @click="hideTaskAlert" class="close-icon">&times;</span>
              </div>
              <div v-if="loadingUser" class="text-center">
                <p>{{ loadingUser }}</p>
              </div>
              <form @submit.prevent="submitForm" v-if="!loadingUser">
                <input v-model="newUser.UserID" type="hidden" class="form-control" />
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for="title" class="col-form-label">First Name</label>
                      <input v-model="newUser.FirstName" type="text" class="form-control" id="firstname" tabindex="1"
                        @keyup="isValidForm" />
                      <span class="text-red-500 text-sm">{{
                        userValidate.FirstName
                      }}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for="lastname" class="col-form-label">Last Name</label>
                      <input v-model="newUser.LastName" type="text" class="form-control" id="lastname" tabindex="2"
                        @keyup="isValidForm" />
                      <span class="text-red-500 text-sm">{{
                        userValidate.LastName
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for="email" class="col-form-label">Email</label>
                      <input v-model="newUser.Email" type="text" class="form-control" id="email" tabindex="3"
                        @keyup="isValidForm" /><span class="text-red-500 text-sm">{{
                          userValidate.Email
                        }}</span>
                    </div>
                    <div class="mb-3">
                      <label for="password" class="col-form-label">Password</label>
                      <input v-model="newUser.Password" type="password" class="form-control" id="password"
                        maxlength="15" tabindex="6" @keyup="isValidForm" />
                      <span class="text-red-500 text-sm">{{
                        userValidate.Password
                      }}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for="username" class="col-form-label">Username</label>
                      <input v-model="newUser.Username" type="text" class="form-control" id="username" tabindex="5"
                        @keyup="isValidForm" />
                      <span class="text-red-500 text-sm">{{
                        userValidate.Username
                      }}</span>
                    </div>
                    <div class="mb-3">
                      <label for="ContactNumber" class="col-form-label">Contact Number</label>
                      <input v-model="newUser.ContactNumber" class="form-control" id="ContactNumber" tabindex="4"
                        type="text" maxlength="10" @keypress="isNumber($event)" @keyup="isValidForm" />
                      <span class="text-red-500 text-sm">{{
                        userValidate.ContactNumber
                      }}</span>
                    </div>
                  </div>
                </div>
                <button type="submit" title="Update" class="btn btn-primary" style="float: right;" default tabindex="6"
                  :disabled="btnEnable">
                  Update
                </button>
              </form>
            </md-card-content>
          </md-card>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import api, { setAuthToken } from "../apiConfig";
import { authMixin } from "./adminStore";
import Cookies from "js-cookie";
import { handleAuthorization, handleServerError, isValidEmail, isValidPhoneNumber, isValidText, preventNumberInput, preventTextInput } from "../Common/commonMethod";
import { ErrorLog } from "../Common/APILog";
import LoadingOverlay from '../Common/blockoutpage.vue';


export default {
  mixins: [authMixin],
  created() {
    this.checkAuthorization(); // Check authorization when the component is created

  },
  name: "EditUser",
  components: {
    LoadingOverlay
  },
  props: {
    userId: {
      validator(value) {
        const numberValue = parseInt(value);
        if (isNaN(numberValue)) {
          return false;
        }
        return true;
      }
    },
  },
 async mounted() {
    this.getUserById();
    const token =await Cookies.get("jwt");
    setAuthToken(token); // Fetch users when the component is mounted

  },
  data() {
    return {
      Isloading:false,
      loadingUser: "",
      btnEnable: false,
      users: [],
      successAlertOnSubmit: "",
      errorAlertOnSubmit: "",
      newUser: {
        UserID: 0,
        FirstName: "",
        LastName: "",
        UserType: "User",
        Email: "",
        Username: "",
        Password: "",
        ContactNumber: "",
      },
      userValidate: {
        FirstName: "",
        LastName: "",
        UserType: "",
        Email: "",
        Username: "",
        Password: "",
        ContactNumber: "",
      },
      errors: {},
      formHeader: "",
    };
  },
  computed: {
  },
  methods: {
    isNumber: function (evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    hideTaskAlert() {
      this.successAlertOnSubmit = "";
      this.errorAlertOnSubmit = "";
    },
    // goBack() {
    //   // Go back to the previous page in the history
    //   this.$router.push("/user-management");
    // },
    async getUsers() {
      this.users = [];
      this.loading = true;
      this.Isloading=true;
      try {
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        const response = await api.get(`/api/users/get-users/${userCookie.UserID}`);
        if (response.data.length > 0) {
          this.users = response.data;
        } else {
        }
        // Continue with any other logic (e.g., navigate to a different page)
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 220, error.stack, "AdminPanel/EditUser/getUsers()");
          }
        }
        else {
          this.errorAlertOnSubmit = "Something went wrong.";
        }
      }
      finally {
        this.Isloading=false;
        //  this.loading = false; // Set loading state to false after data is fetched
      }
    },
    isValidForm() {
      let isValid = true;
      // First Name
      if (!this.newUser.FirstName.trim()) {
        this.userValidate.FirstName = "First name is required.";
        isValid = false;
      } else {
        this.userValidate.FirstName = "";
      }
      if (!this.newUser.LastName.trim()) {
        this.userValidate.LastName = "Last name is required.";
        isValid = false;
      } else {
        this.userValidate.LastName = "";
      }
      if (!this.newUser.ContactNumber) {
        this.userValidate.ContactNumber = "Contact number is required.";
        isValid = false;
      }
      else if (!isValidPhoneNumber(this.newUser.ContactNumber)) {
        this.userValidate.ContactNumber = "Please enter a valid Contact Number.";
        isValid = false;
      }
      else if (this.newUser.ContactNumber.length == 10) {
        var userFilter = this.users.filter(item => {
          return (
            item.ContactNumber == parseInt(this.newUser.ContactNumber)
            && item.UserID != this.newUser.UserID
          );
        });
        if (userFilter.length > 0) {
          this.userValidate.ContactNumber = "The entered contact is already exists.";
          isValid = false;
        }
        else {
          this.userValidate.ContactNumber = "";
        }
      }
      else {
        this.userValidate.ContactNumber = "";
      }

      // Email
      if (!this.newUser.Email.trim()) {
        this.userValidate.Email = "Email is required.";
        isValid = false;
      } else if (!isValidEmail(this.newUser.Email.trim())) {
        this.userValidate.Email = "Please enter valid email address.";
        isValid = false;
      }
      else if (isValidEmail(this.newUser.Email.trim())) {
        var userFilter = this.users.filter(item => {
          return (
            item.Email == this.newUser.Email.trim()
            && item.UserID != this.newUser.UserID
          );
        });
        console.log(userFilter)
        if (userFilter.length > 0) {
          this.userValidate.Email = "The entered email already exists.";
          isValid = false;
        }
        else {
          this.userValidate.Email = "";
        }
      }
      else {
        this.userValidate.Email = "";
      }

      if (!this.newUser.Username.trim()) {
        this.userValidate.Username = "User name is required.";
        isValid = false;
      }
      else if (/\s/.test(this.newUser.Username)) {
        this.userValidate.Username = "Please remove whitespace in user name.";
        isValid = false;
      }
      else {
        this.userValidate.Username = "";
      }

      if (!this.newUser.Password.trim()) {
        this.userValidate.Password = "Password is required.";
        isValid = false;
      } else {
        this.userValidate.Password = "";
      }
      if (this.newUser.Password.length < 8 && this.newUser.Password) {
        this.userValidate.Password = "Password must be minimum of 8 characters.";
        isValid = false;
      }
      else if (this.newUser.Password.length >= 8) {
        var lowerCaseLetters = /[a-z]/g;
        var upperCaseLetters = /[A-Z]/g;
        var number = /[0-9]/g;
        var special = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/;
        if (!this.newUser.Password.match(lowerCaseLetters)) {
          this.userValidate.Password = "Password must contain lowercase letter.";
          isValid = false;
        }
        if (!this.newUser.Password.match(upperCaseLetters)) {
          this.userValidate.Password = "Password must contain capital letter.";
          isValid = false;
        }
        if (!this.newUser.Password.match(number)) {
          this.userValidate.Password = "Password must contain number.";
          isValid = false;
        }
        if (!this.newUser.Password.match(special)) {
          this.userValidate.Password = "Password must contain special character.";
          isValid = false;
        }

      }
      return isValid;
    },
    async submitForm() {
      // Reset validation messages
      this.resetValidation();
      // Validate form
      if (this.isValidForm()) {
        this.updateUser();
      }
    },
    async updateUser() {
      this.errorAlertOnSubmit = "";
      this.Isloading=true;
      this.btnEnable = true;
      try {
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        const response = await api.put(
          `/api/users/update-user/${this.newUser.UserID}`,
          {
            Username: this.newUser.Username,
            Password: this.newUser.Password,
            UserType: this.newUser.UserType,
            FirstName: this.newUser.FirstName,
            LastName: this.newUser.LastName,
            Email: this.newUser.Email,
            ContactNumber: this.newUser.ContactNumber,
          }
        );
        if (response.data.success) {
          this.successAlertOnSubmit = response.data.msg;

        } else {
          this.successAlertOnSubmit = response.data.msg;
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 398, error.stack, "AdminPanel/EditUser/updateUser()");
          }
        }
        else {
          this.errorAlertOnSubmit = "Something went wrong.";
        }
      } finally {
        this.Isloading=false;
        this.btnEnable = false;
        setTimeout(() => {
          this.errorAlertOnSubmit = "";
          this.successAlertOnSubmit = "";
        }, 5000); // 3 seconds in milliseconds
        setTimeout(() => {
          // Call your function here
          // this.goBack();
        }, 2000);
      }
    },
    resetValidation() {
      this.userValidate = {
        FirstName: "",
        LastName: "",
        ContactNumber: "",
        Email: "",
        Username: "",
        Password: "",
      };
    },
    resetForm() {
      this.newUser = {
        UserID: 0,
        FirstName: "",
        LastName: "",
        UserType: "User",
        Email: "",
        Username: "",
        Password: "",
      };
      this.errorAlertOnSubmit = "";
    },

    async getUserById() {
      try {
        const token =await Cookies.get("jwt");
        this.Isloading=true;
        setAuthToken(token);
        this.loadingUser = "Loading...";
        const response = await api.get(`/api/users/getuserbyid/${this.userId}`);
        if (response.data) {
          this.newUser = response.data[0];
          this.resetValidation();
        } else {
          this.errorAlertOnSubmit = "Internal server error";
        }
        // Continue with any other logic (e.g., navigate to a different page)
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 470, error.stack, "AdminPanel/EditUser/getUserById()");
          }
        }
        else {
          this.errorAlertOnSubmit = "Something went wrong.";
        }
      } finally {
        this.getUsers();
        this.Isloading=false;
        this.loadingUser = "";
      }
    },
  },
};
</script>

<style scoped>
/* Add Tailwind CSS classes here if needed */
.close-icon {
  cursor: pointer;
  float: right;
  font-size: 30px;
  margin-top: -10px;
}

#message span {
  display: block;
}
</style>
