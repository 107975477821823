<template>
    <div class="content">
        <!-- Your main content goes here -->
        <LoadingOverlay :loading="Isloading" />
        <div class="md-layout">
            <div class="md-layout-item md-large-size-100 md-size-66">
                <form @submit.prevent="updateUser">
                    <md-card style="padding-bottom: 20px;">
                        <md-card-header data-background-color="green" class="d-flex justify-content-between"
                            style="overflow: hidden;">
                            <h4 class="title" style="float: left;">My Profile</h4>
                        </md-card-header>
                        <md-card-content>
                            <!-- Display success alert -->
                            <div v-if="successAlertOnSubmit" class="alert alert-success text-left" role="alert">
                                {{ successAlertOnSubmit }}
                                <span @click="hideUserAlert" class="close-icon">&times;</span>
                            </div>
                            <div v-if="loadingUser" class="text-center">
                                <p>{{ loadingUser }}</p>
                            </div>
                            <form @submit.prevent="submitUserForm" v-if="!loadingUser">
                                <input v-model="editSuperAdmin.UserID" type="hidden" class="form-control" />
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <label for="firstName" class="col-form-label">First Name</label>
                                            <input v-model="editSuperAdmin.FirstName" type="text" class="form-control"
                                                id="firstName" tabindex="1" @input="isValidUserForm" />
                                            <span class="text-red-500 text-sm">{{
                                                userValidate.FirstName
                                            }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <label for="title" class="col-form-label">Last Name</label>
                                            <input v-model="editSuperAdmin.LastName" type="text" class="form-control"
                                                id="title" tabindex="1" @input="isValidUserForm" />
                                            <span class="text-red-500 text-sm">{{
                                                userValidate.LastName
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <label for="email" class="col-form-label">Email</label>
                                            <input v-model="editSuperAdmin.Email" type="text" class="form-control"
                                                id="email" tabindex="1" @input="isValidUserForm" />
                                            <span class="text-red-500 text-sm">{{
                                                userValidate.Email
                                            }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <label for="Username" class="col-form-label">User Name</label>
                                            <input v-model="editSuperAdmin.Username" type="text" class="form-control"
                                                id="Username" :disabled="true" tabindex="1" @input="isValidUserForm" />
                                            <span class="text-red-500 text-sm">{{
                                                userValidate.Username
                                            }}</span>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-6">
                                        <div class="mb-3">
                                            <label for="Password" class="col-form-label">Password</label>
                                            <input v-model="editSuperAdmin.Password" type="password"
                                                class="form-control" id="Password" tabindex="1"
                                                @input="isValidUserForm" />
                                            <span class="text-red-500 text-sm">{{
                                                userValidate.Password
                                            }}</span>
                                        </div>
                                    </div> -->
                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <label for="Contact" class="col-form-label">Contact</label>
                                            <input v-model="editSuperAdmin.ContactNumber" type="text" maxlength="10"
                                                class="form-control" id="Contact" tabindex="1"
                                                @keypress="isNumber($event)" @input="isValidUserForm" />
                                            <span class="text-red-500 text-sm">{{
                                                userValidate.ContactNumber
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" title="Update" class="btn btn-primary" default tabindex="6" style="float: right;"
                                    :disabled="btnEnable">
                                    Update
                                </button>
                            </form>
                        </md-card-content>
                    </md-card>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import api, { setAuthToken } from "../apiConfig";
import { authMixin } from "./SuperAdminStore";
import Cookies from "js-cookie";
import LoadingOverlay from '../Common/blockoutpage.vue'
import { handleAuthorization, handleServerError, isValidPhoneNumber, setPageTitle } from "../Common/commonMethod";
import { ErrorLog } from "../Common/APILog";

export default {
    mixins: [authMixin],
    created() {
        // setPageTitle(this.$router.name)
        this.checkAuthorization(); // Check authorization when the component is created
        this.getUserById();
    },
    name: "editSuperAdmin",
    components: {
        LoadingOverlay
    },
    props: {
    },
    data() {
        return {
            Isloading: false,
            loadingUser: "",
            successAlertOnSubmit: "",
            categoryDropdownOptions: [],
            priorityDropdownOptions: [],
            btnEnable: false,
            editSuperAdmin: {
                UserID: 0,
                FirstName: "",
                LastName: "",
                Username: "",
                ContactNumber: "",
                Email: "",
                // Password: "",
                UserType: "User",
            },
            userValidate: {
                FirstName: "",
                LastName: "",
                Username: "",
                ContactNumber: "",
                Email: "",
                UserType: "",
                // Password: "",
            },
            errors: {},
            formHeader: "",
        };
    },
    computed: {
    },
    mounted() {
        // Initial data
    },
    methods: {
        isNumber: function (evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },
        goBack() {
            // Go back to the previous page in the history
            this.$router.go(-1);
        },
        hideUserAlert() {
            this.successAlertOnSubmit = "";
        },
        async updateUser() {
            if (this.isValidUserForm()) {
                this.Isloading = true;
                this.formHeader = "";
                this.btnEnable = true;
                try {
                    const token = Cookies.get("jwt");
                    setAuthToken(token);
                    let userCookie = Cookies.get("user");
                    userCookie = userCookie ? JSON.parse(userCookie) : null;
                    const userId = this.editSuperAdmin.UserID
                    console.log(this.editSuperAdmin.UserID)
                    const response = await api.put(
                        `/api/superAdmin/update-superAdminProfile/${userId}`,
                        {
                            Username: this.editSuperAdmin.Username,
                            // Password: this.editSuperAdmin.Password,
                            FirstName: this.editSuperAdmin.FirstName,
                            LastName: this.editSuperAdmin.LastName,
                            Email: this.editSuperAdmin.Email,
                            ContactNumber: this.editSuperAdmin.ContactNumber,
                            ModifiedBy: userCookie.UserID
                        }
                    );
                    this.Isloading = false;
                    if (response.data.success) {
                        this.Isloading = false
                        //this.successAlertOnSubmit = response.data.msg;
                        this.successAlertOnSubmit = "Profile updated successfully";
                        setTimeout(() => {
                            this.successAlertOnSubmit = "";
                            // this.formHeader="";
                        }, 5000);
                    } else {
                        this.formHeader = response.data.msg;
                    }
                } 
                catch (error) {
                    if (error.response && error.response.data) {
                        if (error.response.status === 401) {
                            handleAuthorization("/login")
                        }
                        else if (error.response.status === 500) {
                            handleServerError("/login")
                        }
                        else {
                            const jsonResponseString = JSON.stringify(error.response.data);
                            try {
                                const jsonObject = JSON.parse(jsonResponseString);
                                this.formHeader = jsonObject.message || "Something went wrong.";
                            } catch (parseError) {
                                this.formHeader = parseError.message || "Something went wrong.";
                            }
                            ErrorLog(error.message, error.response.status, 230, error.stack, "SuperAdminPanel/SuperAdmin_Profile/updateUser()");
                        }
                    }
                    else {
                        this.formHeader = "Something went wrong.";
                    }
                } finally {
                    this.btnEnable = false;
                    this.Isloading = false;
                }
            }
        },

        isValidEmail(email) {
            // A simple email validation function, you can replace it with a more robust one if needed
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },
        resetValidation() {
            this.userValidate = {
                FirstName: "",
                LastName: "",
                ContactNumber: "",
                Email: "",
                Username: "",
                // Password: "",
            };
        },
        resetForm() {
            this.editSuperAdmin = {
                UserID: 0,
                FirstName: "",
                LastName: "",
                UserType: "User",
                Email: "",
                Username: "",
                // Password: "",
            };
            this.formHeader = "";
        },

        async submitUserForm() {
            this.resetValidation();
            if (this.isValidUserForm()) {
                //this.editSuperAdmin.TaskID === 0 ? this.createTask() : this.updateUser();
                this.updateUser();
            }
        },
        isValidUserForm() {
            let isValid = true;
            const ContactNumber = this.editSuperAdmin.ContactNumber;
            // First Name
            if (!this.editSuperAdmin.FirstName.trim()) {
                this.userValidate.FirstName = "First name is required.";
                isValid = false;
            } else {
                this.userValidate.FirstName = "";
            }
            if (!this.editSuperAdmin.LastName.trim()) {
                this.userValidate.LastName = "Last name is required.";
                isValid = false;
            } else {
                this.userValidate.LastName = "";
            }
            if (ContactNumber === undefined || ContactNumber === "") {
                this.userValidate.ContactNumber = "Contact Number is required.";
                isValid = false;
            }
            else if (!isValidPhoneNumber(ContactNumber)) {
                this.userValidate.ContactNumber = "Please enter a valid Contact Number.";
                isValid = false;
            }
            else {
                this.userValidate.ContactNumber = "";
            }
            // Email
            if (!this.editSuperAdmin.Email.trim()) {
                this.userValidate.Email = "Email is required.";
                isValid = false;
            } else if (!this.isValidEmail(this.editSuperAdmin.Email.trim())) {
                this.userValidate.Email = "Please enter valid email address.";
                isValid = false;
            } else {
                this.userValidate.Email = "";
            }
            if (!this.editSuperAdmin.Username.trim()) {
                this.userValidate.Username = "User name is required.";
                isValid = false;
            } else {
                this.userValidate.Username = "";
            }
            return isValid;
        },
        async getUserById() {
            try {
                this.Isloading = true;
                this.loadingUser = "Loading...";
                const token = Cookies.get("jwt");
                setAuthToken(token);
                let userCookie = Cookies.get("user");
                userCookie = userCookie ? JSON.parse(userCookie) : null;
                const response = await api.get(
                    `/api/superAdmin/getAdminById/${userCookie.UserID}`
                );
                this.Isloading = false;
                if (response.data) {
                    console.log("user", response.data);
                    this.editSuperAdmin = response.data[0];
                    this.resetValidation();
                }
                else {
                    this.formHeader = `Error: ${response.statusText}`;
                }
            } catch (error) {
                console.log("err", error);
                if (error.response && error.response.data) {
                    if (error.response.status === 401) {
                        handleAuthorization("/login")
                    }
                    else if (error.response.status === 500) {
                        handleServerError("/login")
                    }
                    else {
                        const jsonResponseString = JSON.stringify(error.response.data);
                        try {
                            const jsonObject = JSON.parse(jsonResponseString);
                            this.formHeader = jsonObject.message || "Something went wrong.";
                        } catch (parseError) {
                            this.formHeader = parseError.message || "Something went wrong.";
                        }
                        ErrorLog(error.message, error.response.status, 360, error.stack, "SuperAdminPanel/SuperAdmin_Profile/getUserById()");
                    }
                }
                else {
                    this.formHeader = "Something went wrong.";
                }
            }
            finally {
                this.Isloading = false;
                this.loadingUser = "";
            }
        },
    },
};
</script>

<style scoped>
/* Add Tailwind CSS classes here if needed */
.close-icon {
    cursor: pointer;
    float: right;
    font-size: 30px;
    margin-top: -10px;
}

.dropdown {
    width: 100%;
    height: 36px;
    padding: 7px 0;
    font-size: 14px;
    line-height: 1.428571429;
    border-color: #D2D2D2;
}
</style>