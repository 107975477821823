<template>
  <div class="content">
    <LoadingOverlay :loading="Isloading" />
    <div class="row">
      <div class="col-md-12">
        <p v-if="UserName" class="username"><span style="color:black;">Logged in as : </span>{{ UserName }}</p>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-50 md-medium-size-50 md-xsmall-size-100 md-size-33">
            <div class="bg-color" style="margin: 0px; margin-top:15px;min-height:92px;">
              <div class="media-body text-xs-left float-right" id="calories-value"
                style="max-width: 445px; border-radius: 4px; position:relative;" v-on:click="OpenTeamTaskPage()">
                <h3 class="card-value" id="calories-text" style="color: rgb(130, 130, 130);">{{ TotalTeamTasks }}</h3>
                <div id="foodimg"><span class="card-text" id="calories-subtitle" style="color: rgb(130, 130, 130);">Team
                    Tasks</span>
                  <span id="food-img" class="icon-user"
                    style="font-size: 65px; margin-right: 3%; margin-bottom: 2%; color: rgb(153, 147, 147);"><i
                      class="fas fa-tasks"></i></span>
                </div>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-small-size-50 md-medium-size-50 md-xsmall-size-100 md-size-33">
            <div class="bg-color" style="margin: 0px; margin-top:15px;min-height:92px;">
              <div class="media-body text-xs-left float-right" id="calories-value"
                style="max-width: 445px; border-radius: 4px; position:relative;" v-on:click="OpenMyTaskPage()">
                <h3 class="card-value" id="calories-text" style="color: rgb(130, 130, 130);">{{ TotalMyTasks }}</h3>
                <div id="foodimg"><span class="card-text" id="calories-subtitle" style="color: rgb(130, 130, 130);">My
                    Tasks</span>
                  <span id="food-img" class="icon-user"
                    style="font-size: 65px; margin-right: 3%; margin-bottom: 2%; color: rgb(153, 147, 147);"><i
                      class="fas fa-list"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-small-size-50 md-medium-size-50 md-xsmall-size-100 md-size-33">
        <div class="bg-color">
          <CompletedTask />
        </div>
      </div>
      <div class="md-layout-item md-small-size-50 md-medium-size-50 md-xsmall-size-100 md-size-33">
        <div class="bg-color">
          <AssignedTaskChart />
        </div>
      </div>

      <div class="md-layout-item md-small-size-50 md-medium-size-50 md-xsmall-size-100 md-size-33">
        <div class="bg-color">
          <div class="topchart-div">
            <div class="inner-div">
              <apexchart type="pie" :options="chartOptions" :series="series" width="91%" />
              <template>
                <h4 class="Count-header" style="margin-top:5px;">Team Tasks: {{ TotalTeamTasks }}</h4>
                <button class="zoom-in" title="Click to Zoom" data-bs-toggle="modal" data-bs-target="#pieChartModal" data-bs-whatever="@mdo">
                  <i class="fas fa-search-plus"></i></button>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
        <div class="col-md-12">
          <button hidden type="button" title="Edit Status" data-bs-toggle="modal" data-bs-target="#taskEditStatusModal"
            data-bs-whatever="@mdo" class="btn btn-primary" id="openEditStatusModal">
            Edit Status
          </button>
          <div v-if="successAlertOnSubmitAssigned" class="alert alert-success text-left" role="alert">
            {{ successAlertOnSubmitAssigned }}
            <span @click="hideTaskAlert" class="close-icon">&times;</span>
          </div>
        </div>
        <nav-tabs-card>
          <template slot="content">
            <span class="md-nav-tabs-title">Team Tasks:</span>
            <md-tabs class="md-success" md-alignment="left">
              <md-tab id="tab-Dues" md-label="Due" md-icon="pending_actions">
                <md-card-content>
                  <div class="col-md-12" style="overflow-x: auto">
                    <div class="task-table">
                      <table style="width: 100%;" :table-header-color="tableHeaderColor"
                        v-if="!loadingDueAssignedTasks">
                        <tr class="toprow">
                          <td style="width:23%;padding-left:5px;" class="table-cell">Task</td>
                          <td style="width:10%" class="table-cell">Priority</td>
                          <td style="width:10%" class="table-cell">Status</td>
                          <td style="width:12%" class="table-cell"> Assigned On</td>
                          <td style="width:12%" class="table-cell">Due Date</td>
                          <td style="width:15%;text-align:right;padding-right:5px;" class="table-cell">Actions</td>
                        </tr>
                        <tr slot="md-table-row" v-for="item in dueAssignedTasks" :key="item.id" 
                        :class="{
                          'expired-row': item.IsExpired,
                          'rowtaskNotAssigned': item.TaskStatus === 1,
                          'rowtaskAssigned': item.TaskStatus === 2,
                          'rowtaskInProcess': item.TaskStatus === 3,
                          'rowtaskCompleted': item.TaskStatus === 4
                        }"
                          style="border-bottom:1px solid #ddd">
                          <td class="md-table-cell"><span v-if="item.Title.length > 60" :title="item.Title">
                              {{ truncateDescription(item.Title) }}
                            </span>
                            <span v-else>
                              {{ item.Title }}
                            </span>
                          </td>
                          <td class="md-table-cell">{{ item.PriorityName }}</td>
                          <td class="md-table-cell">
                            <span v-if="item.TaskStatus === 1" class="taskNotAssigned">
                              Not Assigned
                            </span>
                            <span v-else-if="item.TaskStatus === 2" class="taskAssigned">Assigned
                            </span>
                            <span v-else-if="item.TaskStatus === 3" class="taskInProcess">In Process
                            </span>
                            <span v-else-if="item.TaskStatus === 4" class="taskCompleted">Completed
                            </span>
                          </td>
                          <td class="md-table-cell">{{ item.CreatedDate }}</td>
                          <td class="md-table-cell">{{ formatDueDate(item.DueDate) }}</td>
                          <td class="md-table-cell" style="text-align:right">
                            <button :disabled="item.IsCompleted && item.TaskStatus === 4
                              " @click="editToDoTaskStatus(item.TaskId)" type="button" class="btn btn-primary btn-sm ml-2" title="Mark Status">
                              <md-icon style="color: white">add_task</md-icon>
                            </button>
                            <button @click="viewPersonalTaskDetail(item.TaskId, 1)" type="button"
                              class="btn btn-primary btn-sm ml-2" title="View Task">
                              <i class="fa fa-eye" aria-hidden="true"></i>
                            </button>
                          </td>
                        </tr>
                      </table>
                      <div class="text-center" v-else>
                        <span>{{ loadingDueAssignedTasks }}</span>
                      </div>
                    </div>
                  </div>
                </md-card-content>
              </md-tab>
            </md-tabs>
          </template>
        </nav-tabs-card>
      </div>
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
        <div class="col-md-12">
          <div v-if="successAlertOnSubmit" class="alert alert-success text-left" role="alert">
            {{ successAlertOnSubmit }}
            <span @click="hideTaskAlert" class="close-icon">&times;</span>
          </div>
        </div>
        <nav-tabs-card>
          <template slot="content">
            <button hidden type="button" title="Delete Task" data-bs-toggle="modal" data-bs-target="#taskDeleteConfirmModal"
              data-bs-whatever="@mdo" class="btn btn-primary" id="openDeleteStatusModal">
              Delete Task
            </button>
            <span class="md-nav-tabs-title">My Tasks:</span>
            <md-tabs class="md-success" md-alignment="left">
              <md-tab id="tab-Dues" md-label="Due" md-icon="pending_actions">
                <md-card-content>
                  <div class="col-md-12" style="overflow-x:auto;">
                    <div class="task-table">
                      <table style="width: 100%;" :table-header-color="tableHeaderColor" v-if="!loadingDueTasks">
                        <tr class="toprow">
                          <td style="width:23%;padding-left:5px;" class="table-cell">Task</td>
                          <td style="width:10%" class="table-cell">Priority</td>
                          <td style="width:10%" class="table-cell">Status</td>
                          <td style="width:12%" class="table-cell"> Assigned On</td>
                          <td style="width:12%" class="table-cell">Due Date</td>
                          <td style="width:15%;text-align:right;padding-right:5px;" class="table-cell">Actions</td>
                        </tr>
                        <tr slot="md-table-row" v-for="item in dueTasks" :key="item.id" class="expired-row"
                          style="border-bottom:1px solid #ddd">
                          <td class="md-table-cell"><span v-if="item.Title.length > 60" :title="item.Title">
                              {{ truncateDescription(item.Title) }}
                            </span>
                            <span v-else>
                              {{ item.Title }}
                            </span>
                          </td>
                          <td class="md-table-cell">{{ item.PriorityName }}</td>
                          <td class="md-table-cell">
                            <span v-if="item.TaskStatus === 1" class="taskNotAssigned">
                              Not Assigned
                            </span>
                            <span v-else-if="item.TaskStatus === 2" class="taskAssigned">New Task
                            </span>
                            <span v-else-if="item.TaskStatus === 3" class="taskInProcess">In Process
                            </span>
                            <span v-else-if="item.TaskStatus === 4" class="taskCompleted">Completed
                            </span>
                          </td>
                          <td class="md-table-cell">{{ formatDueDate(item.CreatedDate) }}</td>
                          <td class="md-table-cell">{{ formatDueDate(item.DueDate) }}</td>
                          <td class="md-table-cell" style="text-align:right">
                            <button :disabled="item.IsCompleted && item.TaskStatus === 4"
                            @click="editTaskStatus(item.TaskID)" type="button" class="btn btn-primary btn-sm ml-2" title="Mark Status">
                              <md-icon style="color: white">add_task</md-icon>
                            </button>
                            <button @click="viewPersonalTaskDetail(item.TaskID, 0)" type="button"
                              class="btn btn-primary btn-sm ml-2" title="View Task">
                              <i class="fa fa-eye" aria-hidden="true"></i>
                            </button>
                            <button :disabled="item.IsCompleted && item.TaskStatus === 4"
                              @click="editTaskDetail(item.TaskID)" type="button" class="btn btn-primary btn-sm ml-2"
                              title="Edit Task">
                              <i class="fas fa-pencil-alt"></i>
                            </button>

                            <button @click="openDeleteModal(item.TaskID)" type="button"
                              class="btn btn-danger btn-sm ml-2" title="Delete Task">
                              <md-icon style="color: white">delete</md-icon>
                            </button>
                          </td>
                        </tr>
                      </table>
                      <div class="text-center" v-else>
                        <span>{{ loadingDueTasks }}</span>
                      </div>
                    </div>
                  </div>
                </md-card-content>
              </md-tab>
            </md-tabs>
            <!-- Modal for Edit Task Status -->
            <div class="modal fade" id="taskEditStatusModal" tabindex="-1" aria-labelledby="taskEditStatusModalLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="taskEditStatusModalLabel">
                      Edit Task Status
                    </h1>
                    <button class="btn-close" title="Close" data-bs-dismiss="modal" v-on:click="hideCommentsandFile()"
                      aria-label="Close" tabindex="-1"></button>
                  </div>
                  <form @submit.prevent="submitEditTaskStatusForm1">
                    <input v-model="newTask.TaskID" type="hidden" class="form-control" />
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="mb-2">
                            <label for="title" class="col-form-label pb-0">Title</label>
                            <input v-model="newTask.Title" type="text" class="form-control pt-0"
                              :disabled="isInputDisabled" id="title" tabindex="1" style="border:0;" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-2">
                            <label for="priority" class="col-form-label pb-0">Priority</label>
                            <select v-model="newTask.PriorityID" class="form-control pt-0" id="priority" tabindex="3"
                              style="border:0;" :disabled="isInputDisabled" @change="isValidTaskForm">
                              <option :value="0">Select Priority</option>
                              <option v-for="priorityOption in priorityDropdownOptions" :key="priorityOption.PriorityID"
                                :value="priorityOption.PriorityID">
                                {{ priorityOption.Name }}
                              </option>
                            </select>
                            <span class="text-red-500 text-sm">{{
                              taskValidate.PriorityID
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="mb-2">
                            <label for="category" class="col-form-label pb-0">Category</label>
                            <select v-model="newTask.CategoryID" class="form-control pt-0" id="category" tabindex="4"
                              style="border:0;" :disabled="isInputDisabled" @change="isValidTaskForm">
                              <option :value="0">Select Category</option>
                              <option v-for="categoryOption in categoryDropdownOptions" :key="categoryOption.CategoryID"
                                :value="categoryOption.CategoryID">
                                {{ categoryOption.CategoryName }}
                              </option>
                            </select>
                            <span class="text-red-500 text-sm">{{
                              taskValidate.CategoryID
                            }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-2">
                            <label for="dueDate" class="col-form-label pb-0">Due Date</label>
                            <label style="font-weight:400;display: block;" class="col-form-label">{{ newTask.DueDate
                              }}</label>
                            <span class="text-red-500 text-sm">{{
                              taskValidate.DueDate
                            }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label for="status" class="col-form-label">Status</label>
                            <select v-model="newTask.TaskStatus" class="dropdown" id="status" v-if="PublicTask"
                              tabindex="4" @change="isValidTaskForm">
                              <option value="2">Select Status</option>
                              <!--  <option value="1">Not Assigned</option> -->
                              <!-- <option value="2">Assigned</option>  -->
                              <option value="3">In Process</option>
                              <option value="4">Completed</option>
                            </select>
                            <select v-model="newTask.TaskStatus" class="dropdown" id="status" v-else tabindex="4"
                              @change="isValidTaskForm">
                              <option value="2">Select Status</option>
                              <!-- <option value="2">New Task</option> -->
                              <option value="3">In Process</option>
                              <option value="4">Completed</option>
                            </select>
                            <span class="text-red-500 text-sm">{{
                              taskValidate.TaskStatus
                            }}</span>
                          </div>
                        </div>
                        <div class="col-md-6" hidden>
                          <div class="mb-3">
                            <label for="isCompleted" class="col-form-label">Is Completed</label>
                            <div class="form-check form-switch">
                              <input v-model="newTask.IsCompleted" type="checkbox" class="form-check-input"
                                id="isCompleted" tabindex="6" @input="isValidTaskForm" />
                              <label class="form-check-label" for="isCompleted">
                                {{
                                  newTask.IsCompleted
                                    ? "Completed"
                                    : "Not Completed"
                                }}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row" v-if="StatusCompleted">
                        <div class="col-md-12">
                          <div class="mb-3">
                            <label for="Comments" class="col-form-label">Comments</label>
                            <textarea v-model="newTask.Comments" class="form-control" id="Comments" tabindex="2"
                              style="height: 36px;"></textarea>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label for="fileUpload" class="col-form-label">File Upload</label>
                            <input type="file" class="form-control" id="fileUpload" ref="file" name="file" tabindex="5"
                              @change="handleFileUpload" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" title="Close" class="btn btn-secondary ml-2" data-bs-dismiss="modal" id="closeStatusModal"
                        tabindex="8" v-on:click="hideCommentsandFile()">
                        Close
                      </button>
                      <button type="button" title="Update" class="btn btn-primary" data-bs-toggle="modal"
                        data-bs-target="#taskConfirmModal" data-bs-whatever="@mdo" tabindex="7">
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!-- Modal for User Chart -->
            <div class="modal fade" id="pieChartModal" tabindex="-1" aria-labelledby="pieChartModalLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-md">
                <div class="modal-content">
                  <div class="modal-header">
                    <!-- <h1 class="modal-title fs-5" id="taskModalLabel">
                      {{ editTaskMode ? "Edit Task" : "Add New Task" }}
                    </h1> -->
                    <button class="btn-close" title="Close" data-bs-dismiss="modal" aria-label="Close" tabindex="-2"></button>
                  </div>
                  <div class="modal-body">
                    <div class="row">
                      <!-- <h4>Are you sure you want to delete?</h4> -->
                      <apexchart type="pie" :options="chartOptions" :series="series" width="90%" />
                    </div>
                  </div>
                  <div class="modal-footer">
                  </div>
                </div>
              </div>
            </div>
            <!-- Modal for Edit Task Status -->
            <div class="modal fade" id="taskConfirmModal" tabindex="-1" aria-labelledby="ConfirmModalLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-md">
                <div class="modal-content">
                  <form @submit.prevent="submitEditTaskStatusForm">
                    <input v-model="newTask.TaskID" type="hidden" class="form-control" />
                    <div class="modal-body">
                      <div class="row">
                        <h4>
                          Are you sure you want to mark as
                          {{ this.funCheckStatus(newTask.TaskStatus) }}?
                        </h4>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" title="No" class="btn btn-secondary ml-2" data-bs-dismiss="modal"
                        id="closeConfirmStatusModal" tabindex="8">
                        No
                      </button>
                      <button type="submit" title="Yes" class="btn btn-primary" tabindex="7">
                        Yes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </template>
        </nav-tabs-card>
        <!-- Modal for confirm delete Task -->
        <div class="modal fade" id="taskDeleteConfirmModal" tabindex="-1" aria-labelledby="DeleteConfirmModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-md">
            <div class="modal-content">
              <form @submit.prevent="submitDeleteTaskStatusForm">
                <input v-model="newTask.TaskID" type="hidden" class="form-control" />
                <div class="modal-body">
                  <div class="row">
                    <h4>Are you sure you want to delete?</h4>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" title="No" class="btn btn-secondary ml-2" data-bs-dismiss="modal"
                    id="closeDeleteConfirmStatusModal" tabindex="8">
                    No
                  </button>
                  <button type="submit" title="" class="btn btn-danger" tabindex="7">
                    Yes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  NavTabsCard,
} from "@/components";
import VueApexCharts from 'vue-apexcharts';
import { authMixin } from "../components/user_pages/userStore";
import api, { setAuthToken } from "../components/apiConfig";
import AssignedTaskChart from "../components/user_pages/AssignedTask"
import CompletedTask from "../components/user_pages/CompletedTask"
import Cookies from "js-cookie";
import { ErrorLog } from "../components/Common/APILog";
import LoadingOverlay from '../components/Common/blockoutpage.vue';
import { handleAuthorization, handleServerError } from "../components/Common/commonMethod";

export default {
  mixins: [authMixin],
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  created() {
    this.checkAuthorization();
  },
  components: {
    NavTabsCard,
    apexchart: VueApexCharts,
    CompletedTask,
    AssignedTaskChart,
    LoadingOverlay
  },
  data() {
    return {
      Isloading:false,
      dueTasks: [],
      completedTasks: [],
      PublicTask: [],
      inprocessTasks: [],
      assignedTasks: [],
      TotalTeamTasks: 0,
      TotalMyTasks: 0,
      MyTaskFlag: false,
      series: [5, 5, 5],
      chartOptions: {
        chart: {
          type: 'pie'
        },
        labels: ['Assigned', 'Completed', 'InProcess']
      },
      loadingTasks: "",
      loadingInProcessTasks: "",
      loadingCompletedTasks: "",
      loadingDueTasks: "",
      CompletedAssignedCount: 0,
      DuesAssignedCount: 0,
      AssignedTaskCount: 0,
      StatusCompleted: false,
      dueAssignedTasks: [],
      completedAssignedTasks: [],
      inprocessAssignedTasks: [],
      assignedAssignedTasks: [],
      loadingAssignedTasks: "",
      loadingInProcessAssignedTasks: "",
      loadingCompletedAssignedTasks: "",
      loadingDueAssignedTasks: "",
      successAlertOnSubmit: "",
      newTask: {
        TaskID: 0,
        Title: "",
        Description: "",
        DueDate: null,
        IsCompleted: false,
        TaskStatus: 1,
        PriorityID: 0,
        CategoryID: 0,
        UserId: 0,
        TaskType: null,
        file: "",
        Comments: null
      },
      taskValidate: {
        Title: "",
        Description: "",
        PriorityID: "",
        CategoryID: "",
        DueDate: "",
        // Add additional validation fields as needed
      },
      categoryDropdownOptions: [],
      priorityDropdownOptions: [],
      isInputDisabled: true,
      successAlertOnSubmitAssigned: "",
      UserName: "",
      flagCount:0,
    };
  },
  mounted() {
    this.getUserDashboard();
  },
  methods: {
    handleFileUpload(event) {
      this.newTask.file = event.target.files[0];
    },
    hideCommentsandFile() {
      this.newTask.Comments = "";
      this.file = "";
      this.StatusCompleted = false;
    },
    closeDeleteConfirmStatusModal() {
      document.getElementById("closeDeleteConfirmStatusModal").click();
    },
    async submitDeleteTaskStatusForm() {
      this.deleteTaskItem(this.newTask.TaskID);
    },
    async deleteTaskItem(taskId) {
      try {
        const token = Cookies.get("jwt");
        this.Isloading=true;
        setAuthToken(token);
        let userCookie = Cookies.get("user");
        console.log(userCookie);
        userCookie = userCookie ? JSON.parse(userCookie) : null;
        // Make your delete API call here
        const response = await api.post(
          `/api/tasks/delete-task/${taskId}/${userCookie.UserID}`
        ); // Replace with your actual API endpoint

        if (response.data.success) {
          //  this.getTasks();
          this.getUserDashboard();
          this.successAlertOnSubmit = response.data.msg;
          this.closeDeleteConfirmStatusModal();
        } else {
          this.successAlertOnSubmit = response.data.msg;
        }
        // Optionally, perform additional actions after successful deletion
      } catch (error) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              // this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              // this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message,error.statuscode,593,error.stack,"page/UserDashboard/deleteTaskItem");
        }
      }finally{
        this.Isloading=false;
      }
    },
    OpenTeamTaskPage() {
      this.$router.push("/user/my-tasks");
    },
    openDeleteModal(taskId) {
      this.newTask.TaskID = taskId;
      document.getElementById("openDeleteStatusModal").click();
    },
    OpenMyTaskPage() {
      this.$router.push("/user/task-management");
    },
    closeConfirmStatusModal() {
      document.getElementById("closeConfirmStatusModal").click();
    },
    isValidTaskForm() {
      let isValid = true;
      // Title
      if (!this.newTask.Title.trim()) {
        this.taskValidate.Title = "Title is required.";
        isValid = false;
      } else {
        this.taskValidate.Title = "";
      }
      // Description
      if (!this.newTask.Description.trim()) {
        this.taskValidate.Description = "Description is required.";
        isValid = false;
      } else {
        this.taskValidate.Description = "";
      }

      // Priority
      if (this.newTask.PriorityID === 0 || this.newTask.PriorityID === "0") {
        this.taskValidate.PriorityID = "Please select priority.";
        isValid = false;
      } else {
        this.taskValidate.PriorityID = "";
      }
      // Category
      if (this.newTask.CategoryID === 0 || this.newTask.CategoryID === "0") {
        this.taskValidate.CategoryID = "Please select category.";
        isValid = false;
      } else {
        this.taskValidate.CategoryID = "";
      }
      // DueDate
      if (!this.newTask.DueDate) {
        this.taskValidate.DueDate = "Due Date is required.";
        isValid = false;
      } else {
        this.taskValidate.DueDate = "";
      }
      if (this.newTask.TaskStatus == 4) {
        this.newTask.Comments = "";
        this.StatusCompleted = true;
      }
      else {
        this.newTask.Comments = "";
        this.StatusCompleted = false;
      }
      return isValid;
    },
    async getPriorityDropdown() {
      try {
        this.Isloading=true;
        const token = Cookies.get("jwt");
        setAuthToken(token);
        const response = await api.get("/api/tasks/get-priority-dropdown");
        if (response.data) {
          this.priorityDropdownOptions = response.data;
        } else {
          // Handle case where no priorities are found
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              // this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              // this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message,error.statuscode,687,error.stack,"pages/UserDashboard/getPriorityDropdown");
          }
        }

      }finally{
        this.Isloading=false;
      }
    },
    editTaskDetail(taskId) {
      this.$router.push({
        name: "User Edit Task",
        params: { taskId },
      });
    },
    async submitEditTaskStatusForm() {
      this.CompleteTask(this.newTask.TaskID, this.newTask.TaskStatus);
    },
    async getCategoryDropdown() {
      try {
        const token = Cookies.get("jwt");
        this.Isloading=true;
        setAuthToken(token);
        const response = await api.get("/api/tasks/get-category-dropdown");
        if (response.data) {
          this.categoryDropdownOptions = response.data;
        } else {
          // Handle case where no categories are found
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              // this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              // this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message,error.statuscode,731,error.stack,"pages/UserDashboard/getCategoryDropdown");
          }
        }
      }finally{
        this.Isloading=false;
      }
    },
    editTaskStatus(taskId) {
      this.MyTaskFlag = true;
      this.getTaskById(taskId);
    },
    editToDoTaskStatus(taskId) {
      this.MyTaskFlag = false;
      this.getTaskById(taskId);
    },
    openEditStatusModal() {
      document.getElementById("openEditStatusModal").click();
    },
    async getTaskById(taskId) {
      try {
        const token = Cookies.get("jwt");
        this.Isloading=true;
        setAuthToken(token);
        const response = await api.get(`/api/tasks/get-task-by-id/${taskId}`);
        if (response.data) {
          this.newTask = response.data.tasks[0];
          console.log(response.data.tasks[0].DueDate.date)
          this.newTask.DueDate = this.formatDueDate(
            response.data.tasks[0].DueDate,
            "update"
          );
          this.openEditStatusModal();
        } else {
         console.log(response.statusText)
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              // this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              // this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message,error.statuscode,782,error.stack,"pages/UserDashboard/getTaskByID");
          }
        }
      }finally{
        this.Isloading=false;
      }
    },
    formatDueDate(originalDate, type = "show") {
      if (!originalDate) return ""; // Handle null or undefined dates
      const dateObject = new Date(originalDate);
      const day = dateObject.getDate().toString().padStart(2, "0");
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObject.getFullYear();
      if (type == "show") {
        return `${day}/${month}/${year}`;
      } else {
        return `${day}/${month}/${year}`;
      }
    },
    truncateDescription(description) {
      // Truncate the description if it's longer than 30 characters
      return description.length > 25
        ? description.slice(0, 25) + "..."
        : description;
    },
    async getTasks() {
      this.dueTasks = [];
      this.completedTasks = [];
      this.inprocessTasks = [];
      this.Isloading=true;
      this.assignedTasks = [];
      this.loadingTasks = "Loading...";
      this.loadingInProcessTasks = "Loading...";
      this.loadingCompletedTasks = "Loading...";
      this.loadingDueTasks = "Loading...";
      try {
        const token = Cookies.get("jwt");
        setAuthToken(token);
        let userCookie = Cookies.get("user");
        userCookie = await userCookie ? JSON.parse(userCookie) : null;
        const UserID=await userCookie.UserID
        const response = await api.get(
          `/api/tasks/get-personal-tasks/${UserID}`
        );
        if (response.data) {
          this.TotalMyTasks = response.data.length;
          // get due tasks
          this.dueTasks = response.data.filter(
            (task) => task.IsExpired === 1 && !task.IsCompleted
          );
          // get completed tasks
          this.completedTasks = response.data.filter(
            (task) => task.IsCompleted && task.TaskStatus === 4
          );
          // get in-process tasks
          this.inprocessTasks = response.data.filter(
            (task) => task.TaskStatus === 3
          );
          // get assigned tasks
          this.assignedTasks = response.data.filter(
            (task) => task.TaskStatus === 2
          );
        } else {
          // Handle case where no tasks are found
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              // this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              // this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message,error.statuscode,863,error.stack,"User/UserDashboard/getTasks")
          }
        }
        // Handle API error
      } finally {
        if (this.assignedTasks.length > 0) {
          this.loadingTasks = "";
        } else {
          this.loadingTasks = "No tasks found.";
        }
        if (this.inprocessTasks.length > 0) {
          this.loadingInProcessTasks = "";
        } else {
          this.loadingInProcessTasks = "No tasks found.";
        }
        if (this.completedTasks.length > 0) {
          this.loadingCompletedTasks = "";
        } else {
          this.loadingCompletedTasks = "No tasks found.";
        }
        if (this.dueTasks.length > 0) {
          this.loadingDueTasks = "";
        } else {
          this.loadingDueTasks = "No tasks found.";
        }
        this.Isloading=false;
      }
    },
    async getUserDashboard() {
      this.dueAssignedTasks = [];
      this.Isloading=true;
      this.completedAssignedTasks = [];
      this.inprocessAssignedTasks = [];
      this.assignedAssignedTasks = [];
      this.loadingAssignedTasks = "Loading...";
      this.loadingInProcessAssignedTasks = "Loading...";
      this.loadingCompletedAssignedTasks = "Loading...";
      this.loadingDueAssignedTasks = "Loading...";
      this.dueTasks = [];
      this.completedTasks = [];
      this.inprocessTasks = [];
      this.assignedTasks = [];
      this.loadingTasks = "Loading...";
      this.loadingInProcessTasks = "Loading...";
      this.loadingCompletedTasks = "Loading...";
      this.loadingDueTasks = "Loading...";
      try {
        const token = Cookies.get("jwt");
        setAuthToken(token);
        let userCookie = Cookies.get("user");
        userCookie =await  userCookie ? JSON.parse(userCookie) : null;
        this.UserName =await userCookie.Username;
        const UserID=await userCookie.UserID
        console.log(userCookie)
        const response = await api.get(
          `/api/tasks/get-userdashboard/${UserID}`
        );
        if (response.data.data.Categories) {
          this.categoryDropdownOptions = response.data.data.Categories;
        }
        if (response.data.data.Priorities) {
          this.priorityDropdownOptions = response.data.data.Priorities;
        }
        if (response.data.data.AssignedTasks) {
          var currentDate = new Date();
          var date = currentDate.getDate() + 4;
          console.log(date);
          this.PublicTask = response.data.data.AssignedTasks.filter(
            (task) => !task.IsCompleted && task.TaskStatus != 1
          );
          console.log("public", this.PublicTask);
          this.TotalTeamTasks = response.data.data.AssignedTasks.length;
          // get due tasks
          this.dueAssignedTasks = response.data.data.AssignedTasks.filter(
            (task) => task.IsExpired === 1 && !task.IsCompleted
          );
          this.DuesAssignedCount = this.dueAssignedTasks.length;
          // get completed tasks
          this.completedAssignedTasks = response.data.data.AssignedTasks.filter(
            (task) => task.IsCompleted && task.TaskStatus === 4
          );
          this.CompletedAssignedCount = this.completedAssignedTasks.length;
          // get in-process tasks
          this.inprocessAssignedTasks = response.data.data.AssignedTasks.filter(
            (task) => task.TaskStatus === 3
          );

          // get assigned tasks
          this.assignedAssignedTasks = response.data.data.AssignedTasks.filter(
            (task) => task.TaskStatus === 2
          );
          this.series = [this.assignedAssignedTasks.length, this.CompletedAssignedCount, this.inprocessAssignedTasks.length];
          this.AssignedTaskCount = this.assignedAssignedTasks.length;
        }
        if (response.data.data.PersonalTasks) {
          this.TotalMyTasks = response.data.data.PersonalTasks.length;
          // get due tasks
          this.dueTasks = response.data.data.PersonalTasks.filter(
            (task) => task.IsExpired === 1 && !task.IsCompleted
          );

          // get completed tasks
          this.completedTasks = response.data.data.PersonalTasks.filter(
            (task) => task.IsCompleted && task.TaskStatus === 4
          );

          // get in-process tasks
          this.inprocessTasks = response.data.data.PersonalTasks.filter(
            (task) => task.TaskStatus === 3
          );

          // get assigned tasks
          this.assignedTasks = response.data.data.PersonalTasks.filter(
            (task) => task.TaskStatus === 2
          );
        } else {
          // Handle case where no tasks are found
        }
      } catch (error) {
        if(this.flagCount<2){
        setTimeout(() => {
          this.getUserDashboard(); // Retry after a delay
        }, 3000);
        this.flagCount++;
      }else{
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              // this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              // this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message,error.statuscode,1002,error.stack,"User/UserDashboard/getUserDashboard")

          }
        }
        console.log(error);
      }
        // Handle API error
      } finally {
        this.Isloading=false;
        if (this.assignedAssignedTasks.length > 0) {
          this.loadingAssignedTasks = "";
        } else {
          this.loadingAssignedTasks = "No tasks found.";
        }

        if (this.inprocessAssignedTasks.length > 0) {
          this.loadingInProcessAssignedTasks = "";
        } else {
          this.loadingInProcessAssignedTasks = "No tasks found.";
        }

        if (this.completedAssignedTasks.length > 0) {
          this.loadingCompletedAssignedTasks = "";
        } else {
          this.loadingCompletedAssignedTasks = "No tasks found.";
        }
        if (this.dueAssignedTasks.length > 0) {
          this.loadingDueAssignedTasks = "";
        } else {
          this.loadingDueAssignedTasks = "No tasks found.";
        }
        if (this.assignedTasks.length > 0) {
          this.loadingTasks = "";
        } else {
          this.loadingTasks = "No tasks found.";
        }
        if (this.inprocessTasks.length > 0) {
          this.loadingInProcessTasks = "";
        } else {
          this.loadingInProcessTasks = "No tasks found.";
        }
        if (this.completedTasks.length > 0) {
          this.loadingCompletedTasks = "";
        } else {
          this.loadingCompletedTasks = "No tasks found.";
        }
        if (this.dueTasks.length > 0) {
          this.loadingDueTasks = "";
        } else {
          this.loadingDueTasks = "No tasks found.";
        }
      }
    },
    async getAssignedTasks() {
      this.dueAssignedTasks = [];
      this.completedAssignedTasks = [];
      this.inprocessAssignedTasks = [];
      this.assignedAssignedTasks = [];
      this.loadingAssignedTasks = "Loading...";
      this.loadingInProcessAssignedTasks = "Loading...";
      this.loadingCompletedAssignedTasks = "Loading...";
      this.loadingDueAssignedTasks = "Loading...";
      this.Isloading=true;
      try {
        const token = Cookies.get("jwt");
        setAuthToken(token);
        let userCookie = Cookies.get("user");
        userCookie = await userCookie ? JSON.parse(userCookie) : null;
        const UserID=await userCookie.UserID
        const response = await api.get(
          `/api/tasks/get-my-tasks/${UserID}`
        );
        if (response.data) {
          this.TotalTeamTasks = response.data.length;
          // get due tasks
          this.dueAssignedTasks = response.data.filter(
            (task) => task.IsExpired === 1 && !task.IsCompleted
          );
          this.DuesAssignedCount = this.dueAssignedTasks.length;
          // get completed tasks
          this.completedAssignedTasks = response.data.filter(
            (task) => task.IsCompleted && task.TaskStatus === 4
          );
          this.CompletedAssignedCount = this.completedAssignedTasks.length;
          // get in-process tasks
          this.inprocessAssignedTasks = response.data.filter(
            (task) => task.TaskStatus === 3
          );
          // get assigned tasks
          this.assignedAssignedTasks = response.data.filter(
            (task) => task.TaskStatus === 2
          );
          this.series = [this.assignedAssignedTasks.length, this.CompletedAssignedCount, this.inprocessAssignedTasks.length];
          this.AssignedTaskCount = this.assignedAssignedTasks.length;
        } else {
          // Handle case where no tasks are found
          this.loadingAssignedTasks = "No tasks found.";
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              // this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              // this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message,error.statuscode,1115,error.stack,"User/UserDashboard/getAssignedTasks")
          }
        }
        // Handle API error
      }
      finally {
        this.Isloading=false;
        if (this.assignedAssignedTasks.length > 0) {
          this.loadingAssignedTasks = "";
        } else {
          this.loadingAssignedTasks = "No tasks found.";
        }

        if (this.inprocessAssignedTasks.length > 0) {
          this.loadingInProcessAssignedTasks = "";
        } else {
          this.loadingInProcessAssignedTasks = "No tasks found.";
        }

        if (this.completedAssignedTasks.length > 0) {
          this.loadingCompletedAssignedTasks = "";
        } else {
          this.loadingCompletedAssignedTasks = "No tasks found.";
        }
        if (this.dueAssignedTasks.length > 0) {
          this.loadingDueAssignedTasks = "";
        } else {
          this.loadingDueAssignedTasks = "No tasks found.";
        }
      }
    },

    viewPersonalTaskDetail(taskId, type) {
      this.$router.push(`/user/task-details/${taskId}/${type}`);
    },
    funCheckStatus(StatusID) {
      let tStatus = "not assigned";
      if (StatusID == 2 && this.MyTaskFlag) {
        tStatus = "new task";
      } else if (StatusID == 2 && !this.MyTaskFlag) {
        tStatus = "assigned";
      } else if (StatusID == 3) {
        tStatus = "in process";
      } else if (StatusID == 4) {
        tStatus = "completed";
      }
      return tStatus;
    },
    async CompleteTask(taskId, status, taskType) {
      try {
        this.Isloading=true;
        const token = Cookies.get("jwt");
        setAuthToken(token);
        let userCookie = Cookies.get("user");
        userCookie = userCookie ? JSON.parse(userCookie) : null;
        // Make your delete API call here
        const formData = new FormData();
        formData.append('TaskID', taskId);
        formData.append('UserID', userCookie.UserID);
        formData.append('Status', status);
        formData.append('TaskType', this.newTask.TaskType);
        formData.append('file', this.newTask.file);
        formData.append('Comments', this.newTask.Comments);
        // Make your update API call here
        const response = await api.post('/api/tasks/complete-task', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }

        });
        if (response.data.success) {
          // this.getAssignedTasks();
          // this.getTasks();
          this.getUserDashboard();
          // this.successAlertOnSubmitAssigned = response.data.msg;
          this.closeConfirmStatusModal();
          let tStatus = "not assigned";
          if (status == 2 && this.MyTaskFlag) {
            tStatus = "new task";
          } else if (status == 2 && !this.MyTaskFlag) {
            tStatus = "assigned";
          } else if (status == 3) {
            tStatus = "in process";
          } else if (status == 4) {
            tStatus = "completed";
          }
          this.successAlertOnSubmitAssigned = `Task marked as ${tStatus} successfully.`;
        } else {
          this.successAlertOnSubmitAssigned=response.statusText;
        }
        // Optionally, perform additional actions after successful deletion
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              // this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              // this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message,error.statuscode,1222,error.stack,"User/UserDashboard/CompleteTask")

          }
        }
      }
      finally {
        this.Isloading=false;
        setTimeout(() => {
          //  this.taskFormHeader = "";
          this.successAlertOnSubmitAssigned = "";
        }, 5000); // 3 seconds in milliseconds
      }
    },
    confirmComplete(taskId, status, taskType = 0) {
      let showStatusName = "in process";
      if (status === 4) {
        showStatusName = "complete";
      }
      const isConfirmed = window.confirm(
        `Are you sure you want to ${showStatusName}?`
      );

      if (isConfirmed) {
        // Call the deleteItem method when confirmed
        this.CompleteTask(taskId, status, taskType);
      }
    },
    hideTaskAlert() {
      this.successAlertOnSubmit = "";
      this.successAlertOnSubmitAssigned = "";
    },
  },
};
</script>
<style scoped>
.close-icon {
  cursor: pointer;
  float: right;
  font-size: 30px;
  margin-top: -10px;
}

.expired-row {
  background-color: #dc8484;
}

.md-tabs-navigation {
  padding-left: 15px 15px 15px 150px !important;
}

.md-layout-item .md-nav-tabs .md-tabs-navigation {
  padding: 15px 15px 15px 150px;
}

@media screen and (min-width:1020px) {
  #taskEditStatusModal .modal-dialog {
    width: 35%;
  }
}

.dropdown {
  width: 100%;
  height: 36px;
  padding: 7px 0;
  font-size: 14px;
  line-height: 1.428571429;
  border-color: #D2D2D2;
}

#calories-text {
  padding-left: 5%;
}

.card-value {
  font-weight: 400;
  font-size: 32px;
  color: #727272;
  margin-bottom: 0px;
  margin-top: 10px;
  letter-spacing: 0;
}

#calories-subtitle {
  padding-left: 5%;
}

.card-text {
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0;
  color: #303030;
}

.icon-user {
  position: absolute;
  right: 0px;
  top: 5px;
}

.media-body {
  cursor: pointer;
}

.media-body:hover h3,
.media-body:hover i {
  color: #0d6efd !important;
}

.media-body:hover .card-text {
  color: #555 !important;
}

.bg-color {
  background: white;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .14);
  margin: 25px 0;
  overflow: hidden;
  padding: 6px;
}

.Count-header {
  font-size: 14px;
  font-weight: 600;
  float: left;
}

.zoom-in {
  float: right;
  border-radius: 5px;
  color: #fff;
  background: #0d6efd;
  border: 2px solid #0d6efd;
  margin-top: 5px;
  padding-top: 3px;
}

.zoom-in i {
  font-size: 16px;
}

/* .topchart-div{
    overflow: hidden;
    min-height: 250px;
  } */
@media (min-width:720px) {
  .modal-dialog {
    width: 700px;
    max-width: 700px;
  }
}

.topchart-div {
  overflow: hidden;
  position: relative;
}

.inner-div {
  position: absolute;
  bottom: 0;
  width: 100%;
}

@media screen and (max-width:1280px) {
  .inner-div {
    position: unset !important;
  }
}

@media screen and (min-width:1281px) {
  .topchart-div {
    height: 192px !important;
  }
}

@media screen and (min-width:1300px) {
  .topchart-div {
    height: 197px !important;
  }
}

@media screen and (min-width:1350px) {
  .topchart-div {
    height: 205px !important;
  }
}

@media screen and (min-width:1400px) {
  .topchart-div {
    height: 220px !important;
  }
}

.modal-content .modal-header {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.modal-title {
  margin-top: 10px;
}

.modal-content .modal-body {
  padding-top: 10px;
}

.task-table {
  width: 100%;
}

.table-cell {
  font-size: 1.0625rem;
  padding-bottom: 10px;
  font-weight: 500;
  padding-top:8px;
}

@media screen and (min-width:990px) and (max-width: 1185px) {
  .task-table {
    width: 1100px;
    overflow-x: auto
  }
}

@media screen and (max-width: 930px) {
  .task-table {
    width: 850px;
    overflow-x: auto
  }
}

.username {
  font-size: 17px;
  text-align: right;
  font-weight: 500;
  color: blue;
  margin-right: 10px;
}
.toprow{
  color: #fff;
    background: #0d6efd;
    height: 35px;
    font-weight: 500;
    font-size: 16px;
}

.rowtaskNotAssigned {
  background: #faead6;
}

.rowtaskAssigned {
  background: #eaea8d;
}

.rowtaskInProcess {
  background: #b4e9d4;
}

</style>
