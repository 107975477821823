<template>
  <div>
    <LoadingOverlay :loading="Isloading" />
    <div class="topchart-div">
      <div class="inner-div">
        <canvas ref="completedChart" width="310"></canvas>
        <template>
          <h4 class="Count-header">Completed Team Tasks : {{ CompletedTeamTaskCount }}</h4>
          <button class="zoom-in" title="Click to Zoom" data-bs-toggle="modal" data-bs-target="#completedChartModal" data-bs-whatever="@mdo">
            <i class="fas fa-search-plus"></i></button>
        </template>
      </div>
    </div>
    <!-- Modal for User Chart -->
    <div class="modal fade" id="completedChartModal" tabindex="-1" aria-labelledby="completedChartModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <button class="btn-close" title="Close" data-bs-dismiss="modal" aria-label="Close" tabindex="-2"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <!-- <h4>Are you sure you want to delete?</h4> -->
              <canvas ref="completedmodalChart"></canvas>
            </div>
          </div>
          <div class="modal-footer">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//   import VueApexCharts from 'vue-apexcharts';
import { ErrorLog } from "../Common/APILog";
import Chart from 'chart.js/auto';
import api, { setAuthToken } from "../../components/apiConfig";
import Cookies from "js-cookie";
import LoadingOverlay from '../Common/blockoutpage.vue';
export default {
  components: {
    LoadingOverlay
  },
  data() {
    return {
      Isloading: false,
      completedChart: null,
      completedmodalChart: null,
      CompletedTeamTaskCount: 0,
      chartData: {
        // labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July','August','September','October','November','December'],
        datasets: [
          {
            label: 'Completed Task',
            data: [65, 59, 80, 81, 56, 0, 40, 10, 20, 20, 30, 40],
            fill: true,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
          }
        ]
      },
    };
  },
  mounted() {
    // Initial data
    this.getTotalCompletedTask();
  },
  methods: {
    async getTotalCompletedTask() {
      try {
        this.Isloading = true;
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        //const response = await api.get("/api/users/get-total-users");
        const response = await api.get(
          `/api/tasks/get-completed-assign-tasks/${userCookie.UserID}`
        );
        if (response.data) {
          this.CompletedTeamTaskCount = Object.values(response.data[0]).reduce((acc, cur) => acc + cur, 0);
          this.chartData.datasets[0].data = response.data[0];
          this.renderCompletedChart();
        }
        // Continue with any other logic (e.g., navigate to a different page)
      } catch (error) {
        if (error.response && error.response.data) {
          const jsonResponseString = JSON.stringify(error.response.data);
          try {
            const jsonObject = JSON.parse(jsonResponseString);
            // this.formHeader = jsonObject.message || "Something went wrong.";
          } catch (parseError) {
            //this.formHeader = parseError.message || "Something went wrong.";
            ErrorLog(error.message, error.response.status, 103, error.stack, "User Panel: CompletedTask / getTotalCompletedTask()");
          }
        }else
        {
          //this.formHeader = "Something went wrong.";
        }
      } finally {
        this.Isloading = false;
        //this.loading = false; // Set loading state to false after data is fetched
      }
    },
    renderCompletedChart() {
      this.completedChart = new Chart(this.$refs.completedChart, {
        type: 'line',
        data: this.chartData,
        options: {
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      });
      this.completedmodalChart = new Chart(this.$refs.completedmodalChart, {
        type: 'line',
        data: this.chartData,
        options: {
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      });
    },
  },
};
</script>
<style scoped>
.Count-header {
  font-size: 14px;
  font-weight: 600;
  float: left;
}

.zoom-in {
  float: right;
  border-radius: 5px;
  color: #fff;
  background: #0d6efd;
  border: 2px solid #0d6efd;
  margin-top: 10px;
  padding-top: 3px;
}

.zoom-in i {
  font-size: 16px;
}

@media (min-width: 620px) {
  .modal-dialog {
    width: 600px;
    max-width: 600px;
  }
}

.topchart-div {
  overflow: hidden;
  position: relative;
}

.inner-div {
  position: absolute;
  bottom: 0;
  width: 100%;
}

@media screen and (max-width:1280px) {
  .inner-div {
    position: unset !important;
  }
}

@media screen and (min-width:1281px) {
  .topchart-div {
    height: 192px !important;
  }
}

@media screen and (min-width:1300px) {
  .topchart-div {
    height: 197px !important;
  }
}

@media screen and (min-width:1350px) {
  .topchart-div {
    height: 205px !important;
  }
}

@media screen and (min-width:1400px) {
  .topchart-div {
    height: 220px !important;
  }
}
</style>