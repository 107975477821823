import { render, staticRenderFns } from "./manageAdmins.vue?vue&type=template&id=3fdb0a92&scoped=true"
import script from "./manageAdmins.vue?vue&type=script&lang=js"
export * from "./manageAdmins.vue?vue&type=script&lang=js"
import style0 from "./manageAdmins.vue?vue&type=style&index=0&id=3fdb0a92&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fdb0a92",
  null
  
)

export default component.exports