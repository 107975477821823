<!-- src/components/Footer.vue -->
<template>
  <footer>
    <p>&copy; 2024 Tasks-Manager. All rights reserved.</p>
    <!-- Add any other footer content or links -->
  </footer>
</template>

<script>
export default {
  name: "FooterView",
};
</script>

<style scoped>
/* Add your footer styles here */
footer {
  background-color: #3e6d2c;
  color: #fff;
  padding: 10px;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
}
</style>
