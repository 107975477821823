<template>
  <div>
    <div class="topchart-div">
      <div class="inner-div">
        <canvas ref="userChart" width="310"></canvas>
        <template>
          <h4 class="Count-header">Total Users : {{ UsersCount }}</h4>
          <button class="zoom-in" title="Click to Zoom" data-bs-toggle="modal" data-bs-target="#userChartModal" data-bs-whatever="@mdo">
            <i class="fas fa-search-plus"></i></button>
        </template>
      </div>
    </div>
    <!-- Modal for User Chart -->
    <div class="modal fade" id="userChartModal" tabindex="-1" aria-labelledby="userChartModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <button class="btn-close" title="Close" data-bs-dismiss="modal" aria-label="Close" tabindex="-2"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <!-- <h4>Are you sure you want to delete?</h4> -->
              <canvas ref="usermodalChart"></canvas>
            </div>
          </div>
          <div class="modal-footer">
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
//   import VueApexCharts from 'vue-apexcharts';
import Chart from 'chart.js/auto';
import api, { setAuthToken } from "../../components/apiConfig";
import Cookies from "js-cookie";
import { ErrorLog } from "../Common/APILog";
import { handleAuthorization, handleServerError } from '../Common/commonMethod';
export default {
  components: {
  },
  data() {
    return {
      userChart: null,
      flag: 0,
      usermodalChart: null,
      UsersCount: 0,
      chartData: {
        // labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July','August','September','October','November','December'],
        datasets: [
          {
            label: 'Users',
            data: [65, 59, 80, 81, 56, 0, 40, 10, 20, 20, 30, 40],
            fill: true,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
          }
        ]
      },
    };
  },
  mounted() {
    // Initial data
    this.getTotalUsers();
  },
  methods: {
    async getTotalUsers() {
      try {
        const token =await Cookies.get("jwt");
        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        setAuthToken(token);
        const response = await api.get(`/api/users/get-total-users/${userCookie.UserID}`);
        if (response.data) {
          this.UsersCount = Object.values(response.data[0]).reduce((acc, cur) => acc + cur, 0);
          this.chartData.datasets[0].data = response.data[0];

          this.renderUserChart();
        }
        // Continue with any other logic (e.g., navigate to a different page)
      } catch (error) {

        if (this.flag > 3) {
          if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              // this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              // this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 101, error.stack, "AdminPanel/userChart/getTotalUsers()");
          }
        }
        else {
          // this.errorAlertOnSubmit = "Something went wrong.";
        }
        }
        else {
          setTimeout(() => {
            this.getTotalUsers(); // Retry after a delay
          }, 1000);
          this.flag++;
        }

      } finally {
        //this.loading = false; // Set loading state to false after data is fetched
      }
    },
    renderUserChart() {
      this.userChart = new Chart(this.$refs.userChart, {
        type: 'line',
        data: this.chartData,
        options: {
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      });
      this.usermodalChart = new Chart(this.$refs.usermodalChart, {
        type: 'line',
        data: this.chartData,
        options: {
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      });
    },
  },
};
</script>
<style scoped>
.Count-header {
  font-size: 14px;
  font-weight: 600;
  float: left;
}

.zoom-in {
  float: right;
  border-radius: 5px;
  color: #fff;
  background: #0d6efd;
  border: 2px solid #0d6efd;
  margin-top: 10px;
  padding-top: 3px;
}

.zoom-in i {
  font-size: 16px;
}

@media (min-width: 720px) {
  .modal-dialog {
    width: 700px;
    max-width: 700px;
  }
}

.topchart-div {
  overflow: hidden;
  position: relative;
}

.inner-div {
  position: absolute;
  bottom: 0;
  width: 100%;
}

@media screen and (max-width:1280px) {
  .inner-div {
    position: unset !important;
  }
}

@media screen and (min-width:1281px) {
  .topchart-div {
    height: 192px !important;
  }
}

@media screen and (min-width:1300px) {
  .topchart-div {
    height: 197px !important;
  }
}

@media screen and (min-width:1350px) {
  .topchart-div {
    height: 205px !important;
  }
}

@media screen and (min-width:1400px) {
  .topchart-div {
    height: 220px !important;
  }
}
</style>