<template>
  <div class="content">
    <LoadingOverlay :loading="Isloading" />
    <div class="md-layout">
      <div class="md-layout-item md-large-size-100 md-size-66">
        <form @submit.prevent="updateUser">
          <md-card style="padding-bottom: 20px;">
            <md-card-header data-background-color="green" style="overflow: hidden;">
              <h4 class="title" style="float: left;">My Profile</h4>
            </md-card-header>
            <md-card-content>
              <!-- Display success alert -->
              <div v-if="successAlertOnSubmit" class="alert alert-success text-left" role="alert">
                {{ successAlertOnSubmit }}
                <span @click="hideUserAlert" class="close-icon">&times;</span>
              </div>
              <div
                v-if="errorAlertOnSubmit"
                class="alert alert-danger text-left"
                role="alert"
              >
                {{ errorAlertOnSubmit }}
                <span @click="hideTaskAlert" class="close-icon"
                  >&times;</span
                >
              </div>
              <div v-if="loadingUser" class="text-center">
                <p>{{ loadingUser }}</p>
              </div>
              <form @submit.prevent="submitUserForm" v-if="!loadingUser">
                <input v-model="editUser.UserID" type="hidden" class="form-control" />

                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for="title" class="col-form-label">First Name</label>
                      <input v-model="editUser.FirstName" type="text" class="form-control" id="title" tabindex="1"
                        @input="isValidUserForm" />
                      <span class="text-red-500 text-sm">{{
                        userValidate.FirstName
                      }}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for="title" class="col-form-label">Last Name</label>
                      <input v-model="editUser.LastName" type="text" class="form-control" id="title" tabindex="1"
                        @input="isValidUserForm" />
                      <span class="text-red-500 text-sm">{{
                        userValidate.LastName
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for="email" class="col-form-label">Email</label>
                      <input v-model="editUser.Email" type="text" class="form-control" id="email" tabindex="1"
                        @input="isValidUserForm" />
                      <span class="text-red-500 text-sm">{{
                        userValidate.Email
                      }}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for="Username" class="col-form-label">User Name</label>
                      <input v-model="editUser.Username" disabled type="text" class="form-control" id="Username" tabindex="1"
                        @input="isValidUserForm" />
                      <span class="text-red-500 text-sm">{{
                        userValidate.Username
                      }}</span>
                    </div>
                  </div>
                  <!-- <div class="col-md-6">
                    <div class="mb-3">
                      <label for="Password" class="col-form-label">Password</label>
                      <input v-model="editUser.Password" type="password" class="form-control" id="Password" tabindex="1"
                        @input="isValidUserForm" />
                      <span class="text-red-500 text-sm">{{
                        userValidate.Password
                      }}</span>
                    </div>
                  </div> -->
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for="Contact" class="col-form-label">Contact</label>
                      <input v-model="editUser.ContactNumber" type="text" maxlength="10" class="form-control"
                        id="Contact" tabindex="1" @keypress="isNumber($event)" @input="isValidUserForm" />
                      <span class="text-red-500 text-sm">{{
                        userValidate.ContactNumber
                      }}</span>
                    </div>
                  </div>
                </div>

                <button type="submit" title="Update" class="btn btn-primary" default tabindex="6" style="float: right;"
                  :disabled="btnEnable">
                  Update
                 
                </button>
              </form>
            </md-card-content>
          </md-card>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import api, { setAuthToken } from "../apiConfig";
import { authMixin } from "./adminStore";
import Cookies from "js-cookie";
import { handleAuthorization, handleServerError, isValidEmail, isValidPhoneNumber, isValidText, preventNumberInput, preventTextInput } from "../Common/commonMethod";
import { ErrorLog } from "../Common/APILog";
import LoadingOverlay from '../Common/blockoutpage.vue';


export default {
  mixins: [authMixin],
  created() {
    this.checkAuthorization(); // Check authorization when the component is created
    this.getUserById();
  },
  name: "EditUser",
  components: {
    LoadingOverlay
  },
  props: {
  },
  data() {
    return {
      Isloading:false,
      loadingUser: "",
      successAlertOnSubmit: "",
      users:[],
      categoryDropdownOptions: [],
      priorityDropdownOptions: [],
      btnEnable: false,
      editUser: {
        UserID: 0,
        FirstName: "",
        LastName: "",
        Username: "",
        ContactNumber: "",
        Email: "",
        // Password: "",
        UserType: "User",
      },
      errorAlertOnSubmit:"",
      userValidate: {
        FirstName: "",
        LastName: "",
        Username: "",
        ContactNumber: "",
        Email: "",
        UserType: "",
        // Password: "",
      },
      errors: {},
      formHeader: "",
    };
  },
  computed: {
  },
  mounted() {
    // Initial data
  },
  methods: {
    isNumber: function (evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    goBack() {
      // Go back to the previous page in the history
      this.$router.go(-1);
    },
    hideUserAlert() {
      //this.taskFormHeader = "";
      this.successAlertOnSubmit = "";
      this.errorAlertOnSubmit="";
    },
    async updateUser() {
      this.formHeader = "";
      this.Isloading=true;
      this.errorAlertOnSubmit="";
      this.btnEnable = true;
      try {
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        const response = await api.put(
          `/api/users/update-myprofile/${this.editUser.UserID}`,
          {
            Username: this.editUser.Username,
            // Password: this.editUser.Password,
            UserType: this.editUser.UserType,
            FirstName: this.editUser.FirstName,
            LastName: this.editUser.LastName,
            Email: this.editUser.Email,
            ContactNumber: this.editUser.ContactNumber,
          }
        );
      if(response.data.success){
        this.successAlertOnSubmit = "Profile updated successfully";
      }
      else{
        this.errorAlertOnSubmit = response.data.msg;
      }

      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 242, error.stack, "AdminPanel/MyProfile/updateUser()");
          }
        }
        else {
          this.errorAlertOnSubmit = "Something went wrong.";
        }
      } finally {
        this.Isloading=false;
        this.btnEnable = false;
        setTimeout(() => {
          this.errorAlertOnSubmit = "";
          this.successAlertOnSubmit="";
        }, 5000); // 3 seconds in milliseconds
     
      }
    },
    resetValidation() {
      this.userValidate = {
        FirstName: "",
        LastName: "",
        ContactNumber: "",
        Email: "",
        Username: "",
        // Password: "",
      };
    },
    resetForm() {
      this.editUser = {
        UserID: 0,
        FirstName: "",
        LastName: "",
        UserType: "User",
        Email: "",
        Username: "",
        // Password: "",
      };
      this.formHeader = "";
    },

    async submitUserForm() {
      this.resetValidation();
      if (this.isValidUserForm()) {
        //this.editUser.TaskID === 0 ? this.createTask() : this.updateUser();
        this.updateUser();
      }
    },
    isValidUserForm() {
      let isValid = true;
      // First Name
      if (!this.editUser.FirstName.trim()) {
        this.userValidate.FirstName = "First name is required.";
        isValid = false;
      } else {
        this.userValidate.FirstName = "";
      }

      if (!this.editUser.LastName.trim()) {
        this.userValidate.LastName = "Last name is required.";
        isValid = false;
      } else {
        this.userValidate.LastName = "";
      }

      if (!this.editUser.ContactNumber) { 
        this.userValidate.ContactNumber = "Contact number is required.";
        isValid = false;
      } 
      else if (!isValidPhoneNumber(this.editUser.ContactNumber)) {
        this.userValidate.ContactNumber = "Please enter a valid Contact Number.";
        isValid = false;
      }
      else if(this.editUser.ContactNumber.length == 10){
        var userFilter=this.users.filter(item => {
            return (
              item.ContactNumber==parseInt(this.editUser.ContactNumber)
              && item.UserID!=this.editUser.UserID
            );
        });
        if(userFilter.length>0){
          this.userValidate.ContactNumber = "The entered contact already exists";
          isValid = false;
        }
        else{
          this.userValidate.ContactNumber = "";
        }
      }
       else {
        this.userValidate.ContactNumber = "";
      }


      // Email
      if (!this.editUser.Email.trim()) {
        this.userValidate.Email = "Email is required.";
        isValid = false;
      } else if (!isValidEmail(this.editUser.Email.trim())) {
        this.userValidate.Email = "Please enter valid email address.";
        isValid = false;
      } 
      else if(isValidEmail(this.editUser.Email.trim())){
        
        var userFilter=this.users.filter(item => {
            return (
              item.Email==this.editUser.Email.trim()
              && item.UserID!=this.editUser.UserID
            );
        });
        console.log(userFilter)
        if(userFilter.length>0){
          this.userValidate.Email = "The entered email already exists";
          isValid = false;
        }
        else{
          this.userValidate.Email = "";
        }
      }
      else {
        this.userValidate.Email = "";
      }


      if (!this.editUser.Username.trim()) {
        this.userValidate.Username = "User name is required.";
        isValid = false;
      }
      else if(/\s/.test(this.editUser.Username)){
        this.userValidate.Username = "Please remove whitespace in user name.";
        isValid = false;
      }
       else {
        this.userValidate.Username = "";
      }

      // if (!this.editUser.Password.trim()) {
      //   this.userValidate.Password = "Password is required.";
      //   isValid = false;
      // } else {
      //   this.userValidate.Password = "";
      // }


      return isValid;
    },
    async getUserById() {
      try {
        this.Isloading=true;
        this.loadingUser = "Loading...";
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        const response = await api.get(
          `/api/admin/tasks/get-user/${userCookie.UserID}`
        );
        if (response.data) {
          console.log("user", response.data);
          this.editUser = response.data[0];
          this.resetValidation();
        } else {
          this.errorAlertOnSubmit = "Something went wrong.Try again later.";
        }
      } catch (error) {
        console.log("err", error);
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 420, error.stack, "AdminPanel/MyProfile/getUserById()");
          }
        }
        else {
          this.errorAlertOnSubmit = "Something went wrong.";
        }
      }
      finally {
        this.loadingUser = "";
        this.Isloading=false;
        setTimeout(() => {
         this.errorAlertOnSubmit = "";
          this.successAlertOnSubmit="";
        }, 5000); // 5 seconds in milliseconds
        this.getUsers();
      }
    },
    async getUsers() {
      this.users = [];
      this.Isloading=true;
      this.loading = true;
      try {
        const token =await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie =await Cookies.get("user");
        userCookie =await userCookie ? JSON.parse(userCookie) : null;
        const response = await api.get(`/api/users/get-users/${userCookie.UserID}`);
        if (response.data.length>0) {
          this.users = response.data;
        } else {
        }
        // Continue with any other logic (e.g., navigate to a different page)
      } catch (error) {
        console.log(error)
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 470, error.stack, "AdminPanel/MyProfile/getUsers()");
          }
        }
        else {
          this.errorAlertOnSubmit = "Something went wrong.";
        }
       } 
      finally {
        this.Isloading=false;
       this.loading = false; // Set loading state to false after data is fetched
      }
    },

  },
};
</script>

<style scoped>
/* Add Tailwind CSS classes here if needed */
.close-icon {
  cursor: pointer;
  float: right;
  font-size: 30px;
  margin-top: -10px;
}

.dropdown {
  width: 100%;
  height: 36px;
  padding: 7px 0;
  font-size: 14px;
  line-height: 1.428571429;
  border-color: #D2D2D2;
}
</style>
