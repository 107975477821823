<template>
  <div class="content">
    <LoadingOverlay :loading="Isloading" />
    <div class="row">
      <div class="col-md-12">
        <p v-if="UserName" class="username"><span style="color:black;">Logged in as :</span> {{ UserName }}</p>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-50 md-medium-size-50 md-xsmall-size-100 md-size-33">
            <div class="bg-color" style="margin: 0px; margin-top:15px;">
              <div class="media-body text-xs-left float-right" id="calories-value"
                style="max-width: 445px; border-radius: 4px; position:relative;" v-on:click="OpenTaskPage()">
                <h3 class="card-value" id="calories-text" style="color: rgb(130, 130, 130);">{{ TotalTaskCount }}</h3>
                <div id="foodimg"><span class="card-text" id="calories-subtitle" style="color: rgb(130, 130, 130);">Team
                    Tasks</span>
                  <span id="food-img" class="icon-user"
                    style="font-size: 65px; margin-right: 3%; margin-bottom: 2%; color: rgb(153, 147, 147);"><i
                      class="fas fa-tasks"></i></span>
                </div>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-small-size-50 md-medium-size-50 md-xsmall-size-100 md-size-33">
            <div class="bg-color" style="margin: 0px; margin-top:15px;">
              <div class="media-body text-xs-left float-right" id="calories-value"
                style="max-width: 445px; border-radius: 4px; position:relative;" v-on:click="OpenUserPage()">
                <h3 class="card-value" id="calories-text" style="color: rgb(130, 130, 130);">{{ TotalUserCount }}</h3>
                <div id="foodimg"><span class="card-text" id="calories-subtitle"
                    style="color: rgb(130, 130, 130);">Total Users</span>
                  <span id="food-img" class="icon-user"
                    style="font-size: 65px; margin-right: 3%; margin-bottom: 2%; color: rgb(153, 147, 147);"><i
                      class="fas fa-users"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-small-size-50 md-medium-size-50 md-xsmall-size-100 md-size-33">
        <div class="bg-color">
          <TaskChart />
        </div>
      </div>
      <div class="md-layout-item md-small-size-50 md-medium-size-50 md-xsmall-size-100 md-size-33 ">
        <div class="bg-color">
          <UserChart />
        </div>
      </div>
      <div class="md-layout-item md-small-size-50 md-medium-size-50 md-xsmall-size-100 md-size-33">
        <div class="bg-color">
          <pie-chart />
        </div>
      </div>
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
        <div class="col-md-12">
          <div v-if="successAlertOnSubmit" class="alert alert-success text-left" role="alert">
            {{ successAlertOnSubmit }}
            <span @click="hideTaskAlert" class="close-icon">&times;</span>
          </div>
        </div>
        <nav-tabs-card>
          <template slot="content">
            <span class="md-nav-tabs-title">Team Tasks:</span>
            <button hidden type="button" title="Edit Task" data-bs-toggle="modal" data-bs-target="#taskEditStatusModal"
              data-bs-whatever="@mdo" class="btn btn-primary" id="openEditStatusModal">
              Edit Status
            </button>
            <md-tabs class="md-success" md-alignment="left">
              <md-tab id="tab-posts" md-label="Completed" md-icon="checklist">
                <div class="col-md-12">
                  <div class="task-table">
                    <div v-if="!loadingCompletedTasks">
                      <table style="width: 100%;" :table-header-color="tableHeaderColor">
                        <tr class="toprow">
                          <td style="width:23%;padding-left:5px;" class="table-cell">Task</td>
                          <td style="width:25%" class="table-cell">Description</td>
                          <td style="width:10%" class="table-cell">Priority</td>
                          <td style="width:12%" class="table-cell">Assigned Date</td>
                          <td style="width:10%" class="table-cell">Due Date</td>
                          <td style="width:15%;text-align:center" class="table-cell">Action</td>
                        </tr>
                        <tr slot="md-table-row" v-for="item in paginatedTasks" :key="item.id" class=""
                          style="border-bottom:1px solid #ddd">
                          <td class="md-table-cell"><span v-if="item.Title.length > 60" :title="item.Title">
                              {{ truncateDescription(item.Title) }}
                            </span>
                            <span v-else>
                              {{ item.Title }}
                            </span>
                          </td>
                          <td class="md-table-cell">
                            <span v-if="item.Description.length > 70" :title="item.Description">
                              {{ truncateDescription(item.Description) }}
                            </span>
                            <span v-else>
                              {{ item.Description }}
                            </span>
                          </td>
                          <td class="md-table-cell">{{ item.PriorityName }}</td>
                          <td class="md-table-cell">
                            {{ formatDueDate(item.CreatedDate) }}
                          </td>
                          <td class="md-table-cell"> {{ formatDueDate(item.DueDate) }} </td>
                          <td class="md-table-cell" style="text-align:center">

                            <button @click="viewPersonalTaskDetail(item.TaskID)" type="button" style="margin-left:17%;"
                              class="btn btn-primary btn-sm ml-2" title="View Task">
                              <i class="fa fa-eye" aria-hidden="true"></i>
                            </button>
                          </td>
                        </tr>
                      </table>
                      <div class="d-flex my-1 mt-2" v-if="!IsTasks">
                        <div style="width: 33%;" class="d-flex">
                          <span style="width: 50%;line-height:50px;">Tasks per page:</span>
                          <Select @change="setPageSize" class="form-select PageSizeSelect" style="width: 40%;"
                            v-model="pageSize">
                            <Option :value="5">5</Option>
                            <Option :value="10">10</Option>
                            <Option :value="20">20</Option>
                            <Option :value="30">30</Option>
                          </Select>
                        </div>
                        <div class="Pagination">
                          <button @click="previousPage" :disabled="currentPage === 1" title="Previous"
                            :class="{ 'disabled-button': currentPage === 1, 'enabled-button': currentPage !== 1 }"><i
                              class="fa fa-angle-left"></i> Prev</button>
                          <span class="current-page-indicator">{{ currentPage }}</span>
                          <button @click="nextPage" :disabled="currentPage === totalPages" title="Next"
                            :class="{ 'disabled-button': currentPage === totalPages, 'enabled-button': currentPage !== totalPages }">Next
                            <i class="fa fa-angle-right"></i></button>
                        </div>
                        <div style="width: 33%;padding-right: 15px;" class="d-flex justify-content-end mx-3">
                          <span style="line-height:50px;" class="text-right mx-1">Total Pages: </span>
                          <span style="line-height:50px;" class="text-right">{{ totalPages }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="text-center" v-else>
                      <span>{{ loadingCompletedTasks }}</span>
                    </div>
                  </div>
                </div>
              </md-tab>
              <md-tab id="tab-Dues" md-label="Due" md-icon="pending_actions">
                <div class="col-md-12">
                  <div class="task-table">
                    <div v-if="!loadingDueTasks">
                      <table style="width: 100%;" :table-header-color="tableHeaderColor">
                        <tr class="toprow">
                          <td style="width:23%;padding-left:5px;" class="table-cell">Task</td>
                          <td style="width:25%" class="table-cell">Description</td>
                          <td style="width:10%" class="table-cell">Priority</td>
                          <td style="width:12%" class="table-cell">Assigned Date</td>
                          <td style="width:10%" class="table-cell">Due Date</td>
                          <td style="width:15%;text-align:right;padding-right:5px;" class="table-cell">Actions</td>
                        </tr>
                        <tr slot="md-table-row" v-for="item in paginatedTasksDue" :key="item.id" class="expired-row">
                          <td class="md-table-cell"><span v-if="item.Title.length > 60" :title="item.Title">
                              {{ truncateDescription(item.Title) }}
                            </span>
                            <span v-else>
                              {{ item.Title }}
                            </span>
                          </td>
                          <td class="md-table-cell">
                            <span v-if="item.Description.length > 70" :title="item.Description">
                              {{ truncateDescription(item.Description) }}
                            </span>
                            <span v-else>
                              {{ item.Description }}
                            </span>
                          </td>
                          <td class="md-table-cell">{{ item.PriorityName }}</td>
                          <td class="md-table-cell">
                            {{ formatDueDate(item.CreatedDate) }}
                          </td>
                          <td class="md-table-cell"> {{ formatDueDate(item.DueDate) }} </td>
                          <td class="md-table-cell" style="text-align:right">
                            <button @click="viewPersonalTaskDetail(item.TaskID)" style="margin-left: 15%;" type="button"
                              class="btn btn-primary btn-sm ml-2" title="View Task">
                              <i class="fa fa-eye" aria-hidden="true"></i>
                            </button>
                            <button @click="editTaskDetail(item.TaskID)" type="button"
                              class="btn btn-primary btn-sm ml-2" title="Edit Task">
                              <i class="fas fa-pencil-alt"></i>
                            </button>
                          </td>
                        </tr>
                      </table>
                      <div class="d-flex my-1 mt-2" v-if="!IsTasks">
                        <div style="width: 33%;" class="d-flex">
                          <span style="width: 50%;line-height:50px;">Tasks per page:</span>
                          <Select @change="setPageSize" class="form-select PageSizeSelect" style="width: 40%;"
                            v-model="pageSize">
                            <Option :value="5">5</Option>
                            <Option :value="10">10</Option>
                            <Option :value="20">20</Option>
                            <Option :value="30">30</Option>
                          </Select>
                        </div>
                        <div class="Pagination">
                          <button @click="previousPageDue" :disabled="currentPageDue === 1" title="Previous"
                            :class="{ 'disabled-button': currentPageDue === 1, 'enabled-button': currentPageDue !== 1 }"><i
                              class="fa fa-angle-left"></i> Prev</button>
                          <span class="current-page-indicator">{{ currentPageDue }}</span>
                          <button @click="nextPageDue" :disabled="currentPageDue === totalPagesDue" title="Next"
                            :class="{ 'disabled-button': currentPageDue === totalPagesDue, 'enabled-button': currentPageDue !== totalPagesDue }">Next
                            <i class="fa fa-angle-right"></i></button>
                        </div>
                        <div style="width: 33%;padding-right: 15px;" class="d-flex justify-content-end mx-3">
                          <span style="line-height:50px;" class="text-right mx-1">Total Pages: </span>
                          <span style="line-height:50px;" class="text-right">{{ totalPagesDue }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="text-center" v-else>
                      <span>{{ loadingDueTasks }}</span>
                    </div>
                  </div>
                </div>
              </md-tab>
            </md-tabs>
          </template>
        </nav-tabs-card>
      </div>
    </div>
  </div>
</template>

<script>
import { NavTabsCard } from "@/components";
import { authMixin } from "../components/admin_pages/adminStore";
import api, { setAuthToken } from "../components/apiConfig";
import TaskChart from '../components/admin_pages/TaskChart.vue';
import Cookies from "js-cookie";
import { handleAuthorization, handleServerError } from "../components/Common/commonMethod";
import PieChart from '../components/admin_pages/PieChart.vue';
import UserChart from '../components/admin_pages/UserChart.vue';
import { ErrorLog } from "../components/Common/APILog";
import LoadingOverlay from '../components/Common/blockoutpage.vue';
export default {
  mixins: [authMixin],
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  created() {
    this.checkAuthorization();
  },
  components: {
    PieChart,
    UserChart,
    NavTabsCard,
    TaskChart,
    LoadingOverlay
  },
  data() {
    return {
      IsTasks: false,
      taskFormHeader: "",
      Isloading: false,
      flagCount: 0,
      taskAssignmentValidate: {
        TaskID: "",
        UserId: "",
        selectedUsers: "",
        DueDate: "",
        // Add additional validation fields as needed
      },
      TaskAssignedChart: null,
      dueTasks: [],
      completedTasks: [],
      inprocessTasks: [],
      assignedTasks: [],
      loadingTasks: "",
      UsersCount: 0,
      loadingInProcessTasks: "",
      loadingCompletedTasks: "",
      loadingDueTasks: "",
      successAlertOnSubmit: "",
      AssignedTaskCount: 0,
      TotalTaskCount: 0,
      btnTaskEnable: false,
      newTask: {
        TaskID: 0,
        Title: "",
        Description: "",
        DueDate: null,
        IsCompleted: false,
        PriorityID: 0,
        CategoryID: 0,
        TaskStatus: 1,
        UserId: 0,
      },
      taskValidate: {
        Title: "",
        Description: "",
        PriorityID: "",
        CategoryID: "",
        DueDate: "",
        // Add additional validation fields as needed
      },
      taskAssignment: {
        updateTaskId: 0,
        TaskID: 0,
        TaskTitle: "",
        UserId: 0,
        selectedUsers: [],
        DueDate: null,
      },
      categoryDropdownOptions: [],
      priorityDropdownOptions: [],
      DropdownOptions: {
        userOptions: [],
        taskOPtions: [],
      },
      isInputDisabled: true,
      TotalUserCount: 0,
      title: "Dashboard",
      UserName: "",
      pageSize: 5, // Number of tasks per page
      currentPage: 1,// Current page
      pageSizeDue: 5,
      currentPageDue: 1 // Current page
    };
  },
  mounted() {
    this.getTasks();
    this.getDropdownOptions();
  },
  computed: {

    paginatedTasks() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.completedTasks.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.completedTasks.length / this.pageSize);
    },
    paginatedTasksDue() {
      const startIndex = (this.currentPageDue - 1) * this.pageSizeDue;
      const endIndex = startIndex + this.pageSizeDue;
      return this.dueTasks.slice(startIndex, endIndex);
    },
    totalPagesDue() {
      return Math.ceil(this.dueTasks.length / this.pageSizeDue);
    }
  },
  methods: {
    setPageSize(event) {
      const selectedSize = event.target.value;
      this.pageSize = parseInt(selectedSize);
      this.currentPage = 1; // Reset to the first page
      this.paginatedTasks();
      // alert(`Page size changed to: ${selectedSize}`);
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPageDue() {
      if (this.currentPageDue < this.totalPagesDue) {
        this.currentPageDue++;
      }
    },
    previousPageDue() {
      if (this.currentPageDue > 1) {
        this.currentPageDue--;
      }
    },
    async submitTaskForm() {
      this.resetTaskValidation();

      if (this.isValidTaskForm()) {
        this.createTask();
      }
    },
    resetTaskValidation() {
      this.taskValidate = {
        Title: "",
        DueDate: "",
      };
    },
    OpenUserPage() {
      console.log("users")
      this.$router.push("/user-management");
    },
    OpenTaskPage() {
      this.$router.push("/task-management");
    },
    async createTask() {
      this.taskFormHeader = "";
      try {
        this.btnTaskEnable = true;
        const token = Cookies.get("jwt");
        setAuthToken(token);
        let userCookie = await Cookies.get("user");
        userCookie = await userCookie ? JSON.parse(userCookie) : null;
        const UserID = await userCookie.UserID
        const response = await api.post("/api/admin/tasks/create-task", {
          Title: this.newTask.Title,
          Description: this.newTask.Description,
          DueDate: this.newTask.DueDate,
          IsCompleted: this.newTask.IsCompleted,
          File: null,
          PriorityID: this.newTask.PriorityID,
          CategoryID: this.newTask.CategoryID,
          UserID: UserID,
          TaskStatus: 1,
          TaskType: "Public",
          UserID: UserID,
          assignBy: UserID,
          userIds: this.taskAssignment.selectedUsers,
        });
        if (response.data.success) {
          //this.getTasks();
          this.closeModal(); // hide modal after successful submit.
          this.successAlertOnSubmit = "Task added successfully.";
        } else {
          this.taskFormHeader = response.data.msg;
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              // this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              // this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.statuscode, 462, error.stack, "pages/Dashboard/createTask");
          }
        }
      } finally {
        this.btnTaskEnable = false;
        this.$router.push('/task-management');
      }
    },
    closeModal() {
      this.resetTaskValidation();
      document.getElementById("closeModal").click();
    },
    async getDropdownOptions() {
      try {
        this.Isloading = true;
        const token = Cookies.get("jwt");
        setAuthToken(token);
        let userCookie = await Cookies.get("user");
        userCookie = await userCookie ? JSON.parse(userCookie) : null;
        const UserID = await userCookie.UserID
        const response = await api.get(
          `/api/admin/tasks/get-dropdown-tasks-users/${UserID}`
        );
        if (response.data) {

          this.DropdownOptions.userOptions = response.data.users;
          this.TotalUserCount = this.DropdownOptions.userOptions.length;

          this.priorityDropdownOptions = response.data.priorities;
          this.categoryDropdownOptions = response.data.categories.slice().sort((a, b) => a.CategoryName.localeCompare(b.CategoryName));
        } else {
          // Handle case where no priorities are found
        }
      } catch (error) {
        if (this.flagCount < 3) {
          setTimeout(() => {
            this.getDropdownOptions(); // Retry after a delay
          }, 3000);
          this.flagCount++;
        }
        else {
          if (error.response && error.response.data) {
            if (error.response.status === 401) {
              handleAuthorization("/login")
            }
            else if (error.response.status === 500) {
              handleServerError("/login")
            }
            else {
              const jsonResponseString = JSON.stringify(error.response.data);
              try {
                const jsonObject = JSON.parse(jsonResponseString);
                // this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
              } catch (parseError) {
                // this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
              }
              ErrorLog(error.message, error.statuscode, 517, error.stack, "pages/Dashboard/getDropdownOptions");
            }
          }

        }
        console.log(error);

        // Handle API error
      } finally {
        this.Isloading = false;
      }
    },
    editTaskDetail(taskId) {
      this.$router.push({
        name: "Edit Task",
        params: { taskId },
      });
    },
    formatDueDate(originalDate, type = "show") {
      if (!originalDate) return ""; // Handle null or undefined dates
      const dateObject = new Date(originalDate);
      const day = dateObject.getDate().toString().padStart(2, "0");
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObject.getFullYear();
      if (type == "show") {
        return `${day}/${month}/${year}`;
      } else {
        return `${year}-${month}-${day}`;
      }
    },
    truncateDescription(description) {
      // Truncate the description if it's longer than 30 characters
      return description.length > 25
        ? description.slice(0, 25) + "..."
        : description;
    },
    closeConfirmStatusModal() {
      document.getElementById("closeConfirmStatusModal").click();
    },
    isValidTaskForm() {
      let isValid = true;
      // Title
      if (!this.newTask.Title.trim()) {
        this.taskValidate.Title = "Title is required.";
        isValid = false;
      } else {
        this.taskValidate.Title = "";
      }
      // Description
      if (!this.newTask.Description.trim()) {
        this.taskValidate.Description = "Description is required.";
        isValid = false;
      } else {
        this.taskValidate.Description = "";
      }
      // Priority
      if (this.newTask.PriorityID === 0 || this.newTask.PriorityID === "0") {
        this.taskValidate.PriorityID = "Please select priority.";
        isValid = false;
      } else {
        this.taskValidate.PriorityID = "";
      }
      // Category
      if (this.newTask.CategoryID === 0 || this.newTask.CategoryID === "0") {
        this.taskValidate.CategoryID = "Please select category.";
        isValid = false;
      } else {
        this.taskValidate.CategoryID = "";
      }
      // DueDate
      if (!this.newTask.DueDate) {
        this.taskValidate.DueDate = "Due Date is required.";
        isValid = false;
      } else {
        this.taskValidate.DueDate = "";
      }
      return isValid;
    },
    isValidTaskAssignmentForm() {
      let isValid = true;
      // Users
      if (this.taskAssignment.selectedUsers.length == 0) {
        this.taskAssignmentValidate.selectedUsers = "Please select users.";
        isValid = false;
      } else {
        this.taskAssignmentValidate.selectedUsers = "";
      }
      // DueDate
      if (!this.taskAssignment.DueDate) {
        this.taskAssignmentValidate.DueDate = "Due Date is required.";
        isValid = false;
      } else {
        this.taskAssignmentValidate.DueDate = "";
      }
      return isValid;
    },
    async getPriorityDropdown() {
      try {
        const token = Cookies.get("jwt");
        setAuthToken(token);
        this.Isloading = true;
        const response = await api.get("/api/admin/tasks/get-priority-dropdown");
        if (response.data) {
          this.priorityDropdownOptions = response.data;
        } else {
          // Handle case where no priorities are found
        }
      } catch (error) {
        if (this.flagCount < 3) {
          setTimeout(() => {
            this.getPriorityDropdown(); // Retry after a delay
          }, 3000);
          this.flagCount++;
        }
        else {
          if (error.response && error.response.data) {
            if (error.response.status === 401) {
              handleAuthorization("/login")
            }
            else if (error.response.status === 500) {
              handleServerError("/login")
            }
            else {
              const jsonResponseString = JSON.stringify(error.response.data);
              try {
                const jsonObject = JSON.parse(jsonResponseString);
                // this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
              } catch (parseError) {
                // this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
              }
              ErrorLog(error.message, error.statuscode, 647, error.stack, "pages/Dashboard/getPriorityDropdown");
            }
          }
        }// Handle API error
      } finally {
        this.Isloading = false;
      }
    },
    async submitEditTaskStatusForm() {
      this.CompleteTask(this.newTask.TaskID, this.newTask.TaskStatus);
    },
    async getCategoryDropdown() {
      try {
        this.Isloading = true;
        const token = Cookies.get("jwt");
        setAuthToken(token);
        const response = await api.get("/api/admin/tasks/get-category-dropdown");
        if (response.data) {
          this.categoryDropdownOptions = response.data;
        } else {
          // Handle case where no categories are found
        }
      } catch (error) {
        if (this.flagCount < 3) {
          setTimeout(() => {
            this.getCategoryDropdown(); // Retry after a delay
          }, 3000);
          this.flagCount++;
        } else {
          if (error.response && error.response.data) {
            if (error.response.status === 401) {
              handleAuthorization("/login")
            }
            else if (error.response.status === 500) {
              handleServerError("/login")
            }
            else {
              const jsonResponseString = JSON.stringify(error.response.data);
              try {
                const jsonObject = JSON.parse(jsonResponseString);
                // this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
              } catch (parseError) {
                // this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
              }
              ErrorLog(error.message, error.statuscode, 691, error.stack, "pages/Dashboard/getCategoryDropdown");
            }
          }
        }// Handle API error
      } finally {
        this.Isloading = false;
      }
    },
    funCheckStatus(StatusID) {
      let tStatus = "not assigned";
      if (StatusID == 2) {
        tStatus = "new task";
      } else if (StatusID == 3) {
        tStatus = "in process";
      } else if (StatusID == 4) {
        tStatus = "completed";
      }
      return tStatus;
    },
    openEditStatusModal() {
      document.getElementById("openEditStatusModal").click();
    },
    async getTasks() {
      this.tasks = [];
      this.Isloading = true;
      this.loadingTasks = "Loading...";
      this.loadingInProcessTasks = "Loading...";
      this.loadingCompletedTasks = "Loading...";
      this.loadingDueTasks = "Loading...";
      try {
        const token = Cookies.get("jwt");
        setAuthToken(token);
        let userCookie = Cookies.get("user");
        userCookie = userCookie ? JSON.parse(userCookie) : null;
        this.UserName = userCookie.Username;
        const UserID = userCookie.UserID
        const response = await api.get(
          `/api/admin/tasks/get-team-tasks/${UserID}`
        );
        if (response.data) {
          console.log(response.data)
          // get due tasks
          this.dueTasks = response.data.filter(
            (task) => task.IsExpired === 1 && !task.IsCompleted
          );
          // get completed tasks
          this.completedTasks = response.data.filter(
            (task) => task.IsCompleted && task.TaskStatus === 4
          );
          // get in-process tasks
          this.inprocessTasks = response.data.filter(
            (task) => task.TaskStatus === 3
          );
          // get assigned tasks
          this.assignedTasks = response.data.filter(
            (task) => task.TaskStatus === 2
          );
          this.TotalTaskCount = this.assignedTasks.length + this.inprocessTasks.length + this.completedTasks.length;
          console.log("taskcount", this.TotalTaskCount);
        } else {
          // Handle case where no tasks are found
        }
      } catch (error) {
        if (this.flagCount < 3) {
          setTimeout(() => {
            this.getTasks(); // Retry after a delay
          }, 3000);
          this.flagCount++;
        } else {
          if (error.response && error.response.data) {
            if (error.response.status === 401) {
              handleAuthorization("/login")
            }
            else if (error.response.status === 500) {
              handleServerError("/login")
            }
            else {
              const jsonResponseString = JSON.stringify(error.response.data);
              try {
                const jsonObject = JSON.parse(jsonResponseString);
                // this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
              } catch (parseError) {
                // this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
              }
              ErrorLog(error.message, error.statuscode, 774, error.stack, "pages/Dashboard/getTasks");
            }
          }
        }// Handle API error

      } finally {
        this.Isloading = false;
        if (this.assignedTasks.length > 0) {
          this.loadingTasks = "";
        } else {
          this.loadingTasks = "No tasks found.";
        }
        if (this.inprocessTasks.length > 0) {
          this.loadingInProcessTasks = "";
        } else {
          this.loadingInProcessTasks = "No tasks found.";
        }
        if (this.completedTasks.length > 0) {
          this.loadingCompletedTasks = "";
        } else {
          this.loadingCompletedTasks = "No tasks found.";
        }
        if (this.dueTasks.length > 0) {
          this.loadingDueTasks = "";
        } else {
          this.loadingDueTasks = "No tasks found.";
        }
      }
    },

    viewPersonalTaskDetail(taskId) {
      this.$router.push({
        name: "Task Details",
        params: { taskId },
      });
    },
    async CompleteTask(taskId, status) {
      try {
        this.Isloading = true;
        const token = Cookies.get("jwt");
        setAuthToken(token);
        let userCookie = Cookies.get("user");
        userCookie = await userCookie ? JSON.parse(userCookie) : null;
        const UserID = await userCookie.UserID
        // Make your delete API call here
        const response = await api.post(`/api/admin/tasks/complete-task`, {
          TaskID: taskId,
          UserID: UserID,
          Status: status,
        }); // Replace with your actual API endpoint

        if (response.data.success) {
          this.getTasks();
          this.closeConfirmStatusModal();
          let tStatus = "not assigned";
          if (status == 2) {
            tStatus = "new task";
          } else if (status == 3) {
            tStatus = "in process";
          } else if (status == 4) {
            tStatus = "completed";
          }
          this.successAlertOnSubmit = `Task marked as ${tStatus} successfully.`;
          // this.successAlertOnSubmit = response.data.msg;
        } else {
          this.successAlertOnSubmit = response.data.msg
        }
        // Optionally, perform additional actions after successful deletion
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              // this.errorAlertOnSubmit = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              // this.errorAlertOnSubmit = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.statuscode, 857, error.stack, "pages/Dashboard/getDropdownOptions");
          }
        }
      } finally {
        this.Isloading = false;
      }
    },
    confirmComplete(taskId, status) {
      let showStatusName = "in process";
      if (status === 4) {
        showStatusName = "complete";
      }
      const isConfirmed = window.confirm(
        `Are you sure you want to ${showStatusName}?`
      );
      if (isConfirmed) {
        // Call the deleteItem method when confirmed
        this.CompleteTask(taskId, status);
      }
    },
    hideTaskAlert() {
      this.successAlertOnSubmit = "";
    },
  },
};
</script>

<style scoped>
.close-icon {
  cursor: pointer;
  float: right;
  font-size: 30px;
  margin-top: -10px;
}

.expired-row {
  background-color: #dc8484;
  border-bottom: 1px solid #eee;
}

.table-cell {
  font-size: 1.0625rem;
  padding-bottom: 10px;
  padding-top: 8px;
  font-weight: 500;
}

.toprow {
  color: #fff;
  background: #0d6efd;
  height: 35px;
  font-weight: 500;
  font-size: 16px;
}

.modal-backdrop.show {
  opacity: 0;
  display: none;
}

#tab-posts {
  margin-left: 20px;
  overflow: auto;
}

#tab-Dues {
  overflow-x: auto;
}

.md-tabs-navigation {
  padding-left: 100px !important;
}

.bg-color {
  background: white;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .14);
  margin: 25px 0;
  padding: 6px;
}

/* .main-panel > .content{
  padding-top: 0px !important;
} */
.Count-header {
  font-size: 14px;
  font-weight: 600;
}

#tab-Dues {
  margin-left: -20px !important;
}

.dropdown {
  width: 100%;
  height: 36px;
  padding: 7px 0;
  font-size: 14px;
  line-height: 1.428571429;
  border-color: #D2D2D2;
}

#tab-posts {
  margin-left: 20px;
}

.nav-btn {
  color: #fff !important;
  padding: 6px;
  margin: 4px;
  border-radius: 5px;
  background: #0d6efd;
  font-weight: 400 !important;
}

.break {
  display: none;
}

@media screen and (max-width:700px) {
  .break {
    display: block;
  }
}

@media screen and (max-width:595px) {
  .nav-btn {
    padding: 4px;
    font-size: 13px;
    margin: 2px;
  }
}

/* @media screen and (max-width:510px) {
  .nav-btn{
display: none;
}
} */

#calories-text {
  padding-left: 5%;
}

.card-value {
  font-weight: 400;
  font-size: 32px;
  color: #727272;
  margin-bottom: 0px;
  margin-top: 10px;
  letter-spacing: 0;
}

#calories-subtitle {
  padding-left: 5%;
}

.card-text {
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0;
  color: #303030;
}

.icon-user {
  position: absolute;
  right: 0px;
  top: 5px;
}

.media-body {
  cursor: pointer;
}

.media-body:hover h3,
.media-body:hover i {
  color: #0d6efd !important;
}

.media-body:hover .card-text {
  color: #555 !important;
}

.task-table {
  width: 100%;
}

@media screen and (min-width:990px) and (max-width: 1185px) {
  .task-table {
    width: 1100px;
    overflow-x: auto
  }
}

@media screen and (max-width: 890px) {
  .task-table {
    width: 830px;
    overflow-x: auto
  }
}

.username {
  font-size: 17px;
  text-align: right;
  font-weight: 500;
  color: blue;
  margin-right: 10px;
}

.Pagination {
  width: 60%;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.Pagination button {
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 17px;
}

.enabled-button {
  background-color: #007bff;
  /* Blue color for enabled button */
  color: #ffffff;
  /* White text color for enabled button */
  border: 2px solid #007bff
}

.disabled-button {
  background-color: lightgray;
  /* Gray color for disabled button */
  color: #ffffff;
  /* White text color for disabled button */
  border: 2px solid lightgray;
}

.current-page-indicator {
  background-color: green;
  border-radius: 50%;
  /* Ensures the element is rounded */
  padding: 3px;
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  /* Adjust this value as needed */
  height: 25px;
  /* Ensure width and height are equal */
}

.PageSizeSelect {
  border-radius: 5px;
  margin: 5px;
  height: 35px;
  font-size: 17px;
}
</style>
