//common
import TableList from "@/pages/TableList.vue";
import Typography from "@/pages/Typography.vue";
import Icons from "@/pages/Icons.vue";
import Maps from "@/pages/Maps.vue";
import Notifications from "@/pages/Notifications.vue";
//layout
import DashboardLayout from "@/pages/Layout/DashboardLayout.vue";
import UserDashboardLayout from "@/pages/Layout/UserDashboardLayout.vue";
import SuperAdminDashboardLayout from "@/pages/Layout/SuperAdminLayout.vue";
//Admin
import Dashboard from "@/pages/Dashboard.vue";
import dummy from "../components/admin_pages/dummy.vue";
import UserManagement from "../components/admin_pages/UserManagement.vue";
import adminTaskMsg from "../components/admin_pages/TaskMessages.vue";
import EditUser from "../components/admin_pages/EditUser.vue";
import UserTask from "../components/admin_pages/UserTasks.vue";
import Tasks from "../components/admin_pages/Tasks.vue";
import MyProfile from "../components/admin_pages/MyProfile.vue";
import Category from "../components/admin_pages/Projects.vue";
import AdminChangePassword from "../components/admin_pages/ChangePassword.vue";
import AdminTaskAssign from "../components/admin_pages/adminTaskAssign.vue";
import adminMyTasks from "../components/admin_pages/myTasks.vue";
import taskDetail from "../components/admin_pages/TaskDetails.vue";
import myTaskDetail from "../components/admin_pages/myTaskDetail.vue";
import personalTasks from "../components/admin_pages/personalTasks.vue";
import editTask from "../components/admin_pages/EditTask.vue";
import TasksPerProject from "../components/admin_pages/TasksPerProject.vue";



//user

import UserDashboard from "@/pages/UserDashboard.vue";
import UserProfile from "@/pages/UserProfile.vue";
//import UpgradeToPRO from "@/pages/UpgradeToPRO.vue";
import forgotPassword from "../components/forgotPassword.vue";
import Login from "../components/login.vue";
import UserTasks from "../components/user_pages/TaskManagement.vue";
import UserProfilePage from "../components/user_pages/UserProfile.vue";
import ChangePassword from "../components/user_pages/ChangePassword.vue";
import UserTaskAssign from "../components/user_pages/userTaskAssign.vue";
import userMyTasks from "../components/user_pages/myTasks.vue";
import userTaskDetail from "../components/user_pages/TaskDetail.vue";
import userEditTask from "../components/user_pages/EditTask.vue";
import userTaskMsg from "../components/user_pages/TaskMessages.vue";
//super admin
import SuperAdminDashboard from "@/pages/Layout/Extra/SuperAdminLayout_Component/SuperAdminDashboard";
import Chat from "../components/Common/Chat.vue";
import manageAdmin from "../components/superAdmin/manageAdmins.vue";
import superAdminProfile from "../components/superAdmin/SuperAdmin_Profile.vue";
import SuperAdminChangePassword from "../components/superAdmin/ChangePassword.vue";
import SuperAdminLogin from "../components/superAdmin/SuperAdminLogin.vue";
import ErrorPage from "../components/Common/ErrorPage.vue";
import ErrorLog from "../components/superAdmin/ErrorLog.vue";
import resetpassword from "../components/resetPassword.vue";
import Admins from '../components/superAdmin/Admins.vue';
import Users from '../components/superAdmin/Users.vue';


const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/login",
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "dummy",
        name: "dummy",
        component: dummy,
      },
      {
        path: "my-profile",
        name: "My Profile",
        component: MyProfile,
      },
      {
        path: "user-management",
        name: "User Management",
        component: UserManagement,
      },
      {
        path: "task-management",
        name: "Team Task",
        component: Tasks,
      },
      {
        path: "my-task-management",
        name: "My Tasks Management",
        component: personalTasks,
      },
      {
        path: "change-password",
        name: "Change Password",
        component: AdminChangePassword,
      },
      {
        path: "user",
        name: "User Profile",
        component: UserProfile,
      },
      {
        path: "Task-Assign",
        name: "To-Do Assignment",
        component: AdminTaskAssign,
      },
      {
        path: "my-tasks",
        name: "My To-Do's",
        component: adminMyTasks,
      },
      {
        path: "task-details/:taskId/:type",
        name: "Task Details",
        component: taskDetail,
        props: true,
      },
      {
        path: "chatmessages/:TaskID/:AssignedBy",
        name: "Chat Message",
        component: Chat,
        props: true,
      },
      {
        path: "edit-user/:userId",
        name: "Edit User",
        component: EditUser,
        props: true,
      },
      {
        path: "user-tasks/:UserID/:FilterID",
        name: "User Assigned Tasks",
        component: UserTask,
        props: true,
      },
      {
        path: "edit-task/:taskId",
        name: "Edit Task",
        component: editTask,
        props: true,
      },
      {
        path: "my-task-details/:taskId",
        name: "My Task Details",
        component: myTaskDetail,
        props: true,
      },
      {
        path: "task-messages",
        name: "Task Messages",
        component: adminTaskMsg,
      },
      {
        path: "maps",
        name: "Maps",
        meta: {
          hideFooter: true,
        },
        component: Maps,
      },
      {
        path: "notifications",
        name: "Notifications",
        component: Notifications,
      },
      {
        path: "Category",
        name: "Task Category",
        component: Category,
      },
      {
        path: "projectTasks/:projectId/:type",
        name: "Project Tasks",
        component: TasksPerProject, 
        props: true,
      },
    ],
  },
  {
    path: "/user",
    component: UserDashboardLayout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: UserDashboard,
      },
      {
        path: "task-management",
        name: "My Tasks",
        component: UserTasks,
      },
      {
        path: "user-profile",
        name: "User Profile",
        component: UserProfilePage,
      },
      {
        path: "task-details/:taskId/:type",
        name: "Task Details",
        component: userTaskDetail,
        props: true,
      },
      {
        path: "chatmessages/:TaskID/:AssignedBy",
        name: "Chat Message",
        component: Chat,
        props: true,
      },
      {
        path: "manageAdmin",
        name: "Manage Admin",
        component: manageAdmin,
        // props: true,
      },
      {
        path: "task-messages",
        name: "Task Messages",
        component: userTaskMsg,
      },
      {
        path: "user-profile",
        name: "User Profile",
        component: UserProfile,
      },
      {
        path: "change-password",
        name: "Change Password",
        component: ChangePassword,
      },
      {
        path: "edit-task/:taskId",
        name: "User Edit Task",
        component: userEditTask,
        props: true,
      },
      {
        path: "task-assign",
        name: "Assigned To-Do's",
        component: UserTaskAssign,
      },
      {
        path: "my-tasks",
        name: "My To-Do's",
        component: userMyTasks,
      },
      {
        path: "icons",
        name: "Icons",
        component: Icons,
      },
      {
        path: "maps",
        name: "Maps",
        meta: {
          hideFooter: true,
        },
        component: Maps,
      },
      {
        path: "notifications",
        name: "Notifications",
        component: Notifications,
      },
      {
        path: "Category",
        name: "Task Category",
        component: Category,
      },
    ],
  },
  {
    path: "/superAdmin",
    component: SuperAdminDashboardLayout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: SuperAdminDashboard,
      },
      {
        path: "profile",
        name: "Super Admin Profile",
        component: superAdminProfile,
      },
      {
        path: "manageAdmin",
        name: "Manage Admin",
        component: manageAdmin,
        // props: true,
      },
      {
        path: "Admins",
        name: "Admins",
        component: Admins,
        // props: true,
      },
      {
        path: "Users",
        name: "Users",
        component: Users,
        // props: true,
      },
      {
        path: "change-password",
        name: "Change Password",
        component: SuperAdminChangePassword,
      },
      {
        path: "error-log",
        name: "Error Log",
        component: ErrorLog,
      },
      {
        path: "icons",
        name: "Icons",
        component: Icons,
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/adminLogin",
    name: "Super Admin Login",
    component: SuperAdminLogin,
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    component: resetpassword,
    props:true,
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: forgotPassword,
  },
  {
    path: "/error",
    name: "Error Page",
    component: ErrorPage,
  },
];

export default routes;
