<template>
  <div class="content">
    <LoadingOverlay :loading="Isloading" />
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
        <md-card>
          <md-card-header data-background-color="green">
            <div class="card-header d-flex justify-content-between align-items-center">
              <h4 class="title">Task Details</h4>
              <div class="ms-auto d-flex">
                <button :hidden="this.type == 0" @click="viewTaskMessages(task.TaskID)" type="button" id="btnChat"
                  class="btn btn-primary btn-sm ml-2" title="Task Messages">
                  Messages <sup><span v-if="unReadChatCount > 0" class="notification">
                      {{ formatChatCount(unReadChatCount) }}
                      <!-- 34 -->
                    </span>
                  </sup>
                </button>
                <button type="button" title="Export" class="btn btn-primary btn-sm ml-2"
                  @click="generateAndDownloadPDF">
                  Export
                </button>
                <!-- <div class="row"> -->
                <!-- <div class="col-md-12"> -->
                <button type="button" title="Update Status" class="btn btn-primary btn-sm ml-2"
                  @click="editTaskStatus(task.TaskID)" :disabled="task.IsCompleted && task.TaskStatus === 4">
                  Update Status
                </button>
                <!-- </div> -->
                <!-- </div> -->
                <button hidden type="button" title="Change Status" data-bs-toggle="modal"
                  data-bs-target="#taskEditStatusModal" data-bs-whatever="@mdo" class="btn btn-primary"
                  id="openEditStatusModal">
                  Change Status
                </button>
                <button type="button" title="Back" class="btn btn-dark btn-sm ms-2" @click="goBack">
                  Back
                </button>
              </div>
            </div>
          </md-card-header>
          <md-card-content>
            <!-- Display success alert -->
            <div v-if="successAlertOnSubmit" class="alert alert-success text-left" role="alert">
              {{ successAlertOnSubmit }}
              <span @click="hideTaskAlert" class="close-icon">&times;</span>
            </div>
            <div v-if="tableHeader" class="alert alert-danger text-left">
              {{ tableHeader }}
              <span @click="hideTaskAlert" class="close-icon">&times;</span>
            </div>
            <div v-if="loading">
              <div class="row" id="pdf-content" ref="chatContainer1">
                <div class="col-md-4 mt-2">
                  <div class="task-detail-row">
                    <span class="task-detail-label">Title:</span>
                    <p class="task-detail-value" style="font-weight: 400;">{{ task.Title }}</p>
                  </div>
                  <div class="task-detail-row">
                    <span class="task-detail-label">Priority:</span>
                    <div class="progress task-detail-value" style="width: 55%; margin-top: 3px">
                      <div v-if="task.PriorityName == 'Low'" title="Low" class="progress-bar bg-info" role="progressbar"
                        style="width: 100%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                        {{ task.PriorityName }}
                      </div>
                      <div v-else-if="task.PriorityName == 'Medium'" title="Medium" class="progress-bar bg-warning"
                        role="progressbar" style="width: 100%; color: black" aria-valuenow="25" aria-valuemin="0"
                        aria-valuemax="100">
                        {{ task.PriorityName }}
                      </div>
                      <div v-else-if="task.PriorityName == 'High'" title="High" class="progress-bar bg-danger"
                        role="progressbar" style="width: 100%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                        {{ task.PriorityName }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="task-detail-row">
                    <span class="task-detail-label">Status:</span>
                    <div class="progress task-detail-value" style="width: 55%;margin-top: 3px">
                      <div v-if="task.TaskStatus === 1" title="Not Assigned" class="progress-bar" role="progressbar"
                        aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="background: none; color: black">
                        Not Assigned
                      </div>
                      <div v-else-if="task.TaskStatus === 2 && publicTask" title="New Task" class="progress-bar"
                        role="progressbar" style="width: 100%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                        Assigned
                      </div>
                      <div v-else-if="task.TaskStatus === 2 && !publicTask" title="New Task" class="progress-bar"
                        role="progressbar" style="width: 100%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                        New Task
                      </div>
                      <div v-else-if="task.TaskStatus === 3" title="In Process" class="progress-bar" role="progressbar"
                        style="width: 100%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                        In Process
                      </div>
                      <div v-else-if="task.TaskStatus === 4" title="Completed" class="progress-bar bg-success"
                        role="progressbar" style="width: 100%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                        Completed
                      </div>
                    </div>
                  </div>
                  <div class="task-detail-row pt-2" v-if="task.TaskType == 'public'">
                    <span class="task-detail-label">Project:</span>
                    <p class="task-detail-value">{{ task.CategoryName }}</p>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="task-detail-row">
                    <span class="task-detail-label">Assigned By:</span>
                    <p class="task-detail-value">
                      {{ task.UserName }}
                    </p>
                  </div>
                  <div class="task-detail-row">
                    <span class="task-detail-label">Assigned Date:</span>
                    <p class="task-detail-value">
                      {{ formatAssignedDate(task.CreatedDate) }}
                    </p>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-12" style="margin-bottom: 1rem;">
                      <p><span class="task-detail-label">Description:</span><span class="task-detail-value">{{
                        task.Description }}</span></p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="task-detail-row">
                    <span class="task-detail-label">Due Date:</span>
                    <p class="task-detail-value">
                      {{ task.DueDate }}
                    </p>
                  </div>
                </div>
                <div class="col-md-12" v-if="task.IsCompleted && task.Comments != null && task.Comments != ''">
                  <div class="row">
                    <div class="col-12">
                      <p><span class="task-detail-label">Comments:</span><span class="task-detail-value">{{
                        task.Comments }}</span></p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6" v-if="task.InfoFile != 'null' && task.InfoFile != '' && task.InfoFile != null">
                  <div class="row">
                    <div class="task-detail-row">
                      <div class="col-md-2">
                        <span class="task-detail-label">Attachment:</span>
                      </div>
                      <div class="col-md-10">
                        <p v-if="imageURLValid && task.InfoFile != null" class="task-detail-value">
                          <a :href="task.InfoFile" target="blank" class="browser-link">
                            <img :src="task.InfoFile" class="image-link" alt="Image">
                          </a>
                        </p>
                        <p v-if="!imageURLValid && task.InfoFile != null" class="file-link task-detail-value">
                          <a :href="task.InfoFile" target="blank">File Link</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6" v-if="task.IsCompleted && task.UserAttachment != null">
                  <div class="task-detail-row">
                    <span class="task-detail-label" style="width:40%;">User Attachment:</span>
                    <p v-if="UserimageURLValid && task.UserAttachment != null" class="task-detail-value">
                      <a :href="task.UserAttachment" target="blank" class="browser-link">
                        <img :src="task.UserAttachment" class="image-link" alt="Image" style="width:70% ;"></a>
                    </p>
                    <p v-if="!UserimageURLValid && task.UserAttachment != null" class="file-link task-detail-value">
                      <a :href="task.UserAttachment" target="blank">File Link</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div><span>Loading...</span></div>
            </div>
            <!-- Modal for Edit Task Status -->
            <div class="modal fade" id="taskEditStatusModal" tabindex="-1" aria-labelledby="taskEditStatusModalLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="taskEditStatusModalLabel">
                      Edit Task Status
                    </h1>
                    <button class="btn-close" title="Close" data-bs-dismiss="modal" v-on:click="hideCommentsandFile()"
                      aria-label="Close" tabindex="-1"></button>
                  </div>
                  <form @submit.prevent="submitEditTaskStatusForm1">
                    <input v-model="newTask.TaskID" type="hidden" class="form-control" />
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="mb-2">
                            <label for="title" class="col-form-label pb-0">Title</label>
                            <input v-model="newTask.Title" type="text" class="form-control pt-0"
                              :disabled="isInputDisabled" id="title" tabindex="1" style="border:0;" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-2">
                            <label for="priority" class="col-form-label pb-0">Priority</label>
                            <select v-model="newTask.PriorityID" class="form-control pt-0" id="priority" tabindex="3"
                              style="border:0;" :disabled="isInputDisabled" @change="isValidTaskForm">
                              <option :value="0">Select Priority</option>
                              <option v-for="priorityOption in priorityDropdownOptions" :key="priorityOption.PriorityID"
                                :value="priorityOption.PriorityID">
                                {{ priorityOption.Name }}
                              </option>
                            </select>
                            <span class="text-red-500 text-sm">{{
                              taskValidate.PriorityID
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6" v-if="newTask.TaskType == 'public'">
                          <div class="mb-2">
                            <label for="category" class="col-form-label pb-0">Project</label>
                            <br>
                            <label for="category" class="col-form-label pb-0"><b>{{newTask.CategoryName}}</b></label>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-2">
                            <label for="dueDate" class="col-form-label pb-0">Due Date</label><br />
                            <label class="col-form-label pt-0" style="font-size: 14px;color:#000;font-weight:400;">{{
                              newTask.DueDate }}</label>
                            <!-- <input v-model="newTask.DueDate" type="date" class="form-control pt-0" id="dueDate"
                              tabindex="5" style="border:0;" :disabled="isInputDisabled" @input="isValidTaskForm" /> -->
                            <span class="text-red-500 text-sm">{{
                              taskValidate.DueDate
                            }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label for="status" class="col-form-label">Status</label>
                            <select v-model="newTask.TaskStatus" class="dropdown" id="status" v-if="publicTask"
                              tabindex="4" @change="isValidTaskForm">
                              <option value="2">Select Status</option>
                              <option value="3">In Process</option>
                              <option value="4">Completed</option>
                            </select>
                            <select v-model="newTask.TaskStatus" class="dropdown" id="status" v-else tabindex="4"
                              @change="isValidTaskForm">
                              <option value="2">Select Status</option>
                              <option value="3">In Process</option>
                              <option value="4">Completed</option>
                            </select>
                            <!-- <span class="text-red-500 text-sm">{{
                              taskValidate.TaskStatus
                            }}</span> -->
                          </div>
                        </div>
                        <div v-if="InProcess" class="col-md-6">
                          <div class="mb-3">
                            <label for="status" class="col-form-label">Progress Percentage</label>
                            <input type="number" v-model.number="inputValue" id="input" hidden placeholder="Input Value" @input="calculate">
                            <input type="number" v-model.number="rangeValue" class="my-2" hidden id="range" placeholder="Range Value" @input="calculate">
                            <input v-model.number="rangeValue" min="1" max="100" id="range-slider" class="my-2" type="range" @input="calculate"> 
                            <span class="mx-2" style="font-size: 16px;">{{ rangeValue }}%</span>
                          </div>
                        </div>
                        <div class="col-md-6" hidden>
                          <div class="mb-3">
                            <label for="isCompleted" class="col-form-label">Is Completed</label>
                            <div class="form-check form-switch">
                              <input v-model="newTask.IsCompleted" type="checkbox" class="form-check-input"
                                id="isCompleted" tabindex="6" @input="isValidTaskForm" />
                              <label class="form-check-label" for="isCompleted">
                                {{
                                  newTask.IsCompleted
                                    ? "Completed"
                                    : "Not Completed"
                                }}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row" v-if="StatusCompleted">
                        <div class="col-md-12">
                          <div class="mb-3">
                            <label for="Comments" class="col-form-label">Comments</label>
                            <textarea v-model="newTask.Comments" class="form-control" id="Comments" tabindex="2"
                              style="height: 36px;"></textarea>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label for="fileUpload" class="col-form-label">File Upload</label>
                            <input type="file" class="form-control" id="fileUpload" ref="file" name="file" tabindex="5"
                              @change="handleFileUpload" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary ml-2" title="Close" data-bs-dismiss="modal"
                        id="closeStatusModal" tabindex="8" v-on:click="hideCommentsandFile()">
                        Close
                      </button>
                      <button type="button" title="Update" class="btn btn-primary" data-bs-toggle="modal"
                        data-bs-target="#taskConfirmModal" data-bs-whatever="@mdo" tabindex="7">
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!-- Modal for Edit Task Status -->
            <div class="modal fade" id="taskConfirmModal" tabindex="-1" aria-labelledby="ConfirmModalLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-md">
                <div class="modal-content">
                  <form @submit.prevent="submitEditTaskStatusForm">
                    <input v-model="newTask.TaskID" type="hidden" class="form-control" />
                    <div class="modal-body">
                      <div class="row">
                        <h4>
                          Are you sure you want to mark as
                          {{ this.funCheckStatus(newTask.TaskStatus) }}?
                        </h4>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" title="No" class="btn btn-secondary ml-2" data-bs-dismiss="modal"
                        id="closeConfirmStatusModal" tabindex="8">
                        No
                      </button>
                      <button type="submit" title="Yes" class="btn btn-primary" tabindex="7">
                        Yes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
    <!-- //PDF CONTENTS TO DOWNLOAD -->
    <div class="row">
      <div class="row" ref="disenio" hidden>
        <div class="col-md-4 mt-2">
          <div class="task-detail-row">
            <span class="task-detail-label">Title:</span>
            <p class="task-detail-value" style="font-weight: 400;">{{ task.Title }}</p>
          </div>
          <div class="task-detail-row">
            <span class="task-detail-label">Priority:</span>
            <div class="progress task-detail-value" style="width: 55%; margin-top: 3px">
              <div v-if="task.PriorityName == 'Low'" title="Low" class="progress-bar bg-info" role="progressbar"
                style="width: 100%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                {{ task.PriorityName }}
              </div>
              <div v-else-if="task.PriorityName == 'Medium'" title="Medium" class="progress-bar bg-warning"
                role="progressbar" style="width: 100%; color: black" aria-valuenow="25" aria-valuemin="0"
                aria-valuemax="100">
                {{ task.PriorityName }}
              </div>
              <div v-else-if="task.PriorityName == 'High'" title="High" class="progress-bar bg-danger"
                role="progressbar" style="width: 100%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                {{ task.PriorityName }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 mt-2">
          <div class="task-detail-row">
            <span class="task-detail-label">Status:</span>
            <div class="progress task-detail-value" style="width: 55%; margin-top: 3px">
              <div v-if="task.TaskStatus === 1" title="Not Assigned" class="progress-bar" role="progressbar"
                aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="background: none; color: black">
                Not Assigned
              </div>
              <div v-else-if="task.TaskStatus === 2 && publicTask" title="New Task" class="progress-bar"
                role="progressbar" style="width: 100%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                Assigned
              </div>
              <div v-else-if="task.TaskStatus === 2 && !publicTask" title="New Task" class="progress-bar"
                role="progressbar" style="width: 100%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                New Task
              </div>
              <div v-else-if="task.TaskStatus === 3" title="In Process" class="progress-bar" role="progressbar"
                style="width: 100%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                In Process
              </div>
              <div v-else-if="task.TaskStatus === 4" title="Completed" class="progress-bar bg-success"
                role="progressbar" style="width: 100%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                Completed
              </div>
            </div>
          </div>
          <div class="task-detail-row pt-2" v-if="task.TaskType == 'public'">
            <span class="task-detail-label">Project:</span>
            <p class="task-detail-value">{{ task.CategoryName }}</p>
          </div>
        </div>
        <div class="col-md-4 mt-2">
          <div class="task-detail-row">
            <span class="task-detail-label">Assigned By:</span>
            <p class="task-detail-value">
              {{ task.UserName }}
            </p>
          </div>
          <div class="task-detail-row">
            <span class="task-detail-label">Assigned Date:</span>
            <p class="task-detail-value">
              {{ formatAssignedDate(task.CreatedDate) }}
            </p>
          </div>
        </div>
        <div class="col-md-8">
          <div class="row">
            <div class="col-12" style="margin-bottom: 1rem;">
              <p><span class="task-detail-label">Description:</span><span class="task-detail-value">{{
                task.Description }}</span></p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="task-detail-row">
            <span class="task-detail-label">Due Date:</span>
            <p class="task-detail-value">
              {{ task.DueDate }}
            </p>
          </div>
        </div>
        <div class="col-md-12" v-if="task.IsCompleted && task.Comments != null && task.Comments != ''">
          <div class="row">
            <div class="col-12">
              <p><span class="task-detail-label">Comments:</span><span class="task-detail-value">{{
                task.Comments }}</span></p>
            </div>
          </div>
        </div>
        <div class="col-md-6" v-if="task.InfoFile != 'null' && task.InfoFile != '' && task.InfoFile != null">
          <div class="row">
            <div class="task-detail-row">
              <div class="col-md-2">
                <span class="task-detail-label">Attachment:</span>
              </div>
              <div class="col-md-10">
                <p v-if="imageURLValid && task.InfoFile != null" class="task-detail-value">
                  <a :href="task.InfoFile" target="blank" class="browser-link">
                    <img :src="task.InfoFile" class="image-link" alt="Image">
                  </a>
                </p>
                <p v-if="!imageURLValid && task.InfoFile != null" class="file-link task-detail-value">
                  <a :href="task.InfoFile" target="blank">File Link</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6" v-if="task.IsCompleted && task.UserAttachment != null">
          <div class="task-detail-row">
            <span class="task-detail-label" style="width:40%;">User Attachment:</span>
            <p v-if="UserimageURLValid && task.UserAttachment != null" class="file-link task-detail-value">
              <a :href="task.UserAttachment" target="blank">File Link</a>
            </p>
          </div>
        </div>
      </div>
      <div class="row" ref="disenio" id="content1" hidden>
        <div class="col-md-4 mt-2">
          <div class="task-detail-row" style=" display: -webkit-box !important;
            -webkit-box-pack: start;
            justify-content: space-between;
            margin-bottom: 1rem;">
            <span class="task-detail-label" style="font-weight: bold;font-size: 16px;">Title:</span>
            <span class="task-detail-value" style="font-weight: 400;font-size: 16px;margin-left: 10px;">
              {{ task.Title }}</span>
          </div>
          <div class="task-detail-row" style=" display: -webkit-box !important;
            -webkit-box-pack: start;
            justify-content: space-between;
            margin-bottom: 1rem;">
            <span class="task-detail-label" style="font-weight: bold;font-size: 16px;">Priority:</span>
            <div class="progress task-detail-value" style="width: 55%;font-size: 16px;margin-left: 10px;">
              <div v-if="task.PriorityName == 'Low'" title="Low" class="progress-bar bg-info" role="progressbar"
                style="width: 100%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                {{ task.PriorityName }}
              </div>
              <div v-else-if="task.PriorityName == 'Medium'" title="Medium" class="progress-bar bg-warning"
                role="progressbar" style="width: 100%; color: black" aria-valuenow="25" aria-valuemin="0"
                aria-valuemax="100">
                {{ task.PriorityName }}
              </div>
              <div v-else-if="task.PriorityName == 'High'" title="High" class="progress-bar bg-danger"
                role="progressbar" style="width: 100%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                {{ task.PriorityName }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 mt-2">
          <div class="task-detail-row" style=" display: -webkit-box !important;
            -webkit-box-pack: start;
            justify-content: space-between;
            margin-bottom: 1rem;">
            <span class="task-detail-label" style="font-weight: bold;font-size: 16px;">Status:</span>
            <div class="progress task-detail-value" style="width: 55%;font-size: 16px;margin-left: 10px;">
              <div v-if="task.TaskStatus === 1" title="Not Assigned" class="progress-bar" role="progressbar"
                aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="background: none; color: black">
                Not Assigned
              </div>
              <div v-else-if="task.TaskStatus === 2 && publicTask" title="New Task" class="progress-bar"
                role="progressbar" style="width: 100%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                Assigned
              </div>
              <div v-else-if="task.TaskStatus === 2 && !publicTask" title="New Task" class="progress-bar"
                role="progressbar" style="width: 100%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                New Task
              </div>
              <div v-else-if="task.TaskStatus === 3" title="In Process" class="progress-bar" role="progressbar"
                style="width: 100%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                In Process
              </div>
              <div v-else-if="task.TaskStatus === 4" title="Completed" class="progress-bar bg-success"
                role="progressbar" style="width: 100%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                Completed
              </div>
            </div>
          </div>

          <div class="task-detail-row pt-2" v-if="task.TaskType == 'public'" style=" display: -webkit-box !important;
            -webkit-box-pack: start;
            justify-content: space-between;
            margin-bottom: 1rem;">
            <span class="task-detail-label" style="font-weight: bold;font-size: 16px;">Project:</span>
            <span class="task-detail-value" style="font-size: 16px;margin-left: 10px;">{{ task.CategoryName }}</span>
          </div>
        </div>
        <div class="col-md-4 mt-2">
          <div class="task-detail-row" style=" display: -webkit-box !important;
            -webkit-box-pack: start;
            justify-content: space-between;
            margin-bottom: 1rem;">
            <span class="task-detail-label" style="font-weight: bold;font-size: 16px;">Assigned By:</span>
            <span class="task-detail-value" style="font-size: 16px;margin-left: 10px;">
              {{ task.UserName }}
            </span>
          </div>
          <div class="task-detail-row" style=" display: -webkit-box !important;
            -webkit-box-pack: start;
            justify-content: space-between;
            margin-bottom: 1rem;">
            <span class="task-detail-label" style="font-weight: bold;font-size: 16px;">Assigned Date:</span>
            <span class="task-detail-value" style="font-size: 16px;margin-left: 10px;">
              {{ formatAssignedDate(task.CreatedDate) }}
            </span>
          </div>
        </div>
        <div class="col-md-8">
          <div class="row">
            <div class="col-12" style="margin-bottom: 1rem;display: flex;">
              <span class="task-detail-label" style="font-weight: bold;font-size: 16px;">Description:</span>
              <span class="task-detail-value" style="font-size: 16px;margin-left: 10px;">
                {{ task.Description }}
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="task-detail-row" style=" display: -webkit-box !important;
            -webkit-box-pack: start;
            justify-content: space-between;
            margin-bottom: 1rem;">
            <span class="task-detail-label" style="font-weight: bold;font-size: 16px;">Due Date:</span>
            <span class="task-detail-value" style="font-size: 16px;margin-left: 10px;">
              {{ task.DueDate }}
            </span>
          </div>
        </div>
        <div class="col-md-12" v-if="task.IsCompleted && task.Comments != null && task.Comments != ''">
          <div class="row">
            <div class="col-12" 
            style="-webkit-box-pack: start;
            display: flex;
            margin-bottom: 1rem;">
              <span class="task-detail-label" style="font-weight: bold;font-size: 16px;">Comments:</span>
              <span class="task-detail-value" style="font-size: 16px;margin-left: 10px;">
                {{ task.Comments }}
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-6" v-if="task.InfoFile != 'null' && task.InfoFile != '' && task.InfoFile != null">
          <div class="row">
            <div class="task-detail-row" style=" display: -webkit-box !important;
            -webkit-box-pack: start;
            justify-content: space-between;
            margin-bottom: 1rem;">
              <div class="col-md-2">
                <span class="task-detail-label" style="font-weight: bold;font-size: 16px;">Attachment:</span>
              </div>
              <div class="col-md-10">
                <span v-if="imageURLValid && task.InfoFile != null" class="task-detail-value"
                  style="font-size: 16px;margin-left: 10px;">
                  <a :href="task.InfoFile" target="blank" class="browser-link">
                    <img :src="task.InfoFile" class="image-link" alt="Image">
                  </a>
                </span>
                <span v-if="!imageURLValid && task.InfoFile != null" class="file-link task-detail-value"
                  style="font-size: 16px;margin-left: 10px;">
                  <a :href="task.InfoFile" target="blank">File Link</a>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6" v-if="task.IsCompleted && task.UserAttachment != null">
          <div class="task-detail-row" style=" display: -webkit-box !important;
            -webkit-box-pack: start;
            justify-content: space-between;
            margin-bottom: 1rem;">
            <span class="task-detail-label" style="width:40%;font-weight: bold;font-size: 16px;">User Attachment:</span>
            <span v-if="UserimageURLValid && task.UserAttachment != null" class="file-link task-detail-value"
              style="font-size: 16px;margin-left: 10px;">
              <a :href="task.UserAttachment" target="blank">File Link</a>
            </span>
          </div>
        </div>
      </div>
      <div class="chat-box" id="content2" hidden>
        <div class="messages justify-content-between" ref="chatContainer" style="padding: 10px;">
          <div class="messagecontent">

        <div v-for="(messages, date) in groupedMessages" :key="date">
              <div class="date-partition text-center" style="text-align: center;margin: 5px 0;padding: 5px 5px;font-size: 14px;font-weight: bold;"><b>{{ date }}</b></div> 
            <div v-for="(message, index) in messages" :key="index">
              <div v-if="UserID != message.SenderId" class="text-left message"
                style="text-align: left; margin-bottom: 6px; justify-content: left; max-width: 70%; word-wrap: break-word;">
                <div v-if="message.FilePath && message.Message">
                  <span class="received text-left my-2"
                    style="align-items: flex-start; color: #ffffff; text-align: left; padding: 7px; display: flex; max-width: 100%;">
                    <span style="color: green;" class="mx-2">{{ `${message.SenderName}:` }}</span>
                    <a :href="`${baseImageURL}/${message.FilePath}`">
                      <span style="color: blue; text-decoration: underline;">{{ `${message.FileName}` }}</span>
                    </a>
                    <span class="mx-2 timestamp"
                      style="font-size: 10px; margin-left: 10px;width: 60px;white-space: nowrap;">{{
                        message.TimestampHours
                      }}</span>
                  </span>
                  <br /><br />
                  <span class="received text-left"
                    style="align-items: flex-start; text-align: left; border-radius: 10px; padding: 7px; display: flex; max-width: 100%;">
                    <span style="color: green; font-weight: bold;">{{ message.SenderName }}:</span>
                    <span style="color: black;"> {{ message.Message }}</span>
                    <span class="mx-2 timestamp"
                      style="font-size: 10px; margin-left: 10px;width: 60px;white-space: nowrap;">{{
                        message.TimestampHours
                      }}</span>
                  </span>
                </div>
                <div v-else-if="message.FilePath">
                  <span class="received text-left"
                    style="align-items: flex-start; text-align: left; border-radius: 10px; padding: 7px; display: flex; max-width: 100%;">
                    <span style="color: green; font-weight: bold;" class="mx-2">{{ `${message.SenderName}:` }}</span>
                    <a :href="`${baseImageURL}/${message.FilePath}`">
                      <span style="color: blue; text-decoration: underline;">{{ `${message.FileName}` }}</span>
                    </a>
                    <span class="mx-2 timestamp"
                      style="font-size: 10px; margin-left: 10px;width: 60px;white-space: nowrap;">{{
                        message.TimestampHours
                      }}</span>
                  </span>
                </div>
                <div v-else-if="message.Message">
                  <span class="received text-left"
                    style="align-items: flex-start; text-align: left; padding: 7px; display: flex; max-width: 100%;">
                    <span style="color: green; font-weight: bold;">{{ message.SenderName }}:</span>
                    <span style="color: black;"> {{ message.Message }}</span>
                    <span class="mx-2 timestamp"
                      style="font-size: 10px; margin-left: 10px;width: 60px;white-space: nowrap;">{{
                        message.TimestampHours
                      }}</span>
                  </span>
                </div>
              </div>
              <div v-else class="text-right message"
                style="text-align: right; margin-bottom: 6px; justify-content: right; max-width: 70%; margin-left: auto; word-wrap: break-word;">
                <div v-if="message.FilePath && message.Message" class="text-right">
                  <span class="text-right my-2"
                    style="align-items: flex-start; text-align: right; padding: 7px; display: flex; justify-content: flex-end; max-width: 100%;">
                    <span style="color: purple; font-weight: bold;" class="mx-2">{{ message.SenderName }}:</span>
                    <a :href="`${baseImageURL}/${message.FilePath}`">
                      <span style="color: blue; text-decoration: underline;">{{ `${message.FileName}` }}</span>
                    </a>
                    <span class="mx-2 timestamp"
                      style="font-size: 10px; margin-left: 10px;width: 60px;white-space: nowrap;">{{
                        message.TimestampHours
                      }}</span>
                  </span>
                  <br /><br />
                  <span class="sent text-right"
                    style="align-items: flex-start; text-align: right; padding: 7px; display: flex; justify-content: flex-end; max-width: 100%;">
                    <span style="color: purple; font-weight: bold;">{{ message.SenderName }} :</span>
                    <span style="color: black;text-align: left;">{{ message.Message }}</span>
                    <span class="mx-2 timestamp"
                      style="font-size: 10px; margin-left: 10px;width: 60px;white-space: nowrap;">{{
                        message.TimestampHours
                      }}</span>
                  </span>
                </div>
                <div v-else-if="message.FilePath">
                  <span class="sent text-right"
                    style="align-items: flex-start; text-align: right; display: flex; justify-content: flex-end; padding: 7px; max-width: 100%;">
                    <span style="color: purple; font-weight: bold;" class="mx-2">{{ message.SenderName }}:</span>
                    <a :href="`${baseImageURL}/${message.FilePath}`">
                      <span style="color: blue; text-decoration: underline;">{{ `${message.FileName}` }}</span>
                    </a>
                    <span class="mx-2 timestamp"
                      style="font-size: 10px; margin-left: 10px;width: 60px;white-space: nowrap;">{{
                        message.TimestampHours
                      }}</span>
                  </span>
                </div>
                <div v-else-if="message.Message">
                  <span class="sent text-right"
                    style="align-items: flex-start; text-align: right; padding: 7px; display: flex; justify-content: flex-end; max-width: 100%;">
                    <span style="color: purple; font-weight: bold;">{{ message.SenderName }}: </span>
                    <span style="color: black;text-align: left;">{{ message.Message }}</span>
                    <span class="mx-2 timestamp"
                      style="font-size: 10px; margin-left: 10px;width: 60px;white-space: nowrap;">{{
                        message.TimestampHours
                      }}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api, { setAuthToken, baseimageURL } from "../apiConfig";
import { authMixin } from "./userStore";
import Cookies from "js-cookie";
import { ErrorLog } from "../Common/APILog";
import LoadingOverlay from '../Common/blockoutpage.vue';
import 'jspdf-autotable';
import { handleAuthorization, handleServerError } from "../Common/commonMethod";

export default {
  mixins: [authMixin],
  components: {
    LoadingOverlay
  },
  props: {
    taskId: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      task: [],
      Isloading: false,
      inputValue: 10,
      rangeValue: 0,
      percentResult: 0,
      subTasks: [],
      unReadChatCount: 0,
      imageURLValid: false,
      UserimageURLValid: false,
      baseImageURL: baseimageURL,
      isInputDisabled: true,
      StatusCompleted: false,
      InProcess: false,
      publicTask: false,
      Assigned:false,
      editTaskStatusMode: false,
      priorityDropdownOptions: [],
      categoryDropdownOptions: [],
      newSubTask: {
        SubTaskID: 0,
        TaskID: 0,
        Title: "",
        Description: "",
        IsCompleted: false,
        UserId: 0,
      },
      newTask: {
        TaskID: 0,
        Title: "",
        Description: "",
        DueDate: null,
        IsCompleted: false,
        TaskStatus: 1,
        PriorityID: 0,
        CategoryID: 0,
        UserId: 0,
        TaskType: null,
        file: "",
        Comments: null
      },
      taskValidate: {
        Title: "",
        Description: "",
        // Add additional validation fields as needed
      },
      handleTaskId: 0,
      loading: false,
      editTaskMode: false,
      successAlertOnSubmit: "",
      loadingTasks: "",
      taskFormHeader: "",
      tableHeader: "",
      btnTaskEnable: false,
      flagCount: 0,
      messages: [],
      groupedMessages:[],
      UserID: 0
    };
  },
  created() {
    // Fetch task details when the component is created
  
    this.fetchTaskDetails();
    this.getUnreadChatById(this.taskId);
    this.getChatByTaskId(this.taskId);
    // console.log("this is type: " + this.type)
  },
  mounted() {
    this.calculate();
    this.getPriorityDropdown();
    this.getCategoryDropdown();
  },
  methods: {
    
    calculate() {
      this.percentResult = (this.rangeValue * this.inputValue) / 100;
      console.log(this.percentResult);
    },
    async getChatByTaskId(taskId) {
      try {
        //this.loadingTasks = "Loading...";
        this.Isloading = true;
        let userCookie = await Cookies.get("user");
        userCookie = await userCookie ? JSON.parse(userCookie) : null;
        this.UserID = await userCookie.UserID;
        const uid = await userCookie.UserID;
        const token = await Cookies.get("jwt");
        setAuthToken(token);
        const response = await api.get(`/api/Chat/get-chat-by-taskid/${taskId}/${uid}`);
        console.log(response)
        if (response.data) {
          this.messages = response.data;
          this.groupedMessages = this.groupMessagesByDate(this.messages)

          if (response.data.length > 0) {
            // this.type=1
          }
          console.log(response.data);
          this.Isloading = false;
          // this.loadingTasks = "";
        }
        else {
          // alert(`Error: ${response.statusText}`);
        }
      } catch (error) {
        //alert("Internal server error." + error);
        console.log("Error: " + error);
      }
    },
    groupMessagesByDate(messages) {
        const groupedMessages = {};

        messages.forEach(message => {
          const date = new Date(message.Timestamp).toDateString();

          if (!groupedMessages[date]) {
            groupedMessages[date] = [];
          }
          groupedMessages[date].push(message);
        });

        return groupedMessages;
      },
    generateAndDownloadPDF() {
      // Access the content from refs
      // const chatContent = this.$refs.chatContainer1.$el.innerHTML;
      const taskcontent = document.getElementById('content1').innerHTML
      const messagecontent = document.getElementById('content2').innerHTML
      let htmlContent=''
      if (this.messages.length > 0) {
       htmlContent = `
          <!DOCTYPE html>
          <html lang="en">
          <head>
            <meta charset="UTF-8">
            <title>Task Document</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                margin: 15px;
              }
            </style>
          </head>
          <body>
            <div style="text-align: center; font-weight: bold; font-size: 22px; margin-bottom: 5px;">
              Task Detail
            </div>
            <hr style="margin: 1px; margin-bottom: 15px;">
            <div>
            ${taskcontent} 
            </div>
              <div style="text-align: center; font-weight: bold; font-size: 22px; margin-bottom: 5px;">
              Task Messages
            </div>
            <hr style="margin: 1px; margin-bottom: 15px;">
            <div style="margin: 1px; background:'yellow'">
            ${messagecontent}
            </div>
          </body>
          </html>
        `;
      }
      else {
         htmlContent = `
          <!DOCTYPE html>
          <html lang="en">
          <head>
            <meta charset="UTF-8">
            <title>Task Document</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                margin: 15px;
              }
            </style>
          </head>
          <body>
            <div style="text-align: center; font-weight: bold; font-size: 22px; margin-bottom: 5px;">
              Task Detail
            </div>
            <hr style="margin: 1px; margin-bottom: 15px;">
            <div>
            ${taskcontent} 
            </div>
          </body>
          </html>
        `;
      }
      // Open a new window
      let printWindow = window.open('', '_blank');

      // Check if the window opened successfully
      if (!printWindow) {
        console.error('Failed to open new window. Please allow pop-ups for this site.');
        return;
      }

      // Construct the HTML content to be printed
      // Write the HTML content to the new window
      printWindow.document.write(htmlContent);

      // Close the document after writing content
      printWindow.document.close();

      // Delay before printing to ensure content is fully loaded
      setTimeout(() => {
        // Print the document
        printWindow.print();

        // Close the print window
        printWindow.close();
      }, 1000); // Adjust delay time if necessary
    },
    formatAssignedDate(originalDate) {
      if (!originalDate) return ""; // Handle null or undefined dates
      const dateObject = new Date(originalDate);
      const hour = dateObject.getHours(-5).toString().padStart(2, "0");
      const minute = dateObject.getMinutes().toString().padStart(2, "0");
      const day = dateObject.getDate().toString().padStart(2, "0");
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObject.getFullYear();
      return `${day}/${month}/${year} ${hour}:${minute}`;
    },
    handleFileUpload(event) {
      this.newTask.file = event.target.files[0];
    },
    hideCommentsandFile() {
      this.newTask.Comments = "";
      this.file = "";
      this.StatusCompleted = false;
    },
    isImageURL(url) {
      const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp'];
      const extension = url.substring(url.lastIndexOf('.')).toLowerCase();
      if (imageExtensions.includes(extension)) {
        this.imageURLValid = true;
      }
      //return imageExtensions.includes(extension);
    },
    isUserImageURL(url) {
      const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp'];
      const extension = url.substring(url.lastIndexOf('.')).toLowerCase();
      if (imageExtensions.includes(extension)) {
        this.UserimageURLValid = true;
      }
      //return imageExtensions.includes(extension);
    },
    goBack() {
      // Go back to the previous page in the history
      this.$router.go(-1);
    },
    closeModal() {
      this.editMode = false;
      this.resetTaskValidation();
      document.getElementById("closeModal").click();
    },
    openEditStatusModal() {
      document.getElementById("openEditStatusModal").click();
    },
    async submitEditTaskStatusForm() {
      this.CompleteTask(this.newTask.TaskID, this.newTask.TaskStatus);
    },
    closeConfirmStatusModal() {
      document.getElementById("closeConfirmStatusModal").click();
    },
    funCheckStatus(StatusID) {
      let tStatus = "not assigned";
      if (StatusID == 2 && this.publicTask) {
        tStatus = "assigned";
      }
      else if (StatusID == 2 && !this.publicTask) {
        tStatus = "new task";
      } else if (StatusID == 3) {
        tStatus = "in process";
      } else if (StatusID == 4) {
        tStatus = "completed";
      }
      return tStatus;
    },
    async CompleteTask(taskId, status) {
      //console.log(status);
      this.Isloading = true;
      try {
        const token = await Cookies.get("jwt");
        setAuthToken(token);
        let userCookie = await Cookies.get("user");
        userCookie = await userCookie ? JSON.parse(userCookie) : null;
        const formData = new FormData();
        formData.append('TaskID', taskId);
        formData.append('UserID', userCookie.UserID);
        formData.append('Status', status);
        formData.append('TaskType', this.newTask.TaskType);
        formData.append('file', this.newTask.file);
        formData.append('Comments', this.newTask.Comments);
        formData.append('ProgressPercent', this.rangeValue);
        // Make your update API call here
        const response = await api.post('/api/tasks/complete-task', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        if (response.data.success) {
          this.fetchTaskDetails();
          this.closeConfirmStatusModal();
          let tStatus = "not assigned";
          if (status == 2 && this.publicTask) {
            tStatus = "assigned";
          } else if (status == 2 && !this.publicTask) {
            tStatus = "new task";
          } else if (status == 3) {
            tStatus = "in process";
          } else if (status == 4) {
            tStatus = "completed";
          }
          this.successAlertOnSubmit = `Task marked as ${tStatus} successfully.`;
        } else {
          this.tableHeader = response.data.msg + ".";
          //alert(response.data.msg);
        }

        // Optionally, perform additional actions after successful deletion
      } catch (error) {

        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.tableHeader = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.tableHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 574, error.stack, "UserPanel/TaskDetail/CompleteTask()");
          }
        }
        else {
          this.tableHeader = "Something went wrong.";
        }
      } finally {
        this.Isloading = false;
      }
    },
    async getCategoryDropdown() {
      try {
        this.Isloading = true;
        const token = await Cookies.get("jwt");
        setAuthToken(token);
        const response = await api.get("/api/tasks/get-category-dropdown");
        if (response.data) {
          console.log("category Options : "+response.data.toString())
          console.log("category Options : "+response)
          this.categoryDropdownOptions = response.data;
        }
        else {
          this.categoryDropdownOptions=[]
        }
      } catch (error) {
        // Handle API error
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.tableHeader = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.tableHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 612, error.stack, "UserPanel/TaskDetail/GetCategoryDropdown()");
          }
        }
        else {
          this.tableHeader = "Something went wrong.";
        }
      } finally {
        this.Isloading = false;
      }
    },
    async getPriorityDropdown() {
      try {
        this.Isloading = true;
        const token = await Cookies.get("jwt");
        setAuthToken(token);
        const response = await api.get("/api/tasks/get-priority-dropdown");
        if (response.data) {
          this.priorityDropdownOptions = response.data;
        } else {
          // Handle case where no priorities are found
        }
      } catch (error) {
        // Handle API error
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.tableHeader = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.tableHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 651, error.stack, "UserPanel/TaskDetail/GetPriorityDropdown()");
          }
        }
        else {
          this.tableHeader = "Something went wrong.";
        }

      } finally {
        this.Isloading = false;
      }
    },
    openTaskModal(refresh = false) {
      if (refresh) {
        this.editTaskMode = false;
        this.newSubTask = {
          SubTaskID: 0,
          Title: "",
          Description: "",
          UserId: 0,
          IsCompleted: false,
        };
        this.resetTaskValidation();
      }
    },
    openModal() {
      document.getElementById("openModal").click();
    },
    editTaskStatus(taskId) {
      this.editTaskMode = false;
      this.editTaskStatusMode = true;
      this.getTaskById(taskId);
    },
    async getTaskById(taskId) {
      try {
        const token = await Cookies.get("jwt");
        this.Isloading = true;
        setAuthToken(token);
        const response = await api.get(`/api/tasks/get-task-by-id/${taskId}`);
        if (response.data) {
          this.newTask = response.data.tasks[0];
          this.newTask.DueDate = this.formatDueDate(
            response.data.tasks[0].DueDate,
            "update"
          );
          console.log("this is task status: "+this.newTask.TaskStatus);
         this.rangeValue= this.newTask.ProgressPercent
          if(this.newTask.TaskStatus===3){
            this.InProcess=true
          }
          if (this.editTaskMode) {
            this.openModal();
          }
          if (this.editTaskStatusMode) {
            this.openEditStatusModal();
          }
          this.resetTaskValidation();
        } else {
          this.tableHeader = response.statusText + "."
          // alert(`Error: ${response.statusText}`);
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.tableHeader = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.tableHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 732, error.stack, "UserPanel/TaskDetail// GetTaskById()");
          }
        } else {
          this.tableHeader = "Something went wrong.";
        }
        //alert("Internal server error.");
      } finally {
        this.Isloading = false;
        setTimeout(() => {
          this.successAlertOnSubmit = "";
          this.tableHeader = "";
        }, 5000); // 5 seconds in milliseconds
      }
    },
    resetTaskValidation() {
      this.newSubTask = {
        SubTaskID: 0,
        Title: "",
        Description: "",
        UserId: 0,
        IsCompleted: false,
      };
    },
    truncateDescription(description) {
      // Truncate the description if it's longer than 30 characters
      return description.length > 30
        ? description.slice(0, 30) + "..."
        : description;
    },
    formatDueDate(originalDate, type = "show") {

      if (!originalDate) return ""; // Handle null or undefined dates

      const dateObject = new Date(originalDate);
      const day = dateObject.getDate().toString().padStart(2, "0");
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObject.getFullYear();
      if (type == "show") {
        return `${day}/${month}/${year}`;
      } else {
        return `${day}/${month}/${year}`;
      }
    },
    // Fetch task details based on taskId
    async fetchTaskDetails() {
      try {
        this.task = [];
        this.Isloading = true;
        this.subTasks = [];
        this.loading = false;
        const token = await Cookies.get("jwt");
        setAuthToken(token);
        const response = await api.get(
          `/api/tasks/get-task-by-id/${this.taskId}`
        );
        if (response.data) {
          this.task = response.data.tasks[0];
          console.log(response.data.tasks[0]);
          this.rangeValue = this.task.ProgressPercent;
          if (this.task.TaskType == "public") {
            this.publicTask = true;
          }
          if (this.task.InfoFile != null) {
            this.isImageURL(this.task.InfoFile);
          }
          if (this.task.UserAttachment != null) {
            this.isUserImageURL(this.task.UserAttachment);
          }
          this.task.DueDate = this.formatDueDate(
            response.data.tasks[0].DueDate,
            "update"
          );
          this.handleTaskId = response.data.tasks[0].TaskID;
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.tableHeader = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.tableHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 835, error.stack, "UserPanel/TaskDetail/fetchTaskDetails()");
          }
        }
        else {
          this.tableHeader = "Something went wrong.";
        }
      } finally {
        this.Isloading = false;
        setTimeout(() => {
          this.tableHeader = "";
          this.successAlertOnSubmit = "";
        }, 5000); // 5 seconds in milliseconds
        this.loading = true;
      }
    },
    hideTaskAlert() {
      this.successAlertOnSubmit = "";
    },
    isValidTaskForm() {
      let isValid = true;
      // Title
      if (!this.newSubTask.Title.trim()) {
        this.taskValidate.Title = "Title is required.";
        isValid = false;
      }

      // Description
      if (!this.newSubTask.Description.trim()) {
        this.taskValidate.Description = "Description is required.";
        isValid = false;
      }
      if (this.newTask.TaskStatus == 2) {
        this.Assigned = true;
      }
      if (this.newTask.TaskStatus == 3) {
        this.InProcess = true;
      }
      if (this.newTask.TaskStatus == 4) {
        this.StatusCompleted = true;
        this.rangeValue=100;
        this.InProcess = false;
      }
      else {
        this.StatusCompleted = false;
      }
      return isValid;
    },
    // get Unread chat count
    async getUnreadChatById(taskId) {
      try {
        this.Isloading = true;
        const token = await Cookies.get("jwt");
        let userCookie = await Cookies.get("user");
        userCookie = await userCookie ? JSON.parse(userCookie) : null;
        const uid = userCookie.UserID
        setAuthToken(token);
        const response = await api.get(
          `/api/Chat/get-unread-chat-count/${taskId}/${uid}`
        );
        if (response.data) {
          const result = response.data[0];
          console.log(result)
          this.unReadChatCount = response.data[0].UnReadChatCount;
          console.log(response.data)
          console.log(this.unReadChatCount);
        }
        else {
          // alert(`Error: ${response.statusText}`);
          this.tableHeader = "Something went wrong. please try again later.";
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.tableHeader = jsonObject.message || "Something went wrong.";
            }
            catch (parseError) {
              this.tableHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 925, error.stack, "UserPanel/TaskDetail/GetUnreadChatById()");
          }
        }
        else {
          this.tableHeader = "Something went wrong.";
        }
        // alert("Internal server error.");
      }
      finally {
        this.Isloading = false;
        setTimeout(() => {
          this.tableHeader = "";
          this.successAlertOnSubmit = "";
        }, 5000); // 5 seconds in milliseconds
        this.loading = true;
      }
    },
    viewTaskMessages(TaskID) {
      this.$router.push({
        path: `/user/chatmessages/${this.taskId}/${this.task.UserID}/`
      });
    },
    formatChatCount(count) {
      return count < 10 ? `0${count}` : count;
    },
  },
};
</script>

<style scoped>
/* Add Tailwind CSS classes here if needed */
.close-icon {
  cursor: pointer;
  float: right;
  font-size: 30px;
  margin-top: -10px;
}

.mb-3 {
  text-align: left;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1em;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.table th {
  background-color: #f2f2f2;
}

/* Additional styling for left and right padding */
.table th,
.table td {
  padding-left: 12px;
  padding-right: 12px;
}

.actionBtns {
  text-align: right !important;
}

.task-details-card {
  /* Add styling for the task details card */
  margin-top: 2rem;
}

.task-details-header {
  /* Add styling for the card header */
  background-color: #4caf50;
  /* Use your preferred color */
  color: #fff;
  padding: 1rem;
}

.task-details-body {
  /* Add styling for the card body */
  padding: 1rem;
}

.task-detail-row {
  /* Add styling for each detail row */
  display: -webkit-box !important;
  -webkit-box-pack: start;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.date-partition {
  display: inline-block;
  text-align: center;
  margin: 20px 0;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 20px;
  background-color: #f0f0f0; /* Adjust the background color as needed */
  color: #333; /* Adjust the text color as needed */
}

.date-partition:before,
.date-partition:after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 50px; /* Adjust the width as needed */
  height: 1px;
  background-color: #ccc; /* Adjust the line color as needed */
  margin: 0 10px;
}
.task-detail-label {
  /* Add styling for the label within a detail row */
  font-weight: bold;
  font-size: 16px;
}

.task-detail-value {
  font-size: 16px;
  margin-left: 10px;
}

.taskNotAssigned {
  color: orange;
}

.alreadyAssignedText {
  color: #ed6524;
  font-size: 15px;
  text-align: center;
}

.taskAssigned {
  color: #ed6524;
}

.taskInProcess {
  color: #864426;
}

.taskCompleted {
  color: green;
}

.user-list {
  min-height: 120px;
  max-height: 120px;
  /* Set a maximum height for the user list */
  overflow-y: auto;
  /* Add a scrollbar when the content exceeds the height */
  border: 1px solid #ccc;
  /* Optional: Add a border for styling */
}

.user-item {
  margin-bottom: 8px;
  /* Optional: Add some spacing between user items */
}

.progress {
  font-size: 13px !important;
}

.dropdown {
  width: 100%;
  height: 36px;
  padding: 7px 0;
  font-size: 14px;
  line-height: 1.428571429;
  border-color: #D2D2D2;
}

@media screen and (min-width:1020px) {
  #taskEditStatusModal .modal-dialog {
    width: 35%;
  }
}

.image-link {
  width: 45%;
  margin: 0px auto;
}

.file-link a {
  color: blue !important;
  font-weight: 500;
}

@media screen and (max-width:767px) {

  .col-md-4,
  .col-md-8,
  .col-md-6 {
    border-right: unset !important
  }
}

.modal-content .modal-header {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.modal-title {
  margin-top: 10px;
}

.modal-content .modal-body {
  padding-top: 10px;
}

.chat-box {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  background: white;
  box-sizing: border-box;
}

.message {
  padding: 6px;
}

.received {
  font-weight: bold;
  align-items: flex-start;
  color: #ffffff;
  text-align: left;
  background-color: #6b7076;
  border-radius: 10px;
  padding: 7px;
  max-width: 70%;
}

.sent {
  font-weight: bold;
  align-items: flex-end;
  text-align: right;
  color: #fff;
  background-color: #0084ff;
  border-radius: 10px;
  padding: 7px;
  max-width: 70%;
}

.messageHeader {
  font-weight: 500;
  font-size: 22px;
  margin: 2px;
  text-align: center;
}

.notification {
  border-radius: 100%;
  padding: 3px;
  text-align: center;
  font-size: 11px;
  color: white;
  background-color: red;
  width: 40px;
}
</style>
