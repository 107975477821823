<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-large-size-100 md-size-66">
        <form @submit.prevent="changePassword">
          <md-card>
            <md-card-header data-background-color="green">
              <h4 class="title">Change Password</h4>
              <!-- <p class="category">Change your password</p> -->
            </md-card-header>
            <md-card-content>
              <div v-if="formHeader" class="error-message text-red-500">
                {{ formHeader }}
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-33">
                  <md-field style="margin-bottom: 7px !important;">
                    <label>Current Password</label>
                    <md-input v-model="currentPassword" type="password" tabindex="1"></md-input>
                  </md-field>
                  <p v-if="errors.currentPassword" class="text-red-500 text-sm text-left mt-1">
                    {{ errors.currentPassword }}
                  </p>
                </div>
                <div class="md-layout-item md-small-size-100 md-size-33">
                  <md-field style="margin-bottom: 7px !important;">
                    <label>New Password</label>
                    <md-input v-model="newPassword" type="password" maxlength="15" tabindex="2"></md-input>
                  </md-field>
                  <p v-if="errors.newPassword" class="text-red-500 text-sm text-left mt-1">
                    {{ errors.newPassword }}
                  </p>
                </div>
                <div class="md-layout-item md-small-size-100 md-size-33">
                  <md-field style="margin-bottom: 7px !important;">
                    <label>Confirm Password</label>
                    <md-input v-model="confirmPassword" type="password" tabindex="3"></md-input>
                  </md-field>
                  <p v-if="errors.confirmPassword" class="text-red-500 text-sm text-left mt-1">
                    {{ errors.confirmPassword }}
                  </p>
                </div>

                <div id="message" class="my-4 mx-3">
                  <b>Note: </b><label>
                    Password must contains at least 8 characters, including 1 special character, 1 capital letter, and 1
                    number.
                  </label>
                </div>
                <div class="md-layout-item md-size-100 text-right">
                  <md-button type="button" title="Change Password" @click="showConfirmation()" class="md-raised md-success" tabindex="4">Change Password
                  </md-button>
                </div>
                <ConfirmDailogBox
                  v-if="confirmationVisible"
                  :message="'Are you sure you want change the password?'"
                  @confirmed="changePassword"
                  @canceled="hideConfirmation"
                />
              </div>
            </md-card-content>
          </md-card>
        </form>
      </div>
    </div>
  </div>
</template>
<script> 
import showAlert from "../Common/sweetAlert";
import ConfirmDailogBox from '../Common/confirmationbox.vue'
import api, { setAuthToken } from "../apiConfig";
import { authMixin } from "./SuperAdminStore";
import Cookies from "js-cookie";
import { ErrorLog } from "../Common/APILog";
import { handleAuthorization, handleServerError } from "../Common/commonMethod";

export default {
  mixins: [authMixin],
  created() {
    this.checkAuthorization(); // Check authorization when the component is created
  },
  name: "ChangePassword",
  components: {
    ConfirmDailogBox
  },
  data() {
    return {
      confirmationVisible: false,
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      errors: {},
      formHeader: "",
      name: "Change Password",
    };
  },
  computed: {
    userId() {
      return this.$store.getters.userId;
    },
    userToken() {
      return this.$store.getters.userToken;
    },
  },
  mounted() {

  },
  methods: {
    async performChangePassword() {
      try {
        let userCookie = Cookies.get("user");
        userCookie = userCookie ? JSON.parse(userCookie) : null;
        const uId = userCookie.UserID;
        const authToken = Cookies.get("jwt");
        setAuthToken(authToken);
        const response = await api.post("/api/superAdmin/admin-change-password", {
          userId: uId,
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
        });
        // Assuming the API response structure includes a 'success' property
        if (response.data.success) {
          // Password change was successful
          setAuthToken();
          showAlert("success", "Password has been changed successfully.", false,7000)
          this.$router.push("/adminLogin");
        } else {
          // Password change failed, set error message
          this.formHeader = response.data.message || "Unable to change the";
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.status === 401) {
            handleAuthorization("/login")
          }
          else if (error.response.status === 500) {
            handleServerError("/login")
          }
          else {
            const jsonResponseString = JSON.stringify(error.response.data);
            try {
              const jsonObject = JSON.parse(jsonResponseString);
              this.formHeader = jsonObject.message || "Something went wrong.";
            } catch (parseError) {
              this.formHeader = parseError.message || "Something went wrong.";
            }
            ErrorLog(error.message, error.response.status, 147, error.stack, "SuperAdminPanel/ChangePassword/performChangePassword");
          }
        }
        else {
          this.formHeader = "Something went wrong.";
        }
      }
    },
    showConfirmation() {
      this.confirmationVisible = true;
    },
    hideConfirmation() {
      this.confirmationVisible = false;
    },
    
    changePassword() {
      this.formHeader = "";
      // Validation logic
      this.errors = {};

      if (!this.currentPassword) {
        this.errors.currentPassword = "Current Password is required.";
      }
      if (!this.newPassword) {
        this.errors.newPassword = "New Password is required.";
      }
      if (this.newPassword.length < 8 && this.newPassword) {
        this.errors.newPassword = "New Password must be minimum of 8 characters.";
      }
      else if (this.newPassword.length >= 8) {
        var lowerCaseLetters = /[a-z]/g;
        var upperCaseLetters = /[A-Z]/g;
        var number = /[0-9]/g;
        var special = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/;
        if (!this.newPassword.match(lowerCaseLetters)) {
          this.errors.newPassword = "New Password must contain lowercase letter.";
        }
        if (!this.newPassword.match(upperCaseLetters)) {
          this.errors.newPassword = "New Password must contain capital letter.";
        }
        if (!this.newPassword.match(number)) {
          this.errors.newPassword = "New Password must contain number.";
        }
        if (!this.newPassword.match(special)) {
          this.errors.newPassword = "New Password must contain special character.";
        }
      }
      if (!this.confirmPassword) {
        this.errors.confirmPassword = "Confirm Password is required.";
      } else if (this.confirmPassword !== this.newPassword) {
        this.errors.confirmPassword = "Confirmed Password didn't match with the new password.";
      }
      this.hideConfirmation()
      // If there are no validation errors, you can proceed with the change password logic
      if (Object.keys(this.errors).length === 0) {
        this.performChangePassword();
        this.hideConfirmation()
      }
    },
  },
};
</script>

<style scoped>
/* Add Tailwind CSS classes here if needed */
#message span {
  display: block;
}
</style>