import { render, staticRenderFns } from "./confirmationbox.vue?vue&type=template&id=5be0919c&scoped=true"
import script from "./confirmationbox.vue?vue&type=script&lang=js"
export * from "./confirmationbox.vue?vue&type=script&lang=js"
import style0 from "./confirmationbox.vue?vue&type=style&index=0&id=5be0919c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5be0919c",
  null
  
)

export default component.exports